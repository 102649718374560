import {  Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import React, { useState, useEffect } from 'react';
import {Button} from '@mui/material';
import { Card } from '@mui/material';
import { CardMedia, CardContent, Typography, CardActions } from "@mui/material";
import axios from 'axios';
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from 'chart.js';
import { Pie } from 'react-chartjs-2';






export default function InstagramPostAnalyticsModal(props) {

const [post_analytics, setPost_analytics] = useState([
    {
      "name": "Post Reactions Like Total",
      "value": 0
    },
    {
      "name": "Post Reactions Love Total",
      "value": 0
    },
    {
      "name": "Post Reactions Wow Total",
      "value": 0
    },
    {
      "name": "Post Engaged Users",
      "value": 2
    },
    {
      "name": "Post Impressions",
      "value": 1
    },
    {
      "name": "Post Impressions Unique",
      "value": 1
    },
    {
      "name": "Post Impressions Fan",
      "value": 0
    },
    {
      "name": "Post Impressions Organic",
      "value": 1
    },
    {
      "name": "Post Impressions Paid",
      "value": 0
    },
    {
      "name": "Avg Sentiment Score",
      "value": -0.33
    }
  ])


const getPostAnalytics = async () => {
  let userId = JSON.parse(localStorage.getItem("userProfileInfo"))?._id;
    const data = await axios.post(process.env.REACT_APP_SERVER_URI + `/api/toowe/users/${userId}/deepAnalytics/instagramPostsAnalytics`, {
        postId: props.selectedPostAnalyticsId,
        pageId: props.SelectedPageId,
        jwToken: sessionStorage?.getItem("token"),

    })
    console.log(data?.data)

    setPost_analytics(data?.data)

}

useEffect(() => {
    getPostAnalytics()
}, [props.selectedPostAnalyticsId])



    const [modal, setModal] = useState(props.isPostAnalytics);
    useEffect(() => {
        setModal(props.isPostAnalytics)
    }, [props.isPostAnalytics])

    const toggle = () =>{ 
        setModal(!modal) 
         props.setIsPostAnalytics(false)
        };








    return(
        <div>
         {/* <Button color="danger" onClick={toggle}>
        Click Me
      </Button> */}
      <Modal isOpen={modal} toggle={toggle} fullscreen >
        <ModalHeader toggle={toggle}>Post Analytics [{props.selectedPostAnalyticsId}] <Button size="small"  href={`https://facebook.com/`+ props.selectedPostAnalyticsId}  /* `target='_blank'` is an attribute used in HTML to open a link in a new browser tab or window when clicked. In this code, it is used in the `Button` component to open the Facebook post in a new tab when the user clicks on it. */
        target='_blank'>view post</Button>
 </ModalHeader>
        <ModalBody>


        {/* <Card className="fbAnalyticsCard" style={{marginTop:'20px', padding:'30px', display:'flex', flexDirection:'row', flexWrap:'wrap'}}> */}

        {/* <Card sx={{ maxWidth: 450 }} style={{width:'350px', margin:'10px'}}>
        <Pie data={data} />
        {
           post_analytics[0]?.value+ post_analytics[1]?.value+ post_analytics[2]?.value == 0 ?
                 <div><b> No data available</b></div> : null
            

        }
        </Card> */}

       

        {/* </Card> */}

            {/* {
                props.selectedPostAnalyticsId
            } */}



{/* {
    post_analytics.map((item, index) => {
        return <div> {item?.name}</div>

    })
} */}


<Card className="fbAnalyticsCard" style={{marginTop:'20px', padding:'30px', display:'flex', flexDirection:'row', flexWrap:'wrap'}}>

{
    post_analytics.length > 0 &&
    post_analytics?.map((item, index) => {
return(
        <Card sx={{ maxWidth: 450 }} style={{width:'350px', margin:'10px'}}>
     <Typography variant="h5" style={{padding:'5px',textAlign:'center', alignContent:'center'}}>
         {item?.name}
        </Typography>

      <CardContent>
        <Typography  variant="h2" component="div" style={{textAlign:'center', alignContent:'center'}}>
         { item.values &&  item?.values[0]?.value}
      
        </Typography>
        <Typography variant="body2" color="text.secondary">
        
        </Typography>
      </CardContent>
      {/* <CardActions style={{display:'flex', 'justifyContent':'space-between'}}>

<Button size="small" href={`https://facebook.com/`+ Most_positive_and_negative_post?.most_negative_post?.post_data?.id}>view post</Button>
<Button size="small" onClick={()=>openPostAnalyticsModal(Most_positive_and_negative_post?.most_negative_post?.post_data?.id)}>See analytics</Button>
</CardActions> */}
    </Card>
)


    })
}

{
    post_analytics.length > 0 &&
    post_analytics.forEach((item, index) => {
       return <div> {item?.name}</div>

    })
}





</Card>



       
       

       
       
       
       
       </ModalBody>
        {/* <ModalFooter>
          <Button color="primary" onClick={toggle}>
            Do Something
          </Button>{' '}
          <Button color="secondary" onClick={toggle}>
            Cancel
          </Button>
        </ModalFooter> */}
      </Modal>
        </div>
    )}