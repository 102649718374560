import React, { useEffect, useState } from "react";
import "./NewSideBarUI.css";
import "../SideBarLayout/SideBarLayout.css";
import app_logo from "../../assets/app_logo.png";
import Logout from "../../assets/logout.png";
import streams_icon_active from "../../assets/streams_icon_active.png";
import stream_icon from "../../assets/streams_icon.png";
import add_network from "../../assets/add_network.png";
import create_post from "../../assets/create_post.png";
import analytics from "../../assets/analytics.png";
import analytics_active from "../../assets/analytics_active.png";
import profile_pic from "../../assets/profile_pic.png";
import calander from "../../assets/calendar_.png";
import calender_active from "../../assets/calendar_active.png";
import backlink from "../../assets/backlink.png";
import backlink_active from "../../assets/backlink_active.png";
import metadsicon from "../../assets/ads_icn.png";
import metadsicon_active from "../../assets/ads_icn_hover.png";

import adslibicon from "../../assets/ads_library.png";
import adslibicon_active from "../../assets/ads_library_active.png";

import aiGenerator from "../../assets/aiGenerator.png";
import aiGenerator_active from "../../assets/aiGenerator_active.png";

import email from "../../assets/inbox_.png";
import email_active from "../../assets/inbox_active.png";
import message from "../../assets/mail_verifier.png";
import message_active from "../../assets/mail_verifier_active.png";
import competitor_Active from "../../assets/competitor_icon_active.png";
import competitor_Inactive from "../../assets/competitor_icon_inactive.png";

import AuthAPI from "../../Utils/AuthAPI";
import { useNavigate, useLocation } from "react-router-dom";
import { useDispatch } from "react-redux";
import {
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from "reactstrap";

import {
  showCreateBoardMenu,
  showCreatePinMenu,
  showcreatePostMenu,
} from "../../Redux/Actions/CreateNewPostAction";

function NewSideBarUI(props) {
  const authAPI = React.useContext(AuthAPI);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const handleClickCreatePost = () => {
    //dispatch(showcreatePostMenu());
    navigate("/createpost");
  };

  const handleClickCreatePin = () => {
    console.log("clicked");
    dispatch(showCreatePinMenu());
  };

  const handleClickCreateBoard = () => {
    dispatch(showCreateBoardMenu());
  };

  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [daysLeft, setDaysLeft] = useState(null);

  const toggle = () => setDropdownOpen((prevState) => !prevState);

//   useEffect(() => {
//     const expiryDate = JSON.parse(localStorage.getItem('userProfileInfo')).planExpiry;

//     const RemaningDays=() =>{
// let days = Math.floor(( expiryDate - Date.now()) / (24*60*60*1000))
// setDaysLeft(days)
//     }

//     RemaningDays();
//     if(daysLeft <= 0){
//       navigate('/upgrade')
//     }

//   }, [window.location.pathname, daysLeft])

  

  const logout = () => {
    localStorage.clear();
    sessionStorage.clear();
    navigate("/login");
  };

  return (
    <div className="layoutMainContainer">
      <div className="newSideBarContainer">
        <div className="sideBarMainArea">
          <div className="logoContainer">
            <img
              src={app_logo}
              alt="logo"
              onClick={(e) => {
                e.preventDefault();
                navigate("/");
              }}
            />
          </div>
          <div className="sideBarBtns">
            <div
              className="streamsBtn"
              onClick={(e) => {
                e.preventDefault();
                navigate("/");
              }}
            >
              <img
                className="iconBtnWidth"
                src={
                  window.location.pathname === "/dashboard"
                    ? streams_icon_active
                    : stream_icon
                }
                alt="streams icon active"
                title="Dashboard"
              />
            </div>
            <div
              className="addNetworkBtn iconBtnMargin"
              onClick={(e) => {
                e.preventDefault();
                navigate("/link-account");
              }}
            >
              <img
                className="iconBtnWidth"
                src={add_network}
                alt="add network"
                title="Add Network"
              />
            </div>
            {/* <div
              className="createPostBtn iconBtnMargin"
              onClick={handleClickCreatePost}
            >
              <img
                className="iconBtnWidth"
                src={create_post}
                alt="create post"
                title="Create New Post"
              />
            </div> */}
            <Dropdown
              isOpen={dropdownOpen}
              toggle={toggle}
              direction="end"
              style={{
                backgroundColor: "none",
                borderStyle: "none",
                marginTop: "8px",
                marginBottom: "8px",
              }}
            >
              <DropdownToggle
                style={{
                  backgroundColor: "#efefef",
                  // backgroundColor: "red",
                  borderStyle: "none",
                }}
              >
                <img
                  className="iconBtnWidth"
                  src={create_post}
                  alt="create post"
                  title="Create New Post"
                />
              </DropdownToggle>
              <DropdownMenu
                className="drop_list__"
                style={{
                  marginLeft: "10px",
                }}
              >
                <DropdownItem onClick={handleClickCreatePost}>
                  Create Post
                </DropdownItem>
                {/* <DropdownItem onClick={handleClickCreatePin}>
                  Create Pin
                </DropdownItem> */}
                <DropdownItem onClick={handleClickCreateBoard}>
                  Create Board
                </DropdownItem>
              </DropdownMenu>
            </Dropdown>
            <div className="createPost-Menu">
              <p>CREATE</p>
              <span className="sidebar_options">
                <p onClick={handleClickCreatePost}>
                  <svg
                    width="16"
                    height="16"
                    viewBox="0 0 20 20"
                    fill="#2f3638"
                  >
                    <path d="M18.75,11.25 C19.5,11.25 20,11.75 20,12.5 L20,18.75 C20,19.5 19.5,20 18.75,20 L1.25,20 C0.5,20 0,19.5 0,18.75 L0,1.25 C0,0.5 0.5,0 1.25,0 L7.5,0 C8.25,0 8.75,0.5 8.75,1.25 C8.75,2 8.25,2.5 7.5,2.5 L2.5,2.5 L2.5,17.5 L17.5,17.5 L17.5,12.5 C17.5,11.75 18,11.25 18.75,11.25 Z M17.62,0.37 L19.62,2.37 C20.12,2.87 20.12,3.62 19.62,4.12 L11.25,12.5 L7.5,12.5 L7.5,8.75 L15.87,0.37 C16.37,-0.12 17.12,-0.12 17.62,0.37 Z"></path>
                  </svg>{" "}
                  Create Post
                </p>
                <p onClick={handleClickCreatePin}>
                  <svg
                    width="16"
                    height="16"
                    viewBox="0 0 20 20"
                    fill="#2f3638"
                  >
                    <path d="M10,0 C4.5,0 0,4.5 0,10 C0,14.25 2.62,17.87 6.37,19.25 C6.25,18.5 6.25,17.25 6.37,16.37 C6.5,15.62 7.5,11.37 7.5,11.37 C7.5,11.37 7.25,10.87 7.25,10 C7.25,8.62 8.12,7.5 9.12,7.5 C10,7.5 10.37,8.12 10.37,8.87 C10.37,9.75 9.87,11 9.5,12.25 C9.25,13.25 10,14 11,14 C12.75,14 14.12,12.12 14.12,9.37 C14.12,7 12.37,5.25 10,5.25 C7.12,5.25 5.5,7.37 5.5,9.62 C5.5,10.5 5.87,11.37 6.25,11.87 C6.25,12.12 6.25,12.25 6.25,12.37 C6.12,12.75 6,13.37 6,13.5 C6,13.62 5.87,13.75 5.62,13.62 C4.37,13 3.62,11.25 3.62,9.75 C3.62,6.62 5.87,3.75 10.25,3.75 C13.75,3.75 16.37,6.25 16.37,9.5 C16.37,13 14.25,15.75 11.12,15.75 C10.12,15.75 9.12,15.25 8.87,14.62 C8.87,14.62 8.37,16.5 8.25,17 C8,17.87 7.37,19 7,19.62 C8,19.87 9,20 10,20 C15.5,20 20,15.5 20,10 C20,4.5 15.5,0 10,0 Z"></path>
                  </svg>{" "}
                  Create Pin
                </p>
                <p onClick={handleClickCreateBoard}>
                  <svg
                    width="16"
                    height="16"
                    viewBox="0 0 20 20"
                    fill="#2f3638"
                  >
                    <path d="M10,0 C4.5,0 0,4.5 0,10 C0,14.25 2.62,17.87 6.37,19.25 C6.25,18.5 6.25,17.25 6.37,16.37 C6.5,15.62 7.5,11.37 7.5,11.37 C7.5,11.37 7.25,10.87 7.25,10 C7.25,8.62 8.12,7.5 9.12,7.5 C10,7.5 10.37,8.12 10.37,8.87 C10.37,9.75 9.87,11 9.5,12.25 C9.25,13.25 10,14 11,14 C12.75,14 14.12,12.12 14.12,9.37 C14.12,7 12.37,5.25 10,5.25 C7.12,5.25 5.5,7.37 5.5,9.62 C5.5,10.5 5.87,11.37 6.25,11.87 C6.25,12.12 6.25,12.25 6.25,12.37 C6.12,12.75 6,13.37 6,13.5 C6,13.62 5.87,13.75 5.62,13.62 C4.37,13 3.62,11.25 3.62,9.75 C3.62,6.62 5.87,3.75 10.25,3.75 C13.75,3.75 16.37,6.25 16.37,9.5 C16.37,13 14.25,15.75 11.12,15.75 C10.12,15.75 9.12,15.25 8.87,14.62 C8.87,14.62 8.37,16.5 8.25,17 C8,17.87 7.37,19 7,19.62 C8,19.87 9,20 10,20 C15.5,20 20,15.5 20,10 C20,4.5 15.5,0 10,0 Z"></path>
                  </svg>{" "}
                  Create Board
                </p>
                <p>
                  <svg
                    width="16"
                    height="16"
                    viewBox="0 0 20 20"
                    fill="#2f3638"
                  >
                    <path d="M15,20 L5,20 C2.43,20 0,17.57 0,15 L0,5 C0,2.43 2.43,0 5,0 L15,0 C17.57,0 20,2.43 20,5 L20,15 C20,17.57 17.57,20 15,20 Z M5,2.5 C3.83,2.5 2.5,3.83 2.5,5 L2.5,15 C2.5,16.19 3.8,17.5 5,17.5 L15,17.5 C16.16,17.5 17.5,16.16 17.5,15 L17.5,5 C17.5,3.83 16.16,2.5 15,2.5 L5,2.5 Z M10,15 C7.24,15 5,12.75 5,10 C5,7.24 7.24,5 10,5 C12.75,5 15,7.24 15,10 C15,12.75 12.75,15 10,15 Z M10,7.5 C8.62,7.5 7.5,8.62 7.5,10 C7.5,11.37 8.62,12.5 10,12.5 C11.37,12.5 12.5,11.37 12.5,10 C12.5,8.62 11.37,7.5 10,7.5 Z M15.18125,6.06 C14.51,6.06 13.98,5.52 13.98,4.86 C13.98,4.2 14.51,3.66 15.18,3.66 C15.84,3.66 16.38,4.2 16.38,4.86 C16.38,5.52 15.84,6.06 15.18,6.06 Z"></path>
                  </svg>{" "}
                  Instagram Story
                </p>
              </span>
            </div>
            <div
              className="analyticBTn iconBtnMargin"
              onClick={(e) => {
                e.preventDefault();
                navigate("/demoanalytics");
              }}
            >
              <img
                className="iconBtnWidth"
                src={
                  window.location.pathname === "/demoanalytics"
                    ? analytics_active
                    : analytics
                }
                alt="analytics"
                title="Analytics"
              />
            </div>

            <div
              className="calanderBTn iconBtnMargin"
              onClick={(e) => {
                e.preventDefault();
                navigate("/calendar");
              }}
            >
              <img
                className="iconBtnWidth"
                src={
                  window.location.pathname === "/calendar"
                    ? calender_active
                    : calander
                }
                alt="Calendar"
                title="Calendar"
              />
            </div>
            <div
              className="calanderBTn iconBtnMargin"
              onClick={(e) => {
                e.preventDefault();
                navigate("/email-verification");
              }}
            >
              <img
                className="iconBtnWidth"
                src={
                  window.location.pathname === "/email-verification"
                    ? email_active
                    : email
                }
                alt="email-verification"
                title="Emails Verifier"
              />
            </div>
            <div
              className="calanderBTn iconBtnMargin"
              onClick={(e) => {
                e.preventDefault();
                navigate("/message");
              }}
            >
              <img
                className="iconBtnWidth"
                data-tooltip="Inbox"
                src={
                  window.location.pathname === "/message"
                    ? message_active
                    : message
                }
                alt="message"
                title="Inbox"
              />
            </div>

            <div
              className="analyticBTn iconBtnMargin"
              onClick={(e) => {
                e.preventDefault();
                navigate("/CompetitorAnalysis");
              }}
            >
              <img
                className="iconBtnWidth"
                src={
                  window.location.pathname === "/CompetitorAnalysis"
                    ? competitor_Active
                    : competitor_Inactive
                }
                alt="competitor analysis"
                title="Competitor Analysis"
              />
            </div>


            <div
              className="calanderBTn iconBtnMargin"
              onClick={(e) => {
                e.preventDefault();
                navigate("/ads");
              }}
            >
              <img
                className="iconBtnWidth"
                src={
                  window.location.pathname === "/ads"
                    ? metadsicon_active
                    : metadsicon
                }
                alt="Meta Ads"
                title="Meta Ads"
              />
            </div> 




            <div
              className="calanderBTn iconBtnMargin"
              onClick={(e) => {
                e.preventDefault();
                navigate("/adsLibrary");
              }}
            >
              <img
                className="iconBtnWidth"
                src={
                  window.location.pathname === "/adsLibrary"
                    ? adslibicon_active
                    : adslibicon
                }
                alt="Ads Library"
                title="Ads Library"
              />
            </div> 


            {/* <div
              className="calanderBTn iconBtnMargin"
              onClick={(e) => {
                e.preventDefault();
                navigate("/postGenerator");
              }}
            >
              <img
                className="iconBtnWidth"
                src={
                  window.location.pathname === "/postGenerator"
                    ? aiGenerator_active
                    : aiGenerator
                }
                alt="postGenerator"
                title="postGenerator"
              />
            </div> */}

          </div>
        </div>

        <div className="profileBtnSection">
          <button
          className="upgradenavButton"
            onClick={(e) => {
              e.preventDefault();
              navigate("/upgrade");
            }}
          >
            UPGRADE
          </button>

          <img
            src={profile_pic}
            onClick={() => {
              navigate(`/user/${props.userDetails._id}`);
            }}
            alt="profile pic"
            title="My Profile"
            style={{ cursor: "pointer" }}
          />

          <img
            src={Logout}
            alt="Logout"
            title="Logout"
            style={{ height: "auto", width: "23px", cursor: "pointer" }}
            onClick={logout}
          />
        </div>
      </div>
      {props.children}
    </div>
  );
}

export default NewSideBarUI;
