import React, { useEffect } from "react";
import CustomDropDown from "../../../Components/CustomDropDown/CustomDropDown";
import "./NewAnalyticsPage.css";
import refreshIcon from "../../../assets/refreshIcon.png";
import calendarIcon from "../../../assets/calendarIcon.png";
import { useState } from "react";
import { Bar, Doughnut, Line, Pie } from 'react-chartjs-2';
// import { LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from 'recharts';

import { Container, Row, Col, Card, CardHeader, CardBody } from 'reactstrap';
import { MapContainer, TileLayer, GeoJSON } from 'react-leaflet';
import 'leaflet/dist/leaflet.css';



import "flatpickr/dist/themes/light.css";
import Flatpickr from "react-flatpickr";

import { Props } from "react-select";
import { useDispatch, useSelector } from "react-redux";
import CreateNewPost from "../CreateNewPost";
import CreatePin from "../CreatePin";
import CreateBoard from "../CreateBoard";
import { useNavigate } from "react-router";
import InteractiveMap from "./InteractiveMap";
import axios from "axios";
import { MoonLoader } from "react-spinners";
import {BsFillArrowUpCircleFill,BsFillArrowDownCircleFill, BsGoogle} from 'react-icons/bs';


function GoogleAnalytics(props) {
  const [selectedForm, setSelected] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  console.log("props", props);
  const isCreateNewPostMenu = useSelector(
    (state) => state.createNewPost.showCreatePostMenu
  );

  const isCreateNewPinMenu = useSelector(
    (state) => state.createNewPin.showCreatePinMenu
  );

  const isCreateNewBoardMenu = useSelector(
    (state) => state.createNewBoard.showCreateBoardMenu
  );

  const makeItZero = (ll) => {
    if(ll === null){
      return 0
    }else{
      return ll
    }
  
  }   


  const [isActive, setIsActive] = useState(false);
  const [isActiveSocials, setIsActiveSocials] = useState(false);
  const [allGoogleAnalyticsAccounts, setAllGoogleAnalyticsAccounts] = useState();
  const [selectedAccount, setSelectedAccount] = useState();
  let userID = JSON.parse(localStorage.getItem("userProfileInfo"))?._id;
  const [userId, setUserId] = useState(userID);


  
  

  useEffect(() => {
    let allGoogleAnalytics = [];
    for (let i = 0; i < props.pagesDetails.pagesDetails.length; i++) {
        if (props.pagesDetails.pagesDetails[i].account_type === "googleAnalytics") {
            allGoogleAnalytics.push(props.pagesDetails.pagesDetails[i].name + " - " + props.pagesDetails.pagesDetails[i].pageId);
        }
    }
    setAllGoogleAnalyticsAccounts(allGoogleAnalytics);
    setSelectedAccount(allGoogleAnalytics[0]);
}, [props.pagesDetails]);




  const [data, setData] = useState( {
    "Countries": {
        "Pakistan": 59,
        "India": 48,
        "United States": 32,
        "Bangladesh": 3,
        "Canada": 2,
        "Indonesia": 1,
        "Ireland": 1
    },
    "DeviceCategory": {
        "desktop": 130,
        "mobile": 16
    },
    "Browser": {
        "Chrome": 135,
        "Safari (in-app)": 2,
        "Edge": 6,
        "Samsung Internet": 1,
        "(not set)": 1,
        "Android Webview": 1
    },
    "OperatingSystem": {
        "Windows": 120,
        "Android": 13,
        "Linux": 5,
        "iOS": 3,
        "Macintosh": 1,
        "Chrome OS": 4
    },
    "dat": [
        {
            "date": "2023-07-20",
            "activeUsers": 40,
            "screenPageViews": 51,
            "newUsers": 20,
            "sessions": 41,
            "averageSessionDuration": 182.45708830555557,
            "bounceRate": 0.6851851851851851,
            "eventCount": 173
        },
        {
            "date": "2023-07-21",
            "activeUsers": 72,
            "screenPageViews": 113,
            "newUsers": 32,
            "sessions": 84,
            "averageSessionDuration": 100.87326643256303,
            "bounceRate": 0.8205882352941176,
            "eventCount": 347
        },
        {
            "date": "2023-07-22",
            "activeUsers": 11,
            "screenPageViews": 10,
            "newUsers": 5,
            "sessions": 11,
            "averageSessionDuration": 142.53264887999998,
            "bounceRate": 0.6,
            "eventCount": 37
        },
        {
            "date": "2023-07-23",
            "activeUsers": 18,
            "screenPageViews": 25,
            "newUsers": 6,
            "sessions": 21,
            "averageSessionDuration": 79.04698926851852,
            "bounceRate": 0.7222222222222222,
            "eventCount": 77
        },
        {
            "date": "2023-07-24",
            "activeUsers": 53,
            "screenPageViews": 73,
            "newUsers": 25,
            "sessions": 61,
            "averageSessionDuration": 193.16776318964648,
            "bounceRate": 0.7267676767676768,
            "eventCount": 237
        },
        {
            "date": "2023-07-25",
            "activeUsers": 37,
            "screenPageViews": 58,
            "newUsers": 12,
            "sessions": 46,
            "averageSessionDuration": 178.3634792049145,
            "bounceRate": 0.7542735042735043,
            "eventCount": 169
        },
        {
            "date": "2023-07-26",
            "activeUsers": 33,
            "screenPageViews": 39,
            "newUsers": 15,
            "sessions": 41,
            "averageSessionDuration": 57.45604665,
            "bounceRate": 0.663888888888889,
            "eventCount": 133
        },
        {
            "date": "2023-07-27",
            "activeUsers": 22,
            "screenPageViews": 33,
            "newUsers": 8,
            "sessions": 31,
            "averageSessionDuration": 39.46664971130953,
            "bounceRate": 0.7410714285714286,
            "eventCount": 106
        },
        {
            "date": "2023-07-28",
            "activeUsers": 14,
            "screenPageViews": 25,
            "newUsers": 6,
            "sessions": 15,
            "averageSessionDuration": 49.7503059375,
            "bounceRate": 0.6458333333333334,
            "eventCount": 67
        },
        {
            "date": "2023-07-29",
            "activeUsers": 11,
            "screenPageViews": 15,
            "newUsers": 2,
            "sessions": 11,
            "averageSessionDuration": 23.984696849999995,
            "bounceRate": 0.8,
            "eventCount": 41
        },
        {
            "date": "2023-07-30",
            "activeUsers": 7,
            "screenPageViews": 9,
            "newUsers": 3,
            "sessions": 9,
            "averageSessionDuration": 60.91278721428571,
            "bounceRate": 0.7857142857142857,
            "eventCount": 33
        },
        {
            "date": "2023-07-31",
            "activeUsers": 20,
            "screenPageViews": 32,
            "newUsers": 10,
            "sessions": 26,
            "averageSessionDuration": 203.45427090952376,
            "bounceRate": 0.619047619047619,
            "eventCount": 99
        }
    ],
    "TotalSums": [
        {
            "name": "Active Users",
            "value": 338
        },
        {
            "name": "Screen Page Views",
            "value": 483
        },
        {
            "name": "New Users",
            "value": 144
        },
        {
            "name": "Sessions",
            "value": 397
        },
        {
            "name": "Avg Session Duration",
            "value": 1311.4659925538172
        },
        {
            "name": "Bounce Rate",
            "value": 8.564592379298261
        },
        {
            "name": "Event Count",
            "value": 1519
        }
    ],
    "respToCompare": [
        {
            "name": "Active Users",
            "value": 228
        },
        {
            "name": "Screen Page Views",
            "value": 340
        },
        {
            "name": "New Users",
            "value": 87
        },
        {
            "name": "sessions",
            "value": 329
        },
        {
            "name": "avg Session Duration",
            "value": 1159.6998807173131
        },
        {
            "name": "Bounce Rate",
            "value": 8.85915748116406
        },
        {
            "name": "Event Count",
            "value": 1045
        }
    ],
    "comparedData": [
        {
            "name": "Active Users",
            "value": 338,
            "IncreaseDecrease": 48.24561403508772
        },
        {
            "name": "Screen Page Views",
            "value": 483,
            "IncreaseDecrease": 42.05882352941177
        },
        {
            "name": "New Users",
            "value": 144,
            "IncreaseDecrease": 65.51724137931035
        },
        {
            "name": "sessions",
            "value": 397,
            "IncreaseDecrease": 20.66869300911854
        },
        {
            "name": "avg Session Duration",
            "value": 1311.4659925538172,
            "IncreaseDecrease": 13.086671332813419
        },
        {
            "name": "Bounce Rate",
            "value": 8.564592379298261,
            "IncreaseDecrease": -3.32497872954724
        },
        {
            "name": "Event Count",
            "value": 1519,
            "IncreaseDecrease": 45.35885167464115
        }
    ],
    "PagePath": {
        "/": 53,
        "/privacy-policy.html": 14,
        "/about.html": 16,
        "/terms.html": 9,
        "/features.html": 17,
        "/support.html": 16,
        "/pricing.html": 6,
        "/index.html": 8,
        "/email-verifier.html": 1,
        "/blog/5-toowe-features-that-will-save-you-time-boost-your-productivity.html": 1,
        "/blog/future-of-social-media-management.html": 2,
        "/blog/toowe-AI-the-ultimate-tool-for-smarter-efficient-social-media-management.html": 1,
        "/refund-policy.html": 2
    },
    "firstUserSource": {
      "(direct)": 47,
      "google": 8,
      "businesstomark.com": 1,
      "moralstory.org": 5,
      "timebusinessnews.com": 2,
      "designerwomen.co.uk": 1,
      "linkedin.com": 1
  }
});

const [ecommData, setecommData] = useState([
  {
      "newDates": [
          "2023-08-05",
          "2023-08-06",
          "2023-08-07",
          "2023-08-08",
          "2023-08-09",
          "2023-08-10"
      ],
      "newAdCostPerConversion": [
          0,
          0,
          0,
          0,
          0,
          0
      ],
      "newPurchaseRevenuePerPayingUser": [
          0,
          0,
          0,
          0,
          0,
          0
      ],
      "newAverageRevenuePerUser": [
          0,
          0,
          0,
          0,
          0,
          0
      ],
      "newCartToViewRate": [
          0,
          0,
          0,
          0,
          0,
          0
      ],
      "newConversions": [
          0,
          0,
          0,
          0,
          0,
          0
      ],
      "newEcommercePurchases": [
          0,
          0,
          0,
          0,
          0,
          0
      ],
      "newTotalPurchasers": [
          0,
          0,
          0,
          0,
          0,
          0
      ],
      "newTotalRevenue": [
          0,
          0,
          0,
          0,
          0,
          0
      ]
  },
  [
      {
          "name": "Total Revenue",
          "value": 0,
          "IncreaseDecrease": null
      },
      {
          "name": "Total Purchasers",
          "value": 0,
          "IncreaseDecrease": null
      },
      {
          "name": "Ecommerce Purchases",
          "value": 0,
          "IncreaseDecrease": null
      },
      {
          "name": "Conversions",
          "value": 0,
          "IncreaseDecrease": null
      }
  ]
]);

const [dataCompare, setDataCompare] = useState()
const [ecommDataCompare, setecommDataCompare] = useState()






  const [selected, setIsSelected] = useState("All Networks");
  const [list, setList] = useState(["one", "two", "three"]);
  const [analyticsStartDate, setAnalyticsStartDate] = useState({
    date: new Date(new Date() - 7 * 24 * 60 * 60 * 1000).toISOString(),
    pindate: new Date(new Date() - 7 * 24 * 60 * 60 * 1000)
      .toISOString()
      .split("T")[0],
  });
  const today = new Date();
  const yesterday = new Date(today);
  yesterday.setDate(today.getDate() - 1);
  
  
  const [analyticsEndDate, setAnalyticsEndDate] = useState({
    date:  yesterday.toISOString(),
    pindate:  yesterday.toISOString()?.split("T")[0],
  });




  // create Commpare dates according to the selected date

  const [comparestartDates, setComparestartDates] = useState({
    date: new Date(new Date() - 7 * 24 * 60 * 60 * 1000).toISOString(),
    pindate: new Date(new Date() - 7 * 24 * 60 * 60 * 1000)
      .toISOString()
      .split("T")[0],
  });

  const [compareEndDate, setCompareEndDate] = useState({
    date: new Date().toISOString(),
    pindate: new Date().toISOString()?.split("T")[0],
  });


 // create useeffect to change compare dates according to the selected date

  useEffect(() => {
    setComparestartDates({
      date: new Date(
        new Date(analyticsStartDate.date) - 7 * 24 * 60 * 60 * 1000
      ).toISOString(),
      pindate: new Date(
        new Date(analyticsStartDate.date) - 7 * 24 * 60 * 60 * 1000
      )
        .toISOString()
        .split("T")[0],
    });
    setCompareEndDate({
      date: new Date(analyticsStartDate.date).toISOString(),
      pindate: new Date(analyticsStartDate.date).toISOString().split("T")[0],
    });
  }, [analyticsStartDate, analyticsEndDate]);

 





  const handleClickReload = () => {
    window.location.reload();
  };

  const handleClickAddNetwork = () => {
    // code to add account
    navigate("/link-account");
  };

  const vibrantColors = [
    "#FF5733", // Orange
    "#FF33A8", // Pink
    "#F433FF", // Purple
    "#9A33FF", // Violet
    "#337DFF", // Blue
    "#33E0FF", // Cyan
    "#33FF48", // Green
    "#ABFF33", // Lime
    "#FFD133", // Yellow
];

// Function to get a random vibrant color
function getRandomVibrantColor() {
    const randomIndex = Math.floor(Math.random() * vibrantColors.length);
    return vibrantColors[randomIndex];
}
    
  
  let distinctPages = [];
  for (let i = 0; i < props.pagesDetails.pagesDetails.length; i++) {
    if (distinctPages.includes(props.pagesDetails.pagesDetails[i].account_type)) {
      console.log("already exists");
    } else {
      distinctPages.push(props.pagesDetails.pagesDetails[i].account_type);
      console.log(distinctPages);
    }
  }
  const [isGoogleAnalyticsVisible, setIsGoogleAnalyticsVisible] = useState(false);

  useEffect(() => {
 
// 

    const fetchData = async () => {
      setIsLoading(true);
      let str = selectedAccount;
      let regex = /properties\/\d+/;
  let match = str?.match(regex);

  //if match is an array then get the first element
  if (Array.isArray(match)) {
    match = match[0];
  }
  
      
      const response = await axios.post(`${process.env.REACT_APP_SERVER_URI}/api/toowe/users/${userId}/crud/googleAnalyticsReport`,{
     startDate: analyticsStartDate.pindate,
      endDate: analyticsEndDate.pindate,
      id: match,
      jwToken: localStorage.getItem("token"),
      });
    

setData(response.data);






 const eccomResponse = await axios.post(`${process.env.REACT_APP_SERVER_URI}/api/toowe/users/${userId}/crud/googleAnalyticsEcomReport`,{
      startDate: analyticsStartDate.pindate,
      endDate: analyticsEndDate.pindate,
      id: match,
      jwToken: localStorage.getItem("token"),
      });
      setecommData(eccomResponse.data);


   

      setIsLoading(false);
    };
if(selectedAccount){

   isGoogleAnalyticsVisible && fetchData();
} else{

}
  }, [selectedAccount, analyticsStartDate, analyticsEndDate, isGoogleAnalyticsVisible]);
   

  // useEffect(() => {
  //   console.log(selectedAccount);
  // }, [selectedAccount]);
  console.log("distinct", distinctPages);
  console.log("list", list);


  const EcoomGraph = (data,name, dates) => {
    // This function takes in an array of data, and returns a line graph
    // that displays the data. The function also takes in a name for the
    // data, and an array of dates that the data was collected on.

    // This is the data that will be used to create the line graph.
    // It is an array of objects, and each object contains the data
    // for one line on the graph.
    const chartData = {
      labels: dates,
      datasets: [
        {
          label: name,
          data: data,
          fill: false,
          borderColor: getRandomVibrantColor,
                    tension: 0.5
        }
      ],

    };
    // These are the options that will be used to create the line graph.
    // It is an object that contains options for the graph.
    let options = {
      scales: {
          xAxes: [{
              gridLines: {
                  color: "rgba(0, 0, 0, 0)",
              }
          }],
          yAxes: [{
              gridLines: {
                  color: "rgba(0, 0, 0, 0)",
              }
          }]
      }
  }

    // This returns the line graph.
    return <Line data={chartData}     options={options} />;
  };




      



  const ActiveUsersByDateChart = () => {
    const chartData = {
      labels: data.dat.map((entry) => entry.date),
      datasets: [
        {
          label: 'Active Users',
          data: data.dat.map((entry) => entry.activeUsers),
          fill: false,
          borderColor: getRandomVibrantColor,
                    tension: 0.5
        }
      ],
   
    };
    let options ={
      scales: {
          xAxes: [{
              gridLines: {
                  color: "rgba(0, 0, 0, 0)",
              }
          }],
          yAxes: [{
              gridLines: {
                  color: "rgba(0, 0, 0, 0)",
              }   
          }]
      }
  }
  
    return <Line data={chartData}     options={options} />;
  };
  const SessionsByDateChart = () => {
    const chartData = {
      labels: data.dat.map((entry) => entry.date),
      datasets: [
        {
          label: 'sessions',
          data: data.dat.map((entry) => entry.sessions),
          fill: false,
          borderColor: getRandomVibrantColor,
          tension: 0.5
        }
      ],
   
    };
    let options ={
      scales: {
          xAxes: [{
              gridLines: {
                  color: "rgba(0, 0, 0, 0)",
              }
          }],
          yAxes: [{
              gridLines: {
                  color: "rgba(0, 0, 0, 0)",
              }   
          }]
      }
  }
  
    return <Line data={chartData}    options={options} />;
  };

  const PagesChart = () => {
    const datas = {



      labels: Object.keys(data.PagePath),
      datasets: [
        {
          label: 'Page Views',
          data: Object.values(data.PagePath),
          backgroundColor: 'rgba(75,192,192,0.4)',
          borderColor: 'rgba(75,192,192,1)',
          borderWidth: 1,
        },
      ],
    };
  
    const options = {
      scales: {
        y: {
          beginAtZero: true,
        },
      },
    };
  
    return (
      <Card>
        
        <CardBody>
          <Bar data={datas} options={options} />
        </CardBody>
      </Card>
    );
  };
  const handleChangeVisibility = (data) => {
    if (data === "googleAnalytics") {
      setIsGoogleAnalyticsVisible(true);
    } 
  };
  const SourcesChart = () => {
    const datas = {



      labels: Object.keys(data?.firstUserSource),
      datasets: [
        {
          label: 'Sources',
          data: Object.values(data?.firstUserSource),
          backgroundColor: 'rgba(75,192,192,0.4)',
          borderColor: 'rgba(75,192,192,1)',
          borderWidth: 1,
        },
      ],
    };
  
    const options = {
      scales: {
        y: {
          beginAtZero: true,
        },
      },
    };
  
  
    return (
      <Card>
        
        <CardBody>
          <Bar data={datas} options={options} />
        </CardBody>
      </Card>
    );
  };
  const ScreenPageViewsByDateChart = () => {
    const chartData = {
      labels: data.dat.map((entry) => entry.date),
      datasets: [
        {
          label: 'Screen Page Views',
          data: data.dat.map((entry) => entry.screenPageViews),
          fill: false,
          borderColor: getRandomVibrantColor,
          tension: 0.5
        }
      ],

    };
   
    return  <Line data={chartData}  />;
   
  };

  const NewUsersByDateChart = () => {
    const chartData = {
      labels: data.dat.map((entry) => entry.date),
      datasets: [
        {
          label: 'New Users',
          data: data.dat.map((entry) => entry.newUsers),
          fill: false,
          borderColor: getRandomVibrantColor,
                    tension: 0.5
        }
      ],

    };

    return <Line data={chartData}  />;
  };

  const AverageSessionDurationByDateChart = () => {
    const chartData = {
      labels: data.dat.map((entry) => entry.date),
      datasets: [
        {
          label: 'Average Session Duration',
          data: data.dat.map((entry) => entry.averageSessionDuration),
          fill: false,
          borderColor: getRandomVibrantColor,

          tension: 0.5
        }
      ],

    };

    return <Line data={chartData}  />;
  };

  const BounceRateByDateChart = () => {
    const chartData = {
      labels: data.dat.map((entry) => entry.date),
      datasets: [
        {
          label: 'Bounce Rate',
          data: data.dat.map((entry) => entry.bounceRate),
          fill: false,
          borderColor: getRandomVibrantColor,
          tension: 0.5
        }
      ],

    };

    return <Line data={chartData}   />;
  };

  const EventCountByDateChart = () => {
    const chartData = {
      labels: data.dat.map((entry) => entry.date),
      datasets: [
        {
          label: 'Event Count',
          data: data.dat.map((entry) => entry.eventCount),
          fill: false,
          borderColor: getRandomVibrantColor,
          tension: 0.5
        }
      ],

    };

    return <Line data={chartData}   />;
  };


 
  
  const ActiveUsersByCountryChart = () => {
    const countriesData = Object.entries(data.Countries);
    const chartData = {
      labels: countriesData.map(([country]) => country),
      datasets: [
        {
          label: 'Active Users',
          data: countriesData.map(([, activeUsers]) => activeUsers),
          backgroundColor: ['#36A2EB', '#FF6384', '#FFCE56', '#4BC0C0', '#FF9F40', '#9966FF', '#FF9900'],
        },
      ],
    };
  
    return <Pie data={chartData} />;
  };
  
  const DeviceCategoryChart = () => {
    const chartData = {
      labels: Object.keys(data?.DeviceCategory),
      datasets: [
        {
          data: Object.values(data?.DeviceCategory),
          backgroundColor: ['#36A2EB', '#FFCE56'],
        },
      ],
    };
  
    return <Doughnut data={chartData} />;
  };
  
  const BrowserChart = () => {
    const chartData = {
      labels: Object.keys(data.Browser),
      datasets: [
        {
          data: Object.values(data.Browser),
          backgroundColor: ['#36A2EB', '#FF6384', '#FFCE56', '#4BC0C0', '#FF9F40'],
        },
      ],
    };
  
    return <Pie data={chartData} />;
  };
  
  const OperatingSystemChart = () => {
    const chartData = {
      labels: Object.keys(data.OperatingSystem),
      datasets: [
        {
          data: Object.values(data.OperatingSystem),
          backgroundColor: ['#36A2EB', '#FFCE56', '#FF6384', '#4BC0C0', '#FF9F40', '#9966FF'],
        },
      ],
    };
  
    return <Doughnut data={chartData} />;
  };

  

  const ConversionsChart = () => {
    const chartData = {
      labels: ecommData[0].newDates,
      datasets: [
        {
          label: 'Conversions',
          data: ecommData[0].newConversions,
          fill: false,
          borderColor: getRandomVibrantColor,
          tension: 0.5
        }
      ],

    };

    return <Line data={chartData}   />;
  };

  const EcommercePurchasesChart = () => {
    const chartData = {
      labels: ecommData[0].newDates,
      datasets: [
        {
          label: 'Ecommerce Purchases',
          data: ecommData[0].newEcommercePurchases,
          fill: false,
          borderColor: getRandomVibrantColor,
          tension: 0.5
        }
      ],

    };

    return <Line data={chartData}   />;
  };

  const TotalPurchasersChart = () => {
    const chartData = {
      labels: ecommData[0].newDates,
      datasets: [
        {
          label: 'Total Purchasers',
          data: ecommData[0].newTotalPurchasers, 
          fill: false,
          borderColor: getRandomVibrantColor,
          tension: 0.5
        }
      ],

    };

    return <Line data={chartData}   />;
  };

  const TotalRevenueChart = () => {
    const chartData = {
      labels: ecommData[0].newDates,
      datasets: [
        {
          label: 'Total Revenue',
          data: ecommData[0].newTotalRevenue,
          fill: false,
          borderColor: getRandomVibrantColor,
          tension: 0.5
        }
      ],

    };

    return <Line data={chartData}   />;
  };








  return (
    <div className="analyticsMainPage pl-0 pr-0">
      {/* <div className="analyticsHeader">
        <div className="leftHeaderFilter">
          {selectedAccount}

          {
            selectedAccount?.length >= 0 && (
              <CustomDropDown
              isActive={isActiveSocials}
              setIsActive={setIsActiveSocials}
              selected={selectedAccount}
              setIsSelected={setSelectedAccount}
              list={allGoogleAnalyticsAccounts}
              type="Customdropdown"
            />)

          }
        
          <div className="dateFilterBlock">
            <div className="fromFilter">
              <Flatpickr
                options={{
                  theme: "light",                                              
                  dateFormat: "Y-m-d",
                  defaultDate: new Date(
                    new Date() - 7 * 24 * 60 * 60 * 1000
                  ).toISOString(),

                  onChange: function (selectedDates, dateStr, instance) {
                    console.log(dateStr);
                    setAnalyticsStartDate({             
                      pindate: dateStr?.split("T")[0],
                      date: dateStr,
                      epoch: Math.floor(Date.parse(dateStr) / 1000),
                    });

                    console.log(analyticsStartDate, analyticsEndDate);
                    // selectedDates.forEach(dat => { let epoch = Date.parse(dat); console.log(epoch) })
                  },
                  // console.log(dateStr)
                  // console.log(instance)
                }}
              />
              <img src={calendarIcon} alt="calendar icon" />
            </div>
            <div className="toFilter">
              <p>TO</p>
              <div className="fromFilter">
                <Flatpickr
                  options={{
                    dateFormat: "Y-m-d",
                    // set default date to yesterday
                    defaultDate: new Date().toISOString(),

                    onChange: function (selectedDate, dateStr, instance) {
                      if (
                        new Date(dateStr) < new Date(analyticsStartDate.date)
                      ) {
                        alert("Please select a date greater than start date");
                      } else {
                        console.log(dateStr);
                        setAnalyticsEndDate({
                          pindate: dateStr?.split("T")[0],
                          date: dateStr,
                          epoch: Math.floor(Date.parse(dateStr) / 1000),
                        });
                      }
                      console.log(analyticsStartDate, analyticsEndDate);
                      // selectedDates.forEach(dat => { let epoch = Date.parse(dat); console.log(epoch) })
                    },
                  }}
                />{" "}
                <img src={calendarIcon} alt="calendar icon" />
              </div>
            </div>
          </div>
        </div>
        <div className="rightHeaderFilter">
          <div className="refreshBtn" onClick={handleClickReload}>
            <img src={refreshIcon} alt="refresh" /> <p>Refresh</p>
          </div>
          <div className="addNetworkHeaderBtn" onClick={handleClickAddNetwork}>
            <p>Add Social Network</p>
          </div>
        </div>
      </div> */}
      {/* </div> */}
      <div className="analyticsContentArea pl-0 pr-0">
      <div className="mediaFilterBlock">
        <p><BsGoogle /> {selectedAccount}</p>
      </div>
{
  isLoading ? (
    <div style={{display: 'flex', justifyContent:'center', alignContent:'center', alignItems:'center', height:'90vh'}}>
      <MoonLoader color="#ff9001" size={100} speedMultiplier={0.3} />
    </div>
  ) :(

    
      isGoogleAnalyticsVisible ? (
        <div>
        <Container>
         <div style={{ marginTop: '10px', display: 'flex', flexWrap: 'wrap', flexDirection: 'row', }}   >
 
           {
             data.comparedData &&  data?.comparedData?.map((item, index) => {
 
               return (
                 <div>
     
                   <div className=" tiles__" >
             <p >  {item.name}</p>  
                       <h1 className="val_ues">{
                       //check if decimal
                       Number.isInteger(item.value) ? item.value :
                       item?.value?.toFixed(2)} </h1>   
                   { item.IncreaseDecrease?.toFixed(2) + "%" + " "}
 
 
                   {
                     item.IncreaseDecrease > 0 ? (
                       <BsFillArrowUpCircleFill color="green" size={14} /> ) : (
                         <BsFillArrowDownCircleFill color="red" size={14} />
                       )
                   }
 {/* <BsFillArrowUpCircleFill color={item.IncreaseDecrease > 0 ? "green" : "red"  } size={14} /> */}
 <span></span>
 
                     </div>
                       
                     
                   </div>
                   
                 
               
               )
             })
 
                  
 
 
           }
 
 {
             ecommData[1].map((item, index) => {
 
               return (
                 <div>
                  
                   <div className=" tiles__" >
             <p >  {item.name}</p> 
                      
                     
                      <h1 className="val_ues">{
 
                 
 
 
                       //check if decimal
                       Number.isInteger(makeItZero(item.value)) ? makeItZero(item.value) :
                       makeItZero(item.value)?.toFixed(2)} </h1>  
                   { item.IncreaseDecrease == null || 'null' || undefined ? 0 + "%" + " " : item?.IncreaseDecrease?.toFixed(2) + "%" + " "}
 
 
                   {
                     item.IncreaseDecrease = null ? null :
                     makeItZero(item.IncreaseDecrease) > 0 ? (
                       <BsFillArrowUpCircleFill color="green" size={14} /> ) : (
                         <BsFillArrowDownCircleFill color="red" size={14} />
                       )
                   }
                     </div>
                       
                      
 
                   </div>
                   
                 
               
               )
             })
 
                  
 
 
           }
 
 
 
 
           </div>
 
 
 
         </Container>
 
         <Container>
 
 
 
 
 
         <Card style={{marginTop:'20px', display:'flex', flexWrap:'wrap',flexDirection:'row', justifyContent:'space-between', border:'0px solid #ccc', marginBottom:'50px'}}   >
           <div style={{width:'30%'}}>
           <div className="mediaFilterBlock" style={{marginTop:'40px', marginLeft: '20px'}}>
 
 <p>Devices</p>
 </div>
             <Card style={{margin:'10px'}}   >
           <DeviceCategoryChart />
           </Card>
 
           </div>
           <div style={{width:'30%'}}>
           <div className="mediaFilterBlock" style={{marginTop:'40px', marginLeft: '20px'}}>
 
 <p>Operating System</p>
 </div>
             <Card style={{margin:'10px'}}   >
           <OperatingSystemChart />
           </Card>
 
           </div>
           <div style={{width:'30%'}}>
           <div className="mediaFilterBlock" style={{marginTop:'40px', marginLeft: '20px'}}>
 
 <p>Browsers</p>
 </div>
             <Card style={{margin:'10px'}}   >
           <BrowserChart />
           </Card>
           
           </div>
          
 
 
 
 </Card>
 
 <Card style={{marginTop:'40px', border: '0px'}}   >
          <div className="mediaFilterBlock" >
 
 <p>Pages visits</p>
 </div>
 <div style={{width:'83vw', marginBottom:'50px' }}>
 
 <PagesChart />
 </div>
 
          </Card>
 
 
          <Card style={{marginTop:'40px', border: '0px'}}   >
          <div className="mediaFilterBlock" >
 
 <p>Sources</p>
 </div>
 <div style={{width:'83vw', marginBottom:'50px' }}>
 
 <SourcesChart />
 </div>
 
          </Card>
 
 
 
  <div className="mediaFilterBlock" 
  style={{marginTop:'0px', marginLeft: '0px'}}>
 
 <p>Active Users Country Wise</p>
 </div>
 
  <Card style={{}}>
   <div className="row">
  <div className="col-lg-9 pr-0">
  <div style={{width:'100%'}}>
 <InteractiveMap countriesData={data?.Countries} /> 
 </div></div>
  <div className="col-lg-3 pl-0">
 
 <div className="location_wrp">
 
   {
     data?.Countries && Object.entries(data?.Countries)?.map(([country, activeUsers]) => {
       return (
         <div className="location__wise__">{country} <span className="location_numbers">{activeUsers}</span></div>
       )
     })
   }
 {/*  
  <div className="location__wise__">India <span className="location_numbers">100</span></div> 
  <div className="location__wise__">Australia <span className="location_numbers">100</span></div> 
  <div className="location__wise__">America <span className="location_numbers">100</span></div> 
  <div className="location__wise__">Mexico <span className="location_numbers">100</span></div>  */}
 
 </div>
  </div>
  </div>
 
 </Card>

 <div className="mediaFilterBlock" style={{marginTop:'50px', marginLeft: '0px'}}>
 
 <p>Active Users over Time </p>
 </div>
  <Card style={{marginTop:'10px'}}   >
 <div style={{width:'70vw', marginLeft:'5vw'}}>
 <ActiveUsersByDateChart />
 
 </div>
   </Card>
         
          <div className="mediaFilterBlock" style={{marginTop:'50px', marginLeft: '0px'}}>
 
 <p> Session over Time </p>
 </div> <Card style={{marginTop:'10px'}}   >
 <div style={{width:'70vw', marginLeft:'5vw'}}>
           <SessionsByDateChart />
           </div>
 
          </Card>
         
          <div className="mediaFilterBlock" style={{marginTop:'50px', marginLeft: '0px'}}>
 <p> Screen Page View over Time </p>
 </div> 
 <Card style={{marginTop:'10px'}}   >
 <div style={{width:'70vw', marginLeft:'5vw'}}>
           <ScreenPageViewsByDateChart />
           </div>
 
          </Card>
         
   <div className="mediaFilterBlock" style={{marginTop:'50px', marginLeft: '0px'}}>
 
 <p> New User over Time </p>
 </div> <Card style={{marginTop:'10px'}}   >
 <div style={{width:'70vw', marginLeft:'5vw'}}>
 <NewUsersByDateChart />
           </div>
 
          </Card>
          
       
         
          <div className="mediaFilterBlock" style={{marginTop:'50px', marginLeft: '0px'}}>
 
 <p>Bounce Rate over Time</p>
 </div> <Card style={{marginTop:'10px'}}   >
 <div style={{width:'70vw', marginLeft:'5vw'}}>
 <BounceRateByDateChart />
           </div>
 
          </Card>
          
  <div className="mediaFilterBlock" 
  style={{marginTop:'50px', marginLeft: '0px'}}>
 
 <p> Events Count over Time </p>
 </div>
 <Card style={{marginTop:'10px'}}   >
 <div style={{width:'70vw', marginLeft:'5vw'}}>
 <EventCountByDateChart />
           </div>
 
          </Card>
       
         </Container>
 
 
 
 
 
 {/* //EcoomGraphs */}
 
 
 <Container>
 
 
   <h2 style={{marginTop:'50px',marginLeft:'0px', fontSize:'30px', textAlign: 'left'}}>
   Ecommerce Data</h2> 
                  
  <div className="mediaFilterBlock" style={{marginTop:'40px', marginLeft: '0px'}}>
 
 <p> Conversions </p>
 </div> <Card style={{marginTop:'10px'}}    >
             
             
             <ConversionsChart />
 
      </Card>
 
      
      <div className="mediaFilterBlock" 
      style={{marginTop:'50px', marginLeft: '0px'}}>
 
 <p> Purchases over time </p>
 </div><Card style={{marginTop:'10px'}}    >
             <EcommercePurchasesChart />
 
      </Card>
  
 
     <div className="mediaFilterBlock" 
      style={{marginTop:'50px', marginLeft: '0px'}}>
 
 <p>Total Purchasers over Time</p>
 </div>
              <Card style={{marginTop:'10px'}}    >
 
             <TotalPurchasersChart />
 
      </Card>
 
 
     
      <div className="mediaFilterBlock" style={{marginTop:'50px', marginLeft: '0px'}}>
 
 <p> Total Revenue over time </p>
 </div>
            <Card style={{marginTop:'10px'}}    >  
            
 
             <TotalRevenueChart />
 
      </Card>
 
            
 
        
               </Container>
 
 
 
       </div>
      ) : (
        <>
         <div className="click_notify_wrp" 
  onClick={() => handleChangeVisibility("googleAnalytics")} 
>
  <p>Stay updated with your audience! <br/>Click here to view your GA4 Project insights, 
              Engaged Users, Impressions, Total Users, Location wise Traffic and other engagement metrics.</p>
              <h2>Click to View</h2>
</div>

        </>
      )
    
  
  )
}


       





        {/* <InteractiveMap /> */}
        </div>
      
      {isCreateNewPostMenu && (
        <CreateNewPost
          pagesDetails={props.pagesDetails.pagesDetails}
          eventData={selectedForm}
          isLoading={isLoading}
          setIsLoading={setIsLoading}
        />
      )}
      {isCreateNewPinMenu && (
        <CreatePin
          pagesDetails={props.pagesDetails.pagesDetails}
          eventData={selectedForm}
          isLoading={isLoading}
          setIsLoading={setIsLoading}
        />    
      )}
      {isCreateNewBoardMenu && (
        <CreateBoard
          pagesDetails={props.pagesDetails.pagesDetails}
          eventData={selectedForm}
          isLoading={isLoading}
          setIsLoading={setIsLoading}
        />
      )}
    </div>
  );
}

export default GoogleAnalytics;
