import react, { React } from "react";
import { useEffect, useState } from "react";
import InstaIconNobg from "../../../assets/InstaIconNobg.png";
import dummyImage from "../../../assets/dummyImage.png";
import editIcon from "../../../assets/editIcon.png";
import deleteIcon from "../../../assets/deleteIcon.png";

import FacebookLike from "../../../assets/Facebook_like.png";
import instaComment from "../../../assets/instaComment.png";
import instaShare from "../../../assets/instaShare.png";
import "../streamScreen.css";
// import FacebookComments from "./FacebookComments";
import axios from "axios";
import { toast } from "react-toastify";
import { stream } from "xlsx";




export default function FacebookPosts({post, user,accountType, stream}) {


  const [editmode, setEditmode] = useState(false);
  const [postText, setPostText] = useState(post.message);

  const [isPostDeleted, setIsPostDeleted] = useState(false);
  let userID = JSON.parse(localStorage.getItem("userProfileInfo"))?._id;
  const [userId, setUserId] = useState(userID);

  const deletePost = () => {
if(window.confirm("Are you sure you want to delete this post?") === false) return;

    axios
      .post(process.env.REACT_APP_SERVER_URI + `/api/toowe/users/${userId}/crud/DeletePost`, {
        message: postText,
        postId: post.id,
        pageId: stream?.pageId,
        token: stream?.token
      },{
        headers: {
          token: JSON.parse(localStorage.getItem("userProfileInfo"))?.token,
          userId: JSON.parse(localStorage.getItem("userProfileInfo"))?._id,
        }
      
      })
      .then((res) => {
        toast.success("Post Deleted Successfully");
        // window.location.reload();
        setIsPostDeleted(true);
      })
      .catch((err) => {
        console.log(err);
      });
  };


  const handleUpdatePost = () => {
    axios
      .post( process.env.REACT_APP_SERVER_URI +  `/api/toowe/users/${userId}/crud/updatePost`, {
        message: postText,
        postId: post.id,
        pageId: stream?.pageId,
        token: stream?.token
      },{
        headers: {
          token: JSON.parse(localStorage.getItem("userProfileInfo"))?.token,
          userId: JSON.parse(localStorage.getItem("userProfileInfo"))?._id,
        }
      
      })
      .then((res) => {
        toast.success("Post Updated Successfully");
        // window.location.reload();
        post.message = postText;
        setEditmode(false);
      })
      .catch((err) => {
        console.log(err);
        toast.error("Unable to update post");
      });
  }

function simplifyHashtags(text) {
    // Regular expression to find hashtags in the format {hashtag|\#|...}
const hashtagRegex = /\{hashtag\|\\#\|([^}]+)\}/g;

// Replace each matched hashtag with its simplified version
let simplifiedText = text?.replace(hashtagRegex, (match, p1) => `#${p1}`);

// Remove backslashes before asterisks
simplifiedText = simplifiedText?.replace(/\\(\*)/g, '$1');

var textWithoutNewlines = simplifiedText?.replace(/[\(\)\[\]\{\}<>|~_]/gm, (x) => "\\" + x);

return textWithoutNewlines;
}



  post.message =  simplifyHashtags(post?.message);



 return (
  isPostDeleted ? " " :  <div className="postContentArea">
      <div className="postCard">
        <div className="postHeader">
          <div>
            <img
              className="postMediaIcon"
              src={user?.picture || 'https://upload.wikimedia.org/wikipedia/commons/7/7c/Profile_avatar_placeholder_large.png'}
              alt="insta"
              loading="lazy"
            />
          </div>
          <div className="postUserInfo">
            <p className="postUserName">{user?.name}</p>
            <p className="postDate">
              {post.created_time && post.created_time}
            </p>
          </div>
          { accountType === "facebook" ? (
<div style={{ display: "flex", marginLeft: "25%" }}>
            <img
              src={editIcon}
              height={"17"}
              width={"17"}
              title={"Edit"}
              className={"blur_hover"}
              style={{ cursor: "pointer" }}
              onClick={() => {
                editmode ? setEditmode(false) : setEditmode(true);
              }}
            />
            <img
              src={deleteIcon}
              height={"19"}
              style={{ cursor: "pointer" }}
              width={"19"}
              title={"Delete"}
              className={"blur_hover"}
              onClick={deletePost}
            />
          </div>
            ) : null
          }
         
        </div>
        <div className="postText">
          <p>
{
  editmode ? (
  <>
    <textarea
    style={{width:'100%'}}
    className={"editPost"}
    value={postText}
    onInput={(e) => {
      setPostText(e.target.value);
    }}
  />

  <button style={{width:'100%'}}  onClick={handleUpdatePost}>
    save
  </button>
  </>
  ) : post.message && post.message.length > 0 ? (
    <div
      dangerouslySetInnerHTML={{
        __html: post.message?.replace(
            /(https?:\/\/[^\s]+)/g,
            function (url) {
              return '<a target="_blank" href=' + url + ">" + url + "</a>";
            }
          )
      }}
    />
)

  : null
}


          </p>
        </div>

        <div className="postImg">
          { accountType === 'linkedin' ? 
  post?.image?.includes('vid') ? (
    <video src={post.image} controls />
  ) : (
    <img src={post?.image} loading="lazy" alt="post_image" />
  )
 :   post?.image ? (
            post?.image ? (
              post?.image?.split("?")[0].slice(-3) === "mp4" ? (
                <video src={post.image} controls />
              ) : (
                <img src={post?.image} loading="lazy" alt="post_image" />
              )
            ) : (
              "can't load media"
            )
          ) : null}

        
        </div>

     



        <div className="postLikeShareSection">
          <span
            onClick={"handleLikeUnlike"}
            style={{ marginTop: "0px" }}
            title="Like"
          >
            {" "}
            <span
              style={{
                marginTop: "5px",
              }}
            >
               <b>{post?.likes ? post?.likes : 0 }</b>
            </span>
            <svg
              className="vk-standard-hs-like"
              title="Like"
              viewBox="0 0 20 20"
              style={{
                height: "16px",
                width: "16px",
                cursor: "pointer",
                marginLeft: "10px",
                // fill:
                //   isLiked === true
                //     ? "blue"
                //     : isLiked === false
                //     ? "currentcolor"
                //     : "currentcolor",
              }}
            >
              <path d="M16.6008316,7.5 C17.5987526,7.5 18.5966736,7.875 19.2203742,8.75 C19.8440748,9.375 20.0935551,10.375 19.968815,11.375 L18.970894,17.125 C18.7214137,18.75 17.3492723,20 15.7276507,20 L5,20 L5,10 C5.12474012,10 7.99376299,0.625 7.99376299,0.625 C7.99376299,0.25 8.36798337,0 8.74220374,0 C10.2390852,0 11.2370062,0.625 11.2370062,2.5 L11.2370062,7.5 L16.6008316,7.5 Z M0,10 L2.5,10 L2.5,20 L0,20 L0,10 Z"></path>
            </svg>
          </span>
          <span>
            {" "}
              <span style={{marginTop: "5px"}}>
               <b>{post?.comments ? post?.comments : 0}</b>
            </span>{" "}
              <img
                  className="postLike"
              src={instaComment}
              alt="Comment"
              title="Comment"
            />
          </span>
          <span>
             {" "}
              <span style={{marginTop: "5px"}}>
               <b>{post?.shares ? post?.shares : 0}</b>
            </span>{" "}
            <img
              className="postLike"
              src={instaShare}
              alt="Share"
              title="Share"
            />
          </span>
        </div>
      </div>
      <div
        className="postComment"
        style={{
          border: "1px solid #b2c1cf",
          display: "flex",
          marginBottom: "8px",
          width: "93%",
          margin: "0 auto",
        }}
      >
        {/* <input
          className={"comments_inp_wrp"}
          placeholder={"write a comment"}
          value={comment}
          onInput={(e) => {
            setComment(e.target.value);
          }}
          style={{
            width: "80%",
            height: "28px",
            fontSize: "12px",
            paddingLeft: "10px",
            fontWeight: "normal",
            // marginTop: "5px",
            outline: "none",
            borderTop: "none",
            borderLeft: "none",
            borderBottom: "none",
            borderRight: "none",
          }}
        />
        <button
          className={"comments_postbtn_wrp"}
          style={{
            width: "20%",
            height: "28px",
            // marginBottom: "4px",
            outline: "none",
            borderTop: "none",
            borderLeft: "none",
            borderBottom: "none",
            borderRight: "none",
            backgroundColor: "#6a8199",
            color: "#fff",
          }}
          onClick={postComment}
        >
          Send
        </button> */}
      </div>
      <div
        className={"hideshw_wrp"}
        onClick={() => {
          // commentsActive ? setcommentsActive(false) : setcommentsActive(true);
        }}
      >
        {/* {commentsActive ? "Hide Comments" : "Show Comments"} */}
      </div>
      {/* {commentsActive ? ( */}
        {/* <FacebookComments id={props.id} token={props.token} /> */}
      {/* ) : null} */}
    </div>
  );
}
