import React, { useState, useEffect } from "react";
import "./Upgrade.css";
import axios from "axios";
import {
  GetDateDiffofFree,
  GetDateDiffofPaid,
} from "../../Utils/GetCurrentDate";
import { useDispatch, useSelector } from "react-redux";
import CreateNewPost from "../Dashboard/CreateNewPost";
import CreatePin from "../Dashboard/CreatePin";
import CreateBoard from "../Dashboard/CreateBoard";
import { getPackageApi } from "../../Utils/api";
import { cashfreeSandbox } from "cashfree-pg-sdk-javascript";
import { v4 as uuidv4 } from "uuid";
import { json, useNavigate, useSearchParams , useLocation} from "react-router-dom";
import { toast, ToastContainer } from "react-toastify";
import { updateUser, UserInfo , updateUserProfileInfo , getPaymentInfoAPI } from "../../Utils/api";
// stripe
import { loadStripe } from "@stripe/stripe-js";
import {
  CardElement,
  Elements,
  // useStripe,
  // useElements,
} from "@stripe/react-stripe-js";
import { Button, Card, CardBody, CardTitle } from "reactstrap";

const Upgrade = ({ renewalDaysLeft, pagesDetails }) => {
  const stripePromise = loadStripe("pk_test_6pRNASCoBOKtIshFeQd4XMUh");
  const [orderiddddd, setOrderiddddd] = useState(null);
  const [paymentStatus, setPaymentStatus] = useState(null);
  const navigate = useNavigate();
  let details = localStorage.getItem("userProfileInfo");
  details = JSON.parse(details);
  const location = useLocation();

  const [userDetails, setUserDetails] = useState(details);
  let userID = JSON.parse(localStorage.getItem("userProfileInfo"))?._id;
  const [userId, setUserId] = useState(userID);

  // const elements = useElements();

  const handleSubmit = async (event) => {
    // event.preventDefault();
    // if (elements == null) {
    //   return;
    // }
    // const {error, paymentMethod} = await stripe.createPaymentMethod({
    //   type: 'card',
    //   card: elements.getElement(CardElement),
    // });
  };

  const getStatus = async (id) => {
    await axios
      .get(
        `${process.env.REACT_APP_SERVER_URI}/api/toowe/users/${userId}/getOrderStatus?orderId=${id}`
      )
      .then((res) => {
        console.log(res.data);
        setPaymentStatus(res.data.order_status);
        if (res.data.order_status === "PAID") {
          axios
            .post(`${process.env.REACT_APP_SERVER_URI}/api/toowe/users/${userId}/saveOrder`, {
              info: res.data,
              _id: userDetails._id,
            })
            .then((res) => {
              localStorage.setItem("userProfileInfo", JSON.stringify(res.data));
              sessionStorage.setItem(
                "userProfileInfo",
                JSON.stringify(res.data)
              );
              axios.post(
                process.env.REACT_APP_SERVER_URI +
                  `/api/toowe/users/${userId}/emailActions/PaymentSuccessEmail`,
                {
                  email: email,
                }
              );
              toast.success("Payment Successful");
              window.location.href = "/upgrade";
            });
        } else if (res.data.order_status === "FAILED") {
          axios.post(
            process.env.REACT_APP_SERVER_URI +
              `/api/toowe/users/${userId}/emailActions/PaymentFailedEmail`,
            {
              email: email,
            }
          );
          toast.error("Payment Failed");
        }
        return res.data;
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const saveOrder = async (data) => {
    await axios
      .post(`${process.env.REACT_APP_SERVER_URI}/api/toowe/users/${userId}/saveOrder`, {
        info: data,
        _id: userDetails._id,
        subscription: JSON.parse(localStorage.getItem("paymentID")),
      })
      .then((res) => {
        localStorage.setItem("userProfileInfo", JSON.stringify(res.data));
        sessionStorage.setItem("userProfileInfo", JSON.stringify(res.data));
        try {
          axios.post(
            process.env.REACT_APP_SERVER_URI +
              `/api/toowe/users/${userId}/emailActions/PaymentSuccessEmail`,
            {
              email: email,
              orderId: data.orderId,
            }
          );
        } catch (err) {
          console.log(err);
        }
        toast.success("Payment Successful");
        window.location.href = "/upgrade";
      });
  };

  useEffect(() => {
    // get query string
    const quer = new URLSearchParams(window.location.search).get("payment");
    const state = new URLSearchParams("payment");
    if (quer === "success") {
      console.log("success");
      toast.success("Payment Successful");
      const payment = JSON.parse(localStorage.getItem("paymentID"));
      axios
        .post(`${process.env.REACT_APP_SERVER_URI}/api/toowe/users/${userId}/payment-status`, {
          paymentId: payment.id,
        })
        .then((res) => {
          if (res.status == 200) {
            saveOrder(res.data);
          } else {
            toast.error("Payment Failed");
            try {
              axios.post(
                process.env.REACT_APP_SERVER_URI +
                  `/api/toowe/users/${userId}/emailActions/PaymentFailedEmail`,
                {
                  email: email,
                }
              );
            } catch (err) {
              console.log(err);
            }
          }
        })
        .catch((err) => {
          console.log(err);
        });
    }
  }, []);
  useEffect(() => {
    if (location.search !== "") {
      const reference = searchParams.get("reference");
      if (reference) {
        const updateInfo = async (paramsInfo) => {
          const res = await updateUserProfileInfo(paramsInfo);

          sessionStorage.setItem("userProfileInfo", JSON.stringify(res.data));
          localStorage.setItem("userProfileInfo", JSON.stringify(res.data));
          let userDataLocal = JSON.parse(
            localStorage.getItem("userProfileInfo")
          );
          await axios.post(
            process.env.REACT_APP_SERVER_URI +
              `/api/toowe/users/${userId}/emailActions/PaymentSuccessEmail`,
            {
              email: userDataLocal.email,
            }
          );
          // setSubscriptionType("");
          // navigate("/dashboard");
        };

        const payId = searchParams.get("reference");
        const userId = JSON.parse(localStorage.getItem("userProfileInfo"));

        const fetchPaymentInfo = async () => {
          const params = {
            payId,
            _id: userId._id,
          };
          const res = await getPaymentInfoAPI(params);
 const exptillday = JSON.parse(localStorage.getItem("Selectedplan")).packageDays
          if (res.status === 200) {
            const payParams = {
              latestPayID: res.data.orderInfo.id,
              latestPayDate: res.data.orderInfo.created_at,
              subscription: res.data.orderInfo.description,
              days: exptillday ,
              planExpiry: new Date().getTime() + parseInt(exptillday)*24*60*60*1000 ,
              

              _id: userId._id,
            };

            updateInfo(payParams);
          }
        };

        fetchPaymentInfo();
      }
    }
  }, [location]);

  const dispatch = useDispatch();
  const [selectedForm, setSelected] = useState(null);
  const [email, setEmail] = useState(userDetails.email || "");
  const [phone, setPhone] = useState(userDetails.phone || "");
  const [isLoading, setIsLoading] = useState(false);
  const [selectedPlan, setSelectedPlan] = useState({});
  const [packageData, setPackageData] = useState([]);

  const [promt, setPromt] = useState(false);
  const [searchParams] = useSearchParams();

  async function paymentHandler(amo, plan, days) {
    axios
      .post(process.env.REACT_APP_SERVER_URI + `/api/toowe/users/${userId}/create-checkout-session`, {
        amount: (parseInt(amo) + parseInt(amo)*0.18) * 100,
        email: email,
        plan: plan,
      })
      .then((res) => {
        console.log(res.data);
        localStorage.setItem(
          "paymentID",
          JSON.stringify({ id: res.data.sessionId?.payment_intent, plan: plan, days: days })
        );
        window.location.href = res.data.sessionId.url;
      })
      .catch((err) => {
        console.log(err);
      });
    //  setSelectedPlan({
    //     price: parseInt(amo) ,
    //     name: plan,
    //  }
    //  )

    //  setPromt(true)
  }
  const CreateOrder = async () => {
    if (!email || !phone) {
      toast.error("Please enter email and phone number");
    } else {
      await axios
        .post(`${process.env.REACT_APP_SERVER_URI}/api/toowe/users/${userId}/createOrder`, {
          order_id: uuidv4(),                       
          order_amount: selectedPlan?.price,
          order_currency: "INR",
          customer_details: {
            customer_id: userDetails._id,
            customer_email: email,
            customer_phone: phone,
          },
          order_meta: {
            return_url:
              process.env.REACT_APP_REDIRECT_URI +
              `/upgrade?order_id={order_id}`,
            notify_url: "https://b8af79f41056.eu.ngrok.io/webhook.php",
          },
          order_expiry_time: "2023-01-29T00:00:00Z",
          order_note: selectedPlan?.name + " Plan",
          order_tags: {
            additionalProp: "string",
          },
        })
        .then((res) => {
          console.log(res.data);
          let cashfree = new cashfreeSandbox.Cashfree(
            res?.data?.payment_session_id                               
          );
          cashfree.redirect();
          return res.data;
        })
        .catch((err) => {
          console.log(err);
        });
    }
  };

  // CreateOrder()

  const isCreateNewPostMenu = useSelector( 
    (state) => state.createNewPost.showCreatePostMenu
  );
            
  const isCreateNewPinMenu = useSelector(
    (state) => state.createNewPin.showCreatePinMenu
  );

  const isCreateNewBoardMenu = useSelector(
    (state) => state.createNewBoard.showCreateBoardMenu
  );
  const checkoutHandler = async (amount, plan) => {
    let details = localStorage.getItem("userProfileInfo");
    details = JSON.parse(details);

    if (details) {
      if (details.subscription === "free") {
        const days = GetDateDiffofFree(details.createdAt);
        var DayLeft = 0;
        DayLeft = 10 - days;
      } else {
        const days = GetDateDiffofPaid(details.latestPayDate);
        if (details.subscription === "Beginner Plan") {
          var DayLeft = 0;
          DayLeft = 31 - days;
        } else if (details.subscription === "Intermediate Plan") {
          var DayLeft = 0;
          DayLeft = 90 - days;
        } else if (details.subscription === "Advanced Plan") {
          var DayLeft = 0;
          DayLeft = 365 - days;
        }
      }
    }

    // if (DayLeft && DayLeft > 1) {
    //   window.alert(`you already have an active pack`);
    //   return;
    // }
    const {
      data: { order },
    } = await axios.post(`${process.env.REACT_APP_SERVER_URI}/api/toowe/users/${userId}/checkout`, {
      amount,
    });

    const logggedInUser = JSON.parse(localStorage.getItem("userProfileInfo"));
    const options = {
      key: process.env.RAZORPAY_API_KEY,
      amount: order.amount,
      currency: "USD",
      name: logggedInUser.fName,
      description: plan,
      order_id: order.id,
      callback_url: `${process.env.REACT_APP_SERVER_URI}/api/toowe/users/${userId}/paymentverification`,
      prefill: {
        name: logggedInUser.fName,
        email: logggedInUser.email,
      },

      theme: {
        color: "#121212",
      },
    };

    

    const razor = new window.Razorpay(options);
    razor.open();
  };

  const getPackagesAPI = async () => {
    const data = await getPackageApi();
    setPackageData(data.data);
  };

  useEffect(() => {
    getPackagesAPI();
  }, []);

  const AmountWithGST = (amount) => {
    let originalAmount = amount.replace(/[^0-9]/g, "");
    let GST_Amount = (originalAmount * 0.18) ;
    let NET_Amount = parseInt(originalAmount) + parseFloat(GST_Amount);
    console.log(amount);
    console.log(originalAmount);
    console.log(NET_Amount);
    console.log(GST_Amount)
    return parseFloat(NET_Amount)*10;
  };


  const  [selectMethod, setSelectMethod] = useState(false);


  const handlePaymentProcesser = (plan, days, amo) => {




  }




  return (
    <div>
       {
     selectMethod ? (
        <div className="CoverAllScreen">

         <Card style={{width:'400px', height:'355px'}}>

          <CardTitle>
            <h3 style={{marginTop: '20px'}}>Select Payment Method</h3>
          </CardTitle>
          <CardBody style={{marginTop: '20px'}}>
             <button className="chck_btns__" onClick={  ()=>{checkoutHandler(
                            AmountWithGST(selectedPlan.amount),
                            `${selectedPlan.plan} Plan`
                          ) ; console.log(selectedPlan)
                          }}>
                          <img src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAHcAAAB3CAMAAAAO5y+4AAAAmVBMVEX///8AIVEHJlT3+PkAI1IAE0wAAEYAEEoAFUwAGE0AH1Dg4+gAHU89SWwAAEQAGk59iaAxlP/r7fAAC0ojkf/T193Axc8AAEDa3eNCn/+psb+yucZjboiiqbiXnq4bMFwnOGKJkqV2gJddZYEyRm3g7f6SxP9/vP/L4v9ZqP+62v/x+P4xmP9HVHVgrv/q9P+rz/9QX380PmRlpJHzAAAEWUlEQVRoge2WyXKkOBCGtSIBAlWhAlPsW3tpG7vh/R9uUqLsiImZPk2Uew76Di7QlsufSoyQx+PxeDwej8fj8Xg8Ho/H4/F4/gw//ojV18enP2H2+eXy8/utvj6+Xd4+vt3s8/VyPr98t9WPp7PlER7b08FY0Lub/QnBvoHdZ4RoLyS3iGDN7m32crk8vZ/PV5A35wTrMAx5RMzpznafLtdndD2f3+G5FXiFH1omkZrvm2n69vjaPp/Pl1/wskZidKOdlJP9zas6Q1kcZ+BLfMP5E9dtXbqlWVxmsK44HsqqzW8nZ0VbUxSXJc3KMj6MxXF5m/34UfTdr8shr2FB4UbXKK0RKhbDTV8nIbzUgXLoEIQvNi24CCdwga48aBueNnTjQT2FgpvGedb1sHkvdIjjKtDGjTUm7T69msRD+QLywkRh2BBTSuNJ6oVC1pXkkvcEkwytgeCSESI3m42Qc6lYCjVQKkb6QKQtjRhZAi4xMfbsSURcStkztVEomwdbpq2xux31wPHwA+S1TXKUhOwAFnzIUSGxHst40kTB6tqyYj2UqAqivs3iWeEhgzwQNYztWBaC6KTIih0rqJHOYLtm00SOKIvYA2Q/79Vw5DtuoHJVY+W1TTKJiJYQB+unGBKoQyt2NjB9iE4nzNcYZQQrq0YhGCnRFLLjsJGzPnPO4wXFCod2TR4QUSC6sKBGdNNh5Q5qBwiQmOrxfH6Dj1GsGG6mKYnwYjUEj6mzi7UTPduEmTN7sG5czQmIlybY1M4pqMnusB9t8EfNbk3AQCM0K5gbuZgOsykm1q6V98XKG9jEoWzB9vJOMnTLisiNonLh3BYSWpWojpqHs8uAPLiSKXsmXdyNhsxGNkBrQjj7owxPuVH70Ytmbc2CvG/ni22SJ6lcIHNkBV0j09q3SbqthQqPeLL+SDNacFpZHZejUMyR5rLHOqcpCdyFGbDbVBm8Dlged8UWIADywi0CeWnyaSnECaU75lbWQrr6rFPcH9uynoU2ls4okGOO+HEzJkm0jbcJwxnFYNeu7iAb9jrnDyRi5qgSlBtrFnSHz4L9BsaGSHfnR2FdXxVRYz0ZRkyOupRETTcCFKoPD13dCBzkUGPMOHdAZxImVbuEEYawHwjru2oOCBusCjQgRG+3BtgJbOHx9XJ5h7EqVb2bgHtiYogwYjxNN6V2CF5jrIUQaW8zqiMBPYGAkwUsOoIAzVZjhBa99X0KWCRSsiruagQKmunPTjUnjvX1er3aJnna9+NbUOwJXF/ULqTfis6OlsOxNtld6rehH7bOqtntyVeNJnTu+2R0tUNPQ783cZPsR7EPLK1uZhG9gV7p6+f714xTsoRefIzSL9x8FmcU/W3PrGw13Jq32ww9/fMgdBKyQfcAmosofjtbc9tp70GeHrfoX4mHiP/eqf/EmH51/H9AZ3NU1x1okqX93Vw1LOv9/1vzeDwej8fj8Xg8Ho/H4/F4PB7P/4S/ACJ6VsANvrIvAAAAAElFTkSuQmCC"/>
                          </button>
            <button className="chck_btns__"   onClick={ ()=>{paymentHandler(selectedPlan.amount, selectedPlan.plan,selectedPlan.days); console.log(selectedPlan)}
            }><img src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAHcAAAAwCAMAAAAcuhVsAAAAZlBMVEX///9ncuVlcOVeauRgbORjbuTs7ft5gudbZ+SUm+zJzPWMlOpxe+axtvDv8Pz4+P7z9P1UYePO0fbBxfPj5fqrsO+DjOmYn+y6vvKJkeqkqu61uvHS1fba3PhueOZ1f+dMWuJ+h+gl2SYCAAADyklEQVRYhe2Y2ZqqOhBGM0rJGGZEgc37v+QOZAS1tZveXpzv1BWQ8C+K1BBA6JhV2UGB71ta1DP8OX2YWlOgjGP4NPfM8WL/hnsqevFhbjZcIwyQpx/mJjlblOlXXM5+P66udPXnORcAN3XxbPjHlrCvuV1Z/TbyLe7vWTqEZXirxEvuqXr6MKeiLB68h6wqynB4lBppHctCQCkAm/rBcXG8GM5QsR7hEoWxjPICtaMakXHVmDnlBCAVxmRbPKtWJoaUpk2xxyaUEqyNsPzmuJhIgwyFsBywvgQ5EUJ0ZmQdkdyAL0c0bEBrUDx42j1wLcWh2fp8Buwb+Nz1guSq+B5XDRr6+RsoHON2PgHrWRZQT4hjf4nqLfYrrnboEXcrYVY5YJvrfPSCAfY3HeeSWGm3dDfAWueuRpC1xZAtl3Bp+Y4LT7iM2ael3SI9aJcYHTndvQk0qfvIXBZh18bAPC5pLtJmj0uA8fwhl459V2NLXqQbPatPUVYqBW4dZt7E5bUntHD5a1LCcFlcCCTEo7hK1omRloPBriBVIzd1BkaSbrnSxH3d0Fx+MSlw768eifVj9LLIKxETS8p7G+v6AVnkJ9cTLjU33XOJHhnUTBIgNK+TeK9HVK9h5lQvgkzrqHjJDV/6i/QpIKQd6vTAbdXgjZG0ecQptNVhbquGcpHqFaS5MsMxE2cvV3nenA5y9apCVe0Lg/bfvtRpU8vy8BhXJwxUw2MutVzU53558vrCj7gv/PW4SPQUvLqe/YDLjZa+FU6Wyzfmc6UVERinoTzCvei4ykxcTdHGLmhnotcPyKMjXFeHtFq7B92ZDgkyu5f1ba6pw42pG34l9O2c2EIlNHey/hrO21xhyl/igrP2cXYTdAZ2LhS6s89q+uC4OCze4jZLyalGU64q19mhNjARBiFyElxu56K+nU3T6c3bkk15vgSu/36VR5zGzWT2WEt5tiEmm+P52nVJGzAKPherBm8auOxhbhNCOIG3uOse0ByqruMymDPK1k2F7SxGwtlauWfv6rvcnYS05K50POcStqypX+S+zSXchOrdBsvjbocYVi3p6sDvcV2p5dx9NNSwdSu363ubgZqlJZT2JvZKyoh6+D+Sm7PF7F0yCdZzxx07NZ9wuPg7iGEGs7WWH67Y/5g4lW0gN4OUx1HnbaxFPcloIHEr+0RVJ4vVxpEwUefC6wtq/tj6XwtrdNUzXoTGpt4PLZQ0vf8plKXi5Z8ivx+J9MmPiYfqx2zfBz9l/3P/+1yZmfBp7gQAl+T27M/eP7PrD/5i/QVFwjaQ08Ki2QAAAABJRU5ErkJggg==
"/>
             </button>

          </CardBody>

<Button className="btn btn-danger" onClick={ ()=>{setSelectMethod(false)}}>Close</Button>
         </Card>

          </div>
       ) : null
     } 
      <ToastContainer />





    

      <div className="profileHeadLayout regular">
        <div className="btnSection">
          <button
            onClick={() => {
              navigate("/link-account");
            }}
          >
            Add Social Network
          </button>
        </div>
      </div>
      <div className="PricingOuterLayout">
        <div className="PricingSectionHead">
          <h2 className="regular planHeadingText">Premium Plans</h2>
        </div>
        <div className="PricingSectionBody">
          {/* ------------------------------------------------------------------------------- */}
        
          {packageData.map((pack, id) => {
            const packageData = localStorage.getItem("userProfileInfo");
            const packkkk = JSON.parse(packageData).subscription.split(" ")[0];
            if (pack.packageText == packkkk) {
              return (
                <>
                  <div className="PlanBoxOuterLayout">
                    <div
                      className="PlanBox regular"
                      style={{ background: "#fdf1e4" }}
                    >
                      <p>
                        <span style={{ fontSize: "35px", color: "#1c2466" }}>
                          {pack.packageText}.
                        </span>
                        <span style={{ color: "#ff9000", fontSize: "50px" }}>
                          <span className="bold" style={{ marginLeft: "60px" }}>
                            ${pack.packageAmount.split("$")}
                          </span>
                          <span
                            style={{
                              color: "rgb(53 88 119)",
                              fontSize: " 14px",
                            }}
                          >
                            +18% GST
                          </span>
                        </span>
                        <span
                          style={{ color: "rgb(53 88 119)", fontSize: "16px" }}
                        >
                          {pack.packageDays <= 31
                            ? " Per Month"
                            : pack.packageDays <= 90 && pack.packageDays >= 32
                            ? "Quarterly"
                            : pack.packageDays <= 365
                            ? "Annually"
                            : ""}
                          {/* {pack.packageDays} Days */}
                        </span>
                      </p>
                      <p
                        style={{
                          float: "left",
                          textAlign: "left",
                          marginLeft: "50px",
                          marginTop: "-5px",
                        }}
                      >
                        <span
                          style={{                                                                  
                            marginBottom: "10px",
                            color: "rgb(53 88 119)",
                          }}
                        >                                                                                                                   
                          <img
                            src={require("../../assets/icons8-check-641.png")}
                            height="20px"
                            width="20px"
                            style={{ marginRight: "10px" }}
                            alt="check"
                          />
                          Unlimited posts
                        </span>
                        <span
                          style={{
                            marginBottom: "10px",
                            color: "rgb(53 88 119)",
                          }}                                                                                                                                                                                                                                                                  
                        >
                          <img
                            src={require("../../assets/icons8-check-641.png")}
                            height="20px"
                            width="20px"
                            style={{ marginRight: "10px" }}
                            alt="check"
                          />
                          Unlimited Social Accounts
                        </span>
                      </p>
                    </div>
                    <button    
                      className="regular"
                      onClick={
                        () =>{
                          // checkoutHandler(
                          //   AmountWithGST(pack.packageAmount),
                          //   `${pack.packageText} Plan`
                          // )
                          localStorage.setItem("Selectedplan",JSON.stringify(pack))

                        setSelectedPlan({
                          plan: pack.packageText,
                          days: pack.packageDays,
                          // amount: parseInt(pack.packageAmount) +  parseInt(pack.packageAmount) * 0.18 + `$` ,
                          amount: pack.packageAmount,
                        })
                          // setSelectMethod(true)

                          checkoutHandler(
                            AmountWithGST(pack.packageAmount),
                            `${pack.packageText} Plan`
                          )

                          // paymentHandler(pack.packageAmount, pack.packageText,pack.packageDays)

                        // AmountWithGST(pack.packageAmount)
                        }
                      }
                    >
                      Selected{" "}
                    </button>
                  </div>
                </>
              );
            } else {
              return (
                <>
                  <div className="PlanBoxOuterLayout">
                    <div
                      className="PlanBox regular"
                      style={{ background: "#e5f0f8" }}
                    >
                      <p>
                        <span style={{ fontSize: "35px", color: "#1c2466" }}>
                          {pack.packageText}
                        </span>
                        <span style={{ color: "#ff9000", fontSize: "50px" }}>
                          <span className="bold" style={{ marginLeft: "60px" }}>
                            ${pack.packageAmount.split("$")}
                          </span>
                          <span
                            style={{
                              color: "rgb(53 88 119)",
                              fontSize: " 14px",
                            }}
                          >
                            +18% GST
                          </span>
                        </span>
                        <span
                          style={{ color: "rgb(53 88 119)", fontSize: "16px" }}
                        >
                          {pack.packageDays <= 31
                            ? " Per Month"
                            : pack.packageDays <= 90 && pack.packageDays >= 32
                            ? "Quarterly"
                            : pack.packageDays <= 365
                            ? "Annually"
                            : ""}
                          {/* {pack.packageDays} Days */}
                        </span>
                      </p>
                      <p
                        style={{
                          float: "left",
                          textAlign: "left",
                          marginLeft: "50px",
                          marginTop: "-5px",
                        }}
                      >
                        <span
                          style={{
                            marginBottom: "10px",
                            color: "rgb(53 88 119)",
                          }}
                        >
                          <img
                            src={require("../../assets/icons8-check-641.png")}
                            height="20px"
                            width="20px"
                            style={{ marginRight: "10px" }}
                            alt="check"
                          />
                          Unlimited posts
                        </span>
                        <span
                          style={{
                            marginBottom: "10px",
                            color: "rgb(53 88 119)",
                          }}
                        >
                          <img
                            src={require("../../assets/icons8-check-641.png")}
                            height="20px"
                            width="20px"
                            style={{ marginRight: "10px" }}
                            alt="check"
                          />
                          Unlimited Social Accounts
                        </span>
                      </p>
                    </div>
                    <button
                      className="regular"
                      onClick={
                        () =>{
                          // checkoutHandler(
                          //   AmountWithGST(pack.packageAmount),
                          //   `${pack.packageText} Plan`
                          // )
                        setSelectedPlan({
                          plan: pack.packageText,
                          days: pack.packageDays,
                          // amount: parseInt(pack.packageAmount) +  parseInt(pack.packageAmount) * 0.18 + `$` ,
                          amount: pack.packageAmount,
                        })
                        localStorage.setItem("Selectedplan",JSON.stringify(pack))
                          // setSelectMethod(true)
                          checkoutHandler(
                            AmountWithGST(pack.packageAmount),
                            `${pack.packageText} Plan`
                          ) 

                          // paymentHandler(pack.packageAmount, pack.packageText,pack.packageDays)

                        // AmountWithGST(pack.packageAmount)
                        }
                      }
                    >
                      Select
                    </button>
                  </div>

                  {/* <button onClick={()=>{paymentHandler(AmountWithGST(pack.packageAmount))}}> `${pack.packageText} Plan`</button> */}
                </>
              );
            }
          })}

    

          {/* ------------------------------------------------------------------------------- */}
        </div>
      </div>
      <div className="profilebottom"></div>
      {/* {isCreateNewPostMenu && (
        <CreateNewPost
          pagesDetails={pagesDetails}
          eventData={selectedForm}
          isLoading={isLoading}
          setIsLoading={setIsLoading}
        />
      )} */}
      {isCreateNewPinMenu && (
        <CreatePin
          pagesDetails={pagesDetails}
          eventData={selectedForm}
          isLoading={isLoading}
          setIsLoading={setIsLoading}
        />
      )}
      {isCreateNewBoardMenu && (
        <CreateBoard
          pagesDetails={pagesDetails}
          eventData={selectedForm}
          isLoading={isLoading}
          setIsLoading={setIsLoading}
        />
      )}
    </div>
  );
};

export default Upgrade;
