import './Style.css'
import { Button, Form, FormGroup, Label, Input, FormText, ButtonToggle } from 'reactstrap';
import Select from 'react-select/async';
import { useState, useEffect } from 'react'
import axios from 'axios';
import Slider from 'rc-slider';
import 'rc-slider/assets/index.css';
import {behaviourList} from './behavious'
import { ToggleButton } from 'react-bootstrap';
import { Switch } from '@mui/material';
import { toast } from 'react-toastify';



const  CreateAdsetForm = (props) => {


const [AllpagesData, setAllpagesData] = useState([])
const [selectedPage, setSelectedPage] = useState('')
let userID = JSON.parse(localStorage.getItem("userProfileInfo"))?._id;
const [userId, setUserId] = useState(userID);
    

  const handleChangeAge = range => {
    setAgeRange(range);
  };


  const GetAllPages = async() => {
    await axios.post( process.env.REACT_APP_SERVER_URI +  `/api/toowe/users/${userId}/crud/getPagesDetail`, {jwToken: localStorage?.getItem("token"), token:"EAAb9ifo1PGABO2uDZCitCehOkQAGPOzOzGZCkc97XgZC3KMEmJGZBVs5JAqexIrdk2uQVs3AiojcDFiLacRDJwPDp89bZAht2fr3tdaki0rVZBZC56WkZB961yDnXvR3QpawO3akEtoa4nZAMIgPhPfZBjUKwGclQHzB9GCUOUvpjX7Nk1Yb9lDaEp9pZAXvW0ZD", AdAccountId: props.adAccountId}).then((res)=>{

        console.log(res.data)
        setAllpagesData(res.data)
    })
  }

useEffect(() => {
    GetAllPages()
}, [])


  const handleCreateAdset = () => {
    console.log('create adset')

axios.post( process.env.REACT_APP_SERVER_URI + `/api/toowe/users/${userId}/ads/CreateFacebookAdset`, {
    name: adSetName,
    status: adSetStatus,
    AdAccountId: props.adAccountId ,
    campaign_id: props.AdCampaign ,
    //daily_budget: DailyBudget,
    //lifetime_budget: LifetimeBudget,
    bid_amount: BidAmount,
    billing_event: Billingevent,
    optimization_goal: OptimizationGoal,                                                                           
    start_time: stratDate,
    end_time: endDate,
    jwToken: localStorage?.getItem("token"),
    token:"EAAb9ifo1PGABO2uDZCitCehOkQAGPOzOzGZCkc97XgZC3KMEmJGZBVs5JAqexIrdk2uQVs3AiojcDFiLacRDJwPDp89bZAht2fr3tdaki0rVZBZC56WkZB961yDnXvR3QpawO3akEtoa4nZAMIgPhPfZBjUKwGclQHzB9GCUOUvpjX7Nk1Yb9lDaEp9pZAXvW0ZD",

    target: {
       "age_min": ageRange[0],
       "age_max": ageRange[1],
       "behaviors": selectedBehaviorsOption.map((item)=>{return item.value}),
       "genders": [
         1
       ],
       "geo_locations": {
        "countries": ['IN'] 
       },
       "interests":selectedIntrestsOption.map((item)=>{return item.value}),
       "life_events": [
         {
           "id": 6002714398172,
           "name": "Newlywed (1 year)"
         }
       ],
       "facebook_positions": [                                             
         "feed"
       ],
       "publisher_platforms": [
         "facebook",
         "audience_network"
       ]
     }
    
}

    
    
).then((res)=>{

  if(res.status == 200){
   toast.success('Adset Created Successfully')
   props.getAdsets()
  }else{
    toast.error( res ||'Something Went Wrong')
  }

}).catch((err)=>{
  toast.error( err.response.data.error.error_user_title ||'Something Went Wrong')
  console.log(err)                                                    
}) 
                                                                                                               



       


}

  const [isDailyBudget, setisDailyBudget] = useState(false);
  const [adSetName,setAdsetName] = useState('')
    const [adSetStatus,setAdsetStatus] = useState('ACTIVE')
       const [newGeoLocation,setnewGeoLocation] = useState([])

    const [DailyBudget,setDailyBudget] = useState(0)
    const [LifetimeBudget,setLifetimeBudget] = useState(0)
    const [BidAmount,setBidAmount] = useState(0)
    const [stratDate,setStratDate] = useState('')
    const [endDate,setEndDate] = useState('')
const [Billingevent,setBillingevent] = useState('IMPRESSIONS')  
const [OptimizationGoal,setOptimizationGoal] = useState('REACH') 
 const [ageRange, setAgeRange] = useState([18, 65]);
    const [selectedGenderOption, setselectedGenderOption] = useState([]);
 const [selectedIntrestsOption, setselectedIntrestsOption] = useState([]);
 const [selectedBehaviorsOption, setselectedBehaviorsOption] = useState([]);
 const [selectedDemographicsOption, setselectedDemographicsOption] = useState([]);




  const loadIntrestsOptions = (inputValue, callback) => {
    axios.get(`https://graph.facebook.com/v21.0/search?type=adinterest&q=${inputValue}&access_token=EAAG7aKk5lloBAAJUWkZCTyYjDZCpI6zKRi881BGNH8M0RZBV37YZCj7vRtwml9SM2ptpDZAqVa8NbwbqtMDUOhDUVw8hgkF4ZCYzNIAEfTuAFiZA6KckGfYXUAlSC32JKZConw6BMduZCymqxmpeVVeQsZAE8HtxPNIt4Kjhpru7rCrxFZBRCWaIZAl2HClX8200mfIZD`)
      .then(response => {
console.log(response)

        const options = response.data.data.map(option => ({
          value: { id: option.id,name:option.name},
          label: option.name
        }));
        console.log(options)
        callback(options);
      })
      .catch(error => {
        console.log(error);
        callback([]);
      });
  };


    const loadBehaviorsOptions = (inputValue, callback) => {

//         axios.get(`https://graph.facebook.com/v15.0/search?type=adTargetingCategory&class=behaviors&q=${inputValue}&access_token=EAAG7aKk5lloBAAJUWkZCTyYjDZCpI6zKRi881BGNH8M0RZBV37YZCj7vRtwml9SM2ptpDZAqVa8NbwbqtMDUOhDUVw8hgkF4ZCYzNIAEfTuAFiZA6KckGfYXUAlSC32JKZConw6BMduZCymqxmpeVVeQsZAE8HtxPNIt4Kjhpru7rCrxFZBRCWaIZAl2HClX8200mfIZD`)
//         .then(response => {
// console.log(response)
console.log(behaviourList)

const list = behaviourList()

        const options = list.map(option => ({
            value: { id: option.id,name:option.name},
            label: option.name
        }));

        callback(options);
        // })
        // .catch(error => {
        // console.log(error);
        // callback([]);
        // });
    };

    
let behlist = behaviourList()

    const behaviorsOptions = behlist.map(option => ({
        value: option,
        label: option.name
    }));


    const loadDemographicsOptions = (inputValue, callback) => {


        axios.get(`https://graph.facebook.com/v21.0/search?type=adgeolocation&class=demographics&q=${inputValue}&access_token=EAAG7aKk5lloBAAJUWkZCTyYjDZCpI6zKRi881BGNH8M0RZBV37YZCj7vRtwml9SM2ptpDZAqVa8NbwbqtMDUOhDUVw8hgkF4ZCYzNIAEfTuAFiZA6KckGfYXUAlSC32JKZConw6BMduZCymqxmpeVVeQsZAE8HtxPNIt4Kjhpru7rCrxFZBRCWaIZAl2HClX8200mfIZD`)
        .then(response => {
// console.log(response)

        const options = response.data.data.map(option => ({
            value: option,
            label: option.name
        }));
    
  

   
        console.log(options)
        callback(options);
        })
        .catch(error => {
        console.log(error);
        callback([]);
        });
    };

useEffect(() => {
   
    console.log(selectedDemographicsOption)

//     const result = {};
//           selectedDemographicsOption.forEach(item => {
//   const { value } = item;
//   if (!result[value.type]) {
//     result[value.type] = [];
//   }
//   result[value.type].push(value.key);
// });

// console.log(result)
}, [selectedDemographicsOption])



  const handleIntrestChange = selected => {
    setselectedIntrestsOption(selected);
  };


  const handleBehaviorsChange = selected => {
    setselectedBehaviorsOption(selected);
    };


    const handleDemographicsChange = selected => {
        setselectedDemographicsOption(selected);
            const result = {};
          selectedDemographicsOption.forEach(item => {
  const { value } = item;
  if (!result[value.type]) {
    result[value.type] = [];
  }
  if(value.type === 'city'){
    result[value.type].push({key:value.key, radius:20, distance_unit:'mile'});
  }else if(value.type === 'country'){
  result[value.type].push(value.key);
  }else if(value.type === 'region'){
    result[value.type].push({key:value.key});
  }
});
setnewGeoLocation(result)
console.log(result)
    };


//   return (
//     <Select
//       options={options}
//       isMulti
//       value={selectedIntrestsOption}
//       onChange={handleChange}
//     />
//   );
// };







  const handleSwitchChange = () => {
    setisDailyBudget(!isDailyBudget);
  };







    return(
                <div className="BigBox-adsets">
                  <div className='form-containerAdsets'>

 <div class="withCloseButton">
  <div class="calender_info withBorderLeft"><h5>Create New Ad Set</h5> </div><div>
  <button onClick={()=>props.setShowCreateAdsetForm(false)}>X</button>  </div></div>

<div className='CreateAdsFormContainerDiv'>
<div className='form_align_wrp'>

<div className='row'>
<div className='col-lg-4'>
<FormGroup>
          <Label for="Name">Adset Name</Label>
          <Input type="text" name="name" onChange={(e)=> setAdsetName(e.target.value)} id="name" placeholder="Enter your Adset Name" />
      
       </FormGroup> 
</div>
</div>


   

<FormGroup> 
<div className='row'>  
  <div className='col-lg-3'>

    <label for="start_time">Start Time</label>
    <Input type="datetime-local" onChange={(e)=> setStratDate(e.target.value)} id="start_time" name="start_time" />
    </div> 
    <div className='col-lg-3'>

 <label for="end_time">End Time</label>
    <Input type="datetime-local" onChange={(e)=> setEndDate(e.target.value)} id="end_time" name="end_time" />
    </div>
    </div>
</FormGroup> 
  

       


{/* <FormGroup>
    <label for='Is Daily Budget'>Is Daily Budget?</label>
  <Switch
        checked={isDailyBudget}
        onChange={handleSwitchChange}
        name="checked"
        color="primary"
      /> */}
      {/* <p>{isDailyBudget ? 'Switch is ON' : 'Switch is OFF'}</p> */}
{/* </FormGroup> */}

{/* {
    isDailyBudget ?
    (
         <FormGroup>
          <Label for="daily_budget">daily budget</Label>
          <Input type="number" name="daily_budget" onChange={(e)=> setDailyBudget(e.target.value)}  id="daily_budget" placeholder="Enter your Daily Budget" />
        </FormGroup>

    ): (
         <FormGroup>
          <Label for="lifetime_budget">Lifetime budget</Label>
          <Input type="number" name="lifetime_budget" onChange={(e)=> setLifetimeBudget(e.target.value)} id="lifetime_budget" placeholder="Enter your Life Time Budget" />
        </FormGroup>
    )

} */}


{/* 
        <FormGroup>
          <Label for="lifetime_budget">Lifetime budget</Label>
          <Input type="number" name="lifetime_budget" id="lifetime_budget" placeholder="Enter your Life Time Budget" />
        </FormGroup> */}


{/* <FormGroup>
   </FormGroup> */}

<div className='row'>
<div className='col-lg-3'>
<FormGroup>
    <label for="bid_amount">Bid Amount</label>
    <Input type="number" onChange={(e)=> setBidAmount(e.target.value)} id="bid_amount" name="bid_amount" />
</FormGroup>
 </div>
<div className='col-lg-3'>
<FormGroup>
    <label for="billing_event">Billing Event</label>
    <Input type="select" id="billing_event" onChange={(e)=> setBillingevent(e.target.value)} name="billing_event" defaultValue={''}>
        <option value="IMPRESSIONS">Impressions</option>
        <option value="POST_ENGAGEMENT">Post Engagement</option>
                <option value="PAGE_LIKE">Page Like</option>

        <option value="LINK_CLICKS">Link Clicks</option>
     </Input>
</FormGroup>
</div>
</div>
<div className='row'>
<div className='col-lg-3'> 
<FormGroup>
    <label for="optimization_goal">Optimization Goal</label>
    <Input type="select" onChange={(e)=> setOptimizationGoal(e.target.value)} id="optimization_goal" name="optimization_goal">
                <option value="REACH">CLICKS</option>
                <option value="PAGE_LIKES">PAGE_LIKES</option>
        <option value="IMPRESSIONS">IMPRESSIONS</option>
        <option value="LINK_CLICKS">TRAFFIC</option>
        </Input>
</FormGroup>
</div>
<div className='col-lg-3'> 

<FormGroup>
    <label for="pages">Select Page</label>
<Input type="select" onChange={(e)=> setSelectedPage(e.target.value)} name="pages" id="pages">
  {
      AllpagesData.map((page, index)=>{
          return(
            <option key={index} value={page}>{page.name}</option>
          )
      })
  }
  </Input>
</FormGroup>
</div>

</div>

<div className='row'>
<div className='col-lg-6'>  
  <FormGroup>
    <label for='age_range'>Age Range</label>
      <Slider
        range
        min={18}
        max={65}
        value={ageRange}
        onChange={handleChangeAge}
      />
      <p>{ageRange[0]} - {ageRange[1]}</p>
    </FormGroup> 
    </div></div>
    <div className='row'> 
<div className='col-lg-3'> 

<FormGroup>
    <label for="intrests">Intrests</label>
<Select
      isMulti
      value={selectedIntrestsOption}
      onChange={handleIntrestChange}
      loadOptions={loadIntrestsOptions}
    />
</FormGroup>
</div>
</div>

<div className='row'>

<FormGroup>
<div className='col-lg-3'> 
    <label for="behaviors">Behaviors</label>
<Select
      isMulti
      value={selectedBehaviorsOption}
      onChange={handleBehaviorsChange}
      options={behaviorsOptions}

      
      loadOptions={loadBehaviorsOptions}
    /></div>
</FormGroup>

<div className='col-lg-3'> 
<FormGroup>
    <label for="demographic_regions">Demographic Regions</label>
<Select
      isMulti
      value={selectedDemographicsOption}
      
      onChange={handleDemographicsChange}
      loadOptions={loadDemographicsOptions}
    />
</FormGroup>
</div>
<div className='row'>
<div className='col-lg-3'> 

        <button className='btn btn-success' style={{marginBottom:'30px'}} onClick={handleCreateAdset}>Submit</button>
     
        </div>   
      </div>
</div>

</div>
<div>
   




</div>
</div>

</div>
</div>
    )

}


export default CreateAdsetForm;