import { ClassNames } from "@emotion/react"
import chartads from"../../assets/fb_clicls_graph.png";
import piechart from"../../assets/grap_ads_pie.png";
import "./AdInsights.css";

const AdsInsightsPage = ({userInfo}) => { 
 
return (
    <div className="calenderModuleCard">
        <div className="AdsBodyContainer">
    <div class="calender_top"><div class="margin-left"></div>
    <div class="calender_info"><h5>Ad Insights </h5><span class="info_lbl__">Account ID: []</span></div>
    <div class="account_select_wrp"><select class="account_select">
        <option value="">Select AD Account id</option></select>
        </div>
    </div>
    <div className="list_items_wrp">
<div className="row mt-5" >
<div className="col-lg-5"> 
    <h5>Clicks - 20,045</h5>
    <img src={chartads} alt="Meta Ads" width="100%"/>
</div>

<div className="col-lg-5"> 
<h5>Plaforms</h5>
    <img src={piechart} alt="Meta Ads" width="100%"/>
</div> 
</div>
<div className="row mt-5" >
    <div className="col-lg-2">
        <div className="block_styles">
            <label>Clicks</label>
            <span>1,600</span>
        </div>
    </div>
    <div className="col-lg-2">
        <div className="block_styles">
            <label>Impressions</label>
            <span>2,800</span>
        </div>
    </div>
    <div className="col-lg-2">
        <div className="block_styles">
            <label>Amount Spend</label>
            <span>$21,100</span>
        </div>
    </div>
    <div className="col-lg-2">
        <div className="block_styles">
            <label>Avg CPC</label>
            <span>$5</span>
        </div>
    </div>
    <div className="col-lg-2">
        <div className="block_styles">
            <label>Avg CPC</label>
            <span>1,600</span>
        </div>
    </div>
</div>


<div className="row mt-5" >
    <div className="col-lg-2">
        <div className="block_styles">
            <label>Avg CPM</label>
            <span>$0.5</span>
        </div>
    </div>
    <div className="col-lg-2">
        <div className="block_styles">
            <label>CTR</label>
            <span>6.58%</span>
        </div>
    </div>
    <div className="col-lg-2">
        <div className="block_styles">
            <label>Page Likes</label>
            <span>1,100</span>
        </div>
    </div>
    <div className="col-lg-2">
        <div className="block_styles">
            <label>Cost/Like</label>
            <span>$14</span>
        </div>
    </div>
    <div className="col-lg-2">
        <div className="block_styles">
            <label>Cost/Post Reaction</label>
            <span>$8</span>
        </div>
    </div>
</div>

</div>

 </div>
{/* body container ends */}


 </div>




)
    }
     
export default AdsInsightsPage
