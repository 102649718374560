import {  Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import React, { useState, useEffect } from 'react';
import {Button} from '@mui/material';
import { Card } from '@mui/material';
import { CardMedia, CardContent, Typography, CardActions } from "@mui/material";
import axios from 'axios';
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from 'chart.js';
import { Pie } from 'react-chartjs-2';






export default function FacebookPostAnalyticsModal(props) {

const [post_analytics, setPost_analytics] = useState([
    {
      "name": "Post Reactions Like Total",
      "value": 0
    },
    {
      "name": "Post Reactions Love Total",
      "value": 0
    },
    {
      "name": "Post Reactions Wow Total",
      "value": 0
    },
    {
      "name": "Post Engaged Users",
      "value": 2
    },
    {
      "name": "Post Impressions",
      "value": 1
    },
    {
      "name": "Post Impressions Unique",
      "value": 1
    },
    {
      "name": "Post Impressions Fan",
      "value": 0
    },
    {
      "name": "Post Impressions Organic",
      "value": 1
    },
    {
      "name": "Post Impressions Paid",
      "value": 0
    },
    {
      "name": "Avg Sentiment Score",
      "value": -0.33
    }
  ])


const getPostAnalytics = async () => {
  let userId = JSON.parse(localStorage.getItem("userProfileInfo"))?._id;
    const data = await axios.post(process.env.REACT_APP_SERVER_URI + `/api/toowe/users/${userId}/deepAnalytics/facebookPostsAnalyticsData`, {
        postId: props.selectedPostAnalyticsId,
        pageId: props.SelectedPageId,
        jwToken: sessionStorage?.getItem("token"),

    })
    console.log(data?.data)

    setPost_analytics(data?.data)

}

useEffect(() => {
  props.selectedPostAnalyticsId &&  getPostAnalytics()
}, [props.selectedPostAnalyticsId])



    const [modal, setModal] = useState(props.isPostAnalytics);
    useEffect(() => {
        setModal(props.isPostAnalytics)
    }, [props.isPostAnalytics])

    const toggle = () =>{ 
        setModal(!modal) 
         props.setIsPostAnalytics(false)
        };




        ChartJS.register(ArcElement, Tooltip, Legend);

         const data = {
          labels: [post_analytics[0]?.name, post_analytics[1]?.name, post_analytics[2]?.name],
          datasets: [
            {
              label: '# of Votes',
              data: [post_analytics[0]?.value, post_analytics[1]?.value, post_analytics[2]?.value ],
              backgroundColor: [
                'rgba(255, 99, 132, 0.2)',
                'rgba(54, 162, 235, 0.2)',
                'rgba(255, 206, 86, 0.2)',
                // 'rgba(75, 192, 192, 0.2)',
                // 'rgba(153, 102, 255, 0.2)',
                // 'rgba(255, 159, 64, 0.2)',
              ],
              borderColor: [
                'rgba(255, 99, 132, 1)',
                'rgba(54, 162, 235, 1)',
                'rgba(255, 206, 86, 1)',
                // 'rgba(75, 192, 192, 1)',
                // 'rgba(153, 102, 255, 1)',
                // 'rgba(255, 159, 64, 1)',
              ],
              borderWidth: 1,
            },
          ],
        };



    return(
        <div>
         {/* <Button color="danger" onClick={toggle}>
        Click Me
      </Button> */}
      <Modal isOpen={modal} toggle={toggle} fullscreen >
        <ModalHeader toggle={toggle}>
        <h2 className="modal__header">Post Analytics  <Button size="small"  href={`https://facebook.com/`+ props.selectedPostAnalyticsId}  /* `target='_blank'` is an attribute used in HTML to open a link in a new browser tab or window when clicked. In this code, it is used in the `Button` component to open the Facebook post in a new tab when the user clicks on it. */
        target='_blank' className="btn_smal">view post</Button>
            <br/><small className="prop_name_cust">[{props.selectedPostAnalyticsId}]</small>

        
        </h2> 
         
 </ModalHeader>
        <ModalBody>

<div className="row">
    <div className="col-lg-4">

  <Card className="card_cust_wrp" sx={{ maxWidth: 450 }} style={{width:'95%', margin:'10px'}}>
        <Pie data={data} />
        {
           post_analytics[0]?.value+ post_analytics[1]?.value+ post_analytics[2]?.value == 0 ?
                 <div className="empty_data_wrp"><b> No Data Available</b></div> : null
            

        }
        </Card>
    </div>
<div className="col-lg-8">  

{
    post_analytics.length > 0 &&
    post_analytics?.map((item, index) => {
return(
        
    <div  className="tiles__ fl_custm" >
     <Typography variant="p" >
         {item?.name}
        </Typography>

       

        <Typography  variant="h2" component="div" className="val_ues">
         {item?.value} 
        </Typography>
        <Typography variant="body2" color="text.secondary">
        
        </Typography>
       

        </div>
      
    
)


    })
}

{
    post_analytics.length > 0 &&
    post_analytics.forEach((item, index) => {
       return <div> {item?.name}</div>

    })
}




    </div>


</div>
         

            {/* {
                props.selectedPostAnalyticsId
            } */}



{/* {
    post_analytics.map((item, index) => {
        return <div> {item?.name}</div>

    })
} */}

     
       </ModalBody>
        {/* <ModalFooter>
          <Button color="primary" onClick={toggle}>
            Do Something
          </Button>{' '}
          <Button color="secondary" onClick={toggle}>
            Cancel
          </Button>
        </ModalFooter> */}
      </Modal>
        </div>
    )}