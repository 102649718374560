import { useState, useEffect } from "react";
import Drawer from "react-modern-drawer";
import { Card, CardBody, CardTitle, CardText } from 'reactstrap';






export default function Notification({ toggelDrawer, isopen  }) {


    const notifications = [
        {
          title: "Notification 1",
          description: "This is the first notification",
          reason: "success",
          date: new Date(),
          id: 1
        },
        {
          title: "Notification 2",
          description: "This is the second notification",
          reason: "failed",
          date: new Date(),
          id: 2
        },
        {
          title: "Notification 3",
          description: "This is the third notification",
          reason: "warning",
          date: new Date(),
          id: 3
        },
        {
          title: "Notification 4",
          description: "This is the fourth notification",
          reason: "general",
          date: new Date(),
          id: 4
        },
         {
                  title: "Notification 1",
                  description: "This is the first notification",
                  reason: "success",
                  date: new Date(),
                  id: 5
                },
                {
                  title: "Notification 2",
                  description: "This is the second notification",
                  reason: "failed",
                  date: new Date(),
                  id: 6
                },
                {
                  title: "Notification 3",
                  description: "This is the third notification",
                  reason: "warning",
                  date: new Date(),
                  id: 7
                },
                {
                  title: "Notification 4",
                  description: "This is the fourth notification",
                  reason: "general",
                  date: new Date(),
                  id: 8
                }
      ];




    return (


        <div className="notification">

    <Drawer
                open={isopen}
                onClose={toggelDrawer}
                direction='right'
                size={400}
                className='bla bla bla'
            >

              <h2 className="title_homepage popup_heading__ ">Notifications</h2>

<div className="form_styles__" style={{marginTop:'50px', display:'flex', alignItems:'start', flexDirection:'column', margin:'25px', scrollBehavior: 'smooth', overflow: 'scroll', height: '85vh'}}>


{
    notifications.map((notification, index) => {
        return (

<div key={index} className="w-80 bg-blue text-sm">
  <Card style={{margin:'5px', width:'330px', textAlign:'left'}}>
    <CardBody>
      <CardTitle tag="h6">{notification.title}</CardTitle>
      <CardText>{notification.description}</CardText>
      <CardText>{notification.date.toDateString()}</CardText>
    </CardBody>
  </Card>


</div>
        )
    })
}




  </div>


                
    
            </Drawer>

        </div>


    )
    }
