import react, { useEffect, useState } from "react";
import {
  Route,
  Routes,
  useNavigate,
  Navigate,
  Outlet,
  useLocation,
} from "react-router-dom";
import "./App.css";
import NoSideBarLayout from "./Components/NoSideBarLayout/NoSideBarLayout";
// import SideBarLayout from "./Components/SideBarLayout/SideBarLayout";
import addSocialMediaAccounts from "./assets/add_new_social.png";
import Dashboard from "./Pages/Dashboard/Dashboard";
import LinkAccount from "./Pages/LinkAccount/LinkAccount";
import LoginPage from "./Pages/LoginPage/LoginPage";
import QuestionsPage from "./Pages/QuestionsPage/QuestionsPage";
import SignUp from "./Pages/SignUp/SignUp";
import LinkedIn from "./Pages/LinkAccount/LinkedIn";
import ForgotPassword from "./Pages/ForgotPassword/ForgotPassword";
import {
  getPageDetailsApi,
  getPaymentInfoAPI,
  getUserEmailsList,
  updateUserProfileInfo,
} from "./Utils/api";
// import Pinterest from "./Pages/LinkAccount/Pinterest";
import Analytics from "./Pages/Dashboard/Analytics/Analytics";
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.min";
import axios from "axios";
import { saveACcountsLinked, savePageDetails } from "./Utils/api";
import Calendar from "./Pages/Dashboard/Calender/Calenders";
import AuthAPI from "./Utils/AuthAPI";
import { isLoggedIn } from "./Utils/api";
import NewSideBarUI from "./Components/NewSideBarUI/NewSideBarUI";
// import NewAnalyticsPage from "./Pages/Dashboard/Analytics/NewAnalyticsPage";
import StreamScreen from "./Pages/StreamsModule/StreamScreen";
import NewAnalyticsPage from "./Pages/Dashboard/Analytics/NewAnalyticsPage";
import NewAnalyticsPageV2 from "./Pages/Dashboard/Analytics/NewAnalyticsPageV2";
import DeepAnalyticsPage from "./Pages/Dashboard/DeepAnalytics/NewAnalyticsPage";
import EmailVerify from "./Pages/EmailVerify/verifyEmail";
import CreateNewPost from "./Pages/Dashboard/CreateNewPost";
import Upgrade from "./Pages/Upgrade/Upgrade";
import Profile from "./Pages/Profile/profile";
import AdminSideBar from "./Components/AdminSideBarLayout/AdminSideBar";
import AdminDashboard from "./Pages/Admin/AdminDashboard";
import AdminQuestion from "./Pages/Admin/Questions/question";
import Packages from "./Pages/Admin/Packages/packages";
import AdminLogin from "./Pages/Admin/Login/adminLogin";
import CreateNewList from "./Pages/CreateNewList/CreateNewList";
import { useSearchParams } from "react-router-dom";
import { GetDateDiffofFree, GetDateDiffofPaid } from "./Utils/GetCurrentDate";
import SubcriptionNotification from "./Components/SubcriptionNotification/SubcriptionNotification";
import Message from "./Pages/Message/Message";
import Loader from "./Components/Loader";
import VerifyPassword from "./Pages/PasswordVerification/VerifyPassword/verifyPassword";
import Page_404 from "./Pages/404/404";
import Analysis from "./Pages/CompetitorAnalysis/Analysis";
import ReactGA from "react-ga";
import ViewUsersQuestions from "./Pages/Admin/ViewUserQuestions/ViewUsersQuestions";
import UpgradeSubscription from "./Pages/Admin/UpgradeSubscription/UpgradeSubscription";
import BacklinksUpload from "./Pages/Admin/BacklinksUpload/BacklinksUpload";
import ArticlesUpload from "./Pages/Admin/ArticlesUpload/ArticlesUpload";
import DirectoriesUpload from "./Pages/Admin/DirectoriesUpload/DirectoriesUpload";
import CSVindex from "./Pages/Dashboard/CSVdata/csvindex";
import GoogleAnalytics from "./Pages/Dashboard/GoogleAnalytics/googleAnalytics";
import PostsGenerator from "./Pages/Dashboard/AiPostGenerator/Main";
import Timeline from "./Pages/UserTimeline/Timeline";
import MediaLibrary from "./Pages/MediaLibrary/MediaLibrary";
import Ads from "./Pages/Ads/Ads";
import Seo from "./Pages/Seo/Seo";
import AdsLibrary from "./Pages/AdsLibrary/AdsLibrary";
import AdsInsightsPage from "./Pages/AdInsights/AdInsights";


const TRACKING_ID = "G-X6X71QHS77"; // OUR_TRACKING_ID

ReactGA.initialize("G-X6X71QHS77");

const AdminRoute = () => {
  if (
    localStorage.getItem("token") &&
    JSON.parse(localStorage.getItem("userProfileInfo")).role === "admin"
  ) {
    return <Outlet />;
  } else if (
    localStorage.getItem("token") &&
    JSON.parse(localStorage.getItem("userProfileInfo")).role === "user"
  ) {
    return <Navigate to="/" />;
  } else {
    return <Navigate to="/admin-login" />;
  }
};



function App() {

  useEffect(() => {
    ReactGA.pageview(window.location.pathname + window.location.search);
    console.log(window.location.pathname + window.location.search);
  }, [window.location.pathname]);

  const navigate = useNavigate();
  const [daysLeft, setDaysLeft] = useState(null);
  let userID = JSON.parse(localStorage.getItem("userProfileInfo"))?._id;
  const [userId, setUserId] = useState(userID);
  const baseURL = `${process.env.REACT_APP_SERVER_URI}/api/toowe/users/${userId}/`;

  const expiryDate =  JSON.parse(
    localStorage.getItem("userProfileInfo")
  )?.planExpiry;
  console.log("expiryDate", expiryDate);


  const RemainingDays = () => {
    const currentDateInSeconds = Math.floor(Date.now() / 1000);
    const remainingSeconds = expiryDate - currentDateInSeconds;
    const days = Math.floor(remainingSeconds / (24 * 60 * 60));
    console.log("days", days, expiryDate);
    setDaysLeft(2);
  };

  useEffect(() => {
    ReactGA.pageview(window.location?.pathname + window.location.search);
  }, []);
  useEffect(() => {
    RemainingDays();
    console.log("left days ------------------>>>>>>>>>>>>>>>" + daysLeft);
    const currentDateInSeconds = Math.floor(Date.now() / 1000);
    const remainingSeconds = expiryDate - currentDateInSeconds;
    const days = Math.floor(remainingSeconds / (24 * 60 * 60));
    console.log("days", days, expiryDate);
    if (remainingSeconds <= currentDateInSeconds) {
      navigate("/upgrade");
    }
  }, []);

  const location = useLocation();

  const [searchParams] = useSearchParams();

  const [role, setRole] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [subscriptionType, setSubscriptionType] = useState("");
  const [renewalDaysLeft, setRenewalDaysLeft] = useState();
  const [listArray, setListArray] = useState({});
  const [listRecord, setListRecord] = useState("");
  const [twitterData, setTwitterData] = useState({
    oauth_token: "",
    oauth_token_secret: "",
    oauth_verifier: "",
  });

  const [auth, setAuth] = useState(false);
  const [pagesDetails, setPagesDetails] = useState([]);
  const [allFbPagesDetails, setAllFbPagesDetails] = useState([]);
  const [loginState, setLoginState] = useState({
    email: "",
    password: "",
  });

  console.log(allFbPagesDetails);
  console.log(pagesDetails);

  const [userInfo, setUserInfo] = useState({});

  const planExpiry = JSON?.parse(
    localStorage.getItem("userProfileInfo")
  )?.planExpiry;

  // <-------------------------------Admin State------------------------------------>

  const [adminLoginState, setAdminLoginState] = useState({
    adminEmail: "",
    adminPassword: "",
  });

  // const isLogged = async () => {
  //   const res = await isLoggedIn()
  //   if(res.status === true){
  //     setAuth(true)
  //   }
  //   else{
  //     setAuth(false)
  //   }
  // }

  // useEffect(()=>{
  //   isLogged()
  // }, [pagesDetails])

  // const checkSession = async () => {
  //   if (localStorage.getItem("token") || sessionStorage.getItem("token")) {
  //     const res = await isLoggedIn({
  //       jwToken:
  //         localStorage.getItem("token") || sessionStorage.getItem("token"),
  //     });
  //     if (!sessionStorage.getItem("token"))
  //       sessionStorage.setItem("token", localStorage.getItem("token"));
  //     setUserInfo(res.data);
  //     setAuth(true);
  //   }
  // };

  const checkSession = () => {
    if (localStorage.getItem("token") || sessionStorage.getItem("token")) {
      isLoggedIn({
        jwToken:
          localStorage.getItem("token") || sessionStorage.getItem("token"),
      })
        .then((res) => {
          setRole(res.data.role);
          if (!sessionStorage.getItem("token"))
            sessionStorage.setItem("token", localStorage.getItem("token"));
          setUserInfo(res.data);
          setAuth(true);
        })
        .catch(() => {
          localStorage.clear();
          sessionStorage.clear();
          navigate("/");
        });
    }
  };

  useEffect(() => {
    checkSession();
  }, []);

  const [signUpState, setSignUpState] = useState({
    fName: "",
    email: "",
    password: "",
    confirmPassword: "",
    otp:"",
    userQuestionDetails: [],
  });

  const OnclickCreateUser = () => {
    navigate("/link-account");
  };

  async function fetchPagesInfo(params) {
    try {
      const body = {
        jwToken: sessionStorage.getItem("token"),
      };
      const res = await getPageDetailsApi(body);

      setPagesDetails(res.data);
      console.log(pagesDetails);
    } catch (error) {
      console.log(error);
    }
  }

  const [page, setPage] = useState(false);

  const fetchMyList = async (id) => {
    const res = await getUserEmailsList({ _id: id });
    console.log("listArray", res);
    setListArray(res.data.MailDataList);
  };

  useEffect(() => {
    if (Object.keys(userInfo).length === 0) {
      return;
    }
    console.log("isRunning");
    fetchMyList(userInfo._id);
    fetchPagesInfo();
  }, [userInfo]);

  // <---------------- Facebook ----------------------->

  async function saveAccountsVerified(params) {
    await saveACcountsLinked(params);
  }

  async function savePagesInfo(params) {
    // console.log("Calling savePagesInfo");
    console.log(params);
    const res = await savePageDetails(params);
    console.log(res);
  }

  const checkFacebookAuth = () => {
    window.FB.getLoginStatus(function (response) {
      if (response.status === "connected") {
        console.log("Account is loggged in");
        let token = response.authResponse.accessToken;
        let id = response.authResponse.userID;
        if (response.authResponse) {
          window.FB.api("/me", function (response) {
            console.log(response);
            const PagesCredentials = {
              token,
              id,
              jwToken: sessionStorage.getItem("token"),
            };
            savePagesInfo(PagesCredentials);
          });
        }
      } else if (response.status === "not_authorized") {
        const onFacebookLoginClick = () => {
          window.FB.login(function (response) {
            console.log("auth", response);
            let token = response.authResponse.accessToken;
            let id = response.authResponse.userID;
            if (response.authResponse) {
              console.log("Welcome!  Fetching your information.... ");
              window.FB.api("/me", function (response) {
                const AccountsData = {
                  accounts: ["facebook"],
                  user: userInfo._id,
                };
                const PagesCredentials = {
                  token,
                  id,
                  user: userInfo._id,
                };
                console.log(response);
                saveAccountsVerified(AccountsData);
                savePagesInfo(PagesCredentials);
              });
            } else {
              console.log("User cancelled login or did not fully authorize.");
            }
          });
        };

        onFacebookLoginClick();
      } else {
        console.log("Dont know");
      }
    });

    window.fbAsyncInit = () => {
      window.FB.init({
        appId: process.env.REACT_APP_FB_APP_ID,
        autoLogAppEvents: true,
        xfbml: true,
        version: "v14.0",
      });
    };
    (function (d, s, id) {
      var js,
        fjs = d.getElementsByTagName(s)[0];
      if (d.getElementById(id)) {
        return;
      }
      js = d.createElement(s);
      js.id = id;
      js.src = "https://connect.facebook.net/en_US/sdk.js";
      fjs.parentNode.insertBefore(js, fjs);
    })(document, "script", "facebook-jssdk");
  };
  // <-------------- Facebook End --------------------->

  const socials = {
    linkedin: {
      name: "Linkedin",
      AuthFunction: (params) => {
        axios
          .post(`${baseURL}toowe/users/${userId}crud/introspectLinkedinToken`, {
            client_id: process.env.REACT_APP_LINKEDIN_CLIENT_ID,
            client_secret: process.env.REACT_APP_LINKEDIN_CLIENT_SECRET,
            token: params.token,
          })
          .then(() => console.log("Linkedin Auth Token is valid"))
          .catch(() => {
            console.log("Invalid/Expired Auth Token Linkedin");
            const popup = window.open(
              `https://www.linkedin.com/oauth/v2/authorization?response_type=code&client_id=${process.env.REACT_APP_LINKEDIN_CLIENT_ID}&scope=r_liteprofile%20r_emailaddress%20w_member_social&state=123456&redirect_uri=${process.env.REACT_APP_REDIRECT_URI}/linkedin`,
              "Linkedin Auth",
              `minWidth=200,minHeight=200`
            );

            if (popup.location.pathname === "/dashboard") {
              popup.close();
            }
            window.location.reload(true);
          });
      },
    },
    facebook: {
      name: "Facebook",
      AuthFunction: (params) => {
        axios
          .post(`${baseURL}toowe/users/${userId}/crud/introspectFacebookToken`, {
            token: params.token,
          })
          .then(() => console.log("Facebook Auth Token is valid"))
          .catch(() => {
            console.log("Token isn't valid");
            checkFacebookAuth();
          });
      },
    },
    instagram: {
      name: "Instagram",
      AuthFunction: (params) => {
        axios
          .post(`${baseURL}toowe/users/${userId}/crud/introspectFacebookToken`, {
            token: params.token,
          })
          .then(() => console.log("Facebook Auth Token is valid"))
          .catch(() => {
            console.log("Token isn't valid");
            checkFacebookAuth();
          });
      },
    },
    twitter: {
      name: "Twitter",
      AuthFunction: (params) => {
        axios
          .post(`${baseURL}toowe/users/${userId}/crud/introspectTwitter`, {
            token: params.token,
            verifier: params.token_secrate,
          })
          .then(() => console.log("Twitter Auth Token is valid"))
          .catch(async () => {
            console.log("Invalid/Expired Auth Token Twitter");
            const res = await axios.post(`${baseURL}toowe/users/${userId}/crud/getTwitter`);
            // window.location.href = res.data.url;
          });
      },
    },
  };

  const getTwitterAccess = async () => {
    const accessToken = await axios.post(`${baseURL}crud/accessToken`, {
      oauth_token: twitterData.oauth_token,
      oauth_verifier: twitterData.oauth_verifier,
    },{
      headers: {
        token: JSON.parse(localStorage.getItem("userProfileInfo"))?.token,
        userId: JSON.parse(localStorage.getItem("userProfileInfo"))?._id,
      }
    });

    twitterData.oauth_token = accessToken?.data.split("&")[0].split("=")[1];
    twitterData.oauth_token_secret = accessToken?.data
      .split("&")[1]
      .split("=")[1];
    setTwitterData({ ...twitterData });

    console.log("Oauth Token ", twitterData.oauth_token);
    console.log("Oauth Token Secret", twitterData.oauth_token_secret);

    const saveData = await axios.post(`${baseURL}crud/twitterMe`, {
      oauth_token: twitterData.oauth_token,
      oauth_token_secret: twitterData.oauth_token_secret,
      jwToken: localStorage.getItem("token"),
    });

    window.location.reload(true);
    console.log(saveData);
  };

  useEffect(() => {
    if (location.search !== "") {
      if (location?.search?.split("&")[1]?.split("=")[0] === "oauth_verifier") {
        console.log(location?.search?.split("&")[0]?.split("=")[1]);
        twitterData.oauth_token = location?.search
          ?.split("&")[0]
          ?.split("=")[1];
        twitterData.oauth_verifier = location?.search
          ?.split("&")[1]
          ?.split("=")[1];
        setTwitterData({ ...twitterData });
        getTwitterAccess();
      }
    }
  }, [location]);



  useEffect(() => {
    if (location.search !== "") {
      const reference = searchParams.get("reference");
      if (reference) {
        const updateInfo = async (paramsInfo) => {
          const res = await updateUserProfileInfo(paramsInfo);

          sessionStorage.setItem("userProfileInfo", JSON.stringify(res.data));
          localStorage.setItem("userProfileInfo", JSON.stringify(res.data));
          let userDataLocal = JSON.parse(
            localStorage.getItem("userProfileInfo")
          );
          await axios.post(
            process.env.REACT_APP_SERVER_URI +
              `/api/toowe/users/${userId}/emailActions/PaymentSuccessEmail`,
            {
              email: userDataLocal.email,
            }
          );
          setRenewalDaysLeft(null);
          setSubscriptionType("");
          navigate("/dashboard");
        };

        const payId = searchParams.get("reference");
        //const userId = JSON.parse(localStorage.getItem("userProfileInfo"));

        const fetchPaymentInfo = async () => {
          const params = {
            payId,
            _id: userId,
          };
          const res = await getPaymentInfoAPI(params);

          if (res.status === 200) {
            const payParams = {
              latestPayID: res.data.orderInfo.id,
              latestPayDate: res.data.orderInfo.created_at,
              subscription: res.data.orderInfo.description,

              _id: userId,
            };

            updateInfo(payParams);
          }
        };

        fetchPaymentInfo();
      }
    }
  }, [location]);

  const checkAuthenticity = () => {
    pagesDetails.map((detail, key) => {
      if (
        detail.account_type === "facebook" ||
        detail.account_type === "linkedin" ||
        detail.account_type === "twitter"
      ) {
        let params = {
          token: detail.token,
          token_secrate: detail.token_secrate,
          userId: detail.userId,
          key: key,
        };

        try {
          socials[detail.account_type].AuthFunction(params);
        } catch (err) {
          console.log(err);
        }
      }
    });
  };







  // useEffect(() => {
  //   window.fbAsyncInit = () => {
  //     window.FB.init({
  //       appId: process.env.REACT_APP_FB_APP_ID,
  //       autoLogAppEvents: true,
  //       xfbml: true,
  //       version: "v14.0",
  //     });
  //   };
  //   (function (d, s, id) {
  //     var js,
  //       fjs = d.getElementsByTagName(s)[0];
  //     if (d.getElementById(id)) {
  //       return;
  //     }
  //     js = d.createElement(s);
  //     js.id = id;
  //     js.src = "https://connect.facebook.net/en_US/sdk.js";
  //     fjs.parentNode.insertBefore(js, fjs);
  //   })(document, "script", "facebook-jssdk");
  // }, []);


  useEffect(() => {
    var details = localStorage.getItem("userProfileInfo");
    details = JSON.parse(details);

    if (details) {
      if (details.subscription === "free") {
        const days = GetDateDiffofFree(details.createdAt);
        let DayLeft = 0;
        DayLeft = 10 - days;

        if (DayLeft <= 5) {
          setRenewalDaysLeft(DayLeft);
          setSubscriptionType(details.subscription);
        }
      } else {
        const days = GetDateDiffofPaid(details.latestPayDate);
        if (details.subscription === "Beginner Plan") {
          let DayLeft = 0;
          DayLeft = 31 - days;

          if (DayLeft <= 5) {
            setRenewalDaysLeft(DayLeft);
            setSubscriptionType(details.subscription);
          }
        } else if (details.subscription === "Intermediate Plan") {
          let DayLeft = 0;
          DayLeft = 90 - days;
          if (DayLeft <= 5) {
            setRenewalDaysLeft(DayLeft);
            setSubscriptionType(details.subscription);
          }
        } else if (details.subscription === "Advanced Plan") {
          var DayLeft = 0;
          DayLeft = 365 - days;
          if (DayLeft <= 5) {
            setRenewalDaysLeft(DayLeft);
            setSubscriptionType(details.subscription);
          }
        }
      }
    }
  }, [renewalDaysLeft, subscriptionType]);
// load a js script from a url
const loadScript = (src, id) => {
  return new Promise((resolve, reject) => {
    if (document.getElementById(id)) {
      resolve();
      return;
    }

    const existingScript = Array.from(document.scripts).find(script => script.src === src);
    if (existingScript) {
      resolve();
      return;
    }

    const script = document.createElement('script');
    script.src = src;
    script.id = id;
    script.async = true;
    script.onload = () => resolve();
    script.onerror = () => reject(new Error(`Failed to load script: ${src}`));
    document.body.appendChild(script);
  });
};
// load the adobe express sdk
const loadAdobeExpressSDK = async () => {
  try {
    await loadScript('https://cc-embed.adobe.com/sdk/v4/CCEverywhere.js', 'adobeCCSDK').then(() => {
      console.log('Adobe Express SDK loaded');
    });
  } catch (error) {
    console.error(error);
  }
};

// initialize the adobe express sdk
const initializeAdobeExpressSDK =async () => {
  console.log(window)
  // return 0;
  if (window.CCEverywhere) {
    // const sdk = new window.CCEverywhere({
    //   clientId: '8dc3df9d65ec40b7aacb1700f2f7a121',
    //   container: '#adobe-express-container',
    //   locale: 'en-US',
    // });

  // let cc = await window.CCEverywhere.initialize({
  //     clientId: '8dc3df9d65ec40b7aacb1700f2f7a121',
  //     container: '#adobe-express-container',
  //     appName: 'Project 1',
  //     locale: 'en-US',
  //   });

    // console.log("ccwindow", cc);

    // cc.editor.create({
    //   docConfig: {
    //     canvasSize: "Instagram"
    //   },
    //   appConfig: {
    //     selectedCategory: "templates",
    //     templateType: "instagram-square-post",
    //     allowedFileTypes: ['image/png', 'image/jpeg', 'application/pdf', 'video/mp4'],
    //     multiPage: true,

    //     // callbacks: {
    //     //   onCancel: () => {}, 
    //     //   onError: (err) => {}, 
    //     //   onLoadStart: () => {}, 
    //     //   onLoad: () => {}, 
    //     //   onPublishStart: () => {}, 
    //     //   onPublish: (publishParams) => {},

          
    //     // }
    //   },
    //   exportConfig: [
    //     {
    //       action: {
    //         context: 'new',
    //         target: 'express'
    //       },
    //       id: 'editor',
    //       label: 'Open in Adobe Express',
    //       style: {
    //         uiType: 'button'
    //       }
    //     }, 
    //     {
    //       action: {
    //         target: 'publish',
    //         outputType: "URL",
    //         closeTargetOnExport: true
    //       },
    //       id: 'saveToHostApp',
    //       label: 'Save in App',
    //       style: {
    //         uiType: 'button'
    //       },
    //     }
    //   ] 
    // });
    
    // cc.editor.onCancel = () => {
    //   console.log("Cancelled");
    // };
    // cc.editor.onError = (err) => {
    //   console.error("Error occurred:", err);
    // };
  } else {
    console.error('Adobe Express SDK not loaded');
    setTimeout(initializeAdobeExpressSDK, 1000); // try again in 1 second
  }
}


useEffect(() => {
  typeof window !== 'undefined' && loadAdobeExpressSDK().then(() => {
    initializeAdobeExpressSDK();
  });
}
  , []);

  return (
    <div className="App" >
      <Loader />
      <AuthAPI.Provider value={{ auth, setAuth }}>
        <Routes>
          {/* <Route element={<UnprotectedRoute />}> */}
          <Route path="/forgotPassword" element={<ForgotPassword />} exact />
          <Route
            path="/login"
            element={
              // <NoSideBarLayout>
              <LoginPage
                loginState={loginState}
                setLoginState={setLoginState}
                setUserInfo={setUserInfo}
                userInfo={userInfo}
              />
              // </NoSideBarLayout>
            }
            exact
          />
          <Route
            path="/verifyPassword"
            element={
              // <NoSideBarLayout>
              <VerifyPassword />
              // </NoSideBarLayout>
            }
            exact
          />
          <Route
            path="/"
            element={
              // <NoSideBarLayout>
              <LoginPage
                loginState={loginState}
                setLoginState={setLoginState}
                setUserInfo={setUserInfo}
                userInfo={userInfo}
              />
              // </NoSideBarLayout>
            }
            exact
          />
          <Route path="*" element={<Page_404 />} exact />

          <Route
            path="/create-account"
            element={
              // <NoSideBarLayout>
              <SignUp
                signUpState={signUpState}
                setSignUpState={setSignUpState}
              />
              // </NoSideBarLayout>
            }
            exact
          />

          <Route
            path="/setup-account"
            element={
              // <NoSideBarLayout>
              <QuestionsPage
                setUserInfo={setUserInfo}
                signUpState={signUpState}
                setSignUpState={setSignUpState}
                onclick={OnclickCreateUser}
              />
              // </NoSideBarLayout>
            }
            exact
          />
          {/* </Route> */}
          <Route element={<ProtectedRoute />}>
            <Route
              path="/link-account"
              element={
                <LinkAccount
                  setPagesDetails={setPagesDetails}
                  allFbPagesDetails={allFbPagesDetails}
                  setAllFbPagesDetails={setAllFbPagesDetails}
                  userInfo={userInfo}
                  signUpState={signUpState}
                  setSignUpState={setSignUpState}
                  fetchPagesInfo={fetchPagesInfo}
                />
              }
              exact
            />

            <Route
              path="/demo"
              element={
                <>
                  {daysLeft < 5 && (
                    <SubcriptionNotification
                      subscriptionType={subscriptionType}
                      renewalDaysLeft={renewalDaysLeft}
                    />
                  )}
                  <NewSideBarUI userDetails={userInfo}>
                    <StreamScreen
                      pagesDetails={pagesDetails}
                      userInfo={userInfo}
                      userDetails={userInfo}
                    />
                  </NewSideBarUI>
                </>
              }
              exact
            />

            <Route
              path="/demoAnalytics"
              element={
                <>
                  {/* {daysLeft < 5 && ( */}
                    <SubcriptionNotification
                      subscriptionType={subscriptionType}
                      renewalDaysLeft={renewalDaysLeft}
                    />
                  {/* )} */}
                  <NewSideBarUI userDetails={userInfo}>
                    <NewAnalyticsPage
                      pagesDetails={pagesDetails}
                      userInfo={userInfo}
                      userDetails={userInfo}
                    />
                    {/* <NewAnalyticsPageV2
                    pagesDetails={pagesDetails}
                    userInfo={userInfo}
                    userDetails={userInfo}
                  /> */}
                  </NewSideBarUI>
                </>
              }
              exact
            />
            <Route
              path="/DeepAnalytics"
              element={
                <>
                  {daysLeft < 5 && (
                    <SubcriptionNotification
                      subscriptionType={subscriptionType}
                      renewalDaysLeft={renewalDaysLeft}
                    />
                  )}
                  <NewSideBarUI userDetails={userInfo}>
                    <DeepAnalyticsPage
                      pagesDetails={pagesDetails}
                      userInfo={userInfo}
                      userDetails={userInfo}
                    />
                  </NewSideBarUI>
                </>
              }
              exact
            />
            <Route
              path="/GoogleAnalytics"
              element={
                <>
                  {daysLeft < 5 && (
                    <SubcriptionNotification
                      subscriptionType={subscriptionType}
                      renewalDaysLeft={renewalDaysLeft}
                    />
                  )}
                  <NewSideBarUI userDetails={userInfo}>
                    <GoogleAnalytics
                      pagesDetails={pagesDetails}
                      userInfo={userInfo}
                      userDetails={userInfo}
                    />
                  </NewSideBarUI>
                </>
              }
              exact
            />

            {/* <Route
              path="/CompetitorAnalysis"
              element={
                <NewSideBarUI userDetails={userInfo}>
                  <div
                    style={{
                      width: "100%",
                      backgroundColor: "rgb(239 239 239)",
                    }}
                  >
                    <div className="add_social">
                      <button
                        onClick={() => {
                          navigate("/link-account");
                        }}
                        type="button"
                        className="add_social_account"
                      >
                        Add Social Network
                      </button>
                    </div>
                    <Analysis
                      userInfo={userInfo}
                      userDetails={userInfo}
                      pagesDetails={pagesDetails}
                    />
                  </div>
                </NewSideBarUI>
              }
              exact
            /> */}
            <Route
              path="/dashboard"
              element={
                <>
                  {/* {daysLeft < 5 && ( */}
                    <SubcriptionNotification
                      subscriptionType={subscriptionType}
                      renewalDaysLeft={renewalDaysLeft}
                    />
                  {/* )} */}
                  <NewSideBarUI userDetails={userInfo}>
                    <StreamScreen
                      pagesDetails={pagesDetails}
                      userInfo={userInfo}
                      userDetails={userInfo}
                      setPagesDetails={setPagesDetails}
                      fetchPages={fetchPagesInfo}

                    />
                  </NewSideBarUI>
                </>
              }
              exact
            />
                <Route
              path="/timeline"
              element={
                <>
                  {daysLeft < 5 && (
                    <SubcriptionNotification
                      subscriptionType={subscriptionType}
                      renewalDaysLeft={renewalDaysLeft}
                    />
                  )}
                  <NewSideBarUI userDetails={userInfo}>
                    <Timeline
                      pagesDetails={pagesDetails}
                      userInfo={userInfo}
                      userDetails={userInfo}
                      setPagesDetails={setPagesDetails}
                      fetchPages={fetchPagesInfo}

                    />
                  </NewSideBarUI>
                </>
              }
              exact
            />
            <Route
              path="/analytics"
              element={
                <>
                  {daysLeft < 5 && (
                    <SubcriptionNotification
                      subscriptionType={subscriptionType}
                      renewalDaysLeft={renewalDaysLeft}
                    />
                  )}
                  <NewSideBarUI
                    setPage={setPage}
                    page={page}
                    userDetails={userInfo}
                  >
                    <Analytics
                      pagesDetails={pagesDetails}
                      userInfo={userInfo}
                      userDetails={userInfo}
                    />
                  </NewSideBarUI>
                </>
              }
              exact
            />

            <Route
              path="/user/:id"
              element={
                <>
                  {daysLeft < 5 && (
                    <SubcriptionNotification
                      subscriptionType={subscriptionType}
                      renewalDaysLeft={renewalDaysLeft}
                    />
                  )}
                  <NewSideBarUI userDetails={userInfo}>
                    <Profile
                      userDetails={userInfo}
                      pagesDetails={pagesDetails}
                    />
                  </NewSideBarUI>
                </>
              }
              exact
            />
            <Route
              path="/upgrade"
              element={
                <>
                  {daysLeft < 5 && (
                    <SubcriptionNotification
                      subscriptionType={subscriptionType}
                      renewalDaysLeft={renewalDaysLeft}
                    />
                  )}
                  <NewSideBarUI userDetails={userInfo}>
                    <Upgrade
                      userDetails={userInfo}
                      renewalDaysLeft={renewalDaysLeft}
                      pagesDetails={pagesDetails}
                    />
                  </NewSideBarUI>
                </>
              }
              exact
            />
            <Route
              path="/createpost"
              element={
                <>
                  {daysLeft < 5 && (
                    <SubcriptionNotification
                      subscriptionType={subscriptionType}
                      renewalDaysLeft={renewalDaysLeft}
                    />
                  )}
                  <NewSideBarUI userDetails={userInfo}>
                    {/* <Upgrade
                      userDetails={userInfo}
                      renewalDaysLeft={renewalDaysLeft}
                      pagesDetails={pagesDetails}
                    /> */}
                    <CreateNewPost 
                    pagesDetails={pagesDetails} 
                    setPagesDetails={setPagesDetails} 
                    isLoading={isLoading} 
                    setIsLoading={setIsLoading} 
                    />
                  </NewSideBarUI>
                </>
              }
              exact
            />
            <Route
              path="/email-verification"
              element={
                <>
                  {/* {daysLeft < 5 && ( */}
                    <SubcriptionNotification
                      subscriptionType={subscriptionType}
                      renewalDaysLeft={renewalDaysLeft}
                    />
                  {/* )} */}
                  <NewSideBarUI
                    setPage={setPage}
                    page={page}
                    userDetails={userInfo}
                  >
                    <EmailVerify
                      pagesDetails={pagesDetails}
                      setPagesDetails={setPagesDetails}
                      userInfo={userInfo}
                      page={page}
                      userDetails={userInfo}
                      setListArray={setListArray}
                      listArray={listArray}
                    />
                  </NewSideBarUI>
                </>
              }
              exact
            />

            <Route
              path="/linkedin"
              element={
                <NoSideBarLayout>
                  <LinkedIn userInfo={userInfo} />
                </NoSideBarLayout>
              }
              exact
            />

            <Route
              path="/create-new-list"
              element={
                <>
                  {daysLeft < 5 && (
                    <SubcriptionNotification
                      subscriptionType={subscriptionType}
                      renewalDaysLeft={renewalDaysLeft}
                    />
                  )}
                  <NewSideBarUI
                    setPage={setPage}
                    page={page}
                    userInfo={userInfo}
                  >
                    <CreateNewList
                      userInfo={userInfo}
                      setListArray={setListArray}
                      listArray={listArray}
                      fetchMyList={fetchMyList}
                      listRecord={listRecord}
                      setListRecord={setListRecord}
                    />
                  </NewSideBarUI>
                </>
              }
              exact
            />

            <Route
              path="/calendar"
              element={
                <>
                  {daysLeft < 5 && (
                    <SubcriptionNotification
                      subscriptionType={subscriptionType}
                      renewalDaysLeft={renewalDaysLeft}
                    />
                  )}
                  <NewSideBarUI userDetails={userInfo}>
                    <div
                      style={{
                        width: "100%",
                        backgroundColor: "rgb(239 239 239)",
                      }}
                    >
                      <div className="add_social">
                        <button
                          onClick={() => {
                            navigate("/link-account");
                          }}
                          type="button"
                          className="addNetworkHeaderBtn"
                          >
                                <img src={addSocialMediaAccounts} className="addsocial_icon_"/> Add Social Network
                        </button>
                      </div>
                      <Calendar
                        pagesDetails={pagesDetails}
                        userInfo={userInfo}
                        style={{ width: "100%" }}
                      />
                    </div>
                  </NewSideBarUI>
                </>
              }
              exact
            />
            <Route
              path="/ads"
              element={
                <>
                  {daysLeft < 5 && (
                    <SubcriptionNotification
                      subscriptionType={subscriptionType}
                      renewalDaysLeft={renewalDaysLeft}
                    />
                  )}
                  <NewSideBarUI userDetails={userInfo}>
                    <div
                      style={{                                              
                        width: "100%",                                              
                        backgroundColor: "rgb(239 239 239)",                                          
                      }}
                    >
                      <div className="add_social"> 
                        <button
                          onClick={() => {
                            navigate("/link-account");
                          }}
                          type="button"
                          className="add_social_account"
                        >
                          Add Social Network
                        </button>
                      </div>
                      <Ads
                        pagesDetails={pagesDetails}
                        userInfo={userInfo}
                        style={{ width: "100%" }}
                      />
                    </div>
                  </NewSideBarUI>
                </>
              }
              exact
            />

<Route
              path="/adinsights"
              element={
                <>
                  {daysLeft < 5 && (
                    <SubcriptionNotification
                      subscriptionType={subscriptionType}
                      renewalDaysLeft={renewalDaysLeft}
                    />
                  )}
                  <NewSideBarUI userDetails={userInfo}>
                    <div
                      style={{                                              
                        width: "100%",                                              
                        backgroundColor: "rgb(239 239 239)",                                          
                      }}
                    >
                      <div className="add_social"> 
                        <button
                          onClick={() => {
                            navigate("/link-account");
                          }}
                          type="button"
                          className="add_social_account"
                        >
                          Add Social Network
                        </button>
                      </div>
                      <AdsInsightsPage
                        pagesDetails={pagesDetails}
                        userInfo={userInfo}
                        style={{ width: "100%" }}
                      />
                    </div>
                  </NewSideBarUI>
                </>
              }
              exact
            />





            <Route
              path="/seo"
              element={
                <>
                  {daysLeft < 5 && (
                    <SubcriptionNotification
                      subscriptionType={subscriptionType}
                      renewalDaysLeft={renewalDaysLeft}
                    />
                  )}
                  <NewSideBarUI userDetails={userInfo}>
                    <div
                      style={{                                              
                        width: "100%",                                              
                        backgroundColor: "rgb(239 239 239)",                                          
                      }}
                    >
                      <div className="add_social"> 
                        <button
                          onClick={() => {
                            navigate("/link-account");
                          }}
                          type="button"
                          className="add_social_account"
                        >
                          Add Social Network
                        </button>
                      </div>
                      <Seo
                        pagesDetails={pagesDetails}
                        userInfo={userInfo}
                        style={{ width: "100%" }}
                      />
                    </div>
                  </NewSideBarUI>
                </>
              }
              exact
            />
              <Route
              path="/PostGenerator"
              element={
                <>
                  {daysLeft < 5 && (
                    <SubcriptionNotification
                      subscriptionType={subscriptionType}
                      renewalDaysLeft={renewalDaysLeft}
                    />
                  )}
                  <NewSideBarUI userDetails={userInfo}>
                    <div
                      style={{
                        width: "100%",
                        backgroundColor: "rgb(239 239 239)",
                      }}
                    >
                      <div className="add_social">
                        <button
                          onClick={() => {
                            navigate("/link-account");
                          }}
                          type="button"
                          className="addNetworkHeaderBtn"
                          >
                                <img src={addSocialMediaAccounts} className="addsocial_icon_"/> Add Social Network
                        </button>
                      </div>
                      <PostsGenerator
                        pagesDetails={pagesDetails}
                        userInfo={userInfo}
                        style={{ width: "100%" }}
                      />
                    </div>
                  </NewSideBarUI>
                </>
              }
              exact
            />
             <Route
              path="/backlinks"
              element={
                <>
                  {daysLeft < 5 && (
                    <SubcriptionNotification
                      subscriptionType={subscriptionType}
                      renewalDaysLeft={renewalDaysLeft}
                    />
                  )}
                  <NewSideBarUI userDetails={userInfo}>
                    <div
                      style={{
                        width: "100%",
                        backgroundColor: "rgb(239 239 239)",
                      }}
                    >
                      <div className="add_social">
                        <button
                          onClick={() => {
                            navigate("/link-account");
                          }}
                          type="button"
                          className="addNetworkHeaderBtn"
                        >
                         <img src={addSocialMediaAccounts} className="addsocial_icon_"/>  Add Social Network
                        </button>
                      </div>
                      <CSVindex
                        pagesDetails={pagesDetails}
                        userInfo={userInfo}
                        style={{ width: "100%" }}
                      />
                    </div>
                  </NewSideBarUI>
                </>
              }
              exact
            />


<Route
              path="/media-library"
              element={
                <>
                  {daysLeft < 5 && (
                    <SubcriptionNotification
                      subscriptionType={subscriptionType}
                      renewalDaysLeft={renewalDaysLeft}
                    />
                  )}
                  <NewSideBarUI userDetails={userInfo}>
                    <div
                      style={{
                        width: "100%",
                        backgroundColor: "rgb(239 239 239)",
                      }}
                    >
                      <div className="add_social">
                        <button
                          onClick={() => {
                            navigate("/media-library");
                          }}
                          type="button"
                          className="addNetworkHeaderBtn"
                        >
                         <img src={addSocialMediaAccounts} className="addsocial_icon_"/>  Add Social Network
                        </button>
                      </div>
                      <MediaLibrary
                        pagesDetails={pagesDetails}
                        userInfo={userInfo}
                        style={{ width: "100%" }}
                      />
                    </div>
                  </NewSideBarUI>
                </>
              }
              exact
            />

            <Route
              path="/message"
              element={
                <>
                  {" "}
                  {daysLeft < 5 && (
                    <SubcriptionNotification
                      subscriptionType={subscriptionType}
                      renewalDaysLeft={renewalDaysLeft}
                    />
                  )}
                  <NewSideBarUI
                    setPage={setPage}
                    page={page}
                    userDetails={userInfo}
                  >
                    <Message pagesDetails={pagesDetails} />
                  </NewSideBarUI>
                </>
              }
              exact
            />
            <Route
              path="/CompetitorAnalysis"
              element={
                <>

{/* 
{
 JSON.parse(localStorage.getItem("userProfileInfo")).subscription === 'free' ? window.location.href = '/upgrade' : null

} */}

                  {/* {daysLeft < 5 && ( */}
                    <SubcriptionNotification
                      subscriptionType={subscriptionType}
                      renewalDaysLeft={renewalDaysLeft}
                    />
                  {/* )} */}
                  <NewSideBarUI
                    setPage={setPage}
                    page={page}
                    userDetails={userInfo}
                  >
                    {" "}
                    <div
                      style={{
                        width: "100%",
                        backgroundColor: "rgb(239 239 239)",
                      }}
                    >
                      <div className="add_social">
                        <button
                          onClick={() => {
                            navigate("/link-account");
                          }}
                          type="button"
                          className="addNetworkHeaderBtn"
                        >
                              <img src={addSocialMediaAccounts} className="addsocial_icon_"/>  Add Social Network
                        </button>
                      </div>
                      <Analysis pagesDetails={pagesDetails} />
                    </div>
                  </NewSideBarUI>
                </>
              }
              exact
            />

<Route
              path="/AdsLibrary"
              element={
                <>
                    <SubcriptionNotification
                      subscriptionType={subscriptionType}
                      renewalDaysLeft={renewalDaysLeft}
                    />
                  {/* )} */}
                  <NewSideBarUI
                    setPage={setPage}
                    page={page}
                    userDetails={userInfo}
                  >
                    {" "}
                    <div
                      style={{
                        width: "100%",
                        backgroundColor: "rgb(239 239 239)",
                      }}
                    >
                      <div className="add_social">
                        <button
                          onClick={() => {
                            navigate("/link-account");
                          }}
                          type="button"
                          className="addNetworkHeaderBtn"
                        >
                              <img src={addSocialMediaAccounts} className="addsocial_icon_"/>  Add Social Network
                        </button>
                      </div>
                      <AdsLibrary pagesDetails={pagesDetails} />
                    </div>
                  </NewSideBarUI>
                </>
              }
              exact
            />

            <Route
              path="/create-new-list"
              element={
                <NewSideBarUI setPage={setPage} page={page}>
                  <CreateNewList />
                </NewSideBarUI>
              }
              exact
            />
          </Route>

          {/* ----------------------------Admin Routes------------------------------------------------- */}
          <Route
            path="/admin-login"
            element={
              <AdminLogin
                adminLoginState={adminLoginState}
                setAdminLoginState={setAdminLoginState}
              />
            }
            exact
          />
          <Route element={<AdminRoute />}>
            <Route
              path="/admin-dashboard"
              element={
                <AdminSideBar>
                  <AdminDashboard />
                </AdminSideBar>
              }
              exact
            />
            <Route
              path="/admin-question-setup"
              element={
                <AdminSideBar>
                  <AdminQuestion />
                </AdminSideBar>
              }
              exact
            />
            <Route
              path="/admin-package-setup"
              element={
                <AdminSideBar>
                  <Packages />
                </AdminSideBar>
              }
              exact
            />
            <Route
              path="/admin-view-users-questions"
              element={
                <AdminSideBar>
                  <ViewUsersQuestions />
                </AdminSideBar>
              }
              exact
            />
            <Route
              path="/admin-upgrade-subscription"
              element={
                <AdminSideBar>
                  <UpgradeSubscription />
                </AdminSideBar>
              }
              exact
            />
            <Route
              path="/admin-upload-backlinks"
              element={
                <AdminSideBar>
                  <BacklinksUpload />
                </AdminSideBar>
              }
              exact
            />
            <Route
              path="/admin-upload-articles"
              element={
                <AdminSideBar>
                  <ArticlesUpload />
                </AdminSideBar>
              }
              exact
            />
            <Route
              path="/admin-upload-directories"
              element={
                <AdminSideBar>
                  <DirectoriesUpload />
                </AdminSideBar>
              }
              exact
            />
          </Route>
        </Routes>
      </AuthAPI.Provider>
    </div>
  );
}

const ProtectedRoute = () => {
  const authAPI = react?.useContext(AuthAPI);
  return (authAPI?.auth === true || localStorage.getItem("token")) &&
    JSON.parse(localStorage?.getItem("userProfileInfo"))?.role === "user" ? (
    <Outlet />
  ) : (authAPI?.auth === true || localStorage.getItem("token")) &&
    JSON.parse(localStorage?.getItem("userProfileInfo"))?.role === "admin" ? (
    <Navigate to="/admin-dashboard" />
  ) : (
    <Navigate to="/login" />
  );
};

export default App;
