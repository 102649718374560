import React, { useEffect, useState } from "react";
import axios from "axios";
import { Card, CardBody, CardImg } from "reactstrap";
import "../Analytics/FbAnalytics/FbAnalytics.css";
import ApexAreaChartsPintrest from "./Charts/ApexAreaChartsPintrest";
import CustomDropDown from "../../../Components/CustomDropDown/CustomDropDown";
import {
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  ButtonDropdown,
} from "reactstrap";
import PinintrestAccountLineChart from "./Charts/PinintrestAccountLineChart";
import ApexAreaChartsPins from "./Charts/ApexAreaChartsPins";
import "./analytics.css";
import PinLineChart from "./Charts/posts/PinLineChart";

export default function PinintrestAnalytics(props) {
  const [start_date, setStart_Date] = useState(props.startDate.date);
  const [end_date, setEnd_Date] = useState(props.endDate.date);
  console.log(start_date, end_date);
  let userID = JSON.parse(localStorage.getItem("userProfileInfo"))?._id;
  const [userId, setUserId] = useState(userID);
  const [pins, setPins] = useState(null);
  const [pin, setPin] = useState(null);
  const [pintrest, setPinterest] = useState(null);
  const [pageData, setPageData] = useState(
    (props?.pagesDetails.pagesDetails?.filter(
      (item) => item.account_type === "BUSINESS"
    ))[0]
  );

  const [GraphdropdownOpen, setGraphdropdownOpen] = useState(false);
  const filters = pin
    ? [
      "IMPRESSION",
      "SAVE",
      "PIN_CLICK",
      "OUTBOUND_CLICK",
      "VIDEO_MRC_VIEW",
      "VIDEO_AVG_WATCH_TIME",
      "VIDEO_V50_WATCH_TIME",
      "QUARTILE_95_PERCENT_VIEW",
    ]
    : [
      "ENGAGEMENT",
      "ENGAGEMENT_RATE",
      "IMPRESSION",
      "OUTBOUND_CLICK",
      "OUTBOUND_CLICK_RATE",
      "PIN_CLICK",
      "PIN_CLICK_RATE",
      "SAVE",
      "SAVE_RATE",
    ];
  const [filter, setFilter] = useState(filters[2]);
  const [smallGraphsPin, setSmallGraphsPin] = useState([
    "IMPRESSION",
    "SAVE",
    "PIN_CLICK",
    "OUTBOUND_CLICK",
  ]);
  const [smallGraphs, setSmallGraphs] = useState([
    "IMPRESSION",
    "PIN_CLICK",
    "SAVE",
    "PIN_CLICK",
  ]);
  const [SelectedPage, setSelectedPage] = useState("Select Page");
  const [networkPages, setNetworkPages] = useState([]);
  const [IsPageSelectedPinintrest, setIsPageSelectedPinintrest] =
    useState(false);
  // const getPins = async () => {
  //   const res = await axios.post(
  //     `${process.env.REACT_APP_SERVER_URI}/api/toowe/users/${userId}/crud/boards/${props.board.id}/pins`,
  //     {
  //       token: props.PageData.token,
  //     }
  //   );
  //   setPins(res.data.items);
  // };

  const handleSetPin = async (e) => {
    setPin(e);
    console.log(pin);
  };
  // useEffect(() => {
  //   getPins();
  // }, [props.board]);

  const handleAddpin = (e) => {
    console.log(e);
    if (smallGraphsPin.includes(e)) {
      // if it is, remove it
      setSmallGraphsPin(smallGraphsPin.filter((item) => item !== e));
    } else {
      // if it isn't, add it
      setSmallGraphsPin([...smallGraphsPin, e]);
    }
  };

  const handleAdd = (e) => {
    // check if the value is already in the array
    if (smallGraphs.includes(e)) {
      // if it is, remove it
      setSmallGraphs(smallGraphs.filter((item) => item !== e));
    } else {
      // if it isn't, add it
      setSmallGraphs([...smallGraphs, e]);
    }

    console.log(smallGraphs);
  };
  useEffect(() => {
    const Networkpages = [];
    props.pagesDetails.pagesDetails?.map((item) => {
      if (item.account_type === "pinterest") {
        Networkpages.push(item.name);
      }
    });
    setNetworkPages(Networkpages);
    setSelectedPage(Networkpages[0]);
    console.log("Network----------->", Networkpages);
  }, [props.pagesDetails]);

  useEffect(() => {
    console.log(SelectedPage);
    setPageData(
      props.pagesDetails.pagesDetails.filter(
        (item) => item.name === SelectedPage
      )[0]
    );
  }, [SelectedPage]);



  const [pintrestAllAnalytics, setPintrestAllAnalytics] = useState(null)


  const [isPintrestVisible, setIsPintrestVisible] = useState(false)

  useEffect(() => {
    const getPintrestAllAnalytics = async () => {
      const res = await axios.post(
        `${process.env.REACT_APP_SERVER_URI}/api/toowe/users/${userId}/crud/PinterestAllAnalytics`,
        {
          token: pageData?.token,
          startDate: props?.startDate?.pindate,
          endDate: props?.endDate.pindate,
          jwToken: localStorage?.getItem("token"),
        }
      );

      setPintrestAllAnalytics(res.data);

      console.log("pintrestAllAnalytics", res.data);

    };
    pageData?.token && isPintrestVisible && getPintrestAllAnalytics();

  }, [props.startDate, props.endDate, pageData, isPintrestVisible]);

  const handleChangeVisibility = () => {
    setIsPintrestVisible(true)
  };


  return (
    <div className="fbAnalyticBlock">
      <div className="mediaFilterBlock">

        <p>{SelectedPage}</p>

      </div>
      {
        isPintrestVisible && pintrestAllAnalytics ? (
          <>
            <div className="mediaKPIBlock">
              {!pin
                ? pintrestAllAnalytics && pintrestAllAnalytics?.overall?.map((chart) => (
                  <ApexAreaChartsPintrest
                    metric={chart}
                    filters={filters}
                    key={chart}

                    data={chart}
                    startDate={props.startDate.pindate}
                    endDate={props.endDate.pindate}
                    PageData={pageData}
                    type={"pintrest"}
                  />
                ))
                : smallGraphsPin.map((chart) => (
                  <div style={{ maxWidth: "370px" }}>
                    <ApexAreaChartsPins
                      metric={chart}
                      filters={filters}
                      PageData={props.PageData}
                      type={"pintrest"}
                      pin={pin}
                      startDate={props.startDate}
                      endDate={props.endDate}
                    />
                  </div>
                ))}
            </div>

            <div className="mediaLineChart">
              <div>
                {!pin ? pintrestAllAnalytics && (
                  <PinintrestAccountLineChart
                    PageData={pageData}
                    date={pintrestAllAnalytics?.graphData?.categories}
                    impressions={pintrestAllAnalytics?.graphData?.data[0]}
                    engagements={pintrestAllAnalytics?.graphData?.data[1]}

                    startDate={props?.startDate?.pindate}
                    endDate={props?.endDate?.pindate}
                  />
                ) : null}
              </div>
            </div>
          </>) :
          (

            <div className="click_notify_wrp"

              onClick={() => handleChangeVisibility()}
            >
              <p>Stay updated with your audience! <br />Click here to view your Pinterest Analytics.
                Engaged Users, Impressions, Total Users and other engagement metrics.</p>
              <h2>Click to View</h2>
            </div>
          )
      }



    </div>
  );
}
