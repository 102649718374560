import React from 'react'
import { useState, useEffect } from 'react'
import CreateNewAd from './CreateNewAd'
import axios from 'axios'
import { Switch } from '@mui/material'




const AllAds = (props) => {

    const [createNewAdOpen, setCreateNewAdOpen] = useState(false)
    const [ads, setAds] = useState([])
    let userID = JSON.parse(localStorage.getItem("userProfileInfo"))?._id;
    const [userId, setUserId] = useState(userID);
    async function getAds(){
        await axios.post( process.env.REACT_APP_SERVER_URI +  `/api/toowe/users/${userId}/ads/GetFacebookAds`, { campaignid: props.AdCampaign , AdAccountId: props.adAccountId, adsetid: props.adSetId, jwToken:localStorage?.getItem("token"), token:"EAAb9ifo1PGABO2uDZCitCehOkQAGPOzOzGZCkc97XgZC3KMEmJGZBVs5JAqexIrdk2uQVs3AiojcDFiLacRDJwPDp89bZAht2fr3tdaki0rVZBZC56WkZB961yDnXvR3QpawO3akEtoa4nZAMIgPhPfZBjUKwGclQHzB9GCUOUvpjX7Nk1Yb9lDaEp9pZAXvW0ZD"}).then((res)=>{
            console.log(res.data)
            setAds(res.data.data)
        })
    }
   useEffect(() => {

      

        getAds();
    }, [window.location.href, props.AdCampaign, props.adAccountId])



    const handleStatusChange = (status,campaign) => async(event) => {
        console.log(status)
        console.log(event.target.checked)
        let newStatus = event.target.checked ? 'ACTIVE' : 'PAUSED'
        console.log(newStatus)
        await axios.post(process.env.REACT_APP_SERVER_URI + `/api/toowe/users/${userId}/ads/pauseUnPauseAd`, { adAccountId: props.adAccountId, adid: campaign, status: newStatus, jwToken:localStorage?.getItem("token"), token:"EAAb9ifo1PGABO2uDZCitCehOkQAGPOzOzGZCkc97XgZC3KMEmJGZBVs5JAqexIrdk2uQVs3AiojcDFiLacRDJwPDp89bZAht2fr3tdaki0rVZBZC56WkZB961yDnXvR3QpawO3akEtoa4nZAMIgPhPfZBjUKwGclQHzB9GCUOUvpjX7Nk1Yb9lDaEp9pZAXvW0ZD" }).then(res =>{
          console.log(res.data)
          getAds()
        })
      }

    
   
    return(
        <div className="BigBox-adsets">
                              <div className='containerAdAll'>
      
             {
                createNewAdOpen && (
                    <CreateNewAd campaignObjective={props.campaignObjective}  adAccountId={props.adAccountId} setCreateNewAdOpen={setCreateNewAdOpen} adSetId={props.adSetId}/>
                )
            }
 <div class="withCloseButton"><div class="calender_info withBorderLeft"><h5>Your all ads</h5> </div><div>
  <button onClick={()=>props.setAllAdsOpen(false)}>X</button>  </div></div>

           
                        {/* <button onClick={()=>props.setAllAdsOpen(false)}>Back</button> */}

            {/* <div>All ADS </div> */}

            <button className='btn btn-success' style={{float:'left', width:'200px', marginTop:'20px', marginBottom:'20px'}} onClick={()=>setCreateNewAdOpen(true)}>Create New Ad</button>



<table>
    <thead>
        <tr>
            <th>Ad Name</th>
            <th>Ad ID</th>
            <th>Ad Status</th>
            </tr>
    </thead>
    <tbody>
        {
            ads.map((ad, index) => {
                return(
                    <tr key={index}>
                        <td>{ad.name}</td>
                        <td>{ad.id}</td>
                 
                        <td><Switch checked={ad.status == 'ACTIVE' ? true: false} onChange={handleStatusChange(ad?.status, ad?.id)}/>{ad?.status}</td>
                    </tr>
                )
            })
        }
    </tbody>
</table>


</div>
</div>
    )}

    export default AllAds