import React, { useState, useEffect, useRef } from "react";
import "../Analytics/FbAnalytics/FbAnalytics.css";
import axios from "axios";
// import Reactwordcloud from 'react-wordcloud';
import ReactionChart from "./ReacttionChart";
import { CardMedia, CardContent, Typography, CardActions, Button, Skeleton } from "@mui/material";
import {AiOutlineLike,AiOutlineComment, AiOutlineShareAlt} from 'react-icons/ai'


import ApexAreaChartsFacebookPage from "./Charts/ApexAreaChartsFacebookPage";
import ApexDonutChart from "./Charts/ApexDonutChart";
import ApexLineChart from "./Charts/ApexLineChart";
import FacebookPageLineChart from "./Charts/FacebookPageLineChart";
import Posts from "./Charts/posts/Posts";
// import "flatpickr/dist/themes/light.css";
// import Flatpickr from 'react-flatpickr';
import ApexAreaChartsFacebookPost from "./Charts/ApexAreaChartsFacebookPost";
import CustomDropDown from "../../../Components/CustomDropDown/CustomDropDown";
import "../../../Components/CustomDropDown/CustomDropDown.css";
import { set } from "react-ga";
import { Card, CardBody, CardFooter } from "reactstrap";
import FacebookPostAnalyticsModal from "./FacebookPostAnalyticsModal";
// import ImpressionChart from "./ImpressionChart";
import ImpressionChart from "./impressionChart";
import ReactWordcloud from "react-wordcloud";
import { BsFacebook } from "react-icons/bs";
const FaceBookAnalytics = (props) => {
  console.log(props);

  const [pageData, setPageData] = useState(
    (props?.pagesDetails.pagesDetails?.filter(
      (item) => item.account_type === "facebook" 
    ))[0]
  );


 



 

  const [AllPages, setAllPages] = useState(props.pagesDetails);
  const [IsPageSelected, setIsPageSelected] = useState(false);
  const [SelectedPage, setSelectedPage] = useState("Select Page");
  const [networkPages, setNetworkPages] = useState([]);
  const [allPosts, setAllPosts] = useState([]);

  useEffect(() => {
    setAllPages(props.pagesDetails);
  }, [props.pagesDetails]);



  useEffect(() => {
    const Networkpages = [];
    props.pagesDetails.pagesDetails?.map((item) => {
      if (item.account_type === "facebook") {
        Networkpages.push(item.name);
      }
    });
    setSelectedPage(Networkpages[0]);
    console.log(props.pagesDetails.pagesDetails);
    setNetworkPages(Networkpages);
  }, [props.pagesDetails]);

  useEffect(() => {
    console.log(SelectedPage);
    setPageData(
      props.pagesDetails.pagesDetails.filter(
        (item) => item.name === SelectedPage
      )[0]
    );
  }, [SelectedPage]);


  const options = {
    rotations: 2,
    rotationAngles: [-90, 0],
    fontFamily: 'Arial',
    fontSizes: [20, 60], // Set the range of font sizes for the words
 
  };
  const size = [1000, 400];

  const words = []

const [wordCloudWords,setWordCloudWords]=useState(words)
const [reactionData,setReactionData]=useState([])
const [headData,setHeadData]=useState([])
const [isHeadDataVisible,setIsHeadDataVisible]=useState(false)
const [isMostPositiveAndNegativePostVisible,setIsMostPositiveAndNegativePostVisible]=useState(false)
const [isPostsVisible,setIsPostsVisible]=useState(false)
const [isPerformanceOverTimeVisible,setIsPerformanceOverTimeVisible]=useState(false)






const [Most_positive_and_negative_post,setMost_positive_and_negative_post]=useState([])
const [isPostAnalytics,setIsPostAnalytics]=useState(false)
const [selectedPostAnalyticsId,setSelectedPostAnalyticsId]=useState("")
const [id,setId]=useState("")
const [pageAnalytics,setPageAnalytics]=useState()
const [impressionChartData,setImpressionChartData]=useState([])


const [isTopPostsLoading,setIsTopPostsLoading]=useState(false)
let userID = JSON.parse(localStorage.getItem("userProfileInfo"))?._id;
const [userId, setUserId] = useState(userID);
const getMost_positive_and_negative_post = async()=>{
  setIsTopPostsLoading(true)
  await axios.post(process.env.REACT_APP_SERVER_URI + `/api/toowe/users/${userId}/deepAnalytics/facebookCommentsSentiment`,{
    pageId:pageData?.pageId,
    startDate:props?.startDate.date,
    endDate:props?.endDate.date,
    jwToken: sessionStorage?.getItem("token"),
  }).then((res)=>{

setMost_positive_and_negative_post(res.data)
setIsTopPostsLoading(false)

    return res.data;
  }
  ).catch((err)=>{
    console.log(err);
    setIsTopPostsLoading(false)

  }
  )
}




const GetheadData =()=>{


  axios.post(process.env.REACT_APP_SERVER_URI + `/api/toowe/users/${userId}/deepAnalytics/facebookotherAnalyticsData`,{
    pageId:pageData?.pageId,
    startDate:props?.startDate.date,
    endDate:props?.endDate.date,
    jwToken: sessionStorage?.getItem("token"),
  }).then((res)=>{
    console.log(res.data);
    setHeadData(res.data.DataToSend);
    setImpressionChartData(res.data.GraphData)
    return res.data;
  }
  ).catch((err)=>{
    console.log(err);
  })
}

const [IsPostsLoading, setIsPostsLoading] = useState(false)


const Get_posts =()=>{
  setIsPostsLoading(true)
axios.post(process.env.REACT_APP_SERVER_URI + `/api/toowe/users/${userId}/deepAnalytics/facebookPagePosts`,{
  pageId:pageData?.pageId,
  startDate:props?.startDate.date,
  endDate:props?.endDate.date,
  jwToken: sessionStorage?.getItem("token"),
}).then((res)=>{
  setIsPostsLoading(false)
  console.log(res.data);
  setAllPosts(res.data);
  return res.data;

})
}



const handleChangeVisibility = (data) => {
  if (data === "headData") {
    setIsHeadDataVisible(!isHeadDataVisible);
  } else if (data === "mostPositiveAndNegativePost") {
    setIsMostPositiveAndNegativePostVisible(!isMostPositiveAndNegativePostVisible);
  } else if (data === "posts") {
    setIsPostsVisible(!isPostsVisible);
  } else if (data === "performanceOverTime") {
    setIsPerformanceOverTimeVisible(!isPerformanceOverTimeVisible);
  }
};

useEffect(() => {
  pageData?.pageId && isPerformanceOverTimeVisible && GetheadData()
  // pageData?.pageId && wordcloudData()
  // pageData?.pageId && reactionChartData()
  pageData?.pageId && isMostPositiveAndNegativePostVisible && getMost_positive_and_negative_post()
  pageData?.pageId && isPostsVisible &&  Get_posts()
  console.log(pageData)
// eslint-disable-next-line react-hooks/exhaustive-deps
}, [props.startDate,props.endDate,pageData?.pageId, isPerformanceOverTimeVisible,isMostPositiveAndNegativePostVisible,isPostAnalytics, isPostsVisible])




const openPostAnalyticsModal=(id)=>{
  setIsPostAnalytics(true)
  setSelectedPostAnalyticsId(id)
  // setId(id)
  console.log(id)
}




  return (
    <div className="fbAnalyticBlock" >
      <div className="mediaFilterBlock" >
        <p> <BsFacebook /> {SelectedPage}</p>
      </div>
      <FacebookPostAnalyticsModal SelectedPageId={pageData?.pageId}  selectedPostAnalyticsId={selectedPostAnalyticsId}  isPostAnalytics={isPostAnalytics} setIsPostAnalytics={setIsPostAnalytics}  />
      


{/*  */}

  <div className="mediaFilterBlock" style={{marginTop:'40px'}}>
  <p>Top Posts</p> 
  </div>


  {
    isTopPostsLoading ? (
      <Skeleton variant="rectangular" width={1000} height={400} />
    ) : (
      isMostPositiveAndNegativePostVisible  ? (
        <Card className="fbAnalyticsCard" style={{marginTop:'20px', padding:'10px', display:'flex', flexDirection:'row', flexWrap:'wrap'}}>
          {Most_positive_and_negative_post?.most_negative_post?.sentiment_score !== 0 && (
            <Card sx={{ maxWidth: 345 }} className="card card_cust_st negative_post" style={{width:'250px', margin:'10px'}}>
              <Typography variant="h5">
                Negative Post
              </Typography>
              <CardMedia
                sx={{ height: 140 }}
                image={Most_positive_and_negative_post?.most_negative_post?.post_data?.full_picture}
                title="Toowe"
              />
              <CardContent className="subhead_line_cust ">
                <Typography  variant="body2" component="div"  style={{textAlign:'left', alignContent:'left'}}>
                  Sentiment Score : <span className="badge bg-danger fs-6"> { Most_positive_and_negative_post?.most_negative_post?.sentiment_score}</span>
                </Typography>
                {/* <Typography variant="body2" color="text.secondary">
                  {Most_positive_and_negative_post?.most_negative_post?.post_data?.message}
                </Typography> */}
              </CardContent>
              <CardActions style={{ display:'flex', 'justifyContent':'space-between'}}>
                <Button size="small"  class="sml_btn__ viewpost_btn" target='_blank' href={`https://facebook.com/`+ Most_positive_and_negative_post?.most_negative_post?.post_data?.id}>View Post</Button>
                <Button size="small" class="sml_btn__  analytics_btn_"  onClick={()=>openPostAnalyticsModal(Most_positive_and_negative_post?.most_negative_post?.post_data?.id)}>View Analytics</Button>
              </CardActions>
            </Card>
          )}
  
          {Most_positive_and_negative_post?.most_positive_post?.sentiment_score !== 0 && (
            <Card sx={{ maxWidth: 345 }} className="card card_cust_st positive_post" style={{width:'250px', margin:'10px'}}>
              <Typography variant="h5">
                Positive Post
              </Typography>
              <CardMedia
                sx={{ height: 140 }}
                loading="lazy"
                image={Most_positive_and_negative_post?.most_positive_post?.post_data?.full_picture}
                title="green iguana"
              />
              <CardContent className="subhead_line_cust ">
                <Typography gutterBottom component="div"   style={{textAlign:'left', alignContent:'left'}}>
                  Sentiment Score : <span className="badge bg-danger fs-6"> { Most_positive_and_negative_post?.most_positive_post?.sentiment_score}</span>
                </Typography>
                {/* <Typography variant="body2" color="text.secondary">
                  {Most_positive_and_negative_post?.most_positive_post?.post_data?.message}
                </Typography> */}
              </CardContent>
              <CardActions style={{display:'flex', 'justifyContent':'space-between'}}>
                <Button size="small" class="sml_btn__ viewpost_btn" target='_blank' href={`https://facebook.com/`+ Most_positive_and_negative_post?.most_positive_post?.post_data?.id}>view post</Button>
                <Button size="small" class="sml_btn__  analytics_btn_" onClick={()=>openPostAnalyticsModal(Most_positive_and_negative_post?.most_positive_post?.post_data?.id)}>View Analytics</Button>
              </CardActions>
            </Card>
          )}
  
          {Most_positive_and_negative_post?.most_engaged_post?.engaged_users !== 0 && (
            <Card sx={{ maxWidth: 345 }} className="card card_cust_st eng_post"  style={{width:'250px', margin:'10px'}}>
              <Typography variant="h5">
                Top Engaging Post
              </Typography>
              <CardMedia
                sx={{ height: 140 }}
                image={Most_positive_and_negative_post?.most_engaged_post?.post_data?.full_picture}
                title="Most Engaging Post "
              />
              <CardContent className="subhead_line_cust ">
                <Typography  variant="body2" component="div"  style={{textAlign:'left', alignContent:'left'}}>
                  Users engaged : <span className="badge bg-danger fs-6">  { Most_positive_and_negative_post?.most_engaged_post?.engaged_users}</span>
                </Typography>
                {/* <Typography variant="body2" color="text.secondary">
                  {Most_positive_and_negative_post?.most_engaged_post?.post_data?.message}
                </Typography> */}
              </CardContent>
              <CardActions style={{display:'flex', 'justifyContent':'space-between'}}>
                <Button size="small" class="sml_btn__ viewpost_btn" target='_blank' href={`https://facebook.com/`+ Most_positive_and_negative_post?.most_engaged_post?.post_data?.id}>view post</Button>
                <Button size="small" class="sml_btn__  analytics_btn_" onClick={()=>openPostAnalyticsModal(Most_positive_and_negative_post?.most_engaged_post?.post_data?.id)}>View Analytics</Button>
              </CardActions>
            </Card>
          )}
  
          {Most_positive_and_negative_post?.most_impressioned_post?.impressions !== 0 && (
            <Card sx={{ maxWidth: 345 }}  className="card card_cust_st eng_post"  style={{width:'250px', margin:'10px'}}>
              <Typography variant="h5">
                Best Impressions
              </Typography>
              <CardMedia
                sx={{ height: 140 }}
                image={Most_positive_and_negative_post?.most_impressioned_post?.post_data?.full_picture}
                title="Toowe"
              />
              <CardContent className="subhead_line_cust">
                <Typography  variant="body2" component="div" style={{textAlign:'left', alignContent:'left'}}>
                  Total impressions : <span className="badge bg-danger fs-6"> { Most_positive_and_negative_post?.most_impressioned_post?.impressions}</span>
                </Typography>
                {/* <Typography variant="body2" color="text.secondary">
                  {Most_positive_and_negative_post?.most_impressioned_post?.post_data?.message}
                </Typography> */}
              </CardContent>
              <CardActions style={{display:'flex', 'justifyContent':'space-between'}}>
            <Button size="small" class="sml_btn__ viewpost_btn" target='_blank' href={`https://facebook.com/`+ Most_positive_and_negative_post?.most_impressioned_post?.post_data?.id}>view post</Button>
            <Button size="small" class="sml_btn__  analytics_btn_" onClick={()=>openPostAnalyticsModal(Most_positive_and_negative_post?.most_impressioned_post?.post_data?.id)}>View Analytics</Button>
          </CardActions>
        </Card>
          )}
    
    </Card>
        
  
      ) : (
        <div className="click_notify_wrp" 
        onClick={() => handleChangeVisibility("mostPositiveAndNegativePost")}
         
      >
        <p>Stay updated with your audience! <br/>Click here to view your Facebook Posts, 
                Engaged Users, Impressions, Total Users and other engagement metrics.</p>
                <h2>Click to View</h2> 
      </div>
      )
    )
  }

 




    <div className="mediaFilterBlock" style={{marginTop:'40px'}}>

<p>Performance over Time</p>
</div>
{
  isPerformanceOverTimeVisible ? (
    <Card className="fbAnalyticsCard" style={{marginTop:'20px', padding:'30px'}}>
    {
      impressionChartData && <ImpressionChart data={impressionChartData}  />
    }
    {/* <ImpressionChart data={impressionChartData}  /> */}
    
    </Card>)
    : (
      <div className="click_notify_wrp" 
      onClick={() => handleChangeVisibility("performanceOverTime")} 
    >
    <p>Stay updated with your audience! <br/>Click here to view your Facebook Posts performance over time period and other engagement metrics.</p>
            <h2>Click to View</h2>  
    </div>
    )

}
   


<div className="mediaFilterBlock" style={{marginTop:'40px'}}>

<p>Your Posts</p>
</div>

{
  IsPostsLoading ? (
    <Skeleton variant="rectangular" width={1000} height={400} />
  ): (
    isPostsVisible ? (
      <div className="fbAnalyticsCard card">
      <table class="table list_posts_">
         
        <tbody>
          {
            allPosts.length > 0 ? (
            allPosts?.map((item)=>{
              return(
                <tr>
                <td><img src={item?.full_picture} style={{height:'50px', width:'50px'}} /></td>
                <td>{item?.message?.slice(0,100)}</td>
                <td> <Button className="like_btn__" size="small"><AiOutlineLike /> {item?.likes?.summary?.total_count || 0} </Button>
              <Button className="cmnt_btn__"  size="small"><AiOutlineComment /> {item?.comments?.summary?.total_count || 0} </Button>
              <Button className="share_btn__"  size="small"><AiOutlineShareAlt /> {item?.shares?.summary?.total_count || 0} </Button>
      </td>
                <td>        <Button className="viewpost_btn__"  size="small"   href={'https://facebook.com/'+ item.id} target='_blank'>View Post</Button>
              <Button className="analytics_btn__"  size="small" onClick={()=>openPostAnalyticsModal(item?.id)}>See Analytics</Button>
          </td>
              </tr>
              )
            })
            ) : (
              <div
              style={{
                width: "100%",
                height: "150px",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                background: "#fff",
                backdropFilter: "blur(5px)",
                border: "1px solid #fff",
                cursor: "pointer", color:"#e0e0e0"
              }}
            >
              <h2>No posts available</h2>
            </div>
      
            )
          }
          {/* <tr>
            <td>Post Image</td>
            <td>Post Content</td>
            <td>Like,share etc</td>
            <td>Buttons</td>
          </tr>
           <tr>
            <td>Post Image</td>
            <td>Post Content</td>
            <td>Like,share etc</td>
            <td>Buttons</td>
          </tr> */}
           
        </tbody>
      </table>
      </div>)
      : (
        <div className="click_notify_wrp"  
        onClick={() => handleChangeVisibility("posts")} 
      >
        
        <p>Stay updated with your audience! <br/>Click here to view your Facebook posts and other engagement metrics.</p>
                    
        <h2>Click to view</h2>
      </div>
      )
  )
}


{/* <div className="mediaFilterBlock" style={{marginTop:'40px'}}>
}



   




   
     {/* </div> */}
    </div>
  );
};

export default FaceBookAnalytics;
