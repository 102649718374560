import React, { useState, useEffect } from "react";
import "./CreateNewPost.css";
import { date, time } from "./Modal/Modal";
import Select from "react-select";
import { useDispatch } from "react-redux";
import { showCreateBoardMenu } from "../../Redux/Actions/CreateNewPostAction";
import Alert from "../../Components/Alert/Alert";
import Modal from "./Modal/Modal";
import emojiesIcon from "../../assets/emojiesIcon.png";

import {
  createBoard,
  getPageDetailsApi,
  scheduleBoard
} from "../../Utils/api";
import { BarLoader } from "react-spinners";
import Pixabay from "../../Components/Pixabay/Pixabay.js";
import data from "@emoji-mart/data";
import Picker from "@emoji-mart/react";
import axios from "axios";
import { toast } from "react-toastify";


console.log(date, time);

function NewPost({ postContent, paths }) {
  return (
    <>
      {postContent}

      <img src={paths} alt="" style={{ width: "100%" }} />
    </>
  );
}
function ImgDetails({ paths, imgName, handleClickDeleteImage }) {
  return (
    <>
      <div
        style={{ border: "2px solid #000", padding: "15px", display: "flex" }}
      >
        <span>
          {" "}
          <img src={paths} alt="" style={{ maxHeight: "100px" }} />
        </span>
        <div>
          <p style={{ marginLeft: "10px", fontSize: "12px" }}>{imgName}</p>
          <p style={{ marginLeft: "10px", fontSize: "12px" }}>
            Source: Uploads
          </p>
        </div>
        <div style={{ marginLeft: "auto" }}>
          <button
            className="delBtn"
            width="auto"
            height="28"
            type="button"
            onClick={handleClickDeleteImage}
          >
            <svg width="16" height="16" viewBox="0 0 20 20" fill="#2f3638">
              <path d="M15,1.25 L15,3.75 L20,3.75 L20,6.25 L18.75,6.25 L18.75,18.75 C18.75,19.5 18.25,20 17.5,20 L2.5,20 C1.75,20 1.25,19.5 1.25,18.75 L1.25,6.25 L0,6.25 L0,3.75 L5,3.75 L5,1.25 C5,0.5 5.5,0 6.25,0 L13.75,0 C14.5,0 15,0.5 15,1.25 Z M7.5,2.5 L7.5,3.75 L12.5,3.75 L12.5,2.5 L7.5,2.5 Z M16.25,6.25 L3.75,6.25 L3.75,17.5 L16.25,17.5 L16.25,6.25 Z M6.25,8.75 L8.75,8.75 L8.75,16.25 L6.25,16.25 L6.25,8.75 Z M11.25,8.75 L13.75,8.75 L13.75,16.25 L11.25,16.25 L11.25,8.75 Z"></path>
            </svg>
          </button>
        </div>
      </div>
    </>
  );
}

function ImageUploadSpinner() {
  return (
    <>
      <div style={{ border: "2px solid #000", padding: "15px" }}>
        <BarLoader color="#143059" height={9} speedMultiplier={2} width={400} />

        {/* <div style={{ marginLeft: "auto" }}>
        <button
          className="delBtn"
          width="auto"
          height="28"
          type="button"
          onClick={handleClickDeleteImage}
        >
          <svg width="16" height="16" viewBox="0 0 20 20" fill="#2f3638">
            <path d="M15,1.25 L15,3.75 L20,3.75 L20,6.25 L18.75,6.25 L18.75,18.75 C18.75,19.5 18.25,20 17.5,20 L2.5,20 C1.75,20 1.25,19.5 1.25,18.75 L1.25,6.25 L0,6.25 L0,3.75 L5,3.75 L5,1.25 C5,0.5 5.5,0 6.25,0 L13.75,0 C14.5,0 15,0.5 15,1.25 Z M7.5,2.5 L7.5,3.75 L12.5,3.75 L12.5,2.5 L7.5,2.5 Z M16.25,6.25 L3.75,6.25 L3.75,17.5 L16.25,17.5 L16.25,6.25 Z M6.25,8.75 L8.75,8.75 L8.75,16.25 L6.25,16.25 L6.25,8.75 Z M11.25,8.75 L13.75,8.75 L13.75,16.25 L11.25,16.25 L11.25,8.75 Z"></path>
          </svg>
        </button>
      </div> */}
      </div>
    </>
  );
}

function CreateBoard({ pagesDetails, userInfo, eventData, setPagesDetails }) {

  useEffect(() => {
    if (pagesDetails.length === 0 && !eventData) {
      getPageDetailsApi({ jwToken: localStorage.getItem('token') }).then((res) => setPagesDetails(res.data))
    }
  }, [])

  let options = [];
  const [showEmojis, setShowEmojis] = useState(false);
  console.log(eventData)
  pagesDetails.forEach((element) => {
    if (element.account_type === "pinterest") {
      options.push({
        value: {
          id: element.pageId,
          token: element.token,
          picture: element.picture,
          account_type: element.account_type,
          secrate: element.token_secrate || null,
          refresh_token: element.refresh_token || null
        },

        label: (
          <div>
            {" "}
            {element.account_type === "BUSINESS" || element.account_type === "pinterest" ? (
              <svg class="gUZ GjR U9O kVc" height="16" width="16" viewBox="0 0 24 24" aria-label="Pinterest" role="img"><path d="M0 12c0 5.123 3.211 9.497 7.73 11.218-.11-.937-.227-2.482.025-3.566.217-.932 1.401-5.938 1.401-5.938s-.357-.715-.357-1.774c0-1.66.962-2.9 2.161-2.9 1.02 0 1.512.765 1.512 1.682 0 1.025-.653 2.557-.99 3.978-.281 1.189.597 2.159 1.769 2.159 2.123 0 3.756-2.239 3.756-5.471 0-2.861-2.056-4.86-4.991-4.86-3.398 0-5.393 2.549-5.393 5.184 0 1.027.395 2.127.889 2.726a.36.36 0 0 1 .083.343c-.091.378-.293 1.189-.332 1.355-.053.218-.173.265-.4.159-1.492-.694-2.424-2.875-2.424-4.627 0-3.769 2.737-7.229 7.892-7.229 4.144 0 7.365 2.953 7.365 6.899 0 4.117-2.595 7.431-6.199 7.431-1.211 0-2.348-.63-2.738-1.373 0 0-.599 2.282-.744 2.84-.282 1.084-1.064 2.456-1.549 3.235C9.584 23.815 10.77 24 12 24c6.627 0 12-5.373 12-12S18.627 0 12 0 0 5.373 0 12"></path></svg>
            ) : element.account_type === "linkedin" ? (
              <svg width="16" height="16" viewBox="0 0 20 20" fill="#2f3638">
                <g>
                  <path
                    d="M10 20C15.523 20 20 15.523 20 10C20 4.477 15.523 0 10 0C4.477 0 0 4.477 0 10C0 15.523 4.477 20 10 20Z"
                    fill="#0A66C2"
                  ></path>
                  <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M6.97347 5.39759C6.97878 5.79554 6.84294 6.1277 6.56703 6.39512C6.29112 6.66254 5.92819 6.79519 5.47825 6.79519H5.46233C5.02299 6.79519 4.66961 6.66254 4.40113 6.39512C4.13371 6.1277 4 5.79554 4 5.39759C4 4.99434 4.13796 4.66112 4.41387 4.39689C4.68978 4.13159 5.05058 4 5.49416 4C5.93987 4 6.29537 4.13265 6.56279 4.39689C6.83127 4.66219 6.96604 4.99434 6.97241 5.39759H6.97347ZM6.80368 7.89883V16H4.15387V7.89883H6.80368ZM10.9126 9.04676C11.0138 8.88166 11.1171 8.7416 11.2236 8.61514C11.336 8.48461 11.4889 8.34241 11.6778 8.19066C11.8688 8.03785 12.1022 7.919 12.3781 7.83516C12.654 7.75027 12.9597 7.70782 13.2971 7.70782C14.2129 7.70782 14.9494 8.01769 15.5065 8.6353C16.0637 9.25398 16.3428 10.1602 16.3428 11.3541V15.9968H13.6983V11.664C13.6983 11.0909 13.59 10.6452 13.3735 10.3194C13.156 9.99576 12.8175 9.83339 12.3569 9.83339C12.0195 9.83339 11.7372 9.92572 11.508 10.1146C11.2809 10.3024 11.1111 10.5348 10.9986 10.8129C10.9402 10.9763 10.9105 11.197 10.9105 11.4751V15.9958H8.26813C8.27874 13.8203 8.28299 12.0587 8.28299 10.7068C8.28299 9.35585 8.28299 8.54935 8.27768 8.28723L8.27025 7.89459H10.9126V9.04676Z"
                    fill="white"
                  ></path>
                </g>
              </svg>
            ) : element.account_type === "twitter" ? (
              <svg width="16" height="16" viewBox="0 0 20 20" fill="#2f3638">
                <g>
                  <path
                    d="M20 10C20 15.523 15.523 20 10 20C4.477 20 0 15.523 0 10C0 4.477 4.477 0 10 0C15.523 0 20 4.477 20 10Z"
                    fill="#1DA1F2"
                  ></path>
                  <path
                    d="M16.134 6.188C15.806 6.699 15.404 7.133 14.934 7.492C14.943 7.566 14.943 7.676 14.943 7.82C14.9401 9.22326 14.5464 10.598 13.806 11.79C13.4322 12.4001 12.9733 12.9537 12.443 13.434C11.8821 13.9286 11.2346 14.3152 10.533 14.574C9.76895 14.8618 8.95839 15.0062 8.14197 15C6.83072 15.008 5.54876 14.6124 4.46997 13.867C4.64197 13.887 4.83297 13.898 5.04397 13.898C6.13107 13.9074 7.18515 13.5247 8.01297 12.82C7.50642 12.8116 7.01719 12.6342 6.62297 12.316C6.21843 11.999 5.9233 11.5633 5.77897 11.07C5.94297 11.098 6.09097 11.11 6.23197 11.11C6.44197 11.11 6.65397 11.082 6.86097 11.023C6.31332 10.9068 5.82547 10.5981 5.48597 10.153C5.12478 9.69652 4.9329 9.129 4.94297 8.547V8.516C5.27096 8.71203 5.64312 8.8221 6.02497 8.836C5.69559 8.60494 5.42835 8.29614 5.24697 7.937C5.05272 7.56604 4.95343 7.15272 4.95797 6.734C4.95797 6.277 5.06797 5.852 5.28197 5.461C5.8689 6.229 6.61188 6.86394 7.46197 7.324C8.30816 7.78759 9.24833 8.05358 10.212 8.102C10.174 7.91133 10.1545 7.71742 10.154 7.523C10.154 6.824 10.388 6.23 10.853 5.738C11.322 5.246 11.884 5 12.544 5C13.236 5 13.818 5.266 14.29 5.797C14.8295 5.68765 15.3445 5.48094 15.81 5.187C15.6435 5.76458 15.2691 6.26008 14.759 6.578C15.216 6.528 15.677 6.395 16.134 6.188Z"
                    fill="white"
                  ></path>
                </g>
              </svg>
            ) : null}
            <span style={{ marginLeft: "5px" }}>{element.name}</span>
          </div>
        ),
      });
    }
  });


  // To convert the date getting from the props to the required Format -> Starts
  const propDate = `${String(eventData?.start).split(" ")[3]}-${new Date(Date.parse(String(eventData?.start).split(" ")[1] + " " + String(eventData?.start).split(" ")[2] + ", " + String(eventData?.start).split(" ")[3])).getMonth() + 1 < 10 ? `0${new Date(Date.parse(String(eventData?.start).split(" ")[1] + " " + String(eventData?.start).split(" ")[2] + ", " + String(eventData?.start).split(" ")[3])).getMonth() + 1}` : new Date(Date.parse(String(eventData?.start).split(" ")[1] + " " + String(eventData?.start).split(" ")[2] + ", " + String(eventData?.start).split(" ")[3])).getMonth() + 1}-${String(eventData?.start).split(" ")[2]}`;

  // To convert the date getting from the props to the required Format -> Ends


  // Converting Title for Pinterest Board to required form => Starts
  const newTitle = eventData?.desc?.slice(28)
  // Converting Title for Pinterest Board to required form => Ends

  const [postContent, setPostContent] = useState(eventData?.title?.split("-")[1].trim() || "");
  const [paths, setPaths] = useState([]);
  const [imgUrl, setImgUrl] = useState("");
  const [imgName, setImgName] = useState("");
  const [selected, setSelected] = useState();
  const [isOpen, setIsOpen] = useState(false);
  const [date, setDate] = useState(!propDate.includes("undefined") ? propDate : "");
  const [time, setTime] = useState(String(eventData?.start)?.split(" ")[4]?.slice(0, 5) || "");
  const [border, setBorder] = useState("");
  const [Gallery, setGallery] = useState(false);
  const [minimized, setMinimized] = useState(false);
  const [postLink, setPostLink] = useState([]);
  const [url, setUrl] = useState("")
  const [name, setName] = useState(newTitle || "")
  const [privacy, setPrivacy] = useState(eventData?.privacy || "PUBLIC")
  const dispatch = useDispatch();
  // function handleOnEnter(postContentt) {
  //     console.log('enter', postContent)
  // }

  let userID = JSON.parse(localStorage.getItem("userProfileInfo"))?._id;
  const [userId, setUserId] = useState(userID);
  console.log(date, time);
  useEffect(() => {
    const newData = postContent?.split(" ");
    const newLink = newData?.filter((data) => data.includes("http" || "https"));
    const link = newLink?.map(async (data) => {
      const res = await axios(`https://api.shrtco.de/v2/shorten?url=${data}`);
      const newLine = res.data.result.full_short_link.toString();
      setPostLink(newLine);
    });
  }, [postContent]);
  const timexoneOffset = new Date().toTimeString();
  const newTimeZone = timexoneOffset.split(" ")[1];
  const newZone = newTimeZone.split(" ")[0];
  const zone = newZone[0] + newZone[1] + ":" + newZone[2] + newZone[3];
  console.log(zone);
  const timezone = date + "T" + time + ":00" + "+" + zone;
  console.log(timezone);

  // Selected Date TimeZone
  const selectedDate = date + " " + time;

  const [schedule, setSchedule] = useState({
    message: "",
    page_token: "",
    pageId: "",
    time: "",
  });

  const handleClickCreateBoard = () => {
    dispatch(showCreateBoardMenu());
  };

  const handleClickDeleteImage = () => {
    setImgUrl("");
    setPaths([]);
    setImgName("");
  };


  const handleSchedulePost = () => {
    let params = {
      time: new Date(selectedDate).getTime(),
      name: name,
      description: postContent,
      privacy: privacy,
      token: selected.value.token,
      refresh_token: selected.value.refresh_token,
      jwToken: sessionStorage.getItem('token'),
      taskId: eventData?.taskId
    };

    if (selected.length !== 0 && postContent.length !== 0) {
      if (selected.value.account_type === "BUSINESS") {
        scheduleBoard(params).then(res => dispatch(showCreateBoardMenu())).catch(err => {
          axios.post(`${process.env.REACT_APP_SERVER_URI}/api/toowe/users/${userId}/crud/token/refresh`, {
            token: selected.value.refresh_token
          }).then((res) => {
            // console.log(res)
            params.token = res.data.access_token
            scheduleBoard(params).then(() => { dispatch(showCreateBoardMenu()) })
          }).catch(err => console.log(err))
        })
      }
    }
  };

  const [scheduleOptions, setScheduleOptions] = useState(false);

  const handlePost = async () => {
    let params = {
      name: name,
      description: postContent,
      privacy: privacy,
      token: selected?.value?.token,
      refresh_token: selected?.value?.refresh_token,
      jwToken: localStorage?.getItem("token")
    };

    if (selected?.length !== 0 && postContent?.length !== 0) {
      console.log("Its ");
      if (selected.value.account_type === "pinterest") {
        createBoard(params).then(res => dispatch(showCreateBoardMenu())).catch(err => {
          axios.post(`${process.env.REACT_APP_SERVER_URI}/api/toowe/users/${userId}/crud/token/refresh`, {
            token: selected.value.refresh_token
          }).then((res) => {
            // console.log(res)
            params.token = res.data.access_token
            createBoard(params).then(() => { dispatch(showCreateBoardMenu())})
          }).catch(err => console.log(err))
        })
        setPostContent("");
        setPaths("");
        setImgName("");
        setUrl("");
        setSelected();
      }
    }
    else{
      if(selected?.length===0 || selected===undefined){
      toast.error("Please select the Account");
      }
      if(postContent?.length === 0){
        toast.error("Please enter the Description");
        }
    }
  };

  const addEmoji = (e) => {
    console.log(e);
    let sym = e.unified.split("-");
    let codesArray = [];
    sym.forEach((el) => codesArray.push("0x" + el));
    let emoji = String.fromCodePoint(...codesArray);
    setPostContent(postContent + emoji);
  };

  useEffect(() => {
    if (eventData?.pageId) {
      pagesDetails.map(data => {
        if (data.pageId === eventData?.pageId) {
          // console.log(data)
          let dat = []

          dat.push({
            value: data,

            label: (
              <div>
                {" "}
                {data.account_type === "BUSINESS" || data.account_type === "pinterest" ? (
                  <svg class="gUZ GjR U9O kVc" height="16" width="16" viewBox="0 0 24 24" aria-label="Pinterest" role="img"><path d="M0 12c0 5.123 3.211 9.497 7.73 11.218-.11-.937-.227-2.482.025-3.566.217-.932 1.401-5.938 1.401-5.938s-.357-.715-.357-1.774c0-1.66.962-2.9 2.161-2.9 1.02 0 1.512.765 1.512 1.682 0 1.025-.653 2.557-.99 3.978-.281 1.189.597 2.159 1.769 2.159 2.123 0 3.756-2.239 3.756-5.471 0-2.861-2.056-4.86-4.991-4.86-3.398 0-5.393 2.549-5.393 5.184 0 1.027.395 2.127.889 2.726a.36.36 0 0 1 .083.343c-.091.378-.293 1.189-.332 1.355-.053.218-.173.265-.4.159-1.492-.694-2.424-2.875-2.424-4.627 0-3.769 2.737-7.229 7.892-7.229 4.144 0 7.365 2.953 7.365 6.899 0 4.117-2.595 7.431-6.199 7.431-1.211 0-2.348-.63-2.738-1.373 0 0-.599 2.282-.744 2.84-.282 1.084-1.064 2.456-1.549 3.235C9.584 23.815 10.77 24 12 24c6.627 0 12-5.373 12-12S18.627 0 12 0 0 5.373 0 12"></path></svg>
                ) : data.account_type === "linkedin" ? (
                  <svg width="16" height="16" viewBox="0 0 20 20" fill="#2f3638">
                    <g>
                      <path
                        d="M10 20C15.523 20 20 15.523 20 10C20 4.477 15.523 0 10 0C4.477 0 0 4.477 0 10C0 15.523 4.477 20 10 20Z"
                        fill="#0A66C2"
                      ></path>
                      <path
                        fillRule="evenodd"
                        clipRule="evenodd"
                        d="M6.97347 5.39759C6.97878 5.79554 6.84294 6.1277 6.56703 6.39512C6.29112 6.66254 5.92819 6.79519 5.47825 6.79519H5.46233C5.02299 6.79519 4.66961 6.66254 4.40113 6.39512C4.13371 6.1277 4 5.79554 4 5.39759C4 4.99434 4.13796 4.66112 4.41387 4.39689C4.68978 4.13159 5.05058 4 5.49416 4C5.93987 4 6.29537 4.13265 6.56279 4.39689C6.83127 4.66219 6.96604 4.99434 6.97241 5.39759H6.97347ZM6.80368 7.89883V16H4.15387V7.89883H6.80368ZM10.9126 9.04676C11.0138 8.88166 11.1171 8.7416 11.2236 8.61514C11.336 8.48461 11.4889 8.34241 11.6778 8.19066C11.8688 8.03785 12.1022 7.919 12.3781 7.83516C12.654 7.75027 12.9597 7.70782 13.2971 7.70782C14.2129 7.70782 14.9494 8.01769 15.5065 8.6353C16.0637 9.25398 16.3428 10.1602 16.3428 11.3541V15.9968H13.6983V11.664C13.6983 11.0909 13.59 10.6452 13.3735 10.3194C13.156 9.99576 12.8175 9.83339 12.3569 9.83339C12.0195 9.83339 11.7372 9.92572 11.508 10.1146C11.2809 10.3024 11.1111 10.5348 10.9986 10.8129C10.9402 10.9763 10.9105 11.197 10.9105 11.4751V15.9958H8.26813C8.27874 13.8203 8.28299 12.0587 8.28299 10.7068C8.28299 9.35585 8.28299 8.54935 8.27768 8.28723L8.27025 7.89459H10.9126V9.04676Z"
                        fill="white"
                      ></path>
                    </g>
                  </svg>
                ) : data.account_type === "twitter" ? (
                  <svg width="16" height="16" viewBox="0 0 20 20" fill="#2f3638">
                    <g>
                      <path
                        d="M20 10C20 15.523 15.523 20 10 20C4.477 20 0 15.523 0 10C0 4.477 4.477 0 10 0C15.523 0 20 4.477 20 10Z"
                        fill="#1DA1F2"
                      ></path>
                      <path
                        d="M16.134 6.188C15.806 6.699 15.404 7.133 14.934 7.492C14.943 7.566 14.943 7.676 14.943 7.82C14.9401 9.22326 14.5464 10.598 13.806 11.79C13.4322 12.4001 12.9733 12.9537 12.443 13.434C11.8821 13.9286 11.2346 14.3152 10.533 14.574C9.76895 14.8618 8.95839 15.0062 8.14197 15C6.83072 15.008 5.54876 14.6124 4.46997 13.867C4.64197 13.887 4.83297 13.898 5.04397 13.898C6.13107 13.9074 7.18515 13.5247 8.01297 12.82C7.50642 12.8116 7.01719 12.6342 6.62297 12.316C6.21843 11.999 5.9233 11.5633 5.77897 11.07C5.94297 11.098 6.09097 11.11 6.23197 11.11C6.44197 11.11 6.65397 11.082 6.86097 11.023C6.31332 10.9068 5.82547 10.5981 5.48597 10.153C5.12478 9.69652 4.9329 9.129 4.94297 8.547V8.516C5.27096 8.71203 5.64312 8.8221 6.02497 8.836C5.69559 8.60494 5.42835 8.29614 5.24697 7.937C5.05272 7.56604 4.95343 7.15272 4.95797 6.734C4.95797 6.277 5.06797 5.852 5.28197 5.461C5.8689 6.229 6.61188 6.86394 7.46197 7.324C8.30816 7.78759 9.24833 8.05358 10.212 8.102C10.174 7.91133 10.1545 7.71742 10.154 7.523C10.154 6.824 10.388 6.23 10.853 5.738C11.322 5.246 11.884 5 12.544 5C13.236 5 13.818 5.266 14.29 5.797C14.8295 5.68765 15.3445 5.48094 15.81 5.187C15.6435 5.76458 15.2691 6.26008 14.759 6.578C15.216 6.528 15.677 6.395 16.134 6.188Z"
                        fill="white"
                      ></path>
                    </g>
                  </svg>
                ) : null}
                <span style={{ marginLeft: "5px" }}>{data.name}</span>
              </div>
            ),
          });

          setSelected(dat[0])
        }
      })
    }
  }, [eventData?.pageId, pagesDetails])

  return (
    <>
      {minimized === false ? (
        <div className="create-post-container">
          <div className="createPost_wrapper">
            <div className="form-header">
            <div className="add_line"></div>
              <div className="form-header-text">
                <h2>New Board</h2>
              </div>
              <div className="form-header-rightBtns">
                <div
                  className="minimize-Btn"
                  onClick={() => setMinimized(true)}
                >
                  <button type="button" height="44" width="auto">
                    <svg
                      width="16"
                      height="16"
                      viewBox="0 0 20 20"
                      fill="currentColor"
                    >
                      <path d="M17.5,18.75 L7.5,18.75 L7.5,16.25 L14.48,16.25 L1.61,3.38 L3.38,1.61 L16.25,14.48 L16.25,7.5 L18.75,7.5 L18.75,17.5 C18.75,18.19 18.19,18.75 17.5,18.75 Z"></path>
                    </svg>
                  </button>
                </div>
                <div className="minimize-Btn" onClick={handleClickCreateBoard}>
                  <button type="button" height="44" width="auto">
                    <svg
                      width="16"
                      height="16"
                      viewBox="0 0 20 20"
                      fill="currentColor"
                    >
                      <path d="M18.37,1.62 C17.87,1.12 17.12,1.12 16.62,1.62 L10,8.25 L3.37,1.62 C2.87,1.12 2.12,1.12 1.62,1.62 C1.12,2.12 1.12,2.87 1.62,3.37 L8.25,10 L1.62,16.62 C1.12,17.12 1.12,17.87 1.62,18.37 C1.87,18.62 2.12,18.75 2.5,18.75 C2.87,18.75 3.12,18.62 3.37,18.37 L10,11.75 L16.62,18.37 C16.87,18.62 17.25,18.75 17.5,18.75 C17.75,18.75 18.12,18.62 18.37,18.37 C18.87,17.87 18.87,17.12 18.37,16.62 L11.75,10 L18.37,3.37 C18.87,2.87 18.87,2.12 18.37,1.62 Z"></path>
                    </svg>
                  </button>
                </div>
              </div>
            </div>
            <div className="form-input-text-container">
              <div className="form-input-text-container-left">
                <div className="leftMain">
                  <div className="leftMain-container">
                    <div className="socialNetwork-picker pb-4">
                      <h3 className="mb-2">Create Board on</h3>
                      <div className="select-social-account">
                        {console.log(selected)}
                        <Select
                          options={options}
                          className="basic-multi-select"
                          classNamePrefix="select"
                          onChange={(event) => {
                            setSelected(event);
                            console.log(selected);
                          }}
                          isMulti={false}
                        />
                      </div>
                    </div>
                    {postContent?.length > 2000 ? (
                      <Alert message="Your text exceeds the character limit for Facebook" />
                    ) : null}
                    <div className="upload-Media-container pt-0">
                      <div className="upload-Media-header">
                        <div className="left-Text">
                          <h3 className="mb-2">Board Name</h3>
                        </div>
                      </div>
                      <input className="text_box_inp"  placeholder="Enter name of the Board" type="text" name="name" id="name"
                        value={name} onChange={(e) => {
                          e.preventDefault();
                          setName(e.target.value)
                        }} />

                    </div>
                    <div className="MessageEditContent-container pb-3">
                      <div className="MessageEditContent-container-header">
                        <div className="MessageEditContent-div">
                          <div>
                            <div
                              role="list"
                              className="MessageEditContent-inner-div"
                            >
                              <div
                                role="listitem"
                                tabIndex="0"
                                className="MessageEditContent-box"
                              >
                                <div className="MessageEditContent-text">
                                  Description
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="sc-hUlgpI hQCMeI">
                          <div className="sc-eUXlCE rc-CharacterCounter bUxVBB">
                            <span className="sc-iqlufy -characterCounterCount kPxjWs">
                              {postContent?.length}
                            </span>
                          </div>
                        </div>
                      </div>

                      <textarea
                        type="text"
                        className="text-box"
                        placeholder="Enter your text and links"
                        style={{
                          height: "200px",
                          width: "100%",
                         border: " 1px solid rgb(208, 208, 208)",
                        }}
                        value={postContent}
                        onChange={(e) => setPostContent(e.target.value)}
                      />
                      {/* <button
                        className="button"
                        onClick={() => setShowEmojis(!showEmojis)}
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          className="icon"
                          fill="none"
                          viewBox="0 0 24 24"
                          stroke="currentColor"
                        >
                          <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeWidth="2"
                            d="M14.828 14.828a4 4 0 01-5.656 0M9 10h.01M15 10h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z"
                          />
                        </svg>
                      </button>

                      {showEmojis && (
                        <div>
                          <Picker
                            data={data}
                            onChange={addEmoji}
                            onEmojiSelect={addEmoji}
                          />
                        </div>
                      )} */}
<div className={'emojiIconContainer'}>
  <img src={emojiesIcon} style={{float: 'right'}} alt="emojies"  onClick={() => setShowEmojis(!showEmojis)} />

</div>


                      {showEmojis && (
                        <div>
                          <Picker
                            data={data}
                            onChange={addEmoji}
                            onEmojiSelect={addEmoji}
                                />
                          {/* <Picker data={data} onEmojiSelect={console.log} /> */}
                        </div>
                      )}
                    </div>
                    <div className="socialNetwork-picker pb-4">
                      <h3>Privacy</h3>
                      <div className="select-social-account">
                        {/* <div className='select-social-account-container'> */}
                        <Select
                          options={
                            [
                              { value: 'PUBLIC', label: 'Public' },
                              { value: 'PROTECTED', label: 'Protected' },
                              { value: 'SECRET', label: 'Secret' }
                            ]
                          }
                          className="basic-multi-select"
                          classNamePrefix="select"
                          value={
                            privacy === "PUBLIC" ? { value: 'PUBLIC', label: 'Public' } :
                              privacy === "PROTECTED" ? { value: 'PROTECTED', label: 'Protected' } :
                                privacy === "SECRET" ? { value: 'SECRET', label: 'Secret' } : ""
                          }
                          onChange={(event) => {
                            setPrivacy(event.value);
                          }}
                          isMulti={false}
                        />
                      </div>

                    </div>
                  </div>
                </div>
              </div>
              <div className="form-input-text-container-right">
                <div className="form-input-text-inner-container-right  small_model_">
                  {Gallery === true ? (
                    <Pixabay setPaths={setPaths} setGallery={setGallery} />
                  ) : (
                    <>

                    </>
                  )}

                  <Modal
                    open={isOpen}
                    onClose={() => setIsOpen(false)}
                    date={date}
                    setDate={setDate}
                    time={time}
                    setTime={setTime}
                    schedule={schedule}
                    setSchedule={setSchedule}
                  />
                </div>
              </div>
            </div>

            <div className="bottom-Container">

              <div className="postNowBtn-container" type="cta">
                  <>
                    {" "}
                    <button
                      className="postNowBtn"
                      width="auto"
                      height="44"
                      cursor="pointer"
                      type="button"
                      onClick={handlePost}
                    >
Create Now                    </button>
                  </>
              </div>
            </div>
          </div>
        </div>
      ) : (
        <div onClick={() => setMinimized(false)} className="fixed">
          {" "}
          <div className="vk-MinimizedComposerModal sc-fJgHZD kaynPP">
            <div className="vk-MaximizeComposerHeader sc-jwUKeQ jqSPTz">
              <p className="sc-jgpVhK hNkawu">New Post</p>
              <button
                className="sc-jIjYVd iGGRsv"
                width="auto"
                height="28"
                type="button"
              >
                <svg width="16" height="16" viewBox="0 0 20 20" fill="#143059">
                  <path d="M17.5,1.25 L7.5,1.25 L7.5,3.75 L14.48,3.75 L1.61,16.61 L3.38,18.38 L16.25,5.51 L16.25,12.5 L18.75,12.5 L18.75,2.5 C18.75,1.81 18.19,1.25 17.5,1.25 Z"></path>
                </svg>
              </button>
            </div>
            <div className="sc-hcSvYt cuXuNS">
              <div className="sc-gjcKCj fZAwwt">{postContent}</div>
            </div>
          </div>
        </div>
      )}
    </>
  );
}

export default CreateBoard;
