
import './BacklinksUpload.css'
import { useDropzone } from "react-dropzone";
import React, { useCallback, useEffect, useState, useRef } from "react";
import axios from 'axios';
import Papa from 'papaparse';
// import 'datatables.net-dt/css/jquery.dataTables.css';
// import $ from 'jquery';
// import 'datatables.net';
import DataTable from 'react-data-table-component';



const BacklinksUpload = ()=> {
  let userID = JSON.parse(localStorage.getItem("userProfileInfo"))?._id;
  const [userId, setUserId] = useState(userID);

const [backlinksdata, setBacklinksData] = useState([
  
    {
        "S.no": "1",
        "Domain": "https://www.diigo.com/",
        "DA": "89",
        "SS": "1%",
        "Dofollow": "Yes"
    },
    {
        "S.no": "2",
        "Domain": "https://ping.twingly.com/",
        "DA": "55",
        "SS": "1%",
        "Dofollow": "Yes"
    },
    {
        "S.no": "3",
        "Domain": "http://pingomatic.com/",
        "DA": "51",
        "SS": "1%",
        "Dofollow": "Yes"
    },
    {
        "S.no": "4",
        "Domain": "https://www.exactseek.com/",
        "DA": "44",
        "SS": "1%",
        "Dofollow": "Yes"
    },
    {
        "S.no": "5",
        "Domain": "https://www.instapaper.com/",
        "DA": "86",
        "SS": "1%",
        "Dofollow": "Yes"
    },
    {
        "S.no": "6",
        "Domain": "https://flipboard.com/",
        "DA": "90",
        "SS": "1%",
        "Dofollow": "Yes"
    },
    {
        "S.no": "7",
        "Domain": "https://bitly.com/",
        "DA": "87",
        "SS": "1%",
        "Dofollow": "Yes"
    },
    {
        "S.no": "8",
        "Domain": "https://getpocket.com/",
        "DA": "91",
        "SS": "1%",
        "Dofollow": "Yes"
    },
    {
        "S.no": "9",
        "Domain": "http://www.gigablast.com/",
        "DA": "52",
        "SS": "10%",
        "Dofollow": "Yes"
    },
    {
        "S.no": "10",
        "Domain": "https://bitly.com/",
        "DA": "88",
        "SS": "10%",
        "Dofollow": "Yes"
    }
])
                                                                                  
                                           
useEffect(() => {
    console.log('useEffect')
    axios.post(`${process.env.REACT_APP_SERVER_URI}/api/toowe/users/${userId}/crud/getBacklinksData`,{type: 'backlinks'},{
      headers: {
        token: JSON.parse(localStorage.getItem("userProfileInfo"))?.token,
        userId: JSON.parse(localStorage.getItem("userProfileInfo"))?._id,
      }
    })
    .then(res => {
        console.log(res.data)
setBacklinksData(res.data.data)
    })}, [])

                                                                  
    const SaveData = async (data) => {
        console.log(data)
        let backlinks = await data.map((backlink, key) => {
            return {
                Serial: key,     
                Domain: backlink[1],                                               
                DA: backlink[2],
                SS: backlink[3],
                Dofollow: backlink[4],
            }   
        
      })
      backlinks.shift()
        console.log(backlinks)
        setBacklinksData(backlinks)
        await axios.post(`${process.env.REACT_APP_SERVER_URI}/api/toowe/users/${userId}/crud/AddBacklinksData`, {
          data : backlinks,
          type : 'backlinks'
        })
        .then(res => {
            console.log(res.data)
            setBacklinksData(res.data.data)
        })
    }

    // let table = new DataTable('#myTable',{
    //     "paging": false,
    //     "searching": false,
    //     "info": false,
    //     "ordering": false,
    //     "scrollY": "200px",
    //     "scrollCollapse": true,
    //     "paging": false,
    //     "scrollX": true,
    // });
    // const tableRef = useRef();

    useEffect(() => {

     




      // const table = $(tableRef.current);
      // table.DataTable({
      //   data: backlinksdata,
      //   columns: [
      //     { data: 'S.no' },
      //     { data: 'Domain' },
      //     { data: 'DA' },
      //     { data: 'SS' },
      //     { data: 'Dofollow'}
      //   ],
      //   // paging: true,
      //   // searching: true,
      //   // sorting: true,
      // });
    }, [backlinksdata]);
  
    const onDrop = useCallback(
        async (acceptedFiles) => {
          console.log("Accepted Files: ", acceptedFiles);
          if (acceptedFiles[0].type ===
            "text/csv" || acceptedFiles[0].type === "text/csv" || acceptedFiles[0].type === "text/csv") {
            if (acceptedFiles[0].size / 1048576 <= 5) {
              const formData = new FormData();
              // console.log(formData)
              const [file] = acceptedFiles;
        
              // accounts.map(account => console.log(account))
    
              formData.append("file", file);
              formData.append("jwToken", localStorage.getItem('token'))
              await fetch(`${process.env.REACT_APP_SERVER_URI}/api/toowe/users/${userId}/crud/AddBacklinksData`, {
                method: "POST",
                body: formData,
              })
              // formData.append("secrate", ` ${selected.value.secrate}`);
            //   setImgName(acceptedFiles.map((file) => file.name));
           
              console.log("Selected in Dropzone")
            } else {
              window.alert("Max Size limit increased. The File size is greater than 5 MB")
            }
          }
          else {
            window.alert("Invalid Format of Image.")
          }
        }
      );

    const uploadCSV = (e) => {
        e.preventDefault();
        console.log('uploading CSV')
    }

ondrop={uploadCSV}
const { getRootProps, getInputProps } = useDropzone({ onDrop });


    return(
        <div className="backlinksUpload" >
      <input {...getInputProps()} />

<div className="headSection regular" >
        <span>Upload Backlinks</span>
      
      </div>

      <input
        type="file"
        accept=".csv,.xlsx,.xls"
        style={{border: 'none',
          marginTop: "5px"}}
        onChange={(e) => {
          const files = e.target.files;
          console.log(files);
          if (files) {
            console.log(files[0]);
            Papa.parse(files[0], {
              complete: function(results) {
                console.log("Finished:", results.data);
                SaveData(results.data)
              }}
            )
          }
        }}
      />
     



            <DataTable
            columns={[
              {
                name: 'serial',
                selector: row => row.Serial,
                sortable: true,
            },
            {
                name: 'Domain',
                selector: row => row.Domain,
                sortable: true,
            },
            {
               name: 'DA',
               selector: row => row.DA,
               sortable: true,
            },{
              name: 'SS',
              selector: row => row.SS,
              sortable: true,
            },
            ]}
            data={backlinksdata}
            direction="auto"
            fixedHeaderScrollHeight="300px"
            pagination
            responsive
            subHeaderAlign="right"
            subHeaderWrap
        />







        </div>

    )}


 

    export default BacklinksUpload;