import React, { useEffect } from "react";
import CreateNewPost from "./CreateNewPost";
import CreatePin from "./CreatePin";
import CreateBoard from "./CreateBoard";
import "./Dashboard.css";
import DashboardHome from "./DashboardHome";
import { useSelector } from "react-redux";
import Stream from "./Stream/Stream";
import { useState } from 'react';
import Index from "./IndexPage/Index";
import SpinnerReact from "../../Components/Spinner/Spinner";
import { updateActivity } from '../../Utils/api'
import { useNavigate } from "react-router";

function Dashboard({ pagesDetails, userInfo, page, setPagesDetails }) {
  const [isLoading, setIsLoading] = useState(false);
  const isCreateNewPostMenu = useSelector(
    (state) => state.createNewPost.showCreatePostMenu
  );
  console.log(isCreateNewPostMenu);

  const isCreateNewPinMenu = useSelector(
    (state) => state.createNewPin.showCreatePinMenu
  )

  const isCreateNewBoardMenu = useSelector(
    (state) => state.createNewBoard.showCreateBoardMenu
  )

  const isStream = useSelector((state) => state.getStream.showStream);
  console.log(isStream);


  useEffect(() => {
    if (localStorage.getItem("token")) {
      const data = {
        jwToken: localStorage.getItem("token"),
        updatedTime: new Date().toLocaleString()
      }
      updateActivity(data)
    }
  }, [])



  return (
    <>
      {
        isLoading ? <SpinnerReact /> :
          ""
      }
      <div className="dashboardContainer">
        {page ? (
          <Index pagesDetails={pagesDetails} />
        ) : (
          <DashboardHome userInfo={userInfo} />
        )}
        {/* {isCreateNewPostMenu && <CreateNewPost pagesDetails={pagesDetails} setPagesDetails={setPagesDetails} isLoading={isLoading} setIsLoading={setIsLoading} />} */}
        {isCreateNewPinMenu && <CreatePin pagesDetails={pagesDetails} setPagesDetails={setPagesDetails} isLoading={isLoading} setIsLoading={setIsLoading} />}
        {isCreateNewBoardMenu && <CreateBoard pagesDetails={pagesDetails} setPagesDetails={setPagesDetails} isLoading={isLoading} setIsLoading={setIsLoading} />}
        {isStream && <Stream pagesDetails={pagesDetails} />}
      </div>
    </>
  );
}

export default Dashboard;
