import React from 'react';
import { Table, Container, Row, Col } from 'reactstrap';
import { Bar } from 'react-chartjs-2';

// Helper function to prepare bar chart data with checks
const prepareBarChartData = (data, labelName) => {
  if (!Array.isArray(data) || data.length === 0) {
    return {
      labels: [],
      datasets: [
        {
          label: `${labelName} Counts`,
          data: [],
          backgroundColor: 'rgba(0, 123, 255, 0.5)',
        },
      ],
    };
  }
  return {
    labels: data.map(item => item[labelName] || 'N/A'),
    datasets: [
      {
        label: `${labelName} Counts`,
        data: data.map(item => item?.count || 0),
        backgroundColor: 'rgba(0, 123, 255, 0.5)',
      },
    ],
  };
};

// Helper function to create tables with checks
const createTable = (sectionData) => {
  if (!sectionData || Object.keys(sectionData).length === 0) {
    return <div>No Data Available</div>;
  }

  return (
    <Table striped>
      <tbody>
        {Object.entries(sectionData).map(([key, value]) => (
          <tr key={key}>
            <th scope="row">{key}</th>
            <td>{value !== null ? value : 'N/A'}</td>
          </tr>
        ))}
      </tbody>
    </Table>
  );
};

const DataVisualizer = ({ data }) => {
  // Check for data object existence
  if (!data) {
    return <div>No data available</div>;
  }

  // Create bar chart data
  const topTLDChartData = prepareBarChartData(data?.topTLD?.line, 'label');
  const topCountryChartData = prepareBarChartData(data?.topCountry?.line, 'label');
  const topAnchorsByBacklinksChartData = prepareBarChartData(data?.topAnchorsByBacklinks?.line, 'anchor');
  const topAnchorsByDomainsChartData = prepareBarChartData(data?.topAnchorsByDomains?.line, 'anchor');

  return (
    <Container fluid>
      <Row>
        <Col md="6">
          <div className='messageTopHeadText mb-3 ml-0'>Top TLDs</div>
          {data.topTLD ? <Bar data={topTLDChartData} /> : <div>No Data Available</div>}
        </Col>
        <Col md="6">
          <div className='messageTopHeadText mb-3 ml-0'>Top Countries</div>
          {data.topCountry ? <Bar data={topCountryChartData} /> : <div>No Data Available</div>}
        </Col>
      </Row>
      <Row>
        <Col md="6">
          <div className='messageTopHeadText mb-3 ml-0'>Top Anchors by Backlinks</div>
          {data.topAnchorsByBacklinks ? <Bar data={topAnchorsByBacklinksChartData} /> : <div>No Data Available</div>}
        </Col>
        <Col md="6">
          <div className='messageTopHeadText mb-3 ml-0'>Top Anchors by Domains</div>
          {data.topAnchorsByDomains ? <Bar data={topAnchorsByDomainsChartData} /> : <div>No Data Available</div>}
        </Col>
      </Row>
      <Row>
        <Col md="6">
          <div className='messageTopHeadText mb-3 ml-0'>Backlinks</div>
          {data.backlinks ? createTable({ Backlinks: data.backlinks }) : <div>No Data Available</div>}
        </Col>
        <Col md="6">
          <div className='messageTopHeadText mb-3 ml-0'>Domains</div>
          {data.domains ? createTable({ Domains: data.domains }) : <div>No Data Available</div>}
        </Col>
      </Row>
      <Row>
        <Col md="6">
          <div className='messageTopHeadText mb-3 ml-0'>IPs</div>
          {data.ips ? createTable({ IPs: data.ips }) : <div>No Data Available</div>}
        </Col>
        <Col md="6">
          <div className='messageTopHeadText mb-3 ml-0'>C-Blocks</div>
          {data.cBlocks ? createTable({ 'C-Blocks': data.cBlocks }) : <div>No Data Available</div>}
        </Col>
      </Row>
      <Row>
        <Col md="6">
          <div className='messageTopHeadText mb-3 ml-0'>Anchors</div>
          {data.anchors ? createTable({ Anchors: data.anchors }) : <div>No Data Available</div>}
        </Col>
      </Row>
    </Container>
  );
};

export default DataVisualizer;
