import Chart from 'react-apexcharts'
import axios from 'axios'
import { useState, useEffect } from 'react'

import { Card, CardHeader, CardTitle, CardBody, CardSubtitle, Dropdown, DropdownToggle, DropdownMenu, DropdownItem, ButtonDropdown } from 'reactstrap'
import { async } from 'emoji-mart'

const areaColors = {
  series3: '#a4f8cd',
  series2: '#60f2ca',
  series1: '#2bdac7'
}

const ApexAreaChartsPins = ({ pin, metric, startDate, endDate, filters, type, PageData }) => {
  const [metricF, setMetricF] = useState(metric);
  const [data, setData] = useState({})
  const [dataset, setDataset] = useState({
    "name": "page",
    "title": "Weekly Total Daily"[
      "09-03",
      "09-04",
      "09-05",
      "09-06",
      "09-07"
    ],
    "data": [
      0,
      0,
      0,
      54,
      92
    ]
  })

  async function fetchData() {

    try {
      let userId = JSON.parse(localStorage.getItem("userProfileInfo"))?._id;
      // if(type === "facebookPage"){
      console.log(pin)
      const response = await axios.post(`${process.env.REACT_APP_SERVER_URI}/api/toowe/users/${userId}/crud/pinsAnalytics/${metricF}`, {
        token: PageData.token,
        id: pin || "726275877426461988",
        start_date: startDate,
        end_date: endDate,

      })
      setDataset({
        "name": metricF,
        "title": "Daily" + " " + metricF,
        "lables": response.data.last30days.categories,
        "data": response.data.last30days.data
      })





      console.log(response)

      // setDataset(response.data.last30days)



      console.log("-------------------->>>>>>>>>>" + data)

      // }else if(type === "facebookPost"){
      //   const result = await  axios.post('${process.env.REACT_APP_SERVER_URI}/api/toowe/users/${userId}/crud/analyticsFacebookPost',{
      //     postId: "103191482473211_104573479001678",
      //     startDate: startDate,
      //     endDate: endDate,
      // metric: "page_impressions",

      // })
      // setDataset(result.data)
      // console.log(result.data)
      // }



    } catch (err) {
      console.log(err)
    }

  };
 

  useEffect(() => {
    fetchData()
    console.log("use effect")
  }, [PageData, metricF, pin, startDate, endDate])





  const series = [{
    name: dataset.name,
    data: dataset.data
  }];
  let percent = dataset.data && dataset.data.length > 1 ? (dataset.data[dataset.data.length - 1] * 100 / dataset.data[dataset.data.length - 2] - 100).toFixed(0) : 0;
  percent = parseInt(percent)
  console.log(typeof (percent))
  //convert Nan to 0
  if (isNaN(percent)) {
    percent = 0
  }
  //create color for percent
  let color = percent >= 0 ? "green" : "red"

  if (percent >= 0) {
    percent = " " + "+" + percent + "%" + "▴"
  } else if (percent < 0) {
    percent = " " + " " + percent + "%" + "▾"
  }
  const options = {

    chart: {
      type: 'area',

      height: 150,
      zoom: {
        enabled: false
      },
      toolbar: {
        show: false
      },
      tooltip: {
        enabled: false,
        show: false
      },
      animations: {
        enabled: true,
        easing: 'easeinout',
        speed: 800,
        animateGradually: {
          enabled: true,
          delay: 150
        },
        title: {
          text: '',
          align: 'left',
          // offsetX: 14
        },
        dynamicAnimation: {
          enabled: true,
          speed: 350
        }
      }
    },
    dataLabels: {
      enabled: false,
      enabledOnSeries: undefined,
    },
    stroke: {
      curve: 'smooth',
      width: 0.8
    },
    grid: {
      show: false,
    },
    title: {
      text: '',
      align: 'left'
    },
    subtitle: {
      text: '',
      align: 'left'
    },
    labels: dataset.lables,
    xaxis: {
      labels: {
        show: false
      }
    },

    yaxis: {
      opposite: false,
      labels: {
        show: false
      }
    },

    title: {
      text: percent,
      align: 'right',
      style: {
        fontSize: '24px',
        fontWeight: 'bold',
        color: color
      }
    },
    subtitle: {
      text: dataset.title + "  ",
      align: 'left',
      style: {
        fontSize: '10px',
      }
    },
    legend: {
      horizontalAlign: 'left'
    }
  };

  const [dropdownOpen, setOpen] = useState(false);

  return (

    <Card style={{ border: 'none', marginTop: '50px', margin: '10px', boxShadow: '0 4px 24px 0 rgb(34 41 47 / 31%)', height: '18rem' }}>

      <CardBody >



        <div style={{ marginBottom: '50px' }}> <h1 style={{ float: 'left' }}>  {dataset.data && dataset.data[dataset.data.length - 1]}</h1>
          <ButtonDropdown toggle={() => { setOpen(!dropdownOpen) }}
            isOpen={dropdownOpen} style={{ float: 'right' }}>
            <DropdownToggle className="bg-transparent" style={{ border: 'none' }} >

              <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path d="M19.479 2l-7.479 12.543v5.924l-1-.6v-5.324l-7.479-12.543h15.958zm3.521-2h-23l9 15.094v5.906l5 3v-8.906l9-15.094z" /></svg>
            </DropdownToggle>
            <DropdownMenu>
              <DropdownItem header>Filters
              </DropdownItem>
              {filters.map((filter) => {
                return (
                  <DropdownItem onClick={() => { setMetricF(filter) }}>{filter}</DropdownItem>
                )
              })
              }

            </DropdownMenu>
          </ButtonDropdown>
        </div>
        {/* {PageData.PageData.pageId} */}

        <Chart options={options} series={series} type='area' height={200} width={220} />
      </CardBody>
    </Card>
  )
}
export default ApexAreaChartsPins
