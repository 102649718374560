

import axios from "axios";
import { useState, useEffect } from "react";


import "./timeline.css";






const Timeline = ({userInfo}) => {
    const [timelineData, setTimelineData] = useState([]);
    const [loading, setLoading] = useState(false);
    let userID = JSON.parse(localStorage.getItem("userProfileInfo"))?._id;
    const [userId, setUserId] = useState(userID);

    const getTimeLineData = async (userId) => {


        const response = await axios.post(`${process.env.REACT_APP_SERVER_URI}/api/toowe/users/${userId}/crud/getUserTimeLine`,{
      
          "id": userId,
       
        },{
            headers: {
                "Content-Type": "application/json",
                //  userInfo: JSON.parse(localStorage.getItem("userInfo")).token,
                 token: JSON.parse(localStorage.getItem("userProfileInfo")).token,

            },
        });
    
        // const response = await fetch(`process.env.REACT_APP_SERVER_URI}/api/toowe/users/${userId}/crud/${userId}`);
        // const data = await response.json();



        return response.data;
    }
    


    useEffect(() => {
        setLoading(true);
        getTimeLineData(userId)
            .then((data) => {
                setTimelineData(data);
                setLoading(false);
            })
          
    }, [userId]);

    if (loading) {
        return <p>Loading...</p>;
    }


    return (
        <div style={{alignItems:'flex-start', textAlign:'start', height:'100vh', width:'100%', overflow:'scroll'}}>
           <div class="streamContentAreaHeader mt-4 ml-5"><h2 class="title_homepage">Timeline </h2></div> 
            <div className="result_wrp ">
            <ul>
                {timelineData?.map((item) => (
                    <li className="sucess" key={item._id}> 
                        {/* <span className="" >{JSON.stringify(item.result)}</p> */}
                        {item?.pageName?<span className="pagename__">Page: <b>{item?.pageName}</b></span>:""}
                        <h4 className="post_status">
                            {item.message}
                            </h4>
                        <p className="time_postedon">Posted On:- {new Date(item.createdAt).toLocaleString()}</p>
                    </li>
                ))}
            </ul>
            </div>
        </div>
    );
};


export default Timeline;