


 export function behaviourList(){

return [
  {
    "id": "6002714895372",
    "name": "Frequent travellers",
    "type": "behaviors",
    "path": [
      "Travel",
      "Frequent travellers"
    ],
    "description": "People whose activities on Facebook suggest that they are frequent travellers.",
    "real_time_cluster": false,
    "audience_size_lower_bound": 1507607007,
    "audience_size_upper_bound": 1772945841
  },
  {
    "id": "6002714898572",
    "name": "Small business owners",
    "type": "behaviors",
    "path": [
      "Digital activities",
      "Small business owners"
    ],
    "description": "People who list themselves as small business owners or own small business Pages on Facebook",
    "real_time_cluster": false,
    "audience_size_lower_bound": 40496536,
    "audience_size_upper_bound": 47623927
  },
  {
    "id": "6002764392172",
    "name": "Facebook Payments users (90 days)",
    "type": "behaviors",
    "path": [
      "Digital activities",
      "Facebook Payments users (90 days)"
    ],
    "description": "People who have used Facebook payments in the past 90 days",
    "real_time_cluster": false,
    "audience_size_lower_bound": 721798,
    "audience_size_upper_bound": 848835
  },
  {
    "id": "6003808923172",
    "name": "Early technology adopters",
    "type": "behaviors",
    "path": [
      "Digital activities",
      "Early technology adopters"
    ],
    "description": "People who are likely to adopt new technologies earlier than others",
    "real_time_cluster": false,
    "audience_size_lower_bound": 81481564,
    "audience_size_upper_bound": 95822320
  },
  {
    "id": "6003986707172",
    "name": "Facebook access (OS): Windows 7",
    "type": "behaviors",
    "path": [
      "Digital activities",
      "Operating system used",
      "Facebook access (OS): Windows 7"
    ],
    "description": "People who primarily access Facebook using Windows 7.",
    "real_time_cluster": false,
    "audience_size_lower_bound": 55025,
    "audience_size_upper_bound": 64710
  },
  {
    "id": "6003966451572",
    "name": "Facebook access (OS): Mac OS X",
    "type": "behaviors",
    "path": [
      "Digital activities",
      "Operating system used",
      "Facebook access (OS): Mac OS X"
    ],
    "description": "People who primarily access Facebook using Mac OS X",
    "real_time_cluster": false,
    "audience_size_lower_bound": 2707173,
    "audience_size_upper_bound": 3183636
  },
  {
    "id": "6003966450972",
    "name": "Facebook access (OS): Windows Vista",
    "type": "behaviors",
    "path": [
      "Digital activities",
      "Operating system used",
      "Facebook access (OS): Windows Vista"
    ],
    "description": "People who primarily access Facebook using Windows Vista",
    "real_time_cluster": false,
    "audience_size_lower_bound": 11421,
    "audience_size_upper_bound": 13432
  },
  {
    "id": "6003966466972",
    "name": "Facebook access (OS): Windows XP",
    "type": "behaviors",
    "path": [
      "Digital activities",
      "Operating system used",
      "Facebook access (OS): Windows XP"
    ],
    "description": "People who primarily access Facebook using Windows XP",
    "real_time_cluster": false,
    "audience_size_lower_bound": 6562,
    "audience_size_upper_bound": 7718
  },
  {
    "id": "6004386303972",
    "name": "Facebook access (mobile): iPhone 4S",
    "type": "behaviors",
    "path": [
      "Mobile Device User",
      "All Mobile Devices by Brand",
      "Apple",
      "Facebook access (mobile): iPhone 4S"
    ],
    "description": "People who primarily access Facebook using an iPhone 4S mobile device.",
    "real_time_cluster": false,
    "audience_size_lower_bound": 31000,
    "audience_size_upper_bound": 36457
  },
  {
    "id": "6004383941372",
    "name": "Facebook access (mobile): iPhone 4",
    "type": "behaviors",
    "path": [
      "Mobile Device User",
      "All Mobile Devices by Brand",
      "Apple",
      "Facebook access (mobile): iPhone 4"
    ],
    "description": "People who primarily access Facebook using an iPhone 4 mobile device.",
    "real_time_cluster": false,
    "audience_size_lower_bound": 10037,
    "audience_size_upper_bound": 11804
  },
  {
    "id": "6004386044572",
    "name": "Facebook access (mobile): Android devices",
    "type": "behaviors",
    "path": [
      "Mobile Device User",
      "All Mobile Devices by Operating System",
      "Facebook access (mobile): Android devices"
    ],
    "description": "People who primarily access Facebook using any Android mobile device",
    "real_time_cluster": false,
    "audience_size_lower_bound": 1653472869,
    "audience_size_upper_bound": 1944484094
  },
  {
    "id": "6004383149972",
    "name": "Facebook access (mobile): feature phones",
    "type": "behaviors",
    "path": [
      "Mobile Device User",
      "Facebook access (mobile): feature phones"
    ],
    "description": "People who primarily access Facebook using a feature phone",
    "real_time_cluster": false,
    "audience_size_lower_bound": 2600885,
    "audience_size_upper_bound": 3058641
  },
  {
    "id": "6004383890572",
    "name": "Facebook access (mobile): iPod Touch",
    "type": "behaviors",
    "path": [
      "Mobile Device User",
      "All Mobile Devices by Brand",
      "Apple",
      "Facebook access (mobile): iPod Touch"
    ],
    "description": "People who primarily access Facebook using an iPod Touch mobile device",
    "real_time_cluster": false,
    "audience_size_lower_bound": 85147,
    "audience_size_upper_bound": 100133
  },
  {
    "id": "6004385895772",
    "name": "Facebook access (mobile): Windows phones",
    "type": "behaviors",
    "path": [
      "Mobile Device User",
      "All Mobile Devices by Operating System",
      "Facebook access (mobile): Windows phones"
    ],
    "description": "People who primarily access Facebook using a Windows mobile device",
    "real_time_cluster": false,
    "audience_size_lower_bound": 78448,
    "audience_size_upper_bound": 92255
  },
  {
    "id": "6004384041172",
    "name": "Facebook access (mobile): Apple (iOS) devices",
    "type": "behaviors",
    "path": [
      "Mobile Device User",
      "All Mobile Devices by Operating System",
      "Facebook access (mobile): Apple (iOS) devices"
    ],
    "description": "People who primarily access Facebook using an Apple (iOS) mobile device",
    "real_time_cluster": false,
    "audience_size_lower_bound": 296360016,
    "audience_size_upper_bound": 348519379
  },
  {
    "id": "6004383806772",
    "name": "Facebook access (mobile): iPad 3",
    "type": "behaviors",
    "path": [
      "Mobile Device User",
      "All Mobile Devices by Brand",
      "Apple",
      "Facebook access (mobile): iPad 3"
    ],
    "description": "People who primarily access Facebook using an iPad 3 mobile device.",
    "real_time_cluster": false,
    "audience_size_lower_bound": 184099,
    "audience_size_upper_bound": 216501
  },
  {
    "id": "6004383808772",
    "name": "Facebook access (mobile): iPad 2",
    "type": "behaviors",
    "path": [
      "Mobile Device User",
      "All Mobile Devices by Brand",
      "Apple",
      "Facebook access (mobile): iPad 2"
    ],
    "description": "People who primarily access Facebook using an iPad 2 mobile device",
    "real_time_cluster": false,
    "audience_size_lower_bound": 286281,
    "audience_size_upper_bound": 336667
  },
  {
    "id": "6004383767972",
    "name": "Facebook access (mobile): iPad 1",
    "type": "behaviors",
    "path": [
      "Mobile Device User",
      "All Mobile Devices by Brand",
      "Apple",
      "Facebook access (mobile): iPad 1"
    ],
    "description": "People who primarily access Facebook using an iPad 1 mobile device",
    "real_time_cluster": false,
    "audience_size_lower_bound": 2190305,
    "audience_size_upper_bound": 2575799
  },
  {
    "id": "6004386010572",
    "name": "Facebook access (mobile): Samsung Android mobile devices",
    "type": "behaviors",
    "path": [
      "Mobile Device User",
      "All Mobile Devices by Brand",
      "Facebook access (mobile): Samsung Android mobile devices"
    ],
    "description": "People who primarily access Facebook using a Samsung Android mobile device",
    "real_time_cluster": false,
    "audience_size_lower_bound": 517889573,
    "audience_size_upper_bound": 609038139
  },
  {
    "id": "6004385886572",
    "name": "Facebook access (mobile): HTC Android mobile devices",
    "type": "behaviors",
    "path": [
      "Mobile Device User",
      "All Mobile Devices by Brand",
      "Facebook access (mobile): HTC Android mobile devices"
    ],
    "description": "People who primarily access Facebook using an HTC Android mobile device.",
    "real_time_cluster": false,
    "audience_size_lower_bound": 862133,
    "audience_size_upper_bound": 1013869
  },
  {
    "id": "6004385868372",
    "name": "Facebook access(mobile): LG Android mobile devices",
    "type": "behaviors",
    "path": [
      "Mobile Device User",
      "All Mobile Devices by Brand",
      "Facebook access(mobile): LG Android mobile devices"
    ],
    "description": "People who primarily access Facebook using an LG Android mobile device",
    "real_time_cluster": false,
    "audience_size_lower_bound": 14065335,
    "audience_size_upper_bound": 16540835
  },
  {
    "id": "6004385865172",
    "name": "Facebook access (mobile): Sony Android mobile devices",
    "type": "behaviors",
    "path": [
      "Mobile Device User",
      "All Mobile Devices by Brand",
      "Facebook access (mobile): Sony Android mobile devices"
    ],
    "description": "People who primarily access Facebook using a Sony Android mobile device",
    "real_time_cluster": false,
    "audience_size_lower_bound": 4678244,
    "audience_size_upper_bound": 5501615
  },
  {
    "id": "6004382299972",
    "name": "Facebook access (mobile): all mobile devices",
    "type": "behaviors",
    "path": [
      "Mobile Device User",
      "Facebook access (mobile): all mobile devices"
    ],
    "description": "People who primarily access Facebook using mobile devices",
    "real_time_cluster": false,
    "audience_size_lower_bound": 1879004966,
    "audience_size_upper_bound": 2209709841
  },
  {
    "id": "6004383049972",
    "name": "Facebook access (mobile): smartphones and tablets",
    "type": "behaviors",
    "path": [
      "Mobile Device User",
      "Facebook access (mobile): smartphones and tablets"
    ],
    "description": "People who primarily access Facebook using a smartphone or tablet device",
    "real_time_cluster": false,
    "audience_size_lower_bound": 1813106611,
    "audience_size_upper_bound": 2132213375
  },
  {
    "id": "6004385879572",
    "name": "Facebook access (mobile): Motorola Android mobile devices",
    "type": "behaviors",
    "path": [
      "Mobile Device User",
      "All Mobile Devices by Brand",
      "Facebook access (mobile): Motorola Android mobile devices"
    ],
    "description": "People who primarily access Facebook using a Motorola Android mobile device",
    "real_time_cluster": false,
    "audience_size_lower_bound": 65025661,
    "audience_size_upper_bound": 76470178
  },
  {
    "id": "6004854404172",
    "name": "Facebook access: older devices and OS",
    "type": "behaviors",
    "path": [
      "Digital activities",
      "Facebook access: older devices and OS"
    ],
    "description": "People who primarily access Facebook on older devices or operating systems before Windows 7, Mac OS X or Windows NT 6.2.",
    "real_time_cluster": false,
    "audience_size_lower_bound": 592568430,
    "audience_size_upper_bound": 696860474
  },
  {
    "id": "6004883585572",
    "name": "Facebook access (mobile): iPhone 5",
    "type": "behaviors",
    "path": [
      "Mobile Device User",
      "All Mobile Devices by Brand",
      "Apple",
      "Facebook access (mobile): iPhone 5"
    ],
    "description": "People who primarily access Facebook using an iPhone 5 mobile device",
    "real_time_cluster": false,
    "audience_size_lower_bound": 118648,
    "audience_size_upper_bound": 139531
  },
  {
    "id": "6004948896972",
    "name": "Facebook Payments users (30 days)",
    "type": "behaviors",
    "path": [
      "Digital activities",
      "Facebook Payments users (30 days)"
    ],
    "description": "People who have used Facebook Payments in the past 30 days",
    "real_time_cluster": false,
    "audience_size_lower_bound": 299499,
    "audience_size_upper_bound": 352211
  },
  {
    "id": "6006298077772",
    "name": "Facebook access (OS): Windows 8",
    "type": "behaviors",
    "path": [
      "Digital activities",
      "Operating system used",
      "Facebook access (OS): Windows 8"
    ],
    "description": "People who primarily access Facebook using Facebook using Windows 8",
    "real_time_cluster": false,
    "audience_size_lower_bound": 74198,
    "audience_size_upper_bound": 87258
  },
  {
    "id": "6007078565383",
    "name": "New smartphone and tablet users",
    "type": "behaviors",
    "path": [
      "Mobile Device User",
      "New smartphone and tablet users"
    ],
    "description": "People who are new smartphone or tablet users.",
    "real_time_cluster": false,
    "audience_size_lower_bound": 127197994,
    "audience_size_upper_bound": 149584841
  },
  {
    "id": "6007481031783",
    "name": "Owns: Galaxy S III devices",
    "type": "behaviors",
    "path": [
      "Mobile Device User",
      "All Mobile Devices by Brand",
      "Samsung",
      "Owns: Galaxy S III devices"
    ],
    "description": "People who likely own a Galaxy S III mobile device",
    "real_time_cluster": false,
    "audience_size_lower_bound": 229852,
    "audience_size_upper_bound": 270306
  },
  {
    "id": "6007847947183",
    "name": "Console gamers",
    "type": "behaviors",
    "path": [
      "Digital activities",
      "Console gamers"
    ],
    "description": "People who have liked Pages related to console gaming or gaming systems",
    "real_time_cluster": false,
    "audience_size_lower_bound": 50685402,
    "audience_size_upper_bound": 59606033
  },
  {
    "id": "6008261969983",
    "name": "Returned from travelling one week ago",
    "type": "behaviors",
    "path": [
      "Travel",
      "Returned from travelling one week ago"
    ],
    "description": "People whose activities on Facebook suggest that they returned from travelling within the past week",
    "real_time_cluster": false,
    "audience_size_lower_bound": 142211628,
    "audience_size_upper_bound": 167240875
  },
  {
    "id": "6008297697383",
    "name": "Returned from travelling two weeks ago",
    "type": "behaviors",
    "path": [
      "Travel",
      "Returned from travelling two weeks ago"
    ],
    "description": "People whose activities on Facebook suggest that they returned from travelling within the past 2 weeks",
    "real_time_cluster": false,
    "audience_size_lower_bound": 224265325,
    "audience_size_upper_bound": 263736023
  },
  {
    "id": "6008868254383",
    "name": "Owns: Kindle Fire",
    "type": "behaviors",
    "path": [
      "Mobile Device User",
      "All Mobile Devices by Brand",
      "Amazon",
      "Owns: Kindle Fire"
    ],
    "description": "People who are likely to own a Kindle Fire",
    "real_time_cluster": false,
    "audience_size_lower_bound": 4254,
    "audience_size_upper_bound": 5003
  },
  {
    "id": "6010095777183",
    "name": "Facebook access (mobile): iPhone 5S",
    "type": "behaviors",
    "path": [
      "Mobile Device User",
      "All Mobile Devices by Brand",
      "Apple",
      "Facebook access (mobile): iPhone 5S"
    ],
    "description": "People who primarily access Facebook using an iPhone 5S mobile device.",
    "real_time_cluster": false,
    "audience_size_lower_bound": 749179,
    "audience_size_upper_bound": 881035
  },
  {
    "id": "6010095794383",
    "name": "Facebook access (mobile): iPhone 5C",
    "type": "behaviors",
    "path": [
      "Mobile Device User",
      "All Mobile Devices by Brand",
      "Apple",
      "Facebook access (mobile): iPhone 5C"
    ],
    "description": "People who primarily access Facebook using an iPhone 5C mobile device",
    "real_time_cluster": false,
    "audience_size_lower_bound": 49985,
    "audience_size_upper_bound": 58783
  },
  {
    "id": "6010231666183",
    "name": "Owns: LG G2 devices",
    "type": "behaviors",
    "path": [
      "Mobile Device User",
      "All Mobile Devices by Brand",
      "LG",
      "Owns: LG G2 devices"
    ],
    "description": "People who are likely to own LG G2 devices",
    "real_time_cluster": false,
    "audience_size_lower_bound": 9115,
    "audience_size_upper_bound": 10720
  },
  {
    "id": "6011191254383",
    "name": "Owns: iPad 4",
    "type": "behaviors",
    "path": [
      "Mobile Device User",
      "All Mobile Devices by Brand",
      "Apple",
      "Owns: iPad 4"
    ],
    "description": "People who are likely to own an iPad 4",
    "real_time_cluster": false,
    "audience_size_lower_bound": 559739,
    "audience_size_upper_bound": 658254
  },
  {
    "id": "6011191259183",
    "name": "Owns: iPad Mini 1",
    "type": "behaviors",
    "path": [
      "Mobile Device User",
      "All Mobile Devices by Brand",
      "Apple",
      "Owns: iPad Mini 1"
    ],
    "description": "People who are likely to own an iPad Mini 1",
    "real_time_cluster": false,
    "audience_size_lower_bound": 407794,
    "audience_size_upper_bound": 479566
  },
  {
    "id": "6011244513583",
    "name": "Owns: iPad Air",
    "type": "behaviors",
    "path": [
      "Mobile Device User",
      "All Mobile Devices by Brand",
      "Apple",
      "Owns: iPad Air"
    ],
    "description": "People who are likely to own an iPad Air.",
    "real_time_cluster": false,
    "audience_size_lower_bound": 1332845,
    "audience_size_upper_bound": 1567426
  },
  {
    "id": "6011244510983",
    "name": "Owns: iPad Mini 2",
    "type": "behaviors",
    "path": [
      "Mobile Device User",
      "All Mobile Devices by Brand",
      "Apple",
      "Owns: iPad Mini 2"
    ],
    "description": "People who are likely to own an iPad Mini 2.",
    "real_time_cluster": false,
    "audience_size_lower_bound": 739637,
    "audience_size_upper_bound": 869814
  },
  {
    "id": "6011390261383",
    "name": "Owns: Huawei",
    "type": "behaviors",
    "path": [
      "Mobile Device User",
      "All Mobile Devices by Brand",
      "Owns: Huawei"
    ],
    "description": "People who are likely to own a Huawei mobile device.",
    "real_time_cluster": false,
    "audience_size_lower_bound": 86857473,
    "audience_size_upper_bound": 102144389
  },
  {
    "id": "6015852294783",
    "name": "Owns: Galaxy Y devices",
    "type": "behaviors",
    "path": [
      "Mobile Device User",
      "All Mobile Devices by Brand",
      "Samsung",
      "Owns: Galaxy Y devices"
    ],
    "description": "People who are likely to own a Galaxy Y mobile device.",
    "real_time_cluster": false,
    "audience_size_lower_bound": 11564,
    "audience_size_upper_bound": 13600
  },
  {
    "id": "6013016790183",
    "name": "Owns: Galaxy S4",
    "type": "behaviors",
    "path": [
      "Mobile Device User",
      "All Mobile Devices by Brand",
      "Samsung",
      "Owns: Galaxy S4"
    ],
    "description": "People who are likely to own a Galaxy S4 mobile device",
    "real_time_cluster": false,
    "audience_size_lower_bound": 306861,
    "audience_size_upper_bound": 360869
  },
  {
    "id": "6013017211983",
    "name": "Owns: Galaxy S III Mini",
    "type": "behaviors",
    "path": [
      "Mobile Device User",
      "All Mobile Devices by Brand",
      "Samsung",
      "Owns: Galaxy S III Mini"
    ],
    "description": "People who are likely to own a Galaxy S III Mini mobile device",
    "real_time_cluster": false,
    "audience_size_lower_bound": 126110,
    "audience_size_upper_bound": 148306
  },
  {
    "id": "6013017235183",
    "name": "Galaxy Note II",
    "type": "behaviors",
    "path": [
      "Mobile Device User",
      "All Mobile Devices by Brand",
      "Samsung",
      "Galaxy Note II"
    ],
    "description": "",
    "real_time_cluster": false,
    "audience_size_lower_bound": 6593,
    "audience_size_upper_bound": 7754
  },
  {
    "id": "6013017236583",
    "name": "Owns: Galaxy Grand",
    "type": "behaviors",
    "path": [
      "Mobile Device User",
      "All Mobile Devices by Brand",
      "Samsung",
      "Owns: Galaxy Grand"
    ],
    "description": "People who are likely to own a Galaxy Grand mobile device",
    "real_time_cluster": false,
    "audience_size_lower_bound": 169486,
    "audience_size_upper_bound": 199316
  },
  {
    "id": "6013017297383",
    "name": "Curve 9220",
    "type": "behaviors",
    "path": [
      "Mobile Device User",
      "All Mobile Devices by Brand",
      "BlackBerry",
      "Curve 9220"
    ],
    "description": "",
    "real_time_cluster": false,
    "audience_size_lower_bound": 391,
    "audience_size_upper_bound": 460
  },
  {
    "id": "6013017308783",
    "name": "Owns: Galaxy S 4 Mini",
    "type": "behaviors",
    "path": [
      "Mobile Device User",
      "All Mobile Devices by Brand",
      "Samsung",
      "Owns: Galaxy S 4 Mini"
    ],
    "description": "People who are likely to own a Galaxy S 4 Mini mobile device.",
    "real_time_cluster": false,
    "audience_size_lower_bound": 65838,
    "audience_size_upper_bound": 77426
  },
  {
    "id": "6013279353983",
    "name": "Owns: Galaxy Note 3",
    "type": "behaviors",
    "path": [
      "Mobile Device User",
      "All Mobile Devices by Brand",
      "Samsung",
      "Owns: Galaxy Note 3"
    ],
    "description": "People who are likely to own a Galaxy Note 3 mobile device.",
    "real_time_cluster": false,
    "audience_size_lower_bound": 539877,
    "audience_size_upper_bound": 634896
  },
  {
    "id": "6013516370183",
    "name": "Commuters",
    "type": "behaviors",
    "path": [
      "Travel",
      "Commuters"
    ],
    "description": "People who likely commute from their home to their workplace on weekdays",
    "real_time_cluster": false,
    "audience_size_lower_bound": 295258137,
    "audience_size_upper_bound": 347223570
  },
  {
    "id": "6014808618583",
    "name": "Owns: Galaxy S5",
    "type": "behaviors",
    "path": [
      "Mobile Device User",
      "All Mobile Devices by Brand",
      "Samsung",
      "Owns: Galaxy S5"
    ],
    "description": "People who are likely to own a Samsung Galaxy S5 mobile device",
    "real_time_cluster": false,
    "audience_size_lower_bound": 693363,
    "audience_size_upper_bound": 815396
  },
  {
    "id": "6014809400783",
    "name": "Owns: Nexus 5",
    "type": "behaviors",
    "path": [
      "Mobile Device User",
      "All Mobile Devices by Brand",
      "Google",
      "Owns: Nexus 5"
    ],
    "description": "People who are likely to own a Nexus 5 mobile device.",
    "real_time_cluster": false,
    "audience_size_lower_bound": 195572,
    "audience_size_upper_bound": 229993
  },
  {
    "id": "6014809859183",
    "name": "Owns: HTC One",
    "type": "behaviors",
    "path": [
      "Mobile Device User",
      "All Mobile Devices by Brand",
      "HTC",
      "Owns: HTC One"
    ],
    "description": "People who are likely to own an HTC One mobile device",
    "real_time_cluster": false,
    "audience_size_lower_bound": 234892,
    "audience_size_upper_bound": 276233
  },
  {
    "id": "6015235495383",
    "name": "Facebook access (network type): Wi-Fi",
    "type": "behaviors",
    "path": [
      "Mobile Device User",
      "Network Connection",
      "Facebook access (network type): Wi-Fi"
    ],
    "description": "People who primarily access Facebook using a Wi-Fi network.",
    "real_time_cluster": false,
    "audience_size_lower_bound": 864330841,
    "audience_size_upper_bound": 1016453070
  },
  {
    "id": "6015441244983",
    "name": "Owns: Galaxy Grand 2",
    "type": "behaviors",
    "path": [
      "Mobile Device User",
      "All Mobile Devices by Brand",
      "Samsung",
      "Owns: Galaxy Grand 2"
    ],
    "description": "People who are likely to own a Samsung Galaxy Grand 2 mobile device",
    "real_time_cluster": false,
    "audience_size_lower_bound": 189096,
    "audience_size_upper_bound": 222377
  },
  {
    "id": "6015547847583",
    "name": "Facebook access (browser): Firefox",
    "type": "behaviors",
    "path": [
      "Digital activities",
      "Internet browser used",
      "Facebook access (browser): Firefox"
    ],
    "description": "People who primarily access Facebook using Firefox",
    "real_time_cluster": false,
    "audience_size_lower_bound": 12803107,
    "audience_size_upper_bound": 15056454
  },
  {
    "id": "6015547900583",
    "name": "Facebook access (browser): Chrome",
    "type": "behaviors",
    "path": [
      "Digital activities",
      "Internet browser used",
      "Facebook access (browser): Chrome"
    ],
    "description": "People who primarily access Facebook using Google Chrome.",
    "real_time_cluster": false,
    "audience_size_lower_bound": 284389909,
    "audience_size_upper_bound": 334442534
  },
  {
    "id": "6015559470583",
    "name": "Lives abroad",
    "type": "behaviors",
    "path": [
      "Ex-pats",
      "Lives abroad"
    ],
    "description": "People living outside their home country",
    "real_time_cluster": false,
    "audience_size_lower_bound": 186738644,
    "audience_size_upper_bound": 219604646
  },
  {
    "id": "6015593608983",
    "name": "Facebook access (browser): Safari",
    "type": "behaviors",
    "path": [
      "Digital activities",
      "Internet browser used",
      "Facebook access (browser): Safari"
    ],
    "description": "People who primarily access Facebook using Safari",
    "real_time_cluster": false,
    "audience_size_lower_bound": 54232278,
    "audience_size_upper_bound": 63777160
  },
  {
    "id": "6015593652183",
    "name": "Facebook access (browser): Opera",
    "type": "behaviors",
    "path": [
      "Digital activities",
      "Internet browser used",
      "Facebook access (browser): Opera"
    ],
    "description": "People who primarily access Facebook using Opera",
    "real_time_cluster": false,
    "audience_size_lower_bound": 9904893,
    "audience_size_upper_bound": 11648155
  },
  {
    "id": "6015593776783",
    "name": "Facebook access (browser): Internet Explorer",
    "type": "behaviors",
    "path": [
      "Digital activities",
      "Internet browser used",
      "Facebook access (browser): Internet Explorer"
    ],
    "description": "People who primarily access Facebook using Internet Explorer",
    "real_time_cluster": false,
    "audience_size_lower_bound": 339421,
    "audience_size_upper_bound": 399160
  },
  {
    "id": "6015683810783",
    "name": "Facebook Page admins",
    "type": "behaviors",
    "path": [
      "Digital activities",
      "Facebook page admins",
      "Facebook Page admins"
    ],
    "description": "People who are an admin of at least one Page on Facebook.",
    "real_time_cluster": false,
    "audience_size_lower_bound": 486199407,
    "audience_size_upper_bound": 571770503
  },
  {
    "id": "6016286626383",
    "name": "Facebook access (mobile): tablets",
    "type": "behaviors",
    "path": [
      "Mobile Device User",
      "Facebook access (mobile): tablets"
    ],
    "description": "People who primarily access Facebook using a tablet.",
    "real_time_cluster": false,
    "audience_size_lower_bound": 1552055318,
    "audience_size_upper_bound": 1825217055
  },
  {
    "id": "6016916298983",
    "name": "Lived in India (formerly Expats – India)",
    "type": "behaviors",
    "path": [
      "Ex-pats",
      "Lived in India (formerly Expats – India)"
    ],
    "description": "People who used to live in India who now live abroad",
    "real_time_cluster": false,
    "audience_size_lower_bound": 13146958,
    "audience_size_upper_bound": 15460823
  },
  {
    "id": "6016925328983",
    "name": "Owns: Galaxy Tab S",
    "type": "behaviors",
    "path": [
      "Mobile Device User",
      "All Mobile Devices by Brand",
      "Samsung",
      "Owns: Galaxy Tab S"
    ],
    "description": "People who are likely to own a Samsung Galaxy Tab S mobile device.",
    "real_time_cluster": false,
    "audience_size_lower_bound": 106898,
    "audience_size_upper_bound": 125713
  },
  {
    "id": "6016925394583",
    "name": "Owns: Galaxy Tab Pro",
    "type": "behaviors",
    "path": [
      "Mobile Device User",
      "All Mobile Devices by Brand",
      "Samsung",
      "Owns: Galaxy Tab Pro"
    ],
    "description": "People who are likely to own a Samsung Galaxy Tab Pro mobile device.",
    "real_time_cluster": false,
    "audience_size_lower_bound": 30076,
    "audience_size_upper_bound": 35370
  },
  {
    "id": "6016925404783",
    "name": "Owns: Galaxy Tab 4",
    "type": "behaviors",
    "path": [
      "Mobile Device User",
      "All Mobile Devices by Brand",
      "Samsung",
      "Owns: Galaxy Tab 4"
    ],
    "description": "People who are likely to own a Samsung Galaxy Tab 4 mobile device",
    "real_time_cluster": false,
    "audience_size_lower_bound": 547768,
    "audience_size_upper_bound": 644176
  },
  {
    "id": "6016925643983",
    "name": "Owns: Galaxy Tab 3",
    "type": "behaviors",
    "path": [
      "Mobile Device User",
      "All Mobile Devices by Brand",
      "Samsung",
      "Owns: Galaxy Tab 3"
    ],
    "description": "People who are likely to own a Galaxy Tab 3 mobile device.",
    "real_time_cluster": false,
    "audience_size_lower_bound": 786725,
    "audience_size_upper_bound": 925189
  },
  {
    "id": "6016925657183",
    "name": "Owns: Galaxy Tab 2",
    "type": "behaviors",
    "path": [
      "Mobile Device User",
      "All Mobile Devices by Brand",
      "Samsung",
      "Owns: Galaxy Tab 2"
    ],
    "description": "People who are likely to own a Galaxy Tab 2 mobile device",
    "real_time_cluster": false,
    "audience_size_lower_bound": 127989,
    "audience_size_upper_bound": 150516
  },
  {
    "id": "6016925667383",
    "name": "Galaxy Tab",
    "type": "behaviors",
    "path": [
      "Mobile Device User",
      "All Mobile Devices by Brand",
      "Samsung",
      "Galaxy Tab"
    ],
    "description": "People who are likely to own a Samsung Galaxy Tab",
    "real_time_cluster": false,
    "audience_size_lower_bound": 612,
    "audience_size_upper_bound": 720
  },
  {
    "id": "6016926254583",
    "name": "Owns: Xperia M",
    "type": "behaviors",
    "path": [
      "Mobile Device User",
      "All Mobile Devices by Brand",
      "Sony",
      "Owns: Xperia M"
    ],
    "description": "People who are likely to own a Sony Xperia M mobile device",
    "real_time_cluster": false,
    "audience_size_lower_bound": 8040,
    "audience_size_upper_bound": 9456
  },
  {
    "id": "6016926310383",
    "name": "Owns: Xperia SL",
    "type": "behaviors",
    "path": [
      "Mobile Device User",
      "All Mobile Devices by Brand",
      "Sony",
      "Owns: Xperia SL"
    ],
    "description": "People who are likely to own a Sony Xperia SLs",
    "real_time_cluster": false,
    "audience_size_lower_bound": 912,
    "audience_size_upper_bound": 1073
  },
  {
    "id": "6016926471583",
    "name": "Xperia T",
    "type": "behaviors",
    "path": [
      "Mobile Device User",
      "All Mobile Devices by Brand",
      "Sony",
      "Xperia T"
    ],
    "description": "People who are likely to own a Sony Xperia T",
    "real_time_cluster": false,
    "audience_size_lower_bound": 1178,
    "audience_size_upper_bound": 1386
  },
  {
    "id": "6016926528983",
    "name": "Owns: Xperia Z",
    "type": "behaviors",
    "path": [
      "Mobile Device User",
      "All Mobile Devices by Brand",
      "Sony",
      "Owns: Xperia Z"
    ],
    "description": "People who are likely to own a Sony Xperia Z mobile device",
    "real_time_cluster": false,
    "audience_size_lower_bound": 11462,
    "audience_size_upper_bound": 13480
  },
  {
    "id": "6016926651383",
    "name": "Owns: Xperia Z Ultra",
    "type": "behaviors",
    "path": [
      "Mobile Device User",
      "All Mobile Devices by Brand",
      "Sony",
      "Owns: Xperia Z Ultra"
    ],
    "description": "People who are likely to own a Sony Xperia Z Ultra mobile device",
    "real_time_cluster": false,
    "audience_size_lower_bound": 4948,
    "audience_size_upper_bound": 5820
  },
  {
    "id": "6017253486583",
    "name": "Facebook access (network type): 2G",
    "type": "behaviors",
    "path": [
      "Mobile Device User",
      "Network Connection",
      "Facebook access (network type): 2G"
    ],
    "description": "People who primarily access Facebook using a 2G network.",
    "real_time_cluster": false,
    "audience_size_lower_bound": 8717719,
    "audience_size_upper_bound": 10252038
  },
  {
    "id": "6017253511583",
    "name": "Facebook access (network type): 3G",
    "type": "behaviors",
    "path": [
      "Mobile Device User",
      "Network Connection",
      "Facebook access (network type): 3G"
    ],
    "description": "People who primarily access Facebook using a 3G network.",
    "real_time_cluster": false,
    "audience_size_lower_bound": 116493891,
    "audience_size_upper_bound": 136996816
  },
  {
    "id": "6017253531383",
    "name": "Facebook access (network type): 4G",
    "type": "behaviors",
    "path": [
      "Mobile Device User",
      "Network Connection",
      "Facebook access (network type): 4G"
    ],
    "description": "People who primarily access Facebook using a 4G network",
    "real_time_cluster": false,
    "audience_size_lower_bound": 824000498,
    "audience_size_upper_bound": 969024586
  },
  {
    "id": "6017535283383",
    "name": "Owns: LG G3",
    "type": "behaviors",
    "path": [
      "Mobile Device User",
      "All Mobile Devices by Brand",
      "LG",
      "Owns: LG G3"
    ],
    "description": "People who are likely to own an LG G3 mobile device",
    "real_time_cluster": false,
    "audience_size_lower_bound": 70171,
    "audience_size_upper_bound": 82522
  },
  {
    "id": "6017831560783",
    "name": "Owns: iPhone 6 Plus",
    "type": "behaviors",
    "path": [
      "Mobile Device User",
      "All Mobile Devices by Brand",
      "Apple",
      "Owns: iPhone 6 Plus"
    ],
    "description": "People who are likely to own an Apple iPhone 6 Plus mobile device",
    "real_time_cluster": false,
    "audience_size_lower_bound": 1740782,
    "audience_size_upper_bound": 2047160
  },
  {
    "id": "6017831572183",
    "name": "Owns: iPhone 6",
    "type": "behaviors",
    "path": [
      "Mobile Device User",
      "All Mobile Devices by Brand",
      "Apple",
      "Owns: iPhone 6"
    ],
    "description": "People who are likely to own an iPhone 6 mobile device",
    "real_time_cluster": false,
    "audience_size_lower_bound": 4018977,
    "audience_size_upper_bound": 4726317
  },
  {
    "id": "6018413514983",
    "name": "Anniversary (within 61-90 days)",
    "type": "behaviors",
    "path": [
      "Anniversary",
      "Anniversary (within 61-90 days)"
    ],
    "description": "People with an anniversary in 61-90 days",
    "real_time_cluster": false,
    "audience_size_lower_bound": 7508950,
    "audience_size_upper_bound": 8830526
  },
  {
    "id": "6018796980983",
    "name": "Lived in Kenya (formerly Expats – Kenya)",
    "type": "behaviors",
    "path": [
      "Ex-pats",
      "Lived in Kenya (formerly Expats – Kenya)"
    ],
    "description": "People who used to live in Kenya who now live abroad",
    "real_time_cluster": false,
    "audience_size_lower_bound": 1009606,
    "audience_size_upper_bound": 1187297
  },
  {
    "id": "6018797004183",
    "name": "Lived in Nigeria (formerly Expats – Nigeria)",
    "type": "behaviors",
    "path": [
      "Ex-pats",
      "Lived in Nigeria (formerly Expats – Nigeria)"
    ],
    "description": "People who used to live in Nigeria who now live abroad",
    "real_time_cluster": false,
    "audience_size_lower_bound": 2089279,
    "audience_size_upper_bound": 2456993
  },
  {
    "id": "6018797036783",
    "name": "Lived in Cameroon (formerly Expats – Cameroon)",
    "type": "behaviors",
    "path": [
      "Ex-pats",
      "Lived in Cameroon (formerly Expats – Cameroon)"
    ],
    "description": "People who used to live in Cameroon who now live abroad",
    "real_time_cluster": false,
    "audience_size_lower_bound": 438882,
    "audience_size_upper_bound": 516126
  },
  {
    "id": "6018797091183",
    "name": "Lived in Philippines (formerly Expats – Philippines)",
    "type": "behaviors",
    "path": [
      "Ex-pats",
      "Lived in Philippines (formerly Expats – Philippines)"
    ],
    "description": "People who used to live in the Philippines who now live abroad",
    "real_time_cluster": false,
    "audience_size_lower_bound": 8583301,
    "audience_size_upper_bound": 10093963
  },
  {
    "id": "6018797127383",
    "name": "Lived in Cuba (formerly Expats – Cuba)",
    "type": "behaviors",
    "path": [
      "Ex-pats",
      "Lived in Cuba (formerly Expats – Cuba)"
    ],
    "description": "People who used to live in Cuba who now live abroad",
    "real_time_cluster": false,
    "audience_size_lower_bound": 1557291,
    "audience_size_upper_bound": 1831375
  },
  {
    "id": "6018797165983",
    "name": "Lived in Ethiopia (formerly Expats – Ethiopia)",
    "type": "behaviors",
    "path": [
      "Ex-pats",
      "Lived in Ethiopia (formerly Expats – Ethiopia)"
    ],
    "description": "People who used to live in Ethiopia who now live abroad",
    "real_time_cluster": false,
    "audience_size_lower_bound": 956283,
    "audience_size_upper_bound": 1124589
  },
  {
    "id": "6018797373783",
    "name": "Lived in Haiti (formerly Expats – Haiti)",
    "type": "behaviors",
    "path": [
      "Ex-pats",
      "Lived in Haiti (formerly Expats – Haiti)"
    ],
    "description": "People who used to live in Haiti who now live abroad",
    "real_time_cluster": false,
    "audience_size_lower_bound": 1267670,
    "audience_size_upper_bound": 1490781
  },
  {
    "id": "6018995113183",
    "name": "Owns: iPad Air 2",
    "type": "behaviors",
    "path": [
      "Mobile Device User",
      "All Mobile Devices by Brand",
      "Apple",
      "Owns: iPad Air 2"
    ],
    "description": "People who are likely to own an iPad Air 2 mobile device",
    "real_time_cluster": false,
    "audience_size_lower_bound": 1931914,
    "audience_size_upper_bound": 2271931
  },
  {
    "id": "6019221024783",
    "name": "Played Canvas games (yesterday)",
    "type": "behaviors",
    "path": [
      "Digital activities",
      "Canvas gaming",
      "Played Canvas games (yesterday)"
    ],
    "description": "People who played a Canvas game yesterday.",
    "real_time_cluster": false,
    "audience_size_lower_bound": 2246272,
    "audience_size_upper_bound": 2641617
  },
  {
    "id": "6019221038183",
    "name": "Played Canvas games (last 7 days)",
    "type": "behaviors",
    "path": [
      "Digital activities",
      "Canvas gaming",
      "Played Canvas games (last 7 days)"
    ],
    "description": "People who played a Canvas game in the last 7 days.",
    "real_time_cluster": false,
    "audience_size_lower_bound": 2245543,
    "audience_size_upper_bound": 2640759
  },
  {
    "id": "6019221046583",
    "name": "Played Canvas games (last 14 days)",
    "type": "behaviors",
    "path": [
      "Digital activities",
      "Canvas gaming",
      "Played Canvas games (last 14 days)"
    ],
    "description": "People who played a Canvas game in the last 14 days.",
    "real_time_cluster": false,
    "audience_size_lower_bound": 2245214,
    "audience_size_upper_bound": 2640372
  },
  {
    "id": "6019098117583",
    "name": "Owns: iPad Mini 3",
    "type": "behaviors",
    "path": [
      "Mobile Device User",
      "All Mobile Devices by Brand",
      "Apple",
      "Owns: iPad Mini 3"
    ],
    "description": "People who are likely to own an iPad Mini 3 mobile device.",
    "real_time_cluster": false,
    "audience_size_lower_bound": 148901,
    "audience_size_upper_bound": 175108
  },
  {
    "id": "6019098214783",
    "name": "Owns: Galaxy Note 4",
    "type": "behaviors",
    "path": [
      "Mobile Device User",
      "All Mobile Devices by Brand",
      "Samsung",
      "Owns: Galaxy Note 4"
    ],
    "description": "People who are likely to own a Galaxy Note 4 mobile device.",
    "real_time_cluster": false,
    "audience_size_lower_bound": 336659,
    "audience_size_upper_bound": 395911
  },
  {
    "id": "6019164544783",
    "name": "Owns: Karbonn",
    "type": "behaviors",
    "path": [
      "Mobile Device User",
      "All Mobile Devices by Brand",
      "Owns: Karbonn"
    ],
    "description": "People who are likely to own a Karbonn mobile device.",
    "real_time_cluster": false,
    "audience_size_lower_bound": 117729,
    "audience_size_upper_bound": 138450
  },
  {
    "id": "6019164586183",
    "name": "Owns: Micromax",
    "type": "behaviors",
    "path": [
      "Mobile Device User",
      "All Mobile Devices by Brand",
      "Owns: Micromax"
    ],
    "description": "People who are likely to own a Micromax mobile device",
    "real_time_cluster": false,
    "audience_size_lower_bound": 463070,
    "audience_size_upper_bound": 544571
  },
  {
    "id": "6019164630583",
    "name": "Owns: Xiaomi",
    "type": "behaviors",
    "path": [
      "Mobile Device User",
      "All Mobile Devices by Brand",
      "Owns: Xiaomi"
    ],
    "description": "People who are likely to own a Xiaomi mobile device.",
    "real_time_cluster": false,
    "audience_size_lower_bound": 239131226,
    "audience_size_upper_bound": 281218322
  },
  {
    "id": "6019246164583",
    "name": "Played Canvas games (last 3 days)",
    "type": "behaviors",
    "path": [
      "Digital activities",
      "Canvas gaming",
      "Played Canvas games (last 3 days)"
    ],
    "description": "People who played a Canvas game in the last 3 days",
    "real_time_cluster": false,
    "audience_size_lower_bound": 2245756,
    "audience_size_upper_bound": 2641010
  },
  {
    "id": "6019366943583",
    "name": "Lived in Spain (formerly Expats – Spain)",
    "type": "behaviors",
    "path": [
      "Ex-pats",
      "Lived in Spain (formerly Expats – Spain)"
    ],
    "description": "People who used to live in Spain who now live abroad",
    "real_time_cluster": false,
    "audience_size_lower_bound": 1619049,
    "audience_size_upper_bound": 1904002
  },
  {
    "id": "6019367014383",
    "name": "Lived in France (formerly Ex-pats – France)",
    "type": "behaviors",
    "path": [
      "Ex-pats",
      "Lived in France (formerly Ex-pats – France)"
    ],
    "description": "People who used to live in France who now live abroad",
    "real_time_cluster": false,
    "audience_size_lower_bound": 1766833,
    "audience_size_upper_bound": 2077796
  },
  {
    "id": "6019367052983",
    "name": "Lived in Germany (formerly Ex-pats – Germany)",
    "type": "behaviors",
    "path": [
      "Ex-pats",
      "Lived in Germany (formerly Ex-pats – Germany)"
    ],
    "description": "People who used to live in Germany who now live abroad",
    "real_time_cluster": false,
    "audience_size_lower_bound": 1498554,
    "audience_size_upper_bound": 1762300
  },
  {
    "id": "6019377644783",
    "name": "Lived in Switzerland (formerly Ex-pats – Switzerland)",
    "type": "behaviors",
    "path": [
      "Ex-pats",
      "Lived in Switzerland (formerly Ex-pats – Switzerland)"
    ],
    "description": "People who used to live in Switzerland who now live abroad",
    "real_time_cluster": false,
    "audience_size_lower_bound": 290017,
    "audience_size_upper_bound": 341061
  },
  {
    "id": "6019396649183",
    "name": "Lived in the United States (formerly Ex-pats – United States)",
    "type": "behaviors",
    "path": [
      "Ex-pats",
      "Lived in the United States (formerly Ex-pats – United States)"
    ],
    "description": "People who used to live in the United States who now live abroad",
    "real_time_cluster": false,
    "audience_size_lower_bound": 16632661,
    "audience_size_upper_bound": 19560010
  },
  {
    "id": "6019396657183",
    "name": "Lived in Poland (formerly Ex-pats – Poland)",
    "type": "behaviors",
    "path": [
      "Ex-pats",
      "Lived in Poland (formerly Ex-pats – Poland)"
    ],
    "description": "People who used to live in Poland who now live abroad",
    "real_time_cluster": false,
    "audience_size_lower_bound": 1430693,
    "audience_size_upper_bound": 1682496
  },
  {
    "id": "6019396654583",
    "name": "Lived in Italy (formerly Ex-pats – Italy)",
    "type": "behaviors",
    "path": [
      "Ex-pats",
      "Lived in Italy (formerly Ex-pats – Italy)"
    ],
    "description": "People who used to live in Italy who now live abroad",
    "real_time_cluster": false,
    "audience_size_lower_bound": 1732637,
    "audience_size_upper_bound": 2037582
  },
  {
    "id": "6019396650783",
    "name": "Lived in Ireland (formerly Ex-pats – Ireland)",
    "type": "behaviors",
    "path": [
      "Ex-pats",
      "Lived in Ireland (formerly Ex-pats – Ireland)"
    ],
    "description": "People who used to live in Ireland who now live abroad",
    "real_time_cluster": false,
    "audience_size_lower_bound": 290640,
    "audience_size_upper_bound": 341793
  },
  {
    "id": "6019396638383",
    "name": "Lived in Hungary (formerly Ex-pats – Hungary)",
    "type": "behaviors",
    "path": [
      "Ex-pats",
      "Lived in Hungary (formerly Ex-pats – Hungary)"
    ],
    "description": "People who used to live in Hungary who now live abroad",
    "real_time_cluster": false,
    "audience_size_lower_bound": 335664,
    "audience_size_upper_bound": 394741
  },
  {
    "id": "6019396764183",
    "name": "Lived in Canada (formerly Expats – Canada)",
    "type": "behaviors",
    "path": [
      "Ex-pats",
      "Lived in Canada (formerly Expats – Canada)"
    ],
    "description": "People who used to live in Canada who now live abroad",
    "real_time_cluster": false,
    "audience_size_lower_bound": 1267245,
    "audience_size_upper_bound": 1490281
  },
  {
    "id": "6019452369983",
    "name": "Lived in China (formerly Ex-pats – China)",
    "type": "behaviors",
    "path": [
      "Ex-pats",
      "Lived in China (formerly Ex-pats – China)"
    ],
    "description": "People who used to live in China who now live abroad",
    "real_time_cluster": false,
    "audience_size_lower_bound": 2046947,
    "audience_size_upper_bound": 2407210
  },
  {
    "id": "6019520122583",
    "name": "Lived in Puerto Rico (formerly Ex-pats – Puerto Rico)",
    "type": "behaviors",
    "path": [
      "Ex-pats",
      "Lived in Puerto Rico (formerly Ex-pats – Puerto Rico)"
    ],
    "description": "People who used to live in Puerto Rico who now live abroad",
    "real_time_cluster": false,
    "audience_size_lower_bound": 1195670,
    "audience_size_upper_bound": 1406109
  },
  {
    "id": "6019564340583",
    "name": "Lived in Brazil (formerly Ex-pats – Brazil)",
    "type": "behaviors",
    "path": [
      "Ex-pats",
      "Lived in Brazil (formerly Ex-pats – Brazil)"
    ],
    "description": "People who used to live in Brazil who now live abroad",
    "real_time_cluster": false,
    "audience_size_lower_bound": 3609172,
    "audience_size_upper_bound": 4244387
  },
  {
    "id": "6019564344583",
    "name": "Lived in Indonesia (formerly Ex-pats – Indonesia)",
    "type": "behaviors",
    "path": [
      "Ex-pats",
      "Lived in Indonesia (formerly Ex-pats – Indonesia)"
    ],
    "description": "People who used to live in Indonesia who now live abroad",
    "real_time_cluster": false,
    "audience_size_lower_bound": 3396821,
    "audience_size_upper_bound": 3994662
  },
  {
    "id": "6019564383383",
    "name": "Lived in South Africa (formerly Expats – South Africa)",
    "type": "behaviors",
    "path": [
      "Ex-pats",
      "Lived in South Africa (formerly Expats – South Africa)"
    ],
    "description": "People who used to live in South Africa who now live abroad",
    "real_time_cluster": false,
    "audience_size_lower_bound": 1143360,
    "audience_size_upper_bound": 1344592
  },
  {
    "id": "6019673233983",
    "name": "Lived in Zimbabwe (formerly Ex-pats – Zimbabwe)",
    "type": "behaviors",
    "path": [
      "Ex-pats",
      "Lived in Zimbabwe (formerly Ex-pats – Zimbabwe)"
    ],
    "description": "People who used to live in Zimbabwe who now live abroad",
    "real_time_cluster": false,
    "audience_size_lower_bound": 923470,
    "audience_size_upper_bound": 1086001
  },
  {
    "id": "6019673448383",
    "name": "Lived in Ghana (formerly Ex-pats – Ghana)",
    "type": "behaviors",
    "path": [
      "Ex-pats",
      "Lived in Ghana (formerly Ex-pats – Ghana)"
    ],
    "description": "People who used to live in Ghana who now live abroad",
    "real_time_cluster": false,
    "audience_size_lower_bound": 686757,
    "audience_size_upper_bound": 807627
  },
  {
    "id": "6019673501783",
    "name": "Lived in Uganda (formerly Ex-pats – Uganda)",
    "type": "behaviors",
    "path": [
      "Ex-pats",
      "Lived in Uganda (formerly Ex-pats – Uganda)"
    ],
    "description": "People who used to live in Uganda who now live abroad",
    "real_time_cluster": false,
    "audience_size_lower_bound": 550754,
    "audience_size_upper_bound": 647687
  },
  {
    "id": "6019673525983",
    "name": "Lived in Colombia (formerly Ex-pats – Colombia)",
    "type": "behaviors",
    "path": [
      "Ex-pats",
      "Lived in Colombia (formerly Ex-pats – Colombia)"
    ],
    "description": "People who used to live in Colombia who now live abroad",
    "real_time_cluster": false,
    "audience_size_lower_bound": 2564140,
    "audience_size_upper_bound": 3015429
  },
  {
    "id": "6019673762183",
    "name": "Lived in Dominican Republic (formerly Ex-pats – Dominican Republic)",
    "type": "behaviors",
    "path": [
      "Ex-pats",
      "Lived in Dominican Republic (formerly Ex-pats – Dominican Republic)"
    ],
    "description": "People who used to live in the Dominican Republic who now live abroad",
    "real_time_cluster": false,
    "audience_size_lower_bound": 1327362,
    "audience_size_upper_bound": 1560978
  },
  {
    "id": "6019673777983",
    "name": "Lived in El Salvador (formerly Expats – El Salvador)",
    "type": "behaviors",
    "path": [
      "Ex-pats",
      "Lived in El Salvador (formerly Expats – El Salvador)"
    ],
    "description": "People who used to live in El Salvador who now live abroad",
    "real_time_cluster": false,
    "audience_size_lower_bound": 1401211,
    "audience_size_upper_bound": 1647825
  },
  {
    "id": "6019673808383",
    "name": "Lived in Guatemala (formerly Ex-pats – Guatemala)",
    "type": "behaviors",
    "path": [
      "Ex-pats",
      "Lived in Guatemala (formerly Ex-pats – Guatemala)"
    ],
    "description": "People who used to live in Guatemala who now live abroad",
    "real_time_cluster": false,
    "audience_size_lower_bound": 1886041,
    "audience_size_upper_bound": 2217985
  },
  {
    "id": "6020530139383",
    "name": "Travel and tourism Page admins",
    "type": "behaviors",
    "path": [
      "Digital activities",
      "Facebook page admins",
      "Travel and tourism Page admins"
    ],
    "description": "People who are an admin of a travel and tourism Page on Facebook",
    "real_time_cluster": false,
    "audience_size_lower_bound": 18640331,
    "audience_size_upper_bound": 21921030
  },
  {
    "id": "6020530156983",
    "name": "Sport Page admins",
    "type": "behaviors",
    "path": [
      "Digital activities",
      "Facebook page admins",
      "Sport Page admins"
    ],
    "description": "People who are an admin of a sport Page on Facebook",
    "real_time_cluster": false,
    "audience_size_lower_bound": 7572148,
    "audience_size_upper_bound": 8904847
  },
  {
    "id": "6020530250383",
    "name": "Retail Page admins",
    "type": "behaviors",
    "path": [
      "Digital activities",
      "Facebook page admins",
      "Retail Page admins"
    ],
    "description": "People who are an admin of a retail Page on Facebook",
    "real_time_cluster": false,
    "audience_size_lower_bound": 10418857,
    "audience_size_upper_bound": 12252577
  },
  {
    "id": "6020568271383",
    "name": "Health and beauty Page admins",
    "type": "behaviors",
    "path": [
      "Digital activities",
      "Facebook page admins",
      "Health and beauty Page admins"
    ],
    "description": "People who are an admin of a health and beauty Page on Facebook",
    "real_time_cluster": false,
    "audience_size_lower_bound": 13958715,
    "audience_size_upper_bound": 16415450
  },
  {
    "id": "6020530269183",
    "name": "Food and restaurant Page admins",
    "type": "behaviors",
    "path": [
      "Digital activities",
      "Facebook page admins",
      "Food and restaurant Page admins"
    ],
    "description": "People who are an admin of a food and restaurant Page on Facebook",
    "real_time_cluster": false,
    "audience_size_lower_bound": 1889454,
    "audience_size_upper_bound": 2221998
  },
  {
    "id": "6020530280983",
    "name": "Community and club Page admins",
    "type": "behaviors",
    "path": [
      "Digital activities",
      "Facebook page admins",
      "Community and club Page admins"
    ],
    "description": "People who are an admin of a community and club Page on Facebook",
    "real_time_cluster": false,
    "audience_size_lower_bound": 16831685,
    "audience_size_upper_bound": 19794062
  },
  {
    "id": "6020530281783",
    "name": "Business Page admins",
    "type": "behaviors",
    "path": [
      "Digital activities",
      "Facebook page admins",
      "Business Page admins"
    ],
    "description": "People who are an admin of a business Page on Facebook",
    "real_time_cluster": false,
    "audience_size_lower_bound": 40957801,
    "audience_size_upper_bound": 48166374
  },
  {
    "id": "6021354152983",
    "name": "Lived in the UK (formerly Ex-pats – UK)",
    "type": "behaviors",
    "path": [
      "Ex-pats",
      "Lived in the UK (formerly Ex-pats – UK)"
    ],
    "description": "People who used to live in the United Kingdom who now live abroad",
    "real_time_cluster": false,
    "audience_size_lower_bound": 4692991,
    "audience_size_upper_bound": 5518958
  },
  {
    "id": "6021354857783",
    "name": "Lived in Australia (formerly Ex-pats – Australia)",
    "type": "behaviors",
    "path": [
      "Ex-pats",
      "Lived in Australia (formerly Ex-pats – Australia)"
    ],
    "description": "People who used to live in Australia who now live abroad",
    "real_time_cluster": false,
    "audience_size_lower_bound": 1210778,
    "audience_size_upper_bound": 1423875
  },
  {
    "id": "6021354882783",
    "name": "Lived in Portugal (formerly Ex-pats – Portugal)",
    "type": "behaviors",
    "path": [
      "Ex-pats",
      "Lived in Portugal (formerly Ex-pats – Portugal)"
    ],
    "description": "People who used to live in Portugal who now live abroad",
    "real_time_cluster": false,
    "audience_size_lower_bound": 984889,
    "audience_size_upper_bound": 1158230
  },
  {
    "id": "6022430911783",
    "name": "Owns: Xperia Z3",
    "type": "behaviors",
    "path": [
      "Mobile Device User",
      "All Mobile Devices by Brand",
      "Sony",
      "Owns: Xperia Z3"
    ],
    "description": "People who are likely to own a Sony Xperia Z3",
    "real_time_cluster": false,
    "audience_size_lower_bound": 12573,
    "audience_size_upper_bound": 14787
  },
  {
    "id": "6022788483583",
    "name": "Frequent international travellers",
    "type": "behaviors",
    "path": [
      "Travel",
      "Frequent international travellers"
    ],
    "description": "People who have travelled abroad more than once in the past 6 months.",
    "real_time_cluster": false,
    "audience_size_lower_bound": 520233678,
    "audience_size_upper_bound": 611794806
  },
  {
    "id": "6023287351383",
    "name": "Lived in Estonia (formerly Expats – Estonia)",
    "type": "behaviors",
    "path": [
      "Ex-pats",
      "Lived in Estonia (formerly Expats – Estonia)"
    ],
    "description": "People who used to live in Estonia who now live abroad",
    "real_time_cluster": false,
    "audience_size_lower_bound": 79836,
    "audience_size_upper_bound": 93888
  },
  {
    "id": "6023287459983",
    "name": "Lived in Norway (formerly Ex-pats – Norway)",
    "type": "behaviors",
    "path": [
      "Ex-pats",
      "Lived in Norway (formerly Ex-pats – Norway)"
    ],
    "description": "People who used to live in Norway who now live abroad",
    "real_time_cluster": false,
    "audience_size_lower_bound": 192323,
    "audience_size_upper_bound": 226172
  },
  {
    "id": "6023287455983",
    "name": "Lived in Denmark (formerly Ex-pats – Denmark)",
    "type": "behaviors",
    "path": [
      "Ex-pats",
      "Lived in Denmark (formerly Ex-pats – Denmark)"
    ],
    "description": "People who used to live in Denmark who now live abroad",
    "real_time_cluster": false,
    "audience_size_lower_bound": 145276,
    "audience_size_upper_bound": 170845
  },
  {
    "id": "6023287438783",
    "name": "Lived in Czech Republic (formerly Ex-pats – Czech Republic)",
    "type": "behaviors",
    "path": [
      "Ex-pats",
      "Lived in Czech Republic (formerly Ex-pats – Czech Republic)"
    ],
    "description": "People who used to live in the Czech Republic who now live abroad",
    "real_time_cluster": false,
    "audience_size_lower_bound": 206193,
    "audience_size_upper_bound": 242484
  },
  {
    "id": "6023287397383",
    "name": "Lived in Sweden (formerly Expats – Sweden)",
    "type": "behaviors",
    "path": [
      "Ex-pats",
      "Lived in Sweden (formerly Expats – Sweden)"
    ],
    "description": "People who used to live in Sweden who now live abroad",
    "real_time_cluster": false,
    "audience_size_lower_bound": 272692,
    "audience_size_upper_bound": 320686
  },
  {
    "id": "6023287393783",
    "name": "Lived in the Netherlands (formerly Ex-pats – the Netherlands)",
    "type": "behaviors",
    "path": [
      "Ex-pats",
      "Lived in the Netherlands (formerly Ex-pats – the Netherlands)"
    ],
    "description": "People who used to live in the Netherlands who now live abroad",
    "real_time_cluster": false,
    "audience_size_lower_bound": 448112,
    "audience_size_upper_bound": 526980
  },
  {
    "id": "6023356562783",
    "name": "Lived in Bangladesh (formerly Ex-pats – Bangladesh)",
    "type": "behaviors",
    "path": [
      "Ex-pats",
      "Lived in Bangladesh (formerly Ex-pats – Bangladesh)"
    ],
    "description": "People who used to live in Bangladesh who now live abroad",
    "real_time_cluster": false,
    "audience_size_lower_bound": 5270221,
    "audience_size_upper_bound": 6197781
  },
  {
    "id": "6023356926183",
    "name": "Lived in Tanzania (formerly Expats – Tanzania)",
    "type": "behaviors",
    "path": [
      "Ex-pats",
      "Lived in Tanzania (formerly Expats – Tanzania)"
    ],
    "description": "People who used to live in Tanzania who now live abroad",
    "real_time_cluster": false,
    "audience_size_lower_bound": 416574,
    "audience_size_upper_bound": 489892
  },
  {
    "id": "6023356955383",
    "name": "Lived in Nepal (formerly Ex-pats – Nepal)",
    "type": "behaviors",
    "path": [
      "Ex-pats",
      "Lived in Nepal (formerly Ex-pats – Nepal)"
    ],
    "description": "People who used to live in Nepal who now live abroad",
    "real_time_cluster": false,
    "audience_size_lower_bound": 3080157,
    "audience_size_upper_bound": 3622265
  },
  {
    "id": "6023356956983",
    "name": "Lived in Jamaica (formerly Ex-pats – Jamaica)",
    "type": "behaviors",
    "path": [
      "Ex-pats",
      "Lived in Jamaica (formerly Ex-pats – Jamaica)"
    ],
    "description": "People who used to live in Jamaica who now live abroad",
    "real_time_cluster": false,
    "audience_size_lower_bound": 663017,
    "audience_size_upper_bound": 779708
  },
  {
    "id": "6023356966183",
    "name": "Lived in Thailand (formerly Ex-pats – Thailand)",
    "type": "behaviors",
    "path": [
      "Ex-pats",
      "Lived in Thailand (formerly Ex-pats – Thailand)"
    ],
    "description": "People who used to live in Thailand who now live abroad",
    "real_time_cluster": false,
    "audience_size_lower_bound": 1594083,
    "audience_size_upper_bound": 1874642
  },
  {
    "id": "6023356986383",
    "name": "Lived in Sierra Leone (formerly Expats – Sierra Leone)",
    "type": "behaviors",
    "path": [
      "Ex-pats",
      "Lived in Sierra Leone (formerly Expats – Sierra Leone)"
    ],
    "description": "People who used to live in Sierra Leone who now live abroad",
    "real_time_cluster": false,
    "audience_size_lower_bound": 193781,
    "audience_size_upper_bound": 227887
  },
  {
    "id": "6023357000583",
    "name": "Lived in Senegal (formerly Ex-pats – Senegal)",
    "type": "behaviors",
    "path": [
      "Ex-pats",
      "Lived in Senegal (formerly Ex-pats – Senegal)"
    ],
    "description": "People who used to live in Senegal who now live abroad",
    "real_time_cluster": false,
    "audience_size_lower_bound": 427916,
    "audience_size_upper_bound": 503230
  },
  {
    "id": "6023422105983",
    "name": "Lived in Côte d'Ivoire (formerly Ex-pats – Ivory Coast)",
    "type": "behaviors",
    "path": [
      "Ex-pats",
      "Lived in Côte d'Ivoire (formerly Ex-pats – Ivory Coast)"
    ],
    "description": "People who used to live in Côte d'Ivoire who now live abroad",
    "real_time_cluster": false,
    "audience_size_lower_bound": 531130,
    "audience_size_upper_bound": 624609
  },
  {
    "id": "6023460563383",
    "name": "Owns: Alcatel",
    "type": "behaviors",
    "path": [
      "Mobile Device User",
      "All Mobile Devices by Brand",
      "Owns: Alcatel"
    ],
    "description": "People who are likely to own an Alcatel mobile device",
    "real_time_cluster": false,
    "audience_size_lower_bound": 5102943,
    "audience_size_upper_bound": 6001062
  },
  {
    "id": "6023460572383",
    "name": "Owns: ZTE",
    "type": "behaviors",
    "path": [
      "Mobile Device User",
      "All Mobile Devices by Brand",
      "Owns: ZTE"
    ],
    "description": "People who are likely to own a ZTE mobile device",
    "real_time_cluster": false,
    "audience_size_lower_bound": 9410894,
    "audience_size_upper_bound": 11067212
  },
  {
    "id": "6023460579583",
    "name": "Owns: Tecno",
    "type": "behaviors",
    "path": [
      "Mobile Device User",
      "All Mobile Devices by Brand",
      "Owns: Tecno"
    ],
    "description": "People who are likely to own a Tecno mobile device.",
    "real_time_cluster": false,
    "audience_size_lower_bound": 66989783,
    "audience_size_upper_bound": 78779985
  },
  {
    "id": "6023460590583",
    "name": "Owns: Cherry Mobile",
    "type": "behaviors",
    "path": [
      "Mobile Device User",
      "All Mobile Devices by Brand",
      "Owns: Cherry Mobile"
    ],
    "description": "People who are likely to own a Cherry mobile device.",
    "real_time_cluster": false,
    "audience_size_lower_bound": 400974,
    "audience_size_upper_bound": 471546
  },
  {
    "id": "6023516315983",
    "name": "Lived in Sri Lanka (formerly Ex-pats – Sri Lanka)",
    "type": "behaviors",
    "path": [
      "Ex-pats",
      "Lived in Sri Lanka (formerly Ex-pats – Sri Lanka)"
    ],
    "description": "People who used to live in Sri Lanka who now live abroad",
    "real_time_cluster": false,
    "audience_size_lower_bound": 929309,
    "audience_size_upper_bound": 1092868
  },
  {
    "id": "6023516338783",
    "name": "Lived in Morocco (formerly Ex-pats – Morocco)",
    "type": "behaviors",
    "path": [
      "Ex-pats",
      "Lived in Morocco (formerly Ex-pats – Morocco)"
    ],
    "description": "People who used to live in Morocco who now live abroad",
    "real_time_cluster": false,
    "audience_size_lower_bound": 1786860,
    "audience_size_upper_bound": 2101348
  },
  {
    "id": "6023516430783",
    "name": "Lived in UAE (formerly Ex-pats – UAE)",
    "type": "behaviors",
    "path": [
      "Ex-pats",
      "Lived in UAE (formerly Ex-pats – UAE)"
    ],
    "description": "People who used to live in the United Arab Emirates who now live abroad",
    "real_time_cluster": false,
    "audience_size_lower_bound": 1511311,
    "audience_size_upper_bound": 1777302
  },
  {
    "id": "6023516368383",
    "name": "Lived in New Zealand (formerly Ex-pats – New Zealand)",
    "type": "behaviors",
    "path": [
      "Ex-pats",
      "Lived in New Zealand (formerly Ex-pats – New Zealand)"
    ],
    "description": "People who used to live in New Zealand who now live abroad",
    "real_time_cluster": false,
    "audience_size_lower_bound": 791601,
    "audience_size_upper_bound": 930923
  },
  {
    "id": "6023516373983",
    "name": "Lived in DR Congo (formerly Ex-pats – DR Congo)",
    "type": "behaviors",
    "path": [
      "Ex-pats",
      "Lived in DR Congo (formerly Ex-pats – DR Congo)"
    ],
    "description": "People who used to live in the Democratic Republic of the Congo who now live abroad",
    "real_time_cluster": false,
    "audience_size_lower_bound": 2067367,
    "audience_size_upper_bound": 2431224
  },
  {
    "id": "6023516403783",
    "name": "Lived in Singapore (formerly Ex-pats – Singapore)",
    "type": "behaviors",
    "path": [
      "Ex-pats",
      "Lived in Singapore (formerly Ex-pats – Singapore)"
    ],
    "description": "People who used to live in Singapore who now live abroad",
    "real_time_cluster": false,
    "audience_size_lower_bound": 69947,
    "audience_size_upper_bound": 82258
  },
  {
    "id": "6023620475783",
    "name": "Lived in the United States (formerly Ex-pats – United States)",
    "type": "behaviors",
    "path": [
      "Ex-pats",
      "Lived in the United States (formerly Ex-pats – United States)"
    ],
    "description": "People who used to live in the United States who now live abroad",
    "real_time_cluster": false,
    "audience_size_lower_bound": 15912271,
    "audience_size_upper_bound": 18712831
  },
  {
    "id": "6023675997383",
    "name": "Lived in Austria (formerly Ex-pats – Austria)",
    "type": "behaviors",
    "path": [
      "Ex-pats",
      "Lived in Austria (formerly Ex-pats – Austria)"
    ],
    "description": "People who used to live in Austria who now live abroad",
    "real_time_cluster": false,
    "audience_size_lower_bound": 146912,
    "audience_size_upper_bound": 172769
  },
  {
    "id": "6023676002183",
    "name": "Lived in Cyprus (formerly Ex-pats – Cyprus)",
    "type": "behaviors",
    "path": [
      "Ex-pats",
      "Lived in Cyprus (formerly Ex-pats – Cyprus)"
    ],
    "description": "People who used to live in Cyprus who now live abroad",
    "real_time_cluster": false,
    "audience_size_lower_bound": 86778,
    "audience_size_upper_bound": 102052
  },
  {
    "id": "6068209522983",
    "name": "Lived in Finland (formerly Expats – Finland)",
    "type": "behaviors",
    "path": [
      "Ex-pats",
      "Lived in Finland (formerly Expats – Finland)"
    ],
    "description": "People who used to live in Finland who now live abroad",
    "real_time_cluster": false,
    "audience_size_lower_bound": 106977,
    "audience_size_upper_bound": 125805
  },
  {
    "id": "6023676017583",
    "name": "Lived in Greece (formerly Ex-pats – Greece)",
    "type": "behaviors",
    "path": [
      "Ex-pats",
      "Lived in Greece (formerly Ex-pats – Greece)"
    ],
    "description": "People who used to live in Greece who now live abroad",
    "real_time_cluster": false,
    "audience_size_lower_bound": 5619,
    "audience_size_upper_bound": 6608
  },
  {
    "id": "6023676022783",
    "name": "Lived in Hong Kong (formerly Ex-pats – Hong Kong)",
    "type": "behaviors",
    "path": [
      "Ex-pats",
      "Lived in Hong Kong (formerly Ex-pats – Hong Kong)"
    ],
    "description": "People who used to live in Hong Kong who now live abroad",
    "real_time_cluster": false,
    "audience_size_lower_bound": 672581,
    "audience_size_upper_bound": 790956
  },
  {
    "id": "6023676028783",
    "name": "Lived in Japan (formerly Ex-pats – Japan)",
    "type": "behaviors",
    "path": [
      "Ex-pats",
      "Lived in Japan (formerly Ex-pats – Japan)"
    ],
    "description": "People who used to live in Japan who now live abroad",
    "real_time_cluster": false,
    "audience_size_lower_bound": 1477103,
    "audience_size_upper_bound": 1737074
  },
  {
    "id": "6068613839383",
    "name": "Lived in Latvia (formerly Expats – Latvia)",
    "type": "behaviors",
    "path": [
      "Ex-pats",
      "Lived in Latvia (formerly Expats – Latvia)"
    ],
    "description": "People who used to live in Latvia who now live abroad",
    "real_time_cluster": false,
    "audience_size_lower_bound": 155358,
    "audience_size_upper_bound": 182702
  },
  {
    "id": "6023676039183",
    "name": "Lived in Lithuania (formerly Ex-pats – Lithuania)",
    "type": "behaviors",
    "path": [
      "Ex-pats",
      "Lived in Lithuania (formerly Ex-pats – Lithuania)"
    ],
    "description": "People who used to live in Lithuania who now live abroad",
    "real_time_cluster": false,
    "audience_size_lower_bound": 248940,
    "audience_size_upper_bound": 292754
  },
  {
    "id": "6023676044383",
    "name": "Lived in Luxembourg (formerly Ex-pats – Luxembourg)",
    "type": "behaviors",
    "path": [
      "Ex-pats",
      "Lived in Luxembourg (formerly Ex-pats – Luxembourg)"
    ],
    "description": "People who used to live in Luxembourg who now live abroad",
    "real_time_cluster": false,
    "audience_size_lower_bound": 31869,
    "audience_size_upper_bound": 37479
  },
  {
    "id": "6023676045583",
    "name": "Lived in Malta (formerly Expats – Malta)",
    "type": "behaviors",
    "path": [
      "Ex-pats",
      "Lived in Malta (formerly Expats – Malta)"
    ],
    "description": "People who used to live in Malta who now live abroad",
    "real_time_cluster": false,
    "audience_size_lower_bound": 36245,
    "audience_size_upper_bound": 42625
  },
  {
    "id": "6023676048183",
    "name": "Lived in Monaco (formerly Ex-pats – Monaco)",
    "type": "behaviors",
    "path": [
      "Ex-pats",
      "Lived in Monaco (formerly Ex-pats – Monaco)"
    ],
    "description": "People who used to live in Monaco who now live abroad",
    "real_time_cluster": false,
    "audience_size_lower_bound": 25302,
    "audience_size_upper_bound": 29756
  },
  {
    "id": "6023676055383",
    "name": "Lived in Slovakia (formerly Ex-pats – Slovakia)",
    "type": "behaviors",
    "path": [
      "Ex-pats",
      "Lived in Slovakia (formerly Ex-pats – Slovakia)"
    ],
    "description": "People who used to live in Slovakia who now live abroad",
    "real_time_cluster": false,
    "audience_size_lower_bound": 243302,
    "audience_size_upper_bound": 286124
  },
  {
    "id": "6023676060183",
    "name": "Lived in Slovenia (formerly Ex-pats – Slovenia)",
    "type": "behaviors",
    "path": [
      "Ex-pats",
      "Lived in Slovenia (formerly Ex-pats – Slovenia)"
    ],
    "description": "People who used to live in Slovenia who now live abroad",
    "real_time_cluster": false,
    "audience_size_lower_bound": 54878,
    "audience_size_upper_bound": 64537
  },
  {
    "id": "6023676072183",
    "name": "Lived in Mexico (formerly Ex-pats – Mexico)",
    "type": "behaviors",
    "path": [
      "Ex-pats",
      "Lived in Mexico (formerly Ex-pats – Mexico)"
    ],
    "description": "People who used to live in Mexico who now live abroad",
    "real_time_cluster": false,
    "audience_size_lower_bound": 10832668,
    "audience_size_upper_bound": 12739218
  },
  {
    "id": "6025000826583",
    "name": "Lived in Argentina (formerly Expats – Argentina)",
    "type": "behaviors",
    "path": [
      "Ex-pats",
      "Lived in Argentina (formerly Expats – Argentina)"
    ],
    "description": "People who used to live in Argentina who now live abroad",
    "real_time_cluster": false,
    "audience_size_lower_bound": 1227334,
    "audience_size_upper_bound": 1443345
  },
  {
    "id": "6025000823583",
    "name": "Lived in Israel (formerly Ex-pats – Israel)",
    "type": "behaviors",
    "path": [
      "Ex-pats",
      "Lived in Israel (formerly Ex-pats – Israel)"
    ],
    "description": "People who used to live in Israel who now live abroad",
    "real_time_cluster": false,
    "audience_size_lower_bound": 529794,
    "audience_size_upper_bound": 623038
  },
  {
    "id": "6025000815983",
    "name": "Lived in Russia (formerly Ex-pats – Russia)",
    "type": "behaviors",
    "path": [
      "Ex-pats",
      "Lived in Russia (formerly Ex-pats – Russia)"
    ],
    "description": "People who used to live in Russia who now live abroad",
    "real_time_cluster": false,
    "audience_size_lower_bound": 1370863,
    "audience_size_upper_bound": 1612136
  },
  {
    "id": "6025000813183",
    "name": "Lived in Saudi Arabia (formerly Ex-pats – Saudi Arabia)",
    "type": "behaviors",
    "path": [
      "Ex-pats",
      "Lived in Saudi Arabia (formerly Ex-pats – Saudi Arabia)"
    ],
    "description": "People who used to live in the Kingdom of Saudi Arabia who now live abroad",
    "real_time_cluster": false,
    "audience_size_lower_bound": 1001034,
    "audience_size_upper_bound": 1177216
  },
  {
    "id": "6025054896983",
    "name": "Lived in Chile (formerly Expats – Chile)",
    "type": "behaviors",
    "path": [
      "Ex-pats",
      "Lived in Chile (formerly Expats – Chile)"
    ],
    "description": "People who used to live in Chile who now live abroad",
    "real_time_cluster": false,
    "audience_size_lower_bound": 528590,
    "audience_size_upper_bound": 621623
  },
  {
    "id": "6025670492783",
    "name": "Lived in Rwanda (formerly Ex-pats – Rwanda)",
    "type": "behaviors",
    "path": [
      "Ex-pats",
      "Lived in Rwanda (formerly Ex-pats – Rwanda)"
    ],
    "description": "People who used to live in Rwanda who now live abroad",
    "real_time_cluster": false,
    "audience_size_lower_bound": 126259,
    "audience_size_upper_bound": 148481
  },
  {
    "id": "6025753961783",
    "name": "Family of those who live abroad",
    "type": "behaviors",
    "path": [
      "Ex-pats",
      "Family of those who live abroad"
    ],
    "description": "Family of people who now live abroad",
    "real_time_cluster": false,
    "audience_size_lower_bound": 32572064,
    "audience_size_upper_bound": 38304748
  },
  {
    "id": "6026404871583",
    "name": "Lived in Venezuela (formerly Expats – Venezuela)",
    "type": "behaviors",
    "path": [
      "Ex-pats",
      "Lived in Venezuela (formerly Expats – Venezuela)"
    ],
    "description": "People who used to live in Venezuela who now live abroad",
    "real_time_cluster": false,
    "audience_size_lower_bound": 4878350,
    "audience_size_upper_bound": 5736940
  },
  {
    "id": "6026660740983",
    "name": "Owns: Galaxy S6",
    "type": "behaviors",
    "path": [
      "Mobile Device User",
      "All Mobile Devices by Brand",
      "Samsung",
      "Owns: Galaxy S6"
    ],
    "description": "People who are likely to own a Samsung Galaxy S6 mobile device",
    "real_time_cluster": false,
    "audience_size_lower_bound": 1483329,
    "audience_size_upper_bound": 1744395
  },
  {
    "id": "6027147160983",
    "name": "Lived in Malaysia (formerly Ex-pats – Malaysia)",
    "type": "behaviors",
    "path": [
      "Ex-pats",
      "Lived in Malaysia (formerly Ex-pats – Malaysia)"
    ],
    "description": "People who used to live in Malaysia who now live abroad",
    "real_time_cluster": false,
    "audience_size_lower_bound": 1740874,
    "audience_size_upper_bound": 2047268
  },
  {
    "id": "6027148962983",
    "name": "Lived in Romania (formerly Ex-pats – Romania)",
    "type": "behaviors",
    "path": [
      "Ex-pats",
      "Lived in Romania (formerly Ex-pats – Romania)"
    ],
    "description": "People who used to live in Romania who now live abroad",
    "real_time_cluster": false,
    "audience_size_lower_bound": 1796655,
    "audience_size_upper_bound": 2112867
  },
  {
    "id": "6027148973583",
    "name": "Lived in South Korea (formerly Ex-pats – South Korea)",
    "type": "behaviors",
    "path": [
      "Ex-pats",
      "Lived in South Korea (formerly Ex-pats – South Korea)"
    ],
    "description": "People who used to live in South Korea who now live abroad",
    "real_time_cluster": false,
    "audience_size_lower_bound": 3226607,
    "audience_size_upper_bound": 3794490
  },
  {
    "id": "6027149004983",
    "name": "Lived in Serbia (formerly Expats – Serbia)",
    "type": "behaviors",
    "path": [
      "Ex-pats",
      "Lived in Serbia (formerly Expats – Serbia)"
    ],
    "description": "People who used to live in Serbia who now live abroad",
    "real_time_cluster": false,
    "audience_size_lower_bound": 573181,
    "audience_size_upper_bound": 674061
  },
  {
    "id": "6027149006383",
    "name": "Lived in Vietnam (formerly Ex-pats – Vietnam)",
    "type": "behaviors",
    "path": [
      "Ex-pats",
      "Lived in Vietnam (formerly Ex-pats – Vietnam)"
    ],
    "description": "People who used to live in Vietnam who now live abroad",
    "real_time_cluster": false,
    "audience_size_lower_bound": 4160616,
    "audience_size_upper_bound": 4892885
  },
  {
    "id": "6027149008183",
    "name": "Lived in Peru (formerly Ex-pats – Peru)",
    "type": "behaviors",
    "path": [
      "Ex-pats",
      "Lived in Peru (formerly Ex-pats – Peru)"
    ],
    "description": "People who used to live in Peru who now live abroad",
    "real_time_cluster": false,
    "audience_size_lower_bound": 1612659,
    "audience_size_upper_bound": 1896488
  },
  {
    "id": "6028974370383",
    "name": "People in India who prefer high-value goods",
    "type": "behaviors",
    "path": [
      "Consumer classification",
      "India",
      "People in India who prefer high-value goods"
    ],
    "description": "Aligned to (A) group, people in India who are predicted to prefer high-value goods",
    "real_time_cluster": false,
    "audience_size_lower_bound": 91963011,
    "audience_size_upper_bound": 108148502
  },
  {
    "id": "6028974351183",
    "name": "People in India who prefer mid- and high-value goods",
    "type": "behaviors",
    "path": [
      "Consumer classification",
      "India",
      "People in India who prefer mid- and high-value goods"
    ],
    "description": "Aligned to (A+B) group, people in India who are predicted to prefer mid to high-value goods",
    "real_time_cluster": false,
    "audience_size_lower_bound": 152696752,
    "audience_size_upper_bound": 179571381
  },
  {
    "id": "6029587661983",
    "name": "Facebook access (OS): Windows 10",
    "type": "behaviors",
    "path": [
      "Digital activities",
      "Operating system used",
      "Facebook access (OS): Windows 10"
    ],
    "description": "People who primarily access Facebook using Windows 10",
    "real_time_cluster": false,
    "audience_size_lower_bound": 268368,
    "audience_size_upper_bound": 315601
  },
  {
    "id": "6031259562783",
    "name": "Owns: iPhone 6S",
    "type": "behaviors",
    "path": [
      "Mobile Device User",
      "All Mobile Devices by Brand",
      "Apple",
      "Owns: iPhone 6S"
    ],
    "description": "People who are likely to own an iPhone 6S mobile device",
    "real_time_cluster": false,
    "audience_size_lower_bound": 5870195,
    "audience_size_upper_bound": 6903350
  },
  {
    "id": "6031259590183",
    "name": "Owns: iPhone 6S Plus",
    "type": "behaviors",
    "path": [
      "Mobile Device User",
      "All Mobile Devices by Brand",
      "Apple",
      "Owns: iPhone 6S Plus"
    ],
    "description": "People who are likely to own an iPhone 6S Plus mobile device.",
    "real_time_cluster": false,
    "audience_size_lower_bound": 4299530,
    "audience_size_upper_bound": 5056248
  },
  {
    "id": "6041891177783",
    "name": "New Page admins",
    "type": "behaviors",
    "path": [
      "Digital activities",
      "Facebook page admins",
      "New Page admins"
    ],
    "description": "People who have become Facebook Page admins within the past two weeks.",
    "real_time_cluster": false,
    "audience_size_lower_bound": 277023272,
    "audience_size_upper_bound": 325779369
  },
  {
    "id": "6042330550783",
    "name": "Owns: Galaxy Note 5",
    "type": "behaviors",
    "path": [
      "Mobile Device User",
      "All Mobile Devices by Brand",
      "Samsung",
      "Owns: Galaxy Note 5"
    ],
    "description": "People who are likely to own a Samsung Galaxy Note 5 mobile device",
    "real_time_cluster": false,
    "audience_size_lower_bound": 542484,
    "audience_size_upper_bound": 637962
  },
  {
    "id": "6043341245183",
    "name": "Owns: LG V10",
    "type": "behaviors",
    "path": [
      "Mobile Device User",
      "All Mobile Devices by Brand",
      "LG",
      "Owns: LG V10"
    ],
    "description": "People who are likely to own an LG V10 mobile device",
    "real_time_cluster": false,
    "audience_size_lower_bound": 20239,
    "audience_size_upper_bound": 23802
  },
  {
    "id": "6043702804583",
    "name": "Lived in Belgium (formerly Expats – Belgium)",
    "type": "behaviors",
    "path": [
      "Ex-pats",
      "Lived in Belgium (formerly Expats – Belgium)"
    ],
    "description": "People who used to live in Belgium who now live abroad",
    "real_time_cluster": false,
    "audience_size_lower_bound": 344880,
    "audience_size_upper_bound": 405579
  },
  {
    "id": "6043523344783",
    "name": "Owns: Galaxy S7",
    "type": "behaviors",
    "path": [
      "Mobile Device User",
      "All Mobile Devices by Brand",
      "Samsung",
      "Owns: Galaxy S7"
    ],
    "description": "People who are likely to own a Samsung Galaxy S7  mobile device",
    "real_time_cluster": false,
    "audience_size_lower_bound": 1826630,
    "audience_size_upper_bound": 2148118
  },
  {
    "id": "6043522870783",
    "name": "Owns: Galaxy S7 Edge",
    "type": "behaviors",
    "path": [
      "Mobile Device User",
      "All Mobile Devices by Brand",
      "Samsung",
      "Owns: Galaxy S7 Edge"
    ],
    "description": "People who are likely to own a Samsung Galaxy S7 Edge mobile device",
    "real_time_cluster": false,
    "audience_size_lower_bound": 2207783,
    "audience_size_upper_bound": 2596353
  },
  {
    "id": "6046095968983",
    "name": "People in South Africa who prefer high-value goods",
    "type": "behaviors",
    "path": [
      "Consumer classification",
      "South Africa",
      "People in South Africa who prefer high-value goods"
    ],
    "description": "Aligned to (8,9,10) LSM group, people in South Africa who are predicted to prefer high-value goods",
    "real_time_cluster": false,
    "audience_size_lower_bound": 2482772,
    "audience_size_upper_bound": 2919740
  },
  {
    "id": "6046096047583",
    "name": "People in South Africa who prefer mid- and high-value goods",
    "type": "behaviors",
    "path": [
      "Consumer classification",
      "South Africa",
      "People in South Africa who prefer mid- and high-value goods"
    ],
    "description": "Aligned to (5,6,7) LSM group, people in South Africa who are predicted to prefer mid- to high-value goods",
    "real_time_cluster": false,
    "audience_size_lower_bound": 4072836,
    "audience_size_upper_bound": 4789656
  },
  {
    "id": "6046096201583",
    "name": "People in Brazil who prefer high-value goods",
    "type": "behaviors",
    "path": [
      "Consumer classification",
      "Brazil",
      "People in Brazil who prefer high-value goods"
    ],
    "description": "Aligned to (A+B) group, people in Brazil who are predicted to prefer high-value goods",
    "real_time_cluster": false,
    "audience_size_lower_bound": 12279650,
    "audience_size_upper_bound": 14440869
  },
  {
    "id": "6054947014783",
    "name": "Owns: iPhone SE",
    "type": "behaviors",
    "path": [
      "Mobile Device User",
      "All Mobile Devices by Brand",
      "Apple",
      "Owns: iPhone SE"
    ],
    "description": "People who likely own an iPhone SE mobile device",
    "real_time_cluster": false,
    "audience_size_lower_bound": 11469448,
    "audience_size_upper_bound": 13488072
  },
  {
    "id": "6047219032183",
    "name": "Lived in Zambia (formerly Ex-pats – Zambia)",
    "type": "behaviors",
    "path": [
      "Ex-pats",
      "Lived in Zambia (formerly Ex-pats – Zambia)"
    ],
    "description": "People who used to live in Zambia who now live abroad",
    "real_time_cluster": false,
    "audience_size_lower_bound": 186240,
    "audience_size_upper_bound": 219019
  },
  {
    "id": "6055133998183",
    "name": "Facebook access (browser): Microsoft Edge",
    "type": "behaviors",
    "path": [
      "Digital activities",
      "Internet browser used",
      "Facebook access (browser): Microsoft Edge"
    ],
    "description": "People who primarily access Facebook using Microsoft Edge",
    "real_time_cluster": false,
    "audience_size_lower_bound": 19836897,
    "audience_size_upper_bound": 23328191
  },
  {
    "id": "6056265200983",
    "name": "Owns: Oppo",
    "type": "behaviors",
    "path": [
      "Mobile Device User",
      "All Mobile Devices by Brand",
      "Owns: Oppo"
    ],
    "description": "People who are likely to own an Oppo mobile device",
    "real_time_cluster": false,
    "audience_size_lower_bound": 183094341,
    "audience_size_upper_bound": 215318946
  },
  {
    "id": "6056265212183",
    "name": "Owns: VIVO devices",
    "type": "behaviors",
    "path": [
      "Mobile Device User",
      "All Mobile Devices by Brand",
      "Owns: VIVO devices"
    ],
    "description": "People who are likely to own VIVO mobile devices",
    "real_time_cluster": false,
    "audience_size_lower_bound": 157863312,
    "audience_size_upper_bound": 185647256
  },
  {
    "id": "6058034528983",
    "name": "Owns: Galaxy Note 7",
    "type": "behaviors",
    "path": [
      "Mobile Device User",
      "All Mobile Devices by Brand",
      "Samsung",
      "Owns: Galaxy Note 7"
    ],
    "description": "People who are likely to own a Samsung Galaxy Note 7  mobile device",
    "real_time_cluster": false,
    "audience_size_lower_bound": 2036,
    "audience_size_upper_bound": 2395
  },
  {
    "id": "6059793664583",
    "name": "Lived in Honduras (formerly Ex-pats – Honduras)",
    "type": "behaviors",
    "path": [
      "Ex-pats",
      "Lived in Honduras (formerly Ex-pats – Honduras)"
    ],
    "description": "People who used to live in Honduras who now live abroad",
    "real_time_cluster": false,
    "audience_size_lower_bound": 1385865,
    "audience_size_upper_bound": 1629778
  },
  {
    "id": "6060616578383",
    "name": "Owns: iPhone 7",
    "type": "behaviors",
    "path": [
      "Mobile Device User",
      "All Mobile Devices by Brand",
      "Apple",
      "Owns: iPhone 7"
    ],
    "description": "People who are likely to own an iPhone 7 mobile device",
    "real_time_cluster": false,
    "audience_size_lower_bound": 9674897,
    "audience_size_upper_bound": 11377679
  },
  {
    "id": "6060616598183",
    "name": "Owns: iPhone 7 Plus",
    "type": "behaviors",
    "path": [
      "Mobile Device User",
      "All Mobile Devices by Brand",
      "Apple",
      "Owns: iPhone 7 Plus"
    ],
    "description": "People who are likely to own an iPhone 7 Plus mobile device.",
    "real_time_cluster": false,
    "audience_size_lower_bound": 10810711,
    "audience_size_upper_bound": 12713397
  },
  {
    "id": "6061668174383",
    "name": "Owns: Google Pixel",
    "type": "behaviors",
    "path": [
      "Mobile Device User",
      "All Mobile Devices by Brand",
      "Google",
      "Owns: Google Pixel"
    ],
    "description": "People who are likely to own a Google Pixel mobile device",
    "real_time_cluster": false,
    "audience_size_lower_bound": 144339,
    "audience_size_upper_bound": 169743
  },
  {
    "id": "6063136545383",
    "name": "Android: 360 degree media supported",
    "type": "behaviors",
    "path": [
      "Mobile Device User",
      "Android: 360 degree media supported"
    ],
    "description": "People whose primary mobile device is Android with support for 360-degree media (photos, videos).",
    "real_time_cluster": false,
    "audience_size_lower_bound": 1090373989,
    "audience_size_upper_bound": 1282279812
  },
  {
    "id": "6063268655983",
    "name": "Facebook access (OS): Mac Sierra",
    "type": "behaviors",
    "path": [
      "Digital activities",
      "Operating system used",
      "Facebook access (OS): Mac Sierra"
    ],
    "description": "People who  primarily access Facebook using Mac Sierra",
    "real_time_cluster": false,
    "audience_size_lower_bound": 2809790,
    "audience_size_upper_bound": 3304314
  },
  {
    "id": "6065048233383",
    "name": "Android: 360 degree media not supported",
    "type": "behaviors",
    "path": [
      "Mobile Device User",
      "Android: 360 degree media not supported"
    ],
    "description": "People whose primary mobile device is Android without support for 360 degree media (photos, videos).",
    "real_time_cluster": false,
    "audience_size_lower_bound": 21421585,
    "audience_size_upper_bound": 25191784
  },
  {
    "id": "6068844014183",
    "name": "Lived in Lebanon (formerly Ex-pats – Lebanon)",
    "type": "behaviors",
    "path": [
      "Ex-pats",
      "Lived in Lebanon (formerly Ex-pats – Lebanon)"
    ],
    "description": "People who used to live in Lebanon who now live abroad",
    "real_time_cluster": false,
    "audience_size_lower_bound": 749441,
    "audience_size_upper_bound": 881343
  },
  {
    "id": "6068843912183",
    "name": "Lived in Jordan (formerly Ex-pats – Jordan)",
    "type": "behaviors",
    "path": [
      "Ex-pats",
      "Lived in Jordan (formerly Ex-pats – Jordan)"
    ],
    "description": "People who used to live in Jordan who now live abroad",
    "real_time_cluster": false,
    "audience_size_lower_bound": 706734,
    "audience_size_upper_bound": 831120
  },
  {
    "id": "6071631541183",
    "name": "Engaged shoppers",
    "type": "behaviors",
    "path": [
      "Purchase behaviour",
      "Engaged shoppers"
    ],
    "description": "People who have clicked on the call-to-action button \"Shop Now\" in the past week.",
    "real_time_cluster": false,
    "audience_size_lower_bound": 579476514,
    "audience_size_upper_bound": 681464381
  },
  {
    "id": "6071248932383",
    "name": "Lived in Algeria (formerly Ex-pats – Algeria)",
    "type": "behaviors",
    "path": [
      "Ex-pats",
      "Lived in Algeria (formerly Ex-pats – Algeria)"
    ],
    "description": "People who used to live in Algeria who now live abroad",
    "real_time_cluster": false,
    "audience_size_lower_bound": 979963,
    "audience_size_upper_bound": 1152437
  },
  {
    "id": "6071248894383",
    "name": "Lived in Nicaragua (formerly Ex-pats – Nicaragua)",
    "type": "behaviors",
    "path": [
      "Ex-pats",
      "Lived in Nicaragua (formerly Ex-pats – Nicaragua)"
    ],
    "description": "People who used to live in Nicaragua who now live abroad",
    "real_time_cluster": false,
    "audience_size_lower_bound": 821735,
    "audience_size_upper_bound": 966361
  },
  {
    "id": "6071248981583",
    "name": "Lived in Kuwait (formerly Ex-pats – Kuwait)",
    "type": "behaviors",
    "path": [
      "Ex-pats",
      "Lived in Kuwait (formerly Ex-pats – Kuwait)"
    ],
    "description": "People who used to live in Kuwait who now live abroad",
    "real_time_cluster": false,
    "audience_size_lower_bound": 145066,
    "audience_size_upper_bound": 170598
  },
  {
    "id": "6071249058983",
    "name": "Lived in Qatar (formerly Ex-pats – Qatar)",
    "type": "behaviors",
    "path": [
      "Ex-pats",
      "Lived in Qatar (formerly Ex-pats – Qatar)"
    ],
    "description": "People who used to live in Qatar who now live abroad",
    "real_time_cluster": false,
    "audience_size_lower_bound": 107591,
    "audience_size_upper_bound": 126528
  },
  {
    "id": "6071590219583",
    "name": "Owns: Gionee",
    "type": "behaviors",
    "path": [
      "Mobile Device User",
      "All Mobile Devices by Brand",
      "Owns: Gionee"
    ],
    "description": "People who likely own a Gionee mobile device",
    "real_time_cluster": false,
    "audience_size_lower_bound": 1720661,
    "audience_size_upper_bound": 2023498
  },
  {
    "id": "6075237200983",
    "name": "Owns: Galaxy S8",
    "type": "behaviors",
    "path": [
      "Mobile Device User",
      "All Mobile Devices by Brand",
      "Samsung",
      "Owns: Galaxy S8"
    ],
    "description": "People who likely own a Galaxy S8 mobile device",
    "real_time_cluster": false,
    "audience_size_lower_bound": 4318828,
    "audience_size_upper_bound": 5078942
  },
  {
    "id": "6075237226583",
    "name": "Owns: Galaxy S8+",
    "type": "behaviors",
    "path": [
      "Mobile Device User",
      "All Mobile Devices by Brand",
      "Samsung",
      "Owns: Galaxy S8+"
    ],
    "description": "People who are likely to own a Galaxy S8+ mobile device",
    "real_time_cluster": false,
    "audience_size_lower_bound": 2629778,
    "audience_size_upper_bound": 3092620
  },
  {
    "id": "6080562616983",
    "name": "Football fans (high content engagement)",
    "type": "behaviors",
    "path": [
      "Soccer",
      "Football fans (high content engagement)"
    ],
    "description": "Interacted with content related to football five or more times over the past 90 days.",
    "real_time_cluster": false,
    "audience_size_lower_bound": 19727212,
    "audience_size_upper_bound": 23199202
  },
  {
    "id": "6080562614783",
    "name": "Football fans (moderate content engagement)",
    "type": "behaviors",
    "path": [
      "Soccer",
      "Football fans (moderate content engagement)"
    ],
    "description": "Interacted with content related to football and sports fewer than five times over the past 90 days.",
    "real_time_cluster": false,
    "audience_size_lower_bound": 116827322,
    "audience_size_upper_bound": 137388931
  },
  {
    "id": "6082317210583",
    "name": "People who prefer high-value goods in UAE",
    "type": "behaviors",
    "path": [
      "Consumer classification",
      "UAE",
      "People who prefer high-value goods in UAE"
    ],
    "description": "Aligned to (A) group, people in UAE who are predicted to prefer high-value goods",
    "real_time_cluster": false,
    "audience_size_lower_bound": 58592,
    "audience_size_upper_bound": 68905
  },
  {
    "id": "6082317378383",
    "name": "People who prefer mid and high-value goods in UAE",
    "type": "behaviors",
    "path": [
      "Consumer classification",
      "UAE",
      "People who prefer mid and high-value goods in UAE"
    ],
    "description": "Aligned to (A+B) group, people in UAE who are predicted to prefer mid-to-high-value goods",
    "real_time_cluster": false,
    "audience_size_lower_bound": 327963,
    "audience_size_upper_bound": 385685
  },
  {
    "id": "6082317392983",
    "name": "People who prefer high-value goods in the Kingdom of Saudi Arabia",
    "type": "behaviors",
    "path": [
      "Consumer classification",
      "Kingdom of Saudi Arabia",
      "People who prefer high-value goods in the Kingdom of Saudi Arabia"
    ],
    "description": "Aligned to (A) group, people in the Kingdom of Saudi Arabia who are predicted to prefer high-value goods",
    "real_time_cluster": false,
    "audience_size_lower_bound": 1126578,
    "audience_size_upper_bound": 1324856
  },
  {
    "id": "6082317405583",
    "name": "People who prefer mid to high-value goods in the Kingdom of Saudi Arabia",
    "type": "behaviors",
    "path": [
      "Consumer classification",
      "Kingdom of Saudi Arabia",
      "People who prefer mid to high-value goods in the Kingdom of Saudi Arabia"
    ],
    "description": "Aligned to (A+B) group, people in the Kingdom of Saudi Arabia who are predicted to prefer mid to high-value goods",
    "real_time_cluster": false,
    "audience_size_lower_bound": 2511395,
    "audience_size_upper_bound": 2953401
  },
  {
    "id": "6083036245383",
    "name": "Owns: Galaxy Note 8",
    "type": "behaviors",
    "path": [
      "Mobile Device User",
      "All Mobile Devices by Brand",
      "Samsung",
      "Owns: Galaxy Note 8"
    ],
    "description": "People who are likely to own a Samsung Galaxy Note 8 mobile device",
    "real_time_cluster": false,
    "audience_size_lower_bound": 3001150,
    "audience_size_upper_bound": 3529353
  },
  {
    "id": "6085888747383",
    "name": "People in Mexico who prefer high-value goods",
    "type": "behaviors",
    "path": [
      "Consumer classification",
      "Mexico",
      "People in Mexico who prefer high-value goods"
    ],
    "description": "Aligned to (AB) group, people in Mexico who are predicted to prefer high-value goods",
    "real_time_cluster": false,
    "audience_size_lower_bound": 4936120,
    "audience_size_upper_bound": 5804878
  },
  {
    "id": "6085888777383",
    "name": "People in Mexico who prefer mid- and high-value goods",
    "type": "behaviors",
    "path": [
      "Consumer classification",
      "Mexico",
      "People in Mexico who prefer mid- and high-value goods"
    ],
    "description": "Aligned to (ABC+) group, people in Mexico who are predicted to prefer mid to high-value goods",
    "real_time_cluster": false,
    "audience_size_lower_bound": 15806755,
    "audience_size_upper_bound": 18588744
  },
  {
    "id": "6086568164383",
    "name": "Marketing API developers (last 90 days)",
    "type": "behaviors",
    "path": [
      "More categories",
      "Marketing API developers (last 90 days)"
    ],
    "description": "App developers that have used the Facebook marketing API in the last 90 days.",
    "real_time_cluster": false,
    "audience_size_lower_bound": 785,
    "audience_size_upper_bound": 924
  },
  {
    "id": "6086326043983",
    "name": "People in Chile who prefer high-value goods",
    "type": "behaviors",
    "path": [
      "Consumer classification",
      "Chile",
      "People in Chile who prefer high-value goods"
    ],
    "description": "Aligned to (ABC1) group, people in Chile who are predicted to prefer high-value goods",
    "real_time_cluster": false,
    "audience_size_lower_bound": 764789,
    "audience_size_upper_bound": 899392
  },
  {
    "id": "6086326068183",
    "name": "People in Chile who prefer mid- and high-value goods",
    "type": "behaviors",
    "path": [
      "Consumer classification",
      "Chile",
      "People in Chile who prefer mid- and high-value goods"
    ],
    "description": "Aligned to (A+B) group, people in Chile who are predicted to prefer mid to high-value goods",
    "real_time_cluster": false,
    "audience_size_lower_bound": 2240665,
    "audience_size_upper_bound": 2635023
  },
  {
    "id": "6086326072983",
    "name": "People in Argentina who prefer high-value goods",
    "type": "behaviors",
    "path": [
      "Consumer classification",
      "Argentina",
      "People in Argentina who prefer high-value goods"
    ],
    "description": "Aligned to (ABC1) group, people in Argentina who are predicted to prefer high-value goods",
    "real_time_cluster": false,
    "audience_size_lower_bound": 988959,
    "audience_size_upper_bound": 1163016
  },
  {
    "id": "6086326078383",
    "name": "People in Argentina who prefer mid- and high-value goods",
    "type": "behaviors",
    "path": [
      "Consumer classification",
      "Argentina",
      "People in Argentina who prefer mid- and high-value goods"
    ],
    "description": "Aligned to (ABC1+C2) group, people in Argentina who are predicted to prefer mid to high-value goods",
    "real_time_cluster": false,
    "audience_size_lower_bound": 3583465,
    "audience_size_upper_bound": 4214156
  },
  {
    "id": "6089632523783",
    "name": "People in Turkey who prefer high-value goods",
    "type": "behaviors",
    "path": [
      "Consumer classification",
      "Turkey",
      "People in Turkey who prefer high-value goods"
    ],
    "description": "Aligned to (A) SES group, people in Turkey who are predicted to prefer high-value goods",
    "real_time_cluster": false,
    "audience_size_lower_bound": 1771216,
    "audience_size_upper_bound": 2082951
  },
  {
    "id": "6089632452783",
    "name": "People in Turkey who prefer mid- and high-value goods",
    "type": "behaviors",
    "path": [
      "Consumer classification",
      "Turkey",
      "People in Turkey who prefer mid- and high-value goods"
    ],
    "description": "Aligned to (A+B) SES group, people in Turkey who are predicted to prefer mid- to high-value goods",
    "real_time_cluster": false,
    "audience_size_lower_bound": 6858211,
    "audience_size_upper_bound": 8065257
  },
  {
    "id": "6091658707783",
    "name": "Uses a mobile device (less than 1 month)",
    "type": "behaviors",
    "path": [
      "Mobile device user/device use time",
      "Uses a mobile device (less than 1 month)"
    ],
    "description": "People who are likely to have used a mobile device for less than 1 month",
    "real_time_cluster": false,
    "audience_size_lower_bound": 127198642,
    "audience_size_upper_bound": 149585604
  },
  {
    "id": "6091658708183",
    "name": "Uses a mobile device (1-3 months)",
    "type": "behaviors",
    "path": [
      "Mobile device user/device use time",
      "Uses a mobile device (1-3 months)"
    ],
    "description": "People who are likely to have used a mobile device for 1-3 months",
    "real_time_cluster": false,
    "audience_size_lower_bound": 162904616,
    "audience_size_upper_bound": 191575829
  },
  {
    "id": "6091658512983",
    "name": "Uses a mobile device (4-6 months)",
    "type": "behaviors",
    "path": [
      "Mobile device user/device use time",
      "Uses a mobile device (4-6 months)"
    ],
    "description": "People who are likely to have used a mobile device for 4-6 months",
    "real_time_cluster": false,
    "audience_size_lower_bound": 190590321,
    "audience_size_upper_bound": 224134218
  },
  {
    "id": "6091658512183",
    "name": "Uses a mobile device (7-9 months)",
    "type": "behaviors",
    "path": [
      "Mobile device user/device use time",
      "Uses a mobile device (7-9 months)"
    ],
    "description": "People who are likely to have used a mobile device for 7-9 months",
    "real_time_cluster": false,
    "audience_size_lower_bound": 159849995,
    "audience_size_upper_bound": 187983595
  },
  {
    "id": "6091658540583",
    "name": "Uses a mobile device (10-12 months)",
    "type": "behaviors",
    "path": [
      "Mobile device user/device use time",
      "Uses a mobile device (10-12 months)"
    ],
    "description": "People who are likely to have used a mobile device for 10-12 months",
    "real_time_cluster": false,
    "audience_size_lower_bound": 136622818,
    "audience_size_upper_bound": 160668434
  },
  {
    "id": "6091658562383",
    "name": "Uses a mobile device (13-18 months)",
    "type": "behaviors",
    "path": [
      "Mobile device user/device use time",
      "Uses a mobile device (13-18 months)"
    ],
    "description": "People who are likely to have used a mobile device for 13-18 months",
    "real_time_cluster": false,
    "audience_size_lower_bound": 227701107,
    "audience_size_upper_bound": 267776503
  },
  {
    "id": "6091658651583",
    "name": "Uses a mobile device (19-24 months)",
    "type": "behaviors",
    "path": [
      "Mobile device user/device use time",
      "Uses a mobile device (19-24 months)"
    ],
    "description": "People who are likely to have used a mobile device for 19-24 months",
    "real_time_cluster": false,
    "audience_size_lower_bound": 185490995,
    "audience_size_upper_bound": 218137411
  },
  {
    "id": "6091658683183",
    "name": "Uses a mobile device (25 months+)",
    "type": "behaviors",
    "path": [
      "Mobile device user/device use time",
      "Uses a mobile device (25 months+)"
    ],
    "description": "People who are likely to have used a mobile device for 25+ months",
    "real_time_cluster": false,
    "audience_size_lower_bound": 523918641,
    "audience_size_upper_bound": 616128322
  },
  {
    "id": "6092145447383",
    "name": "People in Indonesia who prefer high-value goods",
    "type": "behaviors",
    "path": [
      "Consumer classification",
      "Indonesia",
      "People in Indonesia who prefer high-value goods"
    ],
    "description": "Aligned to (Upper I) group, people in Indonesia who are predicted to prefer high-value goods",
    "real_time_cluster": false,
    "audience_size_lower_bound": 16478628,
    "audience_size_upper_bound": 19378867
  },
  {
    "id": "6092512412783",
    "name": "Facebook access (mobile): iPhone 8",
    "type": "behaviors",
    "path": [
      "Mobile Device User",
      "All Mobile Devices by Brand",
      "Apple",
      "Facebook access (mobile): iPhone 8"
    ],
    "description": "People who are likely to own an iPhone 8 mobile device.",
    "real_time_cluster": false,
    "audience_size_lower_bound": 7704399,
    "audience_size_upper_bound": 9060374
  },
  {
    "id": "6092512424583",
    "name": "Facebook access (mobile): iPhone 8 Plus",
    "type": "behaviors",
    "path": [
      "Mobile Device User",
      "All Mobile Devices by Brand",
      "Apple",
      "Facebook access (mobile): iPhone 8 Plus"
    ],
    "description": "People who are likely to own an iPhone 8 Plus mobile device",
    "real_time_cluster": false,
    "audience_size_lower_bound": 9956321,
    "audience_size_upper_bound": 11708634
  },
  {
    "id": "6092512462983",
    "name": "Facebook access (mobile): iPhone X",
    "type": "behaviors",
    "path": [
      "Mobile Device User",
      "All Mobile Devices by Brand",
      "Apple",
      "Facebook access (mobile): iPhone X"
    ],
    "description": "People who are likely to own an iPhone X mobile device",
    "real_time_cluster": false,
    "audience_size_lower_bound": 8893864,
    "audience_size_upper_bound": 10459185
  },
  {
    "id": "6100052630783",
    "name": "Interested in upcoming events",
    "type": "behaviors",
    "path": [
      "More categories",
      "Interested in upcoming events"
    ],
    "description": "People who have expressed interest in attending an upcoming Facebook event.",
    "real_time_cluster": false,
    "audience_size_lower_bound": 6350000,
    "audience_size_upper_bound": 7467601
  },
  {
    "id": "6100406737783",
    "name": "People in Pakistan who prefer high-value goods",
    "type": "behaviors",
    "path": [
      "Consumer classification",
      "Pakistan",
      "People in Pakistan who prefer high-value goods"
    ],
    "description": "Aligned to (A) SEC group, people in Pakistan who are predicted to prefer high-value goods",
    "real_time_cluster": false,
    "audience_size_lower_bound": 1483750,
    "audience_size_upper_bound": 1744891
  },
  {
    "id": "6100407062383",
    "name": "People in Pakistan who prefer mid- and high-value goods",
    "type": "behaviors",
    "path": [
      "Consumer classification",
      "Pakistan",
      "People in Pakistan who prefer mid- and high-value goods"
    ],
    "description": "Aligned to (A+B) SEC group, people in Pakistan who are predicted to prefer mid- to high-value goods",
    "real_time_cluster": false,
    "audience_size_lower_bound": 7465983,
    "audience_size_upper_bound": 8779997
  },
  {
    "id": "6100407234583",
    "name": "People in Malaysia who prefer mid- and high-value goods",
    "type": "behaviors",
    "path": [
      "Consumer classification",
      "Malaysia",
      "People in Malaysia who prefer mid- and high-value goods"
    ],
    "description": "Aligned to (AB+C1) SEC group, people in Malaysia who are predicted to prefer mid- to high-value goods",
    "real_time_cluster": false,
    "audience_size_lower_bound": 6176818,
    "audience_size_upper_bound": 7263939
  },
  {
    "id": "6100407132383",
    "name": "People in Malaysia who prefer high-value goods",
    "type": "behaviors",
    "path": [
      "Consumer classification",
      "Malaysia",
      "People in Malaysia who prefer high-value goods"
    ],
    "description": "Aligned to (AB) SEC group, people in Malaysia who are predicted to prefer high-value goods",
    "real_time_cluster": false,
    "audience_size_lower_bound": 2314025,
    "audience_size_upper_bound": 2721294
  },
  {
    "id": "6106223987983",
    "name": "Owns: Galaxy S9",
    "type": "behaviors",
    "path": [
      "Mobile Device User",
      "All Mobile Devices by Brand",
      "Samsung",
      "Owns: Galaxy S9"
    ],
    "description": "People who are likely to own a Galaxy S9 mobile device",
    "real_time_cluster": false,
    "audience_size_lower_bound": 4482441,
    "audience_size_upper_bound": 5271351
  },
  {
    "id": "6106224431383",
    "name": "Owns: Galaxy S9+",
    "type": "behaviors",
    "path": [
      "Mobile Device User",
      "All Mobile Devices by Brand",
      "Samsung",
      "Owns: Galaxy S9+"
    ],
    "description": "People who are likely to own a Galaxy S9+ mobile device",
    "real_time_cluster": false,
    "audience_size_lower_bound": 4194749,
    "audience_size_upper_bound": 4933025
  },
  {
    "id": "6106805412383",
    "name": "Owns: OnePlus",
    "type": "behaviors",
    "path": [
      "Mobile Device User",
      "Owns: OnePlus"
    ],
    "description": "People who are likely to own a OnePlus mobile device",
    "real_time_cluster": false,
    "audience_size_lower_bound": 15858632,
    "audience_size_upper_bound": 18649752
  },
  {
    "id": "6110446593183",
    "name": "People  in Indonesia who prefer mid-value and high-value goods",
    "type": "behaviors",
    "path": [
      "Consumer classification",
      "Indonesia",
      "People  in Indonesia who prefer mid-value and high-value goods"
    ],
    "description": "Aligned to (upper I, upper II and middle I) group, people in Indonesia who are predicted to prefer high-value goods",
    "real_time_cluster": false,
    "audience_size_lower_bound": 39677890,
    "audience_size_upper_bound": 46661199
  },
  {
    "id": "6110813675983",
    "name": "People in Brazil who prefer mid-value and high-value goods",
    "type": "behaviors",
    "path": [
      "Consumer classification",
      "Brazil",
      "People in Brazil who prefer mid-value and high-value goods"
    ],
    "description": "Aligned to (A+B+C) group, people in Brazil who are predicted to prefer mid-value and high-value goods",
    "real_time_cluster": false,
    "audience_size_lower_bound": 30322328,
    "audience_size_upper_bound": 35659058
  },
  {
    "id": "6110633547383",
    "name": "People who prefer high-value goods in the Philippines",
    "type": "behaviors",
    "path": [
      "Consumer classification",
      "Philippines",
      "People who prefer high-value goods in the Philippines"
    ],
    "description": "Aligned to (A) group, people in the Philippines who are predicted to prefer high-value goods",
    "real_time_cluster": false,
    "audience_size_lower_bound": 5625799,
    "audience_size_upper_bound": 6615940
  },
  {
    "id": "6110636171983",
    "name": "People who prefer mid-value and high-value goods in the Philippines",
    "type": "behaviors",
    "path": [
      "Consumer classification",
      "Philippines",
      "People who prefer mid-value and high-value goods in the Philippines"
    ],
    "description": "Aligned to (A+B) group, people in the Philippines who are predicted to prefer mid to high-value goods",
    "real_time_cluster": false,
    "audience_size_lower_bound": 26757801,
    "audience_size_upper_bound": 31467175
  },
  {
    "id": "6120699687383",
    "name": "Facebook access (mobile): iPhone XS",
    "type": "behaviors",
    "path": [
      "Mobile Device User",
      "All Mobile Devices by Brand",
      "Apple",
      "Facebook access (mobile): iPhone XS"
    ],
    "description": "People who are likely to own an iPhone XS mobile device",
    "real_time_cluster": false,
    "audience_size_lower_bound": 5165966,
    "audience_size_upper_bound": 6075177
  },
  {
    "id": "6120699721983",
    "name": "Facebook access (mobile): iPhone XS Max",
    "type": "behaviors",
    "path": [
      "Mobile Device User",
      "All Mobile Devices by Brand",
      "Apple",
      "Facebook access (mobile): iPhone XS Max"
    ],
    "description": "People who are likely to own an iPhone XS Max mobile device",
    "real_time_cluster": false,
    "audience_size_lower_bound": 7497431,
    "audience_size_upper_bound": 8816979
  },
  {
    "id": "6120699725783",
    "name": "Facebook access (mobile): iPhone XR",
    "type": "behaviors",
    "path": [
      "Mobile Device User",
      "All Mobile Devices by Brand",
      "Apple",
      "Facebook access (mobile): iPhone XR"
    ],
    "description": "People who are likely to own an iPhone XR mobile device",
    "real_time_cluster": false,
    "audience_size_lower_bound": 16521585,
    "audience_size_upper_bound": 19429385
  },
  {
    "id": "6202657388783",
    "name": "People who have visited Facebook Gaming",
    "type": "behaviors",
    "path": [
      "Digital activities",
      "People who have visited Facebook Gaming"
    ],
    "description": "People who have recently accessed Facebook Gaming to watch videos, view posts, access tournaments or play games.",
    "real_time_cluster": false,
    "audience_size_lower_bound": 12302815,
    "audience_size_upper_bound": 14468111
  },
  {
    "id": "6203619619383",
    "name": "Friends of football fans",
    "type": "behaviors",
    "path": [
      "Soccer",
      "Friends of football fans"
    ],
    "description": "Friends of anyone who is a moderately or highly engaged football fan. Excludes people who are already football fans.",
    "real_time_cluster": false,
    "audience_size_lower_bound": 1308407222,
    "audience_size_upper_bound": 1538686894
  },
  {
    "id": "6203619967383",
    "name": "Friends of those who live abroad",
    "type": "behaviors",
    "path": [
      "Ex-pats",
      "Friends of those who live abroad"
    ],
    "description": "Friends of people who now live abroad",
    "real_time_cluster": false,
    "audience_size_lower_bound": 1568282881,
    "audience_size_upper_bound": 1844300669
  },
  {
    "id": "6297846662583",
    "name": "Instagram business profile admins",
    "type": "behaviors",
    "path": [
      "Digital activities",
      "Instagram business profile admins"
    ],
    "description": "People who are an administrator of an Instagram business profile",
    "real_time_cluster": false,
    "audience_size_lower_bound": 46117904,
    "audience_size_upper_bound": 54234656
  },
  {
    "id": "6273196847983",
    "name": "New active business (< 12 months)",
    "type": "behaviors",
    "path": [
      "Digital activities",
      "New active business (< 12 months)"
    ],
    "description": "Admins of engaged businesses that were created in the last 12 months.",
    "real_time_cluster": false,
    "audience_size_lower_bound": 15067193,
    "audience_size_upper_bound": 17719019
  },
  {
    "id": "6273108079183",
    "name": "New active business (< 6 months)",
    "type": "behaviors",
    "path": [
      "Digital activities",
      "New active business (< 6 months)"
    ],
    "description": "Admins of engaged businesses that were created in the last 6 months.",
    "real_time_cluster": false,
    "audience_size_lower_bound": 10111809,
    "audience_size_upper_bound": 11891488
  },
  {
    "id": "6273108107383",
    "name": "New active business (< 24 months)",
    "type": "behaviors",
    "path": [
      "Digital activities",
      "New active business (< 24 months)"
    ],
    "description": "Admins of engaged businesses that were created in the last 24 months.",
    "real_time_cluster": false,
    "audience_size_lower_bound": 22413433,
    "audience_size_upper_bound": 26358198
  },
  {
    "id": "6284960011583",
    "name": "Purchase with offer",
    "type": "behaviors",
    "path": [
      "Behaviours",
      "Purchase behaviours",
      "Purchase with offer",
      "Purchase with offer"
    ],
    "description": "People who are more likely to purchase with an offer.",
    "real_time_cluster": false,
    "audience_size_lower_bound": 12347559,
    "audience_size_upper_bound": 14520730
  },
  {
    "id": "6240205632783",
    "name": "People who have recently engaged with Facebook Pages related to news and politics",
    "type": "behaviors",
    "path": [],
    "real_time_cluster": false,
    "description": "People who have recently engaged with content on Facebook Pages that are related to news and politics, such as governments, political organisations, public policy or current events",
    "audience_size_lower_bound": 0,
    "audience_size_upper_bound": 0
  },
  {
    "id": "6240205659183",
    "name": "People who have recently engaged with Facebook Pages related to crime and tragedy",
    "type": "behaviors",
    "path": [],
    "real_time_cluster": false,
    "description": "People who have recently engaged with content on Facebook Pages that are related to crime and tragedy, such as natural disasters or military conflicts",
    "audience_size_lower_bound": 0,
    "audience_size_upper_bound": 0
  },
  {
    "id": "6240205649983",
    "name": "People who have recently engaged with Facebook Pages related to social issues",
    "type": "behaviors",
    "path": [],
    "real_time_cluster": false,
    "description": "People who have recently engaged with content on Facebook Pages that are related to social issues, such as civil rights, environment policies or education policies",
    "audience_size_lower_bound": 0,
    "audience_size_upper_bound": 0
  }
]


}