import React, { useState, useEffect } from "react";
import "./CreateNewPost.css";
import emojiesIcon from "../../assets/emojiesIcon.png";

import { date, time } from "./Modal/Modal";
import Select from "react-select";
import { useDispatch } from "react-redux";
import { showCreatePinMenu } from "../../Redux/Actions/CreateNewPostAction";
import DropzoneArea from "../../Components/Dropzone/Dropzone";
import Alert from "../../Components/Alert/Alert";
// import InputEmoji from "react-input-emoji";
import Modal from "./Modal/Modal";
import { Tooltip } from "reactstrap";
import { createPin, getPageDetailsApi, schedulePin } from "../../Utils/api";
import { BarLoader, MoonLoader } from "react-spinners";
import Pixabay from "../../Components/Pixabay/Pixabay.js";
import data from "@emoji-mart/data";
import Picker from "@emoji-mart/react";
import axios from "axios";

// async =()=>{
//     const data = await getPageDetailsApi()
//     console.log(data)

// }

console.log(date, time);

function NewPost({ postContent, paths }) {
  return (
    <>
      {postContent}

      <img src={paths} alt="" style={{ width: "100%" }} />
    </>
  );
}
function ImgDetails({ paths, imgName, handleClickDeleteImage }) {
  return (
    <>
      <div
        style={{ border: "2px solid #000", padding: "15px", display: "flex" }}
      >
        <span>
          {" "}
          <img src={paths} alt="" style={{ maxHeight: "100px" }} />
        </span>
        <div>
          <p style={{ marginLeft: "10px", fontSize: "12px" }}>{imgName}</p>
          <p style={{ marginLeft: "10px", fontSize: "12px" }}>
            Source: Uploads
          </p>
        </div>
        <div style={{ marginLeft: "auto" }}>
          <button
            className="delBtn"
            width="auto"
            height="28"
            type="button"
            onClick={handleClickDeleteImage}
          >
            <svg width="16" height="16" viewBox="0 0 20 20" fill="#2f3638">
              <path d="M15,1.25 L15,3.75 L20,3.75 L20,6.25 L18.75,6.25 L18.75,18.75 C18.75,19.5 18.25,20 17.5,20 L2.5,20 C1.75,20 1.25,19.5 1.25,18.75 L1.25,6.25 L0,6.25 L0,3.75 L5,3.75 L5,1.25 C5,0.5 5.5,0 6.25,0 L13.75,0 C14.5,0 15,0.5 15,1.25 Z M7.5,2.5 L7.5,3.75 L12.5,3.75 L12.5,2.5 L7.5,2.5 Z M16.25,6.25 L3.75,6.25 L3.75,17.5 L16.25,17.5 L16.25,6.25 Z M6.25,8.75 L8.75,8.75 L8.75,16.25 L6.25,16.25 L6.25,8.75 Z M11.25,8.75 L13.75,8.75 L13.75,16.25 L11.25,16.25 L11.25,8.75 Z"></path>
            </svg>
          </button>
        </div>
      </div>
    </>
  );
}

function ImageUploadSpinner() {
  return (
    <>
      <div style={{ border: "2px solid #000", padding: "15px" }}>
        <BarLoader color="#143059" height={9} speedMultiplier={2} width={400} />

        {/* <div style={{ marginLeft: "auto" }}>
        <button
          className="delBtn"
          width="auto"
          height="28"
          type="button"
          onClick={handleClickDeleteImage}
        >
          <svg width="16" height="16" viewBox="0 0 20 20" fill="#2f3638">
            <path d="M15,1.25 L15,3.75 L20,3.75 L20,6.25 L18.75,6.25 L18.75,18.75 C18.75,19.5 18.25,20 17.5,20 L2.5,20 C1.75,20 1.25,19.5 1.25,18.75 L1.25,6.25 L0,6.25 L0,3.75 L5,3.75 L5,1.25 C5,0.5 5.5,0 6.25,0 L13.75,0 C14.5,0 15,0.5 15,1.25 Z M7.5,2.5 L7.5,3.75 L12.5,3.75 L12.5,2.5 L7.5,2.5 Z M16.25,6.25 L3.75,6.25 L3.75,17.5 L16.25,17.5 L16.25,6.25 Z M6.25,8.75 L8.75,8.75 L8.75,16.25 L6.25,16.25 L6.25,8.75 Z M11.25,8.75 L13.75,8.75 L13.75,16.25 L11.25,16.25 L11.25,8.75 Z"></path>
          </svg>
        </button>
      </div> */}
      </div>
    </>
  );
}

function CreatePin({ pagesDetails, userInfo, eventData, setPagesDetails }) {
  const [tooltipToggle, setTooltipToggle] = useState(false);
  const toggle = () => setTooltipToggle(!tooltipToggle);
  let options = [];
  const [showEmojis, setShowEmojis] = useState(false);

  useEffect(() => {
    if (pagesDetails.length === 0 && !eventData) {
      getPageDetailsApi({ jwToken: localStorage.getItem("token") }).then(
        (res) => setPagesDetails(res.data)
      );
    }
  }, []);

  pagesDetails.forEach((element) => {
    if (element.account_type === "pinterest") {
      options.push({
        value: {
          id: element.pageId,
          token: element.token,
          picture: element.picture,
          account_type: element.account_type,
          secrate: element.token_secrate || null,
          refresh_token: element.refresh_token || null,
        },

        label: (
          <div>
            {" "}
            {element.account_type === "BUSINESS" ||
            element.account_type === "pinterest" ? (
              <svg
                className="gUZ GjR U9O kVc"
                height="16"
                width="16"
                viewBox="0 0 24 24"
                aria-label="Pinterest"
                role="img"
              >
                <path d="M0 12c0 5.123 3.211 9.497 7.73 11.218-.11-.937-.227-2.482.025-3.566.217-.932 1.401-5.938 1.401-5.938s-.357-.715-.357-1.774c0-1.66.962-2.9 2.161-2.9 1.02 0 1.512.765 1.512 1.682 0 1.025-.653 2.557-.99 3.978-.281 1.189.597 2.159 1.769 2.159 2.123 0 3.756-2.239 3.756-5.471 0-2.861-2.056-4.86-4.991-4.86-3.398 0-5.393 2.549-5.393 5.184 0 1.027.395 2.127.889 2.726a.36.36 0 0 1 .083.343c-.091.378-.293 1.189-.332 1.355-.053.218-.173.265-.4.159-1.492-.694-2.424-2.875-2.424-4.627 0-3.769 2.737-7.229 7.892-7.229 4.144 0 7.365 2.953 7.365 6.899 0 4.117-2.595 7.431-6.199 7.431-1.211 0-2.348-.63-2.738-1.373 0 0-.599 2.282-.744 2.84-.282 1.084-1.064 2.456-1.549 3.235C9.584 23.815 10.77 24 12 24c6.627 0 12-5.373 12-12S18.627 0 12 0 0 5.373 0 12"></path>
              </svg>
            ) : element.account_type === "linkedin" ? (
              <svg width="16" height="16" viewBox="0 0 20 20" fill="#2f3638">
                <g>
                  <path
                    d="M10 20C15.523 20 20 15.523 20 10C20 4.477 15.523 0 10 0C4.477 0 0 4.477 0 10C0 15.523 4.477 20 10 20Z"
                    fill="#0A66C2"
                  ></path>
                  <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M6.97347 5.39759C6.97878 5.79554 6.84294 6.1277 6.56703 6.39512C6.29112 6.66254 5.92819 6.79519 5.47825 6.79519H5.46233C5.02299 6.79519 4.66961 6.66254 4.40113 6.39512C4.13371 6.1277 4 5.79554 4 5.39759C4 4.99434 4.13796 4.66112 4.41387 4.39689C4.68978 4.13159 5.05058 4 5.49416 4C5.93987 4 6.29537 4.13265 6.56279 4.39689C6.83127 4.66219 6.96604 4.99434 6.97241 5.39759H6.97347ZM6.80368 7.89883V16H4.15387V7.89883H6.80368ZM10.9126 9.04676C11.0138 8.88166 11.1171 8.7416 11.2236 8.61514C11.336 8.48461 11.4889 8.34241 11.6778 8.19066C11.8688 8.03785 12.1022 7.919 12.3781 7.83516C12.654 7.75027 12.9597 7.70782 13.2971 7.70782C14.2129 7.70782 14.9494 8.01769 15.5065 8.6353C16.0637 9.25398 16.3428 10.1602 16.3428 11.3541V15.9968H13.6983V11.664C13.6983 11.0909 13.59 10.6452 13.3735 10.3194C13.156 9.99576 12.8175 9.83339 12.3569 9.83339C12.0195 9.83339 11.7372 9.92572 11.508 10.1146C11.2809 10.3024 11.1111 10.5348 10.9986 10.8129C10.9402 10.9763 10.9105 11.197 10.9105 11.4751V15.9958H8.26813C8.27874 13.8203 8.28299 12.0587 8.28299 10.7068C8.28299 9.35585 8.28299 8.54935 8.27768 8.28723L8.27025 7.89459H10.9126V9.04676Z"
                    fill="white"
                  ></path>
                </g>
              </svg>
            ) : element.account_type === "twitter" ? (
              <svg width="16" height="16" viewBox="0 0 20 20" fill="#2f3638">
                <g>
                  <path
                    d="M20 10C20 15.523 15.523 20 10 20C4.477 20 0 15.523 0 10C0 4.477 4.477 0 10 0C15.523 0 20 4.477 20 10Z"
                    fill="#1DA1F2"
                  ></path>
                  <path
                    d="M16.134 6.188C15.806 6.699 15.404 7.133 14.934 7.492C14.943 7.566 14.943 7.676 14.943 7.82C14.9401 9.22326 14.5464 10.598 13.806 11.79C13.4322 12.4001 12.9733 12.9537 12.443 13.434C11.8821 13.9286 11.2346 14.3152 10.533 14.574C9.76895 14.8618 8.95839 15.0062 8.14197 15C6.83072 15.008 5.54876 14.6124 4.46997 13.867C4.64197 13.887 4.83297 13.898 5.04397 13.898C6.13107 13.9074 7.18515 13.5247 8.01297 12.82C7.50642 12.8116 7.01719 12.6342 6.62297 12.316C6.21843 11.999 5.9233 11.5633 5.77897 11.07C5.94297 11.098 6.09097 11.11 6.23197 11.11C6.44197 11.11 6.65397 11.082 6.86097 11.023C6.31332 10.9068 5.82547 10.5981 5.48597 10.153C5.12478 9.69652 4.9329 9.129 4.94297 8.547V8.516C5.27096 8.71203 5.64312 8.8221 6.02497 8.836C5.69559 8.60494 5.42835 8.29614 5.24697 7.937C5.05272 7.56604 4.95343 7.15272 4.95797 6.734C4.95797 6.277 5.06797 5.852 5.28197 5.461C5.8689 6.229 6.61188 6.86394 7.46197 7.324C8.30816 7.78759 9.24833 8.05358 10.212 8.102C10.174 7.91133 10.1545 7.71742 10.154 7.523C10.154 6.824 10.388 6.23 10.853 5.738C11.322 5.246 11.884 5 12.544 5C13.236 5 13.818 5.266 14.29 5.797C14.8295 5.68765 15.3445 5.48094 15.81 5.187C15.6435 5.76458 15.2691 6.26008 14.759 6.578C15.216 6.528 15.677 6.395 16.134 6.188Z"
                    fill="white"
                  ></path>
                </g>
              </svg>
            ) : null}
            <span style={{ marginLeft: "5px" }}>{element.name}</span>
          </div>
        ),
      });
    }
  });

  // To convert the date getting from the props to the required Format -> Starts

  const propDate = `${String(eventData?.start).split(" ")[3]}-${
    new Date(
      Date.parse(
        String(eventData?.start).split(" ")[1] +
          " " +
          String(eventData?.start).split(" ")[2] +
          ", " +
          String(eventData?.start).split(" ")[3]
      )
    ).getMonth() +
      1 <
    10
      ? `0${
          new Date(
            Date.parse(
              String(eventData?.start).split(" ")[1] +
                " " +
                String(eventData?.start).split(" ")[2] +
                ", " +
                String(eventData?.start).split(" ")[3]
            )
          ).getMonth() + 1
        }`
      : new Date(
          Date.parse(
            String(eventData?.start).split(" ")[1] +
              " " +
              String(eventData?.start).split(" ")[2] +
              ", " +
              String(eventData?.start).split(" ")[3]
          )
        ).getMonth() + 1
  }-${String(eventData?.start).split(" ")[2]}`;

  // To convert the date getting from the props to the required Format -> Ends

  // const checkEventData = eventData?.
  const [postContent, setPostContent] = useState(eventData?.title || "");
  const [paths, setPaths] = useState(eventData?.imgUrl || {});
  const [imgUrl, setImgUrl] = useState("");
  const [imgName, setImgName] = useState("");
  const [selected, setSelected] = useState([]);
  const [isOpen, setIsOpen] = useState(false);
  const [date, setDate] = useState(
    !propDate.includes("undefined") ? propDate : ""
  );
  const [time, setTime] = useState(
    String(eventData?.start)?.split(" ")[4]?.slice(0, 5) || ""
  );
  const [border, setBorder] = useState("");
  const [Gallery, setGallery] = useState(false);
  const [minimized, setMinimized] = useState(false);
  const [postLink, setPostLink] = useState([]);
  const [url, setUrl] = useState(eventData?.url || "");
  const [isLoading, setIsLoading] = useState(false);
  const dispatch = useDispatch();
  let userID = JSON.parse(localStorage.getItem("userProfileInfo"))?._id;
  const [userId, setUserId] = useState(userID);
  console.log(date, time);
  useEffect(() => {
    const newData = postContent.split(" ");
    const newLink = newData.filter((data) => data.includes("http" || "https"));
    const link = newLink.map(async (data) => {
      const res = await axios(`https://api.shrtco.de/v2/shorten?url=${data}`);
      const newLine = res.data.result.full_short_link.toString();
      setPostLink(newLine);
    });
  }, [postContent]);
  const timexoneOffset = new Date().toTimeString();
  const newTimeZone = timexoneOffset.split(" ")[1];
  const newZone = newTimeZone.split(" ")[0];
  const zone = newZone[0] + newZone[1] + ":" + newZone[2] + newZone[3];
  console.log(zone);
  const timezone = date + "T" + time + ":00" + "+" + zone;
  console.log(timezone);

  // Selected Date TimeZone
  const selectedDate = date + " " + time;

  const [schedule, setSchedule] = useState({
    message: "",
    page_token: "",
    pageId: "",
    time: "",
  });

  const handleClickCreatePin = () => {
    dispatch(showCreatePinMenu());
  };

  const handleClickDeleteImage = () => {
    setImgUrl("");
    setPaths([]);
    setImgName("");
  };

  const pinDescription =
    postContent.startsWith("Pinterest") && postContent.endsWith("(Pin)")
      ? postContent.split("-")[1].slice(0, -5).trim()
      : postContent;

  const handleSchedulePost = () => {
    selected.map((account) => {
      console.log(account);
      let params = {
        link: url,
        description: pinDescription,
        token: account.value.token,
        board_id: account.value.id,
        media_source: {
          source_type: "image_url",
          url: paths?.pinterest || paths,
        },
        refresh_token: account.value.refresh_token,
        jwToken: sessionStorage.getItem("token"),
        time: new Date(selectedDate).getTime(),
        altUrl: paths?.pinterest,
        taskId: eventData?.taskId,
      };
      if (account.length !== 0 && postContent.length !== 0) {
        console.log("Its ");
        if (account.value.account_type === "pinterest") {
          schedulePin(params)
            .then((res) => dispatch(showCreatePinMenu()))
            .catch((err) => {
              axios
                .post(
                  `${process.env.REACT_APP_SERVER_URI}/api/toowe/users/${userId}/crud/token/refresh`,
                  {
                    token: account.value.refresh_token,
                  }
                )
                .then((res) => {
                  params.token = res.data.access_token;
                  schedulePin(params).then(() => {
                    dispatch(showCreatePinMenu());
                  });
                })
                .catch((err) => console.log(err));
            });
        }
      }
      console.log(params);
    });
    setPostContent("");
    setPaths("");
    setImgName("");
    setUrl("");
  };

  const [scheduleOptions, setScheduleOptions] = useState(false);

  const [postingLoader, setpostingLoader] = useState(false);

  const handlePost = () => {
    setpostingLoader(true);
    selected.map((account) => {
      let params = {
        link: url,
        description: postContent,
        token: account?.value?.token,
        board_id: account?.value?.id,
        media_source: {
          source_type: "image_url",
          url: paths.facebook || imgUrl,
        },
        refresh_token: account.value.refresh_token,
        jwToken: sessionStorage.getItem("token"),
      };
      console.log(params);

      if (account.length !== 0 && postContent.length !== 0) {
        console.log("Its ");
        if (account.value.account_type === "pinterest") {
          createPin(params)
            .then((res) => dispatch(showCreatePinMenu()))
            .catch((err) => {
              axios
                .post(
                  `${process.env.REACT_APP_SERVER_URI}/api/toowe/users/${userId}/crud/token/refresh`,
                  {
                    token: account.value.refresh_token,
                  }
                )
                .then((res) => {
                  params.token = res.data.access_token;
                  createPin(params).then(() => {
                    dispatch(showCreatePinMenu());
                  });
                })
                .catch((err) => console.log(err));
            });
        }
      }
    });
    setpostingLoader(false);
    setPostContent("");
    setPaths("");
    setImgName("");
    setUrl("");
  };

  const addEmoji = (e) => {
    console.log(e);
    let sym = e.unified.split("-");
    let codesArray = [];
    sym.forEach((el) => codesArray.push("0x" + el));
    let emoji = String.fromCodePoint(...codesArray);
    setPostContent(postContent + emoji);
  };

  console.log(eventData);

  useEffect(() => {
    if (eventData?.pageId) {
      pagesDetails.map((data) => {
        if (data.pageId === eventData?.pageId) {
          // console.log(data)
          let dat = [];

          dat.push({
            value: data,

            label: (
              <div>
                {" "}
                {data.account_type === "BUSINESS" ||
                data.account_type === "pinterest" ? (
                  <svg
                    className="gUZ GjR U9O kVc"
                    height="16"
                    width="16"
                    viewBox="0 0 24 24"
                    aria-label="Pinterest"
                    role="img"
                  >
                    <path d="M0 12c0 5.123 3.211 9.497 7.73 11.218-.11-.937-.227-2.482.025-3.566.217-.932 1.401-5.938 1.401-5.938s-.357-.715-.357-1.774c0-1.66.962-2.9 2.161-2.9 1.02 0 1.512.765 1.512 1.682 0 1.025-.653 2.557-.99 3.978-.281 1.189.597 2.159 1.769 2.159 2.123 0 3.756-2.239 3.756-5.471 0-2.861-2.056-4.86-4.991-4.86-3.398 0-5.393 2.549-5.393 5.184 0 1.027.395 2.127.889 2.726a.36.36 0 0 1 .083.343c-.091.378-.293 1.189-.332 1.355-.053.218-.173.265-.4.159-1.492-.694-2.424-2.875-2.424-4.627 0-3.769 2.737-7.229 7.892-7.229 4.144 0 7.365 2.953 7.365 6.899 0 4.117-2.595 7.431-6.199 7.431-1.211 0-2.348-.63-2.738-1.373 0 0-.599 2.282-.744 2.84-.282 1.084-1.064 2.456-1.549 3.235C9.584 23.815 10.77 24 12 24c6.627 0 12-5.373 12-12S18.627 0 12 0 0 5.373 0 12"></path>
                  </svg>
                ) : data.account_type === "linkedin" ? (
                  <svg
                    width="16"
                    height="16"
                    viewBox="0 0 20 20"
                    fill="#2f3638"
                  >
                    <g>
                      <path
                        d="M10 20C15.523 20 20 15.523 20 10C20 4.477 15.523 0 10 0C4.477 0 0 4.477 0 10C0 15.523 4.477 20 10 20Z"
                        fill="#0A66C2"
                      ></path>
                      <path
                        fillRule="evenodd"
                        clipRule="evenodd"
                        d="M6.97347 5.39759C6.97878 5.79554 6.84294 6.1277 6.56703 6.39512C6.29112 6.66254 5.92819 6.79519 5.47825 6.79519H5.46233C5.02299 6.79519 4.66961 6.66254 4.40113 6.39512C4.13371 6.1277 4 5.79554 4 5.39759C4 4.99434 4.13796 4.66112 4.41387 4.39689C4.68978 4.13159 5.05058 4 5.49416 4C5.93987 4 6.29537 4.13265 6.56279 4.39689C6.83127 4.66219 6.96604 4.99434 6.97241 5.39759H6.97347ZM6.80368 7.89883V16H4.15387V7.89883H6.80368ZM10.9126 9.04676C11.0138 8.88166 11.1171 8.7416 11.2236 8.61514C11.336 8.48461 11.4889 8.34241 11.6778 8.19066C11.8688 8.03785 12.1022 7.919 12.3781 7.83516C12.654 7.75027 12.9597 7.70782 13.2971 7.70782C14.2129 7.70782 14.9494 8.01769 15.5065 8.6353C16.0637 9.25398 16.3428 10.1602 16.3428 11.3541V15.9968H13.6983V11.664C13.6983 11.0909 13.59 10.6452 13.3735 10.3194C13.156 9.99576 12.8175 9.83339 12.3569 9.83339C12.0195 9.83339 11.7372 9.92572 11.508 10.1146C11.2809 10.3024 11.1111 10.5348 10.9986 10.8129C10.9402 10.9763 10.9105 11.197 10.9105 11.4751V15.9958H8.26813C8.27874 13.8203 8.28299 12.0587 8.28299 10.7068C8.28299 9.35585 8.28299 8.54935 8.27768 8.28723L8.27025 7.89459H10.9126V9.04676Z"
                        fill="white"
                      ></path>
                    </g>
                  </svg>
                ) : data.account_type === "twitter" ? (
                  <svg
                    width="16"
                    height="16"
                    viewBox="0 0 20 20"
                    fill="#2f3638"
                  >
                    <g>
                      <path
                        d="M20 10C20 15.523 15.523 20 10 20C4.477 20 0 15.523 0 10C0 4.477 4.477 0 10 0C15.523 0 20 4.477 20 10Z"
                        fill="#1DA1F2"
                      ></path>
                      <path
                        d="M16.134 6.188C15.806 6.699 15.404 7.133 14.934 7.492C14.943 7.566 14.943 7.676 14.943 7.82C14.9401 9.22326 14.5464 10.598 13.806 11.79C13.4322 12.4001 12.9733 12.9537 12.443 13.434C11.8821 13.9286 11.2346 14.3152 10.533 14.574C9.76895 14.8618 8.95839 15.0062 8.14197 15C6.83072 15.008 5.54876 14.6124 4.46997 13.867C4.64197 13.887 4.83297 13.898 5.04397 13.898C6.13107 13.9074 7.18515 13.5247 8.01297 12.82C7.50642 12.8116 7.01719 12.6342 6.62297 12.316C6.21843 11.999 5.9233 11.5633 5.77897 11.07C5.94297 11.098 6.09097 11.11 6.23197 11.11C6.44197 11.11 6.65397 11.082 6.86097 11.023C6.31332 10.9068 5.82547 10.5981 5.48597 10.153C5.12478 9.69652 4.9329 9.129 4.94297 8.547V8.516C5.27096 8.71203 5.64312 8.8221 6.02497 8.836C5.69559 8.60494 5.42835 8.29614 5.24697 7.937C5.05272 7.56604 4.95343 7.15272 4.95797 6.734C4.95797 6.277 5.06797 5.852 5.28197 5.461C5.8689 6.229 6.61188 6.86394 7.46197 7.324C8.30816 7.78759 9.24833 8.05358 10.212 8.102C10.174 7.91133 10.1545 7.71742 10.154 7.523C10.154 6.824 10.388 6.23 10.853 5.738C11.322 5.246 11.884 5 12.544 5C13.236 5 13.818 5.266 14.29 5.797C14.8295 5.68765 15.3445 5.48094 15.81 5.187C15.6435 5.76458 15.2691 6.26008 14.759 6.578C15.216 6.528 15.677 6.395 16.134 6.188Z"
                        fill="white"
                      ></path>
                    </g>
                  </svg>
                ) : null}
                <span style={{ marginLeft: "5px" }}>{data.name}</span>
              </div>
            ),
          });

          setSelected(dat[0]);
        }
      });
    }
  }, [eventData?.pageId, pagesDetails]);

  console.log(selected);

  return (
    <>
      {minimized === false ? (
        <div className="create-post-container">

        {postingLoader === true ? (
            <div className="Email_Loader">
            
              <MoonLoader
                color={"#ff9001"}
                loading={postingLoader}
                size={150}
              />
            </div>
          ) : null}
           
                   
          <div className="createPost_wrapper">
            <div className="form-header">
              <div className="add_line"></div>

              <div className="form-header-text">
                <h2>New Pin</h2>
              </div>
              <div className="form-header-rightBtns">
                {/* <div className="like-Btns">
            <svg
              width="19px"
              height="19px"
              viewBox="0 0 20 20"
              className="sc-cfkLgm fguMvy"
              fill="currentColor"
            >
              <path
                d="M19.7917 9.6375C19.791 9.14042 19.5932 8.66389 19.2418 8.3124C18.8903 7.96092 18.4137 7.76316 17.9167 7.7625H12.3117C12.2803 7.76254 12.2494 7.75551 12.2211 7.74193C12.1929 7.72834 12.168 7.70856 12.1485 7.68405C12.1289 7.65954 12.1152 7.63093 12.1082 7.60036C12.1013 7.56979 12.1013 7.53805 12.1083 7.5075C12.4335 6.21715 12.6344 4.89863 12.7083 3.57C12.7444 2.8748 12.5194 2.19122 12.0775 1.65333C11.8954 1.44577 11.6715 1.27906 11.4205 1.16416C11.1694 1.04927 10.8969 0.988775 10.6208 0.986664C10.4077 0.975987 10.1947 1.00849 9.99447 1.08222C9.79425 1.15595 9.611 1.26938 9.45572 1.41571C9.30044 1.56204 9.17634 1.73824 9.09086 1.93373C9.00539 2.12923 8.96031 2.33998 8.95833 2.55333C8.97363 4.08947 8.39856 5.57284 7.35184 6.69726C6.30511 7.82168 4.86663 8.5013 3.33333 8.59583H0.833332C0.667572 8.59583 0.508601 8.66168 0.39139 8.77889C0.27418 8.8961 0.208332 9.05507 0.208332 9.22083V16.7208C0.208332 16.8866 0.27418 17.0456 0.39139 17.1628C0.508601 17.28 0.667572 17.3458 0.833332 17.3458C4.61 17.3458 5.84083 17.875 6.92667 18.34C7.88326 18.8048 8.93671 19.0353 10 19.0125H15.4167C15.7353 19.0123 16.0486 18.931 16.327 18.7763C16.6055 18.6215 16.84 18.3983 17.0083 18.1279C17.1766 17.8574 17.2733 17.5485 17.2892 17.2303C17.3051 16.9121 17.2397 16.5951 17.0992 16.3092C17.5388 16.0857 17.8728 15.698 18.0288 15.2301C18.1847 14.7622 18.1501 14.2517 17.9325 13.8092C18.3722 13.5857 18.7061 13.198 18.8621 12.7301C19.0181 12.2622 18.9835 11.7517 18.7658 11.3092C19.0744 11.1523 19.3336 10.9131 19.5146 10.6181C19.6957 10.323 19.7916 9.98366 19.7917 9.6375Z"
                fill="#143059"
              ></path>
            </svg>
            <svg
              width="19px"
              height="19px"
              viewBox="0 0 20 20"
              fill="currentColor"
            >
              <path
                d="M0.208344 10.3625C0.209005 10.8596 0.406761 11.3361 0.758249 11.6876C1.10974 12.0391 1.58627 12.2368 2.08334 12.2375H7.68834C7.71969 12.2375 7.75065 12.2445 7.7789 12.2581C7.80716 12.2717 7.83198 12.2914 7.85153 12.316C7.87107 12.3405 7.88483 12.3691 7.89178 12.3996C7.89874 12.4302 7.8987 12.462 7.89168 12.4925C7.56652 13.7829 7.3656 15.1014 7.29168 16.43C7.25563 17.1252 7.48062 17.8088 7.92251 18.3467C8.10457 18.5542 8.32847 18.7209 8.57952 18.8358C8.83057 18.9507 9.10309 19.0112 9.37918 19.0133C9.59227 19.024 9.80533 18.9915 10.0055 18.9178C10.2058 18.8441 10.389 18.7306 10.5443 18.5843C10.6996 18.438 10.8237 18.2618 10.9091 18.0663C10.9946 17.8708 11.0397 17.66 11.0417 17.4467C11.0264 15.9105 11.6014 14.4272 12.6482 13.3027C13.6949 12.1783 15.1334 11.4987 16.6667 11.4042H19.1667C19.3324 11.4042 19.4914 11.3383 19.6086 11.2211C19.7258 11.1039 19.7917 10.9449 19.7917 10.7792V3.27917C19.7917 3.11341 19.7258 2.95444 19.6086 2.83723C19.4914 2.72002 19.3324 2.65417 19.1667 2.65417C15.39 2.65417 14.1592 2.125 13.0733 1.66C12.1168 1.19521 11.0633 0.964694 10 0.987504H4.58334C4.26475 0.987665 3.95145 1.06897 3.67298 1.22374C3.39452 1.37852 3.16006 1.60167 2.99171 1.87215C2.82337 2.14263 2.72669 2.45153 2.71079 2.76973C2.6949 3.08792 2.7603 3.40492 2.90084 3.69084C2.46117 3.91432 2.12721 4.30196 1.97124 4.76986C1.81528 5.23776 1.84986 5.74825 2.06751 6.19084C1.62784 6.41432 1.29388 6.80196 1.13791 7.26986C0.981946 7.73776 1.01653 8.24825 1.23418 8.69084C0.925593 8.84769 0.666431 9.08688 0.485378 9.38192C0.304324 9.67696 0.208442 10.0163 0.208344 10.3625Z"
                fill="#143059"
              ></path>
            </svg>
          </div> */}
                <div
                  className="minimize-Btn"
                  onClick={() => setMinimized(true)}
                >
                  <button type="button" height="44" width="auto">
                    <svg
                      width="16"
                      height="16"
                      viewBox="0 0 20 20"
                      fill="currentColor"
                    >
                      <path d="M17.5,18.75 L7.5,18.75 L7.5,16.25 L14.48,16.25 L1.61,3.38 L3.38,1.61 L16.25,14.48 L16.25,7.5 L18.75,7.5 L18.75,17.5 C18.75,18.19 18.19,18.75 17.5,18.75 Z"></path>
                    </svg>
                  </button>
                </div>
                <div className="minimize-Btn" onClick={handleClickCreatePin}>
                  <button type="button" height="44" width="auto">
                    <svg
                      width="16"
                      height="16"
                      viewBox="0 0 20 20"
                      fill="currentColor"
                    >
                      <path d="M18.37,1.62 C17.87,1.12 17.12,1.12 16.62,1.62 L10,8.25 L3.37,1.62 C2.87,1.12 2.12,1.12 1.62,1.62 C1.12,2.12 1.12,2.87 1.62,3.37 L8.25,10 L1.62,16.62 C1.12,17.12 1.12,17.87 1.62,18.37 C1.87,18.62 2.12,18.75 2.5,18.75 C2.87,18.75 3.12,18.62 3.37,18.37 L10,11.75 L16.62,18.37 C16.87,18.62 17.25,18.75 17.5,18.75 C17.75,18.75 18.12,18.62 18.37,18.37 C18.87,17.87 18.87,17.12 18.37,16.62 L11.75,10 L18.37,3.37 C18.87,2.87 18.87,2.12 18.37,1.62 Z"></path>
                    </svg>
                  </button>
                </div>
              </div>
            </div>
            <div className="form-input-text-container">
              <div className="form-input-text-container-left">
                <div className="leftMain">
                  <div className="leftMain-container">
                    <div className="socialNetwork-picker">
                      <h3 className="mb-3">Pin to</h3>
                      <div className="select-social-account">
                        {/* <div className='select-social-account-container'> */}
                        {console.log(selected)}
                        <Select
                          options={options}
                          className="basic-multi-select"
                          classNamePrefix="select"
                          value={selected}
                          onChange={(event) => {
                            setSelected(event);
                          }}
                          isMulti={true}
                        />
                        {/* <div className='select-social-account-input-container'> */}
                        {/* <input className="social-account-input" placeholder="Select a social account" autocomplete="new-password" value="" />
                                              <svg width="16px" height="16px" viewBox="0 0 20 20" fill="#241F21" className="sc-hbmRdt cRpGmY"><polygon points="10 14.25 3.25 7.5 5 5.75 10 10.75 15 5.75 16.75 7.5"></polygon></svg> */}
                        {/* <input className="social-account-input"  placeholder="Select a social account" autocomplete="new-password" type="text" list="pages" />


<datalist id="pages" className="custom-select" >
<select className='dropdown' onSelect={handelSelect} >
  {pagesDetails.map(element =>{
      return <option className='option' onClick={console.log(element.id)} value={element.id}
     
      >{element.name}</option>
  })}
  </select>    

</datalist> */}

                        {/* </div> */}

                        {/* </div> */}
                      </div>

                      {/* <span>
                        <h4 className="recently-used-container">
                          Recently used:
                        </h4>
                        <div className="dummy-page-options-block">
                          <div
                            className="dummy-page-options-inner-block"
                            role="button"
                            title="Dummy page"
                            tabIndex="0"
                          >
                            <span
                              className="dummy-page-text"
                              onClick={() => {
                                setSelected(selected.push(options[0]));
                              }}
                            >
                              {options[0].label}
                            </span>
                          </div>
                        </div>
                      </span> */}
                    </div>
                    {postContent.length > 2000 ? (
                      <Alert message="Your text exceeds the character limit for Facebook" />
                    ) : null}

                    <div className="upload-Media-container">
                      <div className="upload-Media-header">
                        <div className="left-Text d-flex align-items-center">
                          <h3 className="MessageEditContent-text">Media</h3>
                          <button id="maxLimitTooltip" className="media_toolTipBtn">
                          <div className="IbuttonBox">
                              i
                              </div>
                          </button>
                        </div>
                        <div
                          className="right-Text"
                          onClick={() => {
                            setGallery(true);
                            console.log(Gallery);
                          }}
                        >
                          {/* <h3>Open media library</h3>*/}
                        </div>
                      </div>
                      <div className="uploadMedia-boxContainer">
                        {/* <div className='upload-from-device media-border'>
                                              <button className="uploadBtn2" width="auto" height="32" type="button">
                                                  <svg width="16" height="16" viewBox="0 0 20 20" fill="#2f3638"><path d="M16.25,8.25 C16.25,8.25 16.25,8.12 16.25,8.12 C16.25,4.62 13.5,1.87 10,1.87 C6.87,1.87 4.25,4.12 3.87,7.25 C1.62,8 0,10 0,12.5 C0,15.62 2.5,18.12 5.62,18.12 C7.75,18.12 13,18.12 15,18.12 C17.75,18.12 20,15.87 20,13.12 C20,10.75 18.37,8.87 16.25,8.25 Z M11.25,11.87 L11.25,15.62 L8.75,15.62 L8.75,11.87 L5,11.87 L10,6.87 L15,11.87 L11.25,11.87 Z"></path></svg>
                                              </button>
                                              <p className="uploadBtn2-text1">Drag files here</p>
                                              <p className="uploadBtn2-text2">Or select files to upload</p>
                                          </div> */}
                        <DropzoneArea
                          setPaths={setPaths}
                          setImgName={setImgName}
                          setImgUrl={setImgUrl}
                          selected={selected}
                          paths={paths}
                          isLoading={isLoading}
                          setIsLoading={setIsLoading}
                        />
                        <Tooltip
                          isOpen={tooltipToggle}
                          target="maxLimitTooltip"
                          toggle={toggle}
                          className="tooltipMaxLimit"
                        >
                          Max Limit for uploading an image is 5MB.
                        </Tooltip>
                        {console.log(selected)}
                        <div
                          className="upload-from-lib media-border"
                          onClick={() => {
                            setGallery(true);
                            console.log(Gallery);
                          }}
                        >
                          <button
                            className="uploadBtn2"
                            width="auto"
                            height="32"
                            type="button"
                          >
                            <svg
                              width="16"
                              height="16"
                              viewBox="0 0 20 20"
                              fill="#2f3638"
                            >
                              <path d="M16.25,8.25 C16.25,8.25 16.25,8.12 16.25,8.12 C16.25,4.62 13.5,1.87 10,1.87 C6.87,1.87 4.25,4.12 3.87,7.25 C1.62,8 0,10 0,12.5 C0,15.62 2.5,18.12 5.62,18.12 C7.75,18.12 13,18.12 15,18.12 C17.75,18.12 20,15.87 20,13.12 C20,10.75 18.37,8.87 16.25,8.25 Z M11.25,11.87 L11.25,15.62 L8.75,15.62 L8.75,11.87 L5,11.87 L10,6.87 L15,11.87 L11.25,11.87 Z"></path>
                            </svg>
                          </button>
                          <p className="uploadBtn2-text1">
                            Explore thousands of assets
                          </p>
                          <p className="uploadBtn2-text2">Browse your media</p>
                        </div>
                      </div>
                    </div>
                    <div className="upload-Media-container pt-0">
                      <div className="upload-Media-header">
                        <div className="left-Text">
                          <h3 className="MessageEditContent-text">Website</h3>
                        </div>
                      </div>
                      <input
                        className="text_box_inp"
                        placeholder="Add the URL this Pin links to"
                        type="url"
                        name="url"
                        id="url"
                        value={url}
                        onChange={(e) => {
                          e.preventDefault();
                          setUrl(e.target.value);
                        }}
                      />
                    </div>
                    <div className="MessageEditContent-container mb-5">
                      <div className="MessageEditContent-container-header">
                        <div className="MessageEditContent-div">
                          <div>
                            <div
                              role="list"
                              className="MessageEditContent-inner-div"
                            >
                              <div
                                role="listitem"
                                tabIndex="0"
                                className="MessageEditContent-box"
                              >
                                <div className="MessageEditContent-text">
                                  Content
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="sc-hUlgpI hQCMeI">
                          <div className="sc-eUXlCE rc-CharacterCounter bUxVBB">
                            <span className="sc-iqlufy -characterCounterCount kPxjWs">
                              {postContent.length}
                            </span>
                          </div>
                        </div>
                      </div>

                      <textarea
                        type="text"
                        className="text-box"
                        placeholder="Enter your text and links"
                        style={{
                          height: "200px",
                          width: "100%",
                          border: " 1px solid rgb(208, 208, 208)",
                        }}
                        value={pinDescription || ""}
                        onChange={(e) => setPostContent(e.target.value)}
                      />
                      {/* <button
                        className="button"
                        onClick={() => setShowEmojis(!showEmojis)}
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          className="icon"
                          fill="none"
                          viewBox="0 0 24 24"
                          stroke="currentColor"
                        >
                          <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeWidth="2"
                            d="M14.828 14.828a4 4 0 01-5.656 0M9 10h.01M15 10h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z"
                          />
                        </svg>
                      </button> */}
                      <div className={"emojiIconContainer"}>
                        <img
                          src={emojiesIcon}
                          style={{ float: "right" }}
                          alt="emojies"
                          onClick={() => setShowEmojis(!showEmojis)}
                        />
                      </div>

                      {showEmojis && (
                        <div>
                          <Picker
                            data={data}
                            onChange={addEmoji}
                            onEmojiSelect={addEmoji}
                          />
                          {/* <Picker data={data} onEmojiSelect={console.log} /> */}
                        </div>
                      )}

                      {paths?.pinintrest ? (
                        <ImgDetails
                          paths={paths}
                          imgName={imgName}
                          handleClickDeleteImage={handleClickDeleteImage}
                        />
                      ) : null}

                      {(imgName.length !== 0) & (paths.length === 0) ? (
                        <ImageUploadSpinner
                          handleClickDeleteImage={handleClickDeleteImage}
                        />
                      ) : null}

                      {/* <ImgDetails /> */}
                    </div>
                  </div>
                </div>
              </div>
              <div className="form-input-text-container-right">
                <div className="small_model_ form-input-text-inner-container-right">
                  {Gallery === true ? (
                    <Pixabay setPaths={setPaths} selected={selected} setGallery={setGallery} />
                  ) : (
                    <>
                      <div className="MessagePreview-area">
                        <div className="MessagePreview-header">
                          <div className="header-left">
                            {selected !== undefined ? (
                              selected?.value?.account_type === "facebook" ? (
                                <svg
                                  width="30"
                                  height="30"
                                  viewBox="0 0 20 20"
                                  fill="#143059"
                                  className="sc-jMGUhp hVdFLT"
                                >
                                  <g>
                                    <path
                                      fill="#1877F2"
                                      d="M20,10c0-5.523-4.477-10-10-10S0,4.477,0,10c0,4.991,3.657,9.128,8.438,9.878v-6.988H5.898V10h2.539V7.797 c0-2.506,1.493-3.891,3.777-3.891c1.094,0,2.238,0.195,2.238,0.195v2.461h-1.261c-1.242,0-1.63,0.771-1.63,1.562V10h2.773 l-0.443,2.891h-2.33v6.988C16.343,19.128,20,14.991,20,10z"
                                    ></path>
                                    <path
                                      fill="#FFFFFF"
                                      d="M13.893,12.891L14.336,10h-2.773V8.124c0-0.791,0.387-1.562,1.63-1.562h1.261V4.102 c0,0-1.144-0.195-2.238-0.195c-2.284,0-3.777,1.384-3.777,3.891V10H5.898v2.891h2.539v6.988C8.947,19.958,9.468,20,10,20 s1.053-0.042,1.562-0.122v-6.988H13.893z"
                                    ></path>
                                  </g>
                                </svg>
                              ) : selected?.value?.account_type ===
                                "linkedin" ? (
                                <svg
                                  width="30"
                                  height="30"
                                  viewBox="0 0 20 20"
                                  fill="#2f3638"
                                >
                                  <g>
                                    <path
                                      d="M10 20C15.523 20 20 15.523 20 10C20 4.477 15.523 0 10 0C4.477 0 0 4.477 0 10C0 15.523 4.477 20 10 20Z"
                                      fill="#0A66C2"
                                    ></path>
                                    <path
                                      fillRule="evenodd"
                                      clipRule="evenodd"
                                      d="M6.97347 5.39759C6.97878 5.79554 6.84294 6.1277 6.56703 6.39512C6.29112 6.66254 5.92819 6.79519 5.47825 6.79519H5.46233C5.02299 6.79519 4.66961 6.66254 4.40113 6.39512C4.13371 6.1277 4 5.79554 4 5.39759C4 4.99434 4.13796 4.66112 4.41387 4.39689C4.68978 4.13159 5.05058 4 5.49416 4C5.93987 4 6.29537 4.13265 6.56279 4.39689C6.83127 4.66219 6.96604 4.99434 6.97241 5.39759H6.97347ZM6.80368 7.89883V16H4.15387V7.89883H6.80368ZM10.9126 9.04676C11.0138 8.88166 11.1171 8.7416 11.2236 8.61514C11.336 8.48461 11.4889 8.34241 11.6778 8.19066C11.8688 8.03785 12.1022 7.919 12.3781 7.83516C12.654 7.75027 12.9597 7.70782 13.2971 7.70782C14.2129 7.70782 14.9494 8.01769 15.5065 8.6353C16.0637 9.25398 16.3428 10.1602 16.3428 11.3541V15.9968H13.6983V11.664C13.6983 11.0909 13.59 10.6452 13.3735 10.3194C13.156 9.99576 12.8175 9.83339 12.3569 9.83339C12.0195 9.83339 11.7372 9.92572 11.508 10.1146C11.2809 10.3024 11.1111 10.5348 10.9986 10.8129C10.9402 10.9763 10.9105 11.197 10.9105 11.4751V15.9958H8.26813C8.27874 13.8203 8.28299 12.0587 8.28299 10.7068C8.28299 9.35585 8.28299 8.54935 8.27768 8.28723L8.27025 7.89459H10.9126V9.04676Z"
                                      fill="white"
                                    ></path>
                                  </g>
                                </svg>
                              ) : selected?.value?.account_type ===
                                "twitter" ? (
                                <svg
                                  width="30"
                                  height="30"
                                  viewBox="0 0 20 20"
                                  fill="#2f3638"
                                >
                                  <g>
                                    <path
                                      d="M20 10C20 15.523 15.523 20 10 20C4.477 20 0 15.523 0 10C0 4.477 4.477 0 10 0C15.523 0 20 4.477 20 10Z"
                                      fill="#1DA1F2"
                                    ></path>
                                    <path
                                      d="M16.134 6.188C15.806 6.699 15.404 7.133 14.934 7.492C14.943 7.566 14.943 7.676 14.943 7.82C14.9401 9.22326 14.5464 10.598 13.806 11.79C13.4322 12.4001 12.9733 12.9537 12.443 13.434C11.8821 13.9286 11.2346 14.3152 10.533 14.574C9.76895 14.8618 8.95839 15.0062 8.14197 15C6.83072 15.008 5.54876 14.6124 4.46997 13.867C4.64197 13.887 4.83297 13.898 5.04397 13.898C6.13107 13.9074 7.18515 13.5247 8.01297 12.82C7.50642 12.8116 7.01719 12.6342 6.62297 12.316C6.21843 11.999 5.9233 11.5633 5.77897 11.07C5.94297 11.098 6.09097 11.11 6.23197 11.11C6.44197 11.11 6.65397 11.082 6.86097 11.023C6.31332 10.9068 5.82547 10.5981 5.48597 10.153C5.12478 9.69652 4.9329 9.129 4.94297 8.547V8.516C5.27096 8.71203 5.64312 8.8221 6.02497 8.836C5.69559 8.60494 5.42835 8.29614 5.24697 7.937C5.05272 7.56604 4.95343 7.15272 4.95797 6.734C4.95797 6.277 5.06797 5.852 5.28197 5.461C5.8689 6.229 6.61188 6.86394 7.46197 7.324C8.30816 7.78759 9.24833 8.05358 10.212 8.102C10.174 7.91133 10.1545 7.71742 10.154 7.523C10.154 6.824 10.388 6.23 10.853 5.738C11.322 5.246 11.884 5 12.544 5C13.236 5 13.818 5.266 14.29 5.797C14.8295 5.68765 15.3445 5.48094 15.81 5.187C15.6435 5.76458 15.2691 6.26008 14.759 6.578C15.216 6.528 15.677 6.395 16.134 6.188Z"
                                      fill="white"
                                    ></path>
                                  </g>
                                </svg>
                              ) : null
                            ) : null}
                          </div>
                          <div className="header-right">
                            <span
                              style={{
                                paddingBottom: "5px",
                                marginTop: "-5px",
                              }}
                            >
                              {selected !== undefined
                                ? selected?.value?.account_type
                                : null}
                            </span>
                          </div>
                        </div>

                        {/* // -------------------------------POST PREVIEW START------------------------------------------- */}
                        {/* {selected !== undefined ? (
                          selected.value.account_type == "facebook" ? (
                            <div className="previewBase-Layout">
                              <div className="previewBase-Layout-innerContainer">
                                <div className="profile-preview-container">
                                  <div className="profile-preview-pic">
                                    <img
                                      src={selected.value.picture}
                                      style={{
                                        clipPath: "circle(50% at 50% 50%)",
                                      }}
                                    />
                                  </div>
                                  <div
                                    className="profile-preview-name"
                                    width="60px"
                                  >
                                    {selected.label}
                                    <div
                                      style={{
                                        fontWeight: "100",
                                        fontSize: "12px",
                                      }}
                                    >
                                      just Now
                                    </div>
                                  </div>
                                </div>
                                <div className="content-preview-container">
                                  {postContent.length !== 0 ||
                                    paths.length !== 0 ? (
                                    <NewPost
                                      postContent={postContent}
                                      paths={paths}
                                    />
                                  ) : (
                                    <>
                                      <div className="content-preview"></div>
                                      <div className="content-preview2"></div>
                                    </>
                                  )}
                                </div>
                                <div className="bottom-preview-container">
                                  <div className="like">
                                    <img
                                      className="bottom-img"
                                      src="https://i.hootsuite.com/assets/plancreate/fb-like.svg"
                                    ></img>{" "}
                                    Like
                                  </div>
                                  <div className="comment">
                                    <img
                                      className="bottom-img"
                                      src="https://i.hootsuite.com/assets/plancreate/fb-comment.svg"
                                    ></img>{" "}
                                    Comment
                                  </div>
                                  <div className="share">
                                    <img
                                      className="bottom-img"
                                      src="https://i.hootsuite.com/assets/plancreate/fb-share.svg"
                                    ></img>{" "}
                                    Share
                                  </div>
                                </div>
                              </div>
                            </div>
                          ) : selected.value.account_type == "linkedin" ? (
                            <div className="previewBase-Layout">
                              <div className="previewBase-Layout-innerContainer">
                                <div className="profile-preview-container">
                                  <div className="profile-preview-pic">
                                    <img
                                      style={{
                                        clipPath: "circle(50% at 50% 50%)",
                                      }}
                                    />
                                  </div>
                                  <div className="profile-preview-name">
                                    {selected.label}
                                    <div
                                      style={{
                                        fontWeight: "100",
                                        fontSize: "12px",
                                      }}
                                    >
                                      just Now
                                    </div>
                                  </div>
                                </div>
                                <div className="content-preview-container">
                                  {postContent.length !== 0 ||
                                    paths.length !== 0 ? (
                                    <NewPost
                                      postContent={postContent}
                                      paths={paths}
                                    />
                                  ) : (
                                    <>
                                      <div className="content-preview"></div>
                                      <div className="content-preview2"></div>
                                    </>
                                  )}
                                </div>
                                <div className="bottom-preview-container">
                                  <div className="like">
                                    <img
                                      className="bottom-img"
                                      src="https://i.hootsuite.com/assets/plancreate/li-like.svg"
                                    ></img>{" "}
                                    Like
                                  </div>
                                  <div className="comment">
                                    <img
                                      className="bottom-img"
                                      src="https://i.hootsuite.com/assets/plancreate/li-comment.svg"
                                    ></img>{" "}
                                    Comment
                                  </div>
                                  <div className="share">
                                    <img
                                      className="bottom-img"
                                      src="https://i.hootsuite.com/assets/plancreate/li-share.svg"
                                    ></img>{" "}
                                    Share
                                  </div>
                                </div>
                              </div>
                            </div>
                          ) : selected.value.account_type == "twitter" ? (
                            <div className="previewBase-Layout">
                              <div className="previewBase-Layout-innerContainer">
                                <div className="profile-preview-container">
                                  <div className="profile-preview-pic">
                                    <img
                                      style={{
                                        clipPath: "circle(50% at 50% 50%)",
                                      }}
                                    />
                                  </div>
                                  <div className="profile-preview-name">
                                    {selected.label}
                                    <div
                                      style={{
                                        fontWeight: "100",
                                        fontSize: "12px",
                                      }}
                                    >
                                      just Now
                                    </div>
                                  </div>
                                </div>
                                <div className="content-preview-container">
                                  {postContent.length !== 0 ||
                                    paths.length !== 0 ? (
                                    <NewPost
                                      postContent={postContent}
                                      paths={paths}
                                    />
                                  ) : (
                                    <>
                                      <div className="content-preview"></div>
                                      <div className="content-preview2"></div>
                                    </>
                                  )}
                                </div>
                                <div className="bottom-preview-container">
                                  <div className="like">
                                    <img
                                      className="bottom-img"
                                      src="https://i.hootsuite.com/assets/plancreate/tw-reply.svg"
                                    ></img>{" "}
                                  </div>
                                  <div className="comment">
                                    <img
                                      className="bottom-img"
                                      src="https://i.hootsuite.com/assets/plancreate/tw-retweet.svg"
                                    ></img>{" "}
                                  </div>
                                  <div className="share">
                                    <img
                                      className="bottom-img"
                                      src="https://i.hootsuite.com/assets/plancreate/tw-like.svg"
                                    ></img>{" "}
                                  </div>
                                </div>
                              </div>
                            </div>
                          ) : null
                        ) : (
                          <div className="previewBase-Layout">
                            <div className="previewBase-Layout-innerContainer">
                              <div className="profile-preview-container">
                                <div className="profile-preview-pic">
                                  <div className="profile-blank"></div>
                                </div>
                                <div className="profile-preview-name" width="60px">
                                  <div className="bottom-blank"></div>
                                </div>
                              </div>
                              <div className="content-preview-container">
                                {postContent.length !== 0 ||
                                  paths.length !== 0 ? (
                                  <NewPost
                                    postContent={pinDescription}
                                    paths={paths}
                                  />
                                ) : (
                                  <>
                                    <div className="content-preview"></div>
                                    <div className="content-preview2"></div>
                                  </>
                                )}
                              </div>
                              <div className="bottom-blank-container">
                                <div className="like">
                                  <div className="bottom-blank"></div>
                                </div>
                                <div className="comment">
                                  <div className="bottom-blank"></div>
                                </div>
                                <div className="share">
                                  <div className="bottom-blank"></div>
                                </div>
                              </div>
                            </div>
                          </div>
                        )} */}

                        <div className="previewBase-Layout">
                          <div className="previewBase-Layout-innerContainer">
                            <div className="profile-preview-container">
                              <div className="profile-preview-pic">
                                {/* <img
                                      src={}
                                      style={{
                                        clipPath: "circle(50% at 50% 50%)",
                                      }}
                                    /> */}
                              </div>
                              <div
                                className="profile-preview-name"
                                width="60px"
                              >
                                {selected.label}
                                <div
                                  style={{
                                    fontWeight: "100",
                                    fontSize: "12px",
                                  }}
                                >
                                  just Now
                                </div>
                              </div>
                            </div>
                            <div className="content-preview-container">
                              {postContent.length !== 0 ||
                              paths.length !== 0 ? (
                                <NewPost
                                  postContent={postContent}
                                  paths={paths?.facebook}
                                />
                              ) : (
                                <>
                                  <div className="content-preview"></div>
                                  <div className="content-preview2"></div>
                                </>
                              )}
                            </div>
                            <div className="bottom-preview-container">
                              <div className="like">
                                <img
                                  className="bottom-img"
                                  src="https://i.hootsuite.com/assets/plancreate/fb-like.svg"
                                ></img>{" "}
                                Like
                              </div>
                              <div className="comment">
                                <img
                                  className="bottom-img"
                                  src="https://i.hootsuite.com/assets/plancreate/fb-comment.svg"
                                ></img>{" "}
                                Comment
                              </div>
                              <div className="share">
                                <img
                                  className="bottom-img"
                                  src="https://i.hootsuite.com/assets/plancreate/fb-share.svg"
                                ></img>{" "}
                                Share
                              </div>
                            </div>
                          </div>
                        </div>

                        {/* ------------------------------------POST PREVIEW END------------------------------------------------- */}
                        <div
                          style={{
                            marginTop: "30px",
                            fontSize: "14px",
                            textAlign: "center",
                          }}
                        >
                          {postLink}
                          Social networks regularly make updates to formatting,
                          so your post may appear slightly different when
                          published. <a href="#">Learn more</a>
                        </div>
                      </div>
                    </>
                  )}

                  <Modal
                    open={isOpen}
                    onClose={() => setIsOpen(false)}
                    date={date}
                    setDate={setDate}
                    time={time}
                    setTime={setTime}
                    schedule={schedule}
                    setSchedule={setSchedule}
                  />
                </div>
              </div>
            </div>

            <div className="bottom-Container">
              <div className="scheduleBtn">
                <span className="scheduleBtn-container">
                  {date || time ? (
                    <>
                      {" "}
                      <button
                        onClick={() => setIsOpen(false)}
                        className="scheduleLaterBtn"
                        width="auto"
                        height="44"
                        type="button"
                        name="time"
                      >
                        {selectedDate}{" "}
                        <span
                          style={{ color: "red" }}
                          onClick={() => {
                            setDate(null);
                            setTime(null);
                            setIsOpen(false);
                          }}
                        >
                          <h3>x</h3>
                        </span>
                      </button>
                    </>
                  ) : (
                    <button
                      onClick={() => setIsOpen(true)}
                      className="scheduleLaterBtn"
                      width="auto"
                      height="44"
                      type="button"
                    >
                      Schedule Now
                    </button>
                  )}
                </span>
              </div>

              <div className="postNowBtn-container" type="cta">
                {date || time ? (
                  <>
                    <button
                      className="postNowBtn"
                      width="auto"
                      height="44"
                      cursor="pointer"
                      type="button"
                      onClick={handleSchedulePost}
                    >
                      Schedule Now
                    </button>
                    <div className="postNowBtn-arrow-container">
                      <div style={{ position: "relative" }}>
                        <button
                          className="postNowBtn-arrow"
                          aria-label="more"
                          width="auto"
                          height="44"
                          type="button"
                          onClick={() => {
                            scheduleOptions === false
                              ? setScheduleOptions(true)
                              : setScheduleOptions(false);
                          }}
                        >
                          <svg
                            width="16"
                            height="16"
                            viewBox="0 0 20 20"
                            fill="#143059"
                          >
                            <polygon points="10 14.25 3.25 7.5 5 5.75 10 10.75 15 5.75 16.75 7.5"></polygon>
                          </svg>
                        </button>
                      </div>
                    </div>
                  </>
                ) : (
                  <>
                    {" "}
                    <button
                      className="postNowBtn"
                      width="auto"
                      height="44"
                      cursor="pointer"
                      type="button"
                      onClick={handlePost}
                    >
                      Post now
                    </button>
                  </>
                )}
              </div>
            </div>
          </div>
        </div>
      ) : (
        <div onClick={() => setMinimized(false)} className="fixed">
          {" "}
          <div className="vk-MinimizedComposerModal sc-fJgHZD kaynPP">
            <div className="vk-MaximizeComposerHeader sc-jwUKeQ jqSPTz">
              <p className="sc-jgpVhK hNkawu">New Post</p>
              <button
                className="sc-jIjYVd iGGRsv"
                width="auto"
                height="28"
                type="button"
              >
                <svg width="16" height="16" viewBox="0 0 20 20" fill="#143059">
                  <path d="M17.5,1.25 L7.5,1.25 L7.5,3.75 L14.48,3.75 L1.61,16.61 L3.38,18.38 L16.25,5.51 L16.25,12.5 L18.75,12.5 L18.75,2.5 C18.75,1.81 18.19,1.25 17.5,1.25 Z"></path>
                </svg>
              </button>
            </div>
            <div className="sc-hcSvYt cuXuNS">
              <div className="sc-gjcKCj fZAwwt">{postContent}</div>
            </div>
          </div>
        </div>
      )}
    </>
  );
}

export default CreatePin;
