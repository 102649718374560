import React, { useEffect, useState } from "react";
import Flatpickr from "react-flatpickr";
import CustomDropDown from "../CustomDropDown/CustomDropDown";

import ga4img from "../../assets/ga4.png";
import fbiconanalytics from "../../assets/facebookLogo.png";
import instaicon from "../../assets/icons8-instagram-48.png";
import axios from "axios";
import { toast } from "react-toastify";
import { Card, Select } from "@material-ui/core";
import "./styles.css";
import ReactWordcloud from "react-wordcloud";
import ReactionChart from "../../Pages/Dashboard/DeepAnalytics/ReacttionChart";
import { json } from "react-router";
import { BsFacebook } from "react-icons/bs";
import { Skeleton } from "@mui/material";

const AnaPage = ({ vendor, startDate, endDate }) => {
  let userID = JSON.parse(localStorage.getItem("userProfileInfo"))?._id;
  const [userId, setUserId] = useState(userID);
  const [customDate, setCustomDate] = useState(null);
  const [NumberOFDaysOnCustomDate, setNumberOFDaysOnCustomDate] = useState(1);
  const [isCustomDate, setIsCustomDate] = useState(false);
  const [orginations, setOrginations] = useState(null);

  const [selectedOrgination, setSelectedOrgination] = useState(null);

  const [IsPageSelected, setIsPageSelected] = useState(false);

  const [anaData, setAnaData] = useState({});

  const [selectedTimeFrame, setSelectedTimeFrame] = useState("day");

  const handleChangeTimeFrame = (data) => {
    setSelectedTimeFrame(data);
    if (data === "day") {
      setNumberOFDaysOnCustomDate(1);
    } else if (data === "week") {
      setNumberOFDaysOnCustomDate(7);
    } else if (data === "month") {
      setNumberOFDaysOnCustomDate(30);
    } else if (data === "custom") {
      setNumberOFDaysOnCustomDate(1);
    }
  };

  const [TopData, setTopData] = useState({
    "facebook": {
      "FaceBookPage_impressions": 0,
      "FaceBookPage_total_actions": 0,
      "FaceBookPage_views": 0
    },
    "Instagram": {
      "InstaImpressions": 0,
      "InstaReach": 0,
      "InstaProfile_views": 0
    }
  });

  const [TopDataLoading, setTopDataLoading] = useState(false)

 async function GetTopData() {


setTopDataLoading(true)
  const TopData = await axios.post(`${process.env.REACT_APP_SERVER_URI}/api/toowe/users/${userId}/crud/GetTopDataOfVendor`, {
    "InstaPageId": vendor?.vendorPages?.filter((item) => item.account_type === "instagram")?.[0]?.pageId,
    "FaceBookPageId": vendor?.vendorPages?.filter((item) => item.account_type === "facebook")?.[0]?.pageId,
    "GoogleAnalyticsId": vendor?.vendorPages?.filter((item) => item.account_type === "googleAnalytics")?.[0]?.pageId,
    "startDate": startDate.pindate,
    "endDate": endDate.pindate
  },{
    headers: {
      token: JSON.parse(localStorage.getItem("userProfileInfo"))?.token,
      userId: JSON.parse(localStorage.getItem("userProfileInfo"))?._id,
    }
  }
  )

  setTopData(TopData.data)
  setTopDataLoading(false)


}





  useEffect(() => {
    // Change the number of days on custom date
    // if (customDate && customDate.length === 2) {
    const customDat = new Date(customDate);
    const currentDate = new Date();
    const diffTime = Math.abs(customDat - currentDate);
    const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
    console.log(diffDays); // Output: 731
   customDate && setNumberOFDaysOnCustomDate(diffDays);
    // }

    console.log("customDate", customDate);
    console.log("NumberOFDaysOnCustomDate", NumberOFDaysOnCustomDate);
  }, [customDate]);

  const options = {
    rotations: 2,
    rotationAngles: [-90, 0],
    fontFamily: "Arial",
    fontSizes: [20, 60], // Set the range of font sizes for the words
  };
  const size = [600, 400];

  const words = [];

  const [wordCloudWords, setWordCloudWords] = useState(words);
  const [reactionData, setReactionData] = useState([]);
  const [sumupReactionData, setSumupReactionData] = useState([]);
  const [headData, setHeadData] = useState([]);
  const wordcloudData = async () => {

    setIsWordCloludDataLoading(true)
    await axios
      .post(
        `${process.env.REACT_APP_SERVER_URI}/api/toowe/users/${userId}/deepAnalytics/getFacebookWordCloudData`,
        {
          pageId: pageData?.pageId,
          startDate: startDate,
          endDate: endDate,
          jwToken: sessionStorage?.getItem("token"),
        }
      )
      .then((res) => {
        console.log(res.data);
        setWordCloudWords(res.data.allKeywordsWithFrequency);
        setIsWordCloludData(true);
        setIsWordCloludDataLoading(false)
        return res.data;
      })
      .catch((err) => {
        console.log(err);
        setIsWordCloludDataLoading(false)
      });
  };

  const getThreeTimesData = async () => {
    vendor &&
      axios
        .post(`${process.env.REACT_APP_SERVER_URI}/api/toowe/users/${userId}/crud/GetHistoryData`, {
          ids:  vendor?.vendorPages?.filter((item) => item.account_type === "googleAnalytics")?.map((item) => item.pageId),
         // vendorID: vendor._id,
          // date: customDate,
         // timeFrame: selectedTimeFrame,
        //  days: NumberOFDaysOnCustomDate,
        startDate: startDate.pindate,
        endDate: endDate.pindate
        },{
          headers: {
            token: JSON.parse(localStorage.getItem("userProfileInfo"))?.token,
            userId: JSON.parse(localStorage.getItem("userProfileInfo"))?._id,
          }
        })
        .then((res) => {
          setAnaData(res.data);
    
        })
        .catch((err) => {
          console.log(err + "===========================================((((((((((((((((((((((((9999999999999999999999");
          // setAnaData({});
        });
  };

  const sumUpValues = (data) => {
    const result = data.reduce((acc, obj) => {
      for (const key in obj) {
        if (key !== "dateData") {
          acc[key] = (acc[key] || 0) + obj[key];
        }
      }
      return acc;
    }, {});

    return result;
  };
  const reactionChartData = async () => {
    setIsReactionDataLoading(true)
    await axios
      .post(
        process.env.REACT_APP_SERVER_URI +
          `/api/toowe/users/${userId}/deepAnalytics/facebookReactionData`,
        {
          pageId: pageData?.pageId,
          startDate: startDate.date,
          endDate: endDate.date,
          jwToken: sessionStorage?.getItem("token"),
        }
      )
      .then((res) => {
        console.log(res.data);
        setReactionData(res.data);
        setIsReactionDataLoading(false)
        setSumupReactionData(sumUpValues(res.data));
        return res.data;
      })
      .catch((err) => {
        console.log(err);
        setIsReactionDataLoading(false)
      });
  };

  const GetheadData = () => {


    setIsHeasDataLoading(true)

    axios
      .post(
        process.env.REACT_APP_SERVER_URI +
          `/api/toowe/users/${userId}/deepAnalytics/facebookotherAnalyticsData`,
        {
          pageId: pageData?.pageId,
          startDate: startDate.date,
          endDate: endDate.date,
          jwToken: sessionStorage?.getItem("token"),
        }
      )
      .then((res) => {
        console.log(res.data);
        setHeadData(res.data.DataToSend);
        setIsHeasDataLoading(false)
        // setImpressionChartData(res.data.GraphData)
        return res.data;
      })
      .catch((err) => {
        console.log(err);
        isHeasDataLoading(false)
      });
  };

  useEffect(() => {
    axios
      .post(`${process.env.REACT_APP_SERVER_URI}/api/toowe/users/${userId}/vendors/getVendors`, {
        vendorUserId: JSON.parse(localStorage.getItem("userProfileInfo"))._id,
        jwToken: JSON.parse(localStorage.getItem("userProfileInfo")).token,
      })
      .then((res) => {
        console.log(res.data);
        setOrginations(res.data);
      })
      .catch((err) => {
        console.log(err);
        toast.error("Error while fetching organizations");
      });

    // Fetch data from the API
  }, []);

  const [keysGA4, setKeysGA4] = useState([]);
  const [keysFacebook, setKeysFacebook] = useState([]);
  const [keysInstagram, setKeysInstagram] = useState([]);

  useEffect(() => {
 
    getThreeTimesData();

    GetTopData();

  }, [startDate,endDate, vendor, selectedTimeFrame, NumberOFDaysOnCustomDate]);

  const handleToggelPicker = () => {
    setIsCustomDate(!isCustomDate);
  };

  const handleCustomDate = (data) => {
    setCustomDate(data);
  };

  console.log(vendor?.vendorPages);
  const [pageData, setPageData] = useState(
    vendor?.vendorPages?.filter((item) => item.account_type === "facebook")?.[0]
  );

  const [SelectedPage, setSelectedPage] = useState("Select Page");
  const [networkPages, setNetworkPages] = useState([]);
  useEffect(() => {
    const Networkpages = [];
    vendor.vendorPages?.map((item) => {
      if (item.account_type === "facebook") {
        Networkpages.push(item.name);
      }
    });
    setSelectedPage(Networkpages[0]);
    console.log(vendor.vendorPages);
    setNetworkPages(Networkpages);
  }, [vendor.vendorPages]);

  useEffect(() => {
    console.log(vendor.vendorPages);
    setPageData(
      vendor?.vendorPages?.filter((item) => item.name === SelectedPage && item.account_type === "facebook")?.[0]
    );
  }, [SelectedPage]);

  const [isHeadData, setIsHeadData] = useState(false);
  const [isWordCloludData, setIsWordCloludData] = useState(false);
  const [isReactionData, setIsReactionData] = useState(false);

  const [isHeasDataLoading, setIsHeasDataLoading] = useState(false);
  const [isWordCloludDataLoading, setIsWordCloludDataLoading] = useState(false);
  const [isReactionDataLoading, setIsReactionDataLoading] = useState(false);

  useEffect(() => {
    if (pageData?.pageId) {
      if (isHeadData) {
        GetheadData();
      }
      if (isWordCloludData) {
        wordcloudData();
      }
      if (isReactionData) {
        reactionChartData();
      }
    }

    // if (vendor._id) {
      getThreeTimesData();
    // }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [startDate, endDate, isHeadData, isWordCloludData, isReactionData, vendor._id]);
  const [totalAnaData, setTotalAnaData] = useState({});

  const handleChangeVisibility = (data) => {
    if (data === "headData") {
      setIsHeadData(!isHeadData);
    } else if (data === "wordCloludData") {
      setIsWordCloludData(!isWordCloludData);
    } else if (data === "reactionData") {
      setIsReactionData(!isReactionData);
    }
  };

  // useEffect(() => {
  //   anaData &&
  //     setTotalAnaData({
  //       GoogleAnalyticsData: {
  //         users:
  //           (anaData?.morning?.GoogleAnalyticsData[0]?.users || 0) +
  //           (anaData?.afternoon?.GoogleAnalyticsData[0]?.users || 0) +
  //           (anaData?.evening?.GoogleAnalyticsData[0]?.users || 0),
  //         newUsers:
  //           (anaData?.morning?.GoogleAnalyticsData[0]?.newUsers || 0) +
  //           (anaData?.afternoon?.GoogleAnalyticsData[0]?.newUsers || 0) +
  //           (anaData?.evening?.GoogleAnalyticsData[0]?.newUsers || 0),
  //         sessions:
  //           (anaData?.morning?.GoogleAnalyticsData[0]?.sessions || 0) +
  //           (anaData?.afternoon?.GoogleAnalyticsData[0]?.sessions || 0) +
  //           (anaData?.evening?.GoogleAnalyticsData[0]?.sessions || 0),
  //         bounceRate:
  //           (anaData?.morning?.GoogleAnalyticsData[0]?.bounceRate || 0) +
  //           (anaData?.afternoon?.GoogleAnalyticsData[0]?.bounceRate || 0) +
  //           (anaData?.evening?.GoogleAnalyticsData[0]?.bounceRate || 0),
  //       },
  //       facebookData: {
  //         pageViews:
  //           (anaData?.morning?.facebookData[0]?.pageViews || 0) +
  //           (anaData?.afternoon?.facebookData[0]?.pageViews || 0) +
  //           (anaData?.evening?.facebookData[0]?.pageViews || 0),
  //         // pageLikes: (anaData?.morning?.facebookData[0]?.pageLikes || 0) + (anaData?.afternoon?.facebookData[0]?.pageLikes || 0) + (anaData?.evening?.facebookData[0]?.pageLikes || 0),
  //         pageFollowers:
  //           (anaData?.morning?.facebookData[0]?.pageFollowers || 0) +
  //           (anaData?.afternoon?.facebookData[0]?.pageFollowers || 0) +
  //           (anaData?.evening?.facebookData[0]?.pageFollowers || 0),
  //         pageEngagement:
  //           (anaData?.morning?.facebookData[0]?.pageEngagement || 0) +
  //           (anaData?.afternoon?.facebookData[0]?.pageEngagement || 0) +
  //           (anaData?.evening?.facebookData[0]?.pageEngagement || 0),
  //       },
  //       instagramData: {
  //         pageViews:
  //           (anaData?.morning?.instagramData[0]?.pageViews || 0) +
  //           (anaData?.afternoon?.instagramData[0]?.pageViews || 0) +
  //           (anaData?.evening?.instagramData[0]?.pageViews || 0),
  //         // pageLikes: (anaData?.morning?.instagramData[0]?.pageLikes || 0) + (anaData?.afternoon?.instagramData[0]?.pageLikes || 0) + (anaData?.evening?.instagramData[0]?.pageLikes || 0),
  //         pageFollowers:
  //           (anaData?.morning?.instagramData[0]?.pageFollowers || 0) +
  //           (anaData?.afternoon?.instagramData[0]?.pageFollowers || 0) +
  //           (anaData?.evening?.instagramData[0]?.pageFollowers || 0),
  //         pageEngagement:
  //           (anaData?.morning?.instagramData[0]?.pageEngagement || 0) +
  //           (anaData?.afternoon?.instagramData[0]?.pageEngagement || 0) +
  //           (anaData?.evening?.instagramData[0]?.pageEngagement || 0),
  //       },
  //     });
  //     // GetTopData();

  // }, [anaData]);

  return (
    <div>
      <div style={{ display: "flex" }}  className="mb-3">
        <div style={{ width: "30vw" }}>
          <div> <img src={ga4img} className="addsocial_icon_"/>  GA4 Analytics</div>
          {

            TopDataLoading ? (
              <div       style={{
                display: "flex",
                width: "100%",
                height: "auto",
                marginBottom: "10px",
                flexWrap: "wrap",
              }}>

                  <div style={{margin:'5px'}}>
             <Skeleton width={"8vw" } height={"8vw" }  />
              </div>
                <div style={{margin:'5px'}}>
             <Skeleton width={"8vw" } height={"8vw" }  />
              </div>
                <div style={{margin:'5px'}}>
             <Skeleton width={"8vw" } height={"8vw" }  />
              </div>

              </div>
            ) :   TopData?.googleAnalytics ? ( <div
              style={{
                display: "flex",
                width: "100%",
                height: "auto",
                marginBottom: "10px",
                flexWrap: "wrap",
              }}
            >

          
    <div className="tiles__" style={{ width: "8vw" }}>
                <p>Active users</p>
                <h2 className="val_ues">
                  {TopData?.googleAnalytics?.activeUsers}
                </h2>
              </div>
       
          
              
             

              <div className="tiles__" style={{ width: "8vw" }}>
                <p>New Users</p>
                <h2 className="val_ues">
                  {TopData?.googleAnalytics?.newUsers}
                </h2>
              </div>

              <div className="tiles__" style={{ width: "8vw" }}>
                <p>Events</p>
                <h2 className="val_ues">
                  {TopData?.googleAnalytics?.eventCount}
                </h2>
              </div>
            </div>)
            : (
              <div>
                Connect Google
              </div>
            )
          }
        </div>
        <div style={{ width: "30vw" }}>
          <div><img src={fbiconanalytics} className="addsocial_icon_"/> Facebook Analytics</div>
          

             {

            TopDataLoading ? (
              <div       style={{
                display: "flex",
                width: "100%",
                height: "auto",
                marginBottom: "10px",
                flexWrap: "wrap",
              }}>

                  <div style={{margin:'5px'}}>
             <Skeleton width={"8vw" } height={"8vw" }  />
              </div>
                <div style={{margin:'5px'}}>
             <Skeleton width={"8vw" } height={"8vw" }  />
              </div>
                <div style={{margin:'5px'}}>
             <Skeleton width={"8vw" } height={"8vw" }  />
              </div>

              </div>
            ) :TopData?.facebook ? ( 
            <div
              style={{
                display: "flex",
                width: "100%",
                height: "auto",
                marginBottom: "10px",
                flexWrap: "wrap",
              }}
            >
              <div className="tiles__" style={{ width: "8vw" }}>
                <p>Impressions</p>
                <h2 className="val_ues">
                  {TopData?.facebook?.FaceBookPage_impressions}
                </h2>
              </div>

              <div className="tiles__" style={{ width: "8vw" }}>
                <p>Total actions</p>
                <h2 className="val_ues">
                  {TopData?.facebook?.FaceBookPage_total_actions}
                </h2>
              </div>

              <div className="tiles__" style={{ width: "8vw" }}>
                <p>Page views</p>
                <h2 className="val_ues">
                  {TopData?.facebook?.FaceBookPage_views }
                </h2>
              </div>
            </div>)
            : (
              <div>
                Connect Facebook
              </div>
            )
          }
        </div>

        <div style={{ width: "30vw" }}>
          <div><img src={instaicon} className="addsocial_icon_"/> Instagram Analytics</div>
           {

            TopDataLoading ? (
              <div       style={{
                display: "flex",
                width: "100%",
                height: "auto",
                marginBottom: "10px",
                flexWrap: "wrap",
              }}>

                  <div style={{margin:'5px'}}>
             <Skeleton width={"8vw" } height={"8vw" }  />
              </div>
                <div style={{margin:'5px'}}>
             <Skeleton width={"8vw" } height={"8vw" }  />
              </div>
                <div style={{margin:'5px'}}>
             <Skeleton width={"8vw" } height={"8vw" }  />
              </div>

              </div>
            ) :  TopData?.instagram ? (
            <div
              style={{
                display: "flex",
                width: "100%",
                height: "auto",
                marginBottom: "10px",
                flexWrap: "wrap",
              }}
            >
              <div className="tiles__" style={{ width: "8vw" }}>
                <p>Page Impressions</p>
                <h2 className="val_ues">
                  {TopData?.instagram?.InstaImpressions}
                </h2>
              </div>

              <div className="tiles__" style={{ width: "8vw" }}>
                <p>Page Reach</p>
                <h2 className="val_ues">
                  {TopData?.instagram?.InstaReach}
                </h2>
              </div>

              <div className="tiles__" style={{ width: "8vw" }}>
                <p>Profile views</p>
                <h2 className="val_ues">
                  {TopData?.instagram?.InstaProfile_views}
                </h2>
              </div>
            </div>
          ) : (
            <div>
              Connect Instagram
            </div>
          )
          }
        </div>
      </div>

      {/* <div style={{display:'flex',width:'100%', height:'auto', marginBottom:'10px', flexWrap:'wrap'}}>

    {
  headData?.map((item)=>{
    return(
      
      <div className="tiles__" >
      <p>{item.name}</p>
      <h2 className="val_ues">{item.value}</h2>
      </div> 
    )
  }
  )
}  </div> */}

 <div style={{ display: "flex" }}>
        <div style={{ width: "43vw", marginRight:"30px" }}>
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              padding: "10px 10px 0",
            }}
          >
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              {
                // JSON.stringify(anaData)
              }
              <div className="mediaFilterBlock"><p>Compare Traffic</p></div>
              
            </div>

            {/* <div style={{ display: "flex" }}>
              <button className="compare_btn__"
                 
                onClick={() => {
                  handleChangeTimeFrame("day");
                }}
              >
                Day
              </button>
              <button  className="compare_btn__"
                
                onClick={() => {
                  handleChangeTimeFrame("week");
                }}
                            >
                              Week
                            </button>
                            <button  className="compare_btn__"
                              
                              onClick={() => {
                                handleChangeTimeFrame("month");
                              }}
                            >
                              Month
                            </button>
                            <button className="compare_btn__" 
                              onClick={() => {
                                handleChangeTimeFrame("custom");
                                // handleToggelPicker();
                              }}
                            >
                              Custom
                            </button>
                            {selectedTimeFrame === "custom" && (
                              <div className="date_cust_btn_">
                                <Flatpickr
                                  // data-enable-time
                                  // set default value to 10 days before current date
                                  defaultDate={new Date().fp_incr(-10)}
                                  //  value={new Date().fp_incr(-10)}
                                  max={new Date()}
                                  // value={}
                                  options={{
                                    maxDate: new Date(),
                                  }}
                                  value={customDate}
                                  onChange={(selectedDates) => setCustomDate(selectedDates)}
                                />
                              </div>
                            )}

                            {NumberOFDaysOnCustomDate}

                            {selectedTimeFrame === "custom" && (
                              <div className="Days_lbl_wrp">
                                {NumberOFDaysOnCustomDate} Days
                              </div>
                            )}
                          </div> */}
                        </div>
                        {/* {JSON.stringify(anaData)} */}
                        {/* {JSON.stringify(keysGA4)}
                        {JSON.stringify(keysFacebook)}
                        {JSON} */}
                       

              {anaData.morning || anaData.afternoon || anaData.night ? (
                            <div className="table-container">
                              <p className="instructionstext_">The "Compare Traffic" option allows you to assess your traffic based on your recent activities. It records Meta-related values over a 24-hour period. Be aware that these values may fluctuate. </p>
                              <table className="tbl_comparision">
                                <thead>
                                  <tr>
                                    <th></th>
                                    <th>Sunrise</th>
                                    <th>Daylight</th>
                                    <th>Twilight</th>
                                  </tr>
                                </thead>
                                <tbody>
                                  <tr>
                                    <td colSpan="4">
                                      <h4 className="compare_headings__">Google Analytics</h4>
                                    </td>
                                    {/* <td>{anaData?.morning.GoogleAnalyticsData[0].users}</td>
                          <td>{anaData?.afternoon.GoogleAnalyticsData[0].users}</td>
                          <td>{anaData?.evening.GoogleAnalyticsData[0].users}</td> */}
                                  </tr>

                                  {
                                    anaData?.morning && Object.keys(anaData?.morning).map((key, index) => (
                                      <tr key={index}>
                                        <td style={{ display: 'flex', }} >{key}</td>
                                        <td>
                                          {anaData?.morning && Math.floor(anaData.morning[key])}
                                        </td>
                                        <td>
                                          {anaData?.afternoon && Math.floor(anaData.afternoon[key])}
                                        </td>
                                        <td>
                                          {anaData?.night && Math.floor(anaData.night[key])}
                                        </td>
                                      </tr>
                                    ))
                                  }
                                 
                                  {/* {keysGA4 && keysGA4.map((key, index) => (
                                    <tr key={index}>
                                      <td style={{display:'flex', }} >{key}</td>
                                      <td>
                                        {anaData?.morning && Math.floor(anaData.morning.keys())}
                                      </td>
                                      <td>
                                        {anaData?.afternoon && Math.floor(anaData.afternoon.keys())}
                                      </td>
                                      <td>
                                        {anaData?.evening && Math.floor(anaData.evening.keys())}
                                      </td>
                                    </tr>
                                  ))} */}
                                  <tr>
                                    {/* <td colSpan="4">
                                      <h4 className="compare_headings__">Facebook</h4>
                                    </td> */}
                                  </tr>

                                  {/* {keysFacebook && keysFacebook.map((key, index) => (
                                    <tr key={index}>
                                      <td>{key}</td>
                                      <td>
                                        {anaData?.morning?.facebookData[0]?.[key] && Math.floor(anaData.morning.facebookData[0][key])}
                                      </td>
                                      <td>
                                        {anaData?.afternoon?.facebookData[0]?.[key] && Math.floor(anaData.afternoon.facebookData[0][key])}
                                      </td>
                                      <td>
                                        {anaData?.evening?.facebookData[0]?.[key] && Math.floor(anaData.evening.facebookData[0][key])}
                                      </td>
                                    </tr>
                                  ))} */}
                                  <tr>
                                    {/* <td colSpan="4">
                                      <h4 className="compare_headings__">Instagram</h4>
                                    </td> */}
                                  </tr>

                                  {/* {keysInstagram && keysInstagram.map((key, index) => (
                      <tr key={index}>
                        <td>{key}</td>
          <td>{anaData?.morning?.instagramData[0]?.[key] }</td>
          <td>{anaData?.afternoon?.instagramData[0]?.[key] }</td>
          <td>{anaData?.evening?.instagramData[0]?.[key] }</td>
        </tr>
      ))} */}
                  </tbody>
                </table>
              </div>
            ) : (

              <div className="click_notify_wrp" style={{height:'400px', paddingTop:'100px', paddingLeft:'10px', paddingRight:'10px'}}>
                {/* <p>Stay updated with your audience! <br/>Click here to view your Google Analytics, Facebook and Instagram insights, users, page views, page likes, page followers, page engagement and many more.</p> */}
                <h3 style={{ color:'#e4870e'}}>NO data available for the selected time frame</h3>
              </div>



            )}







            
        </div>

        <div style={{ width: "40vw" }}>
          <div className="mediaFilterBlock">
            <p><BsFacebook /> {SelectedPage}</p>
          </div>


{
  isHeasDataLoading ? (
    <Skeleton width={"100%"} height={"400px"}  />
  ) : headData && isHeadData ? (
    <>
      <div
        style={{
          display: "flex",
          width: "100%",
          height: "auto",
          marginBottom: "10px",
          flexWrap: "wrap",
        }}
      >
        {headData?.map((item) => {
          return (
            <div className="tiles__">
              <p>{item.name}</p>
              <h2 className="val_ues">{item.value}</h2>
            </div>
          );
        })}

        {/* <div style={{width:'25%', height:'auto', display:'flex', flexDirection:'column', justifyContent:'center', alignItems:'center', padding:'20px', border:'1px solid #d2d2d2'}}>

</div> 
*/}
      </div>
    </>
  ) : (
    <div className="click_notify_wrp"
      onClick={() => handleChangeVisibility("headData")} 
    >
      <p>Stay updated with your audience! <br/>Click here to view your Facebook page insights, 
      Engaged Users, Page Impressions, Page Views Total, Negative Feedback, Paid Impressions and other engagement metrics.</p>
      <h2>Click to View</h2>
    </div>
  )
}

          {/* {} */}

          <div className="mediaFilterBlock" style={{ marginTop: "30px" }}>
            <p>Most Frequent Words</p>
          </div>

          {
            isWordCloludDataLoading ? (

              <Skeleton width={"100%"} height={"400px"}  />
            ) : wordCloudWords && isWordCloludData ? (
              <Card className="fbAnalyticsCard">
                <div 
                >
                  <ReactWordcloud
                    words={wordCloudWords}
                    options={options}
                    size={size}
                  />
  
                  {/* <ReactWordcloud words={wordCloudWords} options={options} size={size}  /> */}
                </div>
              </Card>
            ) : (
              <div className="click_notify_wrp" 
                onClick={() => handleChangeVisibility("wordCloludData")}
                 
              ><p>   Stay updated with your audience! <br/>Click here to view your Facebook page most frequent used words, these values are completely depends on your posts.</p>
              <h2>Click to View</h2> 
              </div>
            )
          }

          {/* {} */}

          <div className="mediaFilterBlock" style={{ marginTop: "30px" }}>
            <p>Reactions</p>
          </div>

          {
            isReactionDataLoading ? (
              <Skeleton width={"100%"} height={"400px"}  />
            ) : (
              reactionData && isReactionData ? (
                <>
                  <div className="emotions_wrp" 
                  >
                    <div className="tiles__">
                      <h1>👍</h1>
                      <h2 className="val_ues">{sumupReactionData.likeData}</h2>
                    </div>
    
                    <div className="tiles__">
                      <h1>🥰</h1>
                      <h2 className="val_ues">{sumupReactionData.loveData}</h2>
                    </div>
    
                    <div className="tiles__">
                      <h1>😲</h1>
                      <h2 className="val_ues">{sumupReactionData.wowData}</h2>
                    </div>
    
                    <div className="tiles__">
                      <h1>😡</h1>
                      <h2 className="val_ues">{sumupReactionData.angerData}</h2>
                    </div>
                  </div>
    
                  <Card
                    className="fbAnalyticsCard emothins_wrp"
                    style={{ marginTop: "20px", padding: "30px" }}
                  >
                    <ReactionChart data={reactionData} />
                  </Card>
                </>
              ) : (
                <div className="click_notify_wrp" 
                  onClick={() => handleChangeVisibility("reactionData")}
                  
                ><p> Stay updated with your audience! 
                <br/>Click here to view your post responses from your audience. Checkout thumbs, Love, Anger and many more </p>
                <h2>Click to View</h2>
                </div>
              )
            )
          }

          {/* {} */}
        </div>
      </div>
    </div>
  );
};

export default AnaPage;
