import React, { useState, useEffect, useRef } from "react";
import SmallGraphs from "./SmallGraphs";
import LineChart from "./LineChart";
import "../FbAnalytics/FbAnalytics.css";
import axios from "axios";
import customDropDown from "../../../../Components/CustomDropDown/CustomDropDown";
import {
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  ButtonDropdown,
} from "reactstrap";
import CustomDropDown from "../../../../Components/CustomDropDown/CustomDropDown";

const YoutubeAnalytics = (props) => {
  const [SelectedPage, setSelectedPage] = useState("Select Page");

  console.log("Youtube", props);
  const [pageData, setPageData] = useState(
    (props?.pagesDetails.pagesDetails?.filter(
      (item) => item.account_type === "youtube" && item.name === SelectedPage
    ))[0]
  );
  useEffect(() => {
    setPageData(
      (props?.pagesDetails.pagesDetails?.filter(
        (item) => item.account_type === "youtube" && item.name === SelectedPage
      ))[0]
    );
  }, []);

  useEffect(() => {
    setPageData(
      (props?.pagesDetails.pagesDetails?.filter(
        (item) => item.account_type === "youtube" && item.name === SelectedPage
      ))[0]
    );
  }, [SelectedPage]);

  const [GraphdropdownOpen, setGraphdropdownOpen] = useState(false);
  const [youData, setYouData] = useState([]);
  const [metric, setMetric] = useState([
    "views",
    "estimatedMinutesWatched",
    "averageViewDuration",
    "averageViewPercentage",
  ]);
  const [AllPages, setAllPages] = useState(props.pagesDetails);
  const [IsPageSelectedYoutube, setIsPageSelectedYoutube] = useState(false);
  const [networkPages, setNetworkPages] = useState([]);
  const matrics = [
    "views",
    "estimatedMinutesWatched",
    "averageViewDuration",
    "averageViewPercentage",
    "subscribersGained",
    "comments",
    "likes",
  ];

  const [AllData, setAllData] = useState([]);
  let userID = JSON.parse(localStorage.getItem("userProfileInfo"))?._id;
  const [userId, setUserId] = useState(userID);

  const getAllData = async () => {
    const response = await axios.post(
      `${process.env.REACT_APP_SERVER_URI}/api/toowe/users/${userId}/crud/YoutubeAllAnalytics`,
      {
        dimension: "day",
        startDate: new Date(props.startDate.date).toISOString().slice(0, 10),
        endDate: new Date(props.endDate.date).toISOString().slice(0, 10),

        jwToken: localStorage.getItem("token"),
        pageId: pageData?.pageId,
      },
      {
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          token: localStorage?.getItem("token"),
        },
      }
    );
    setAllData(response.data);
  };


  useEffect(() => {
  pageData?.pageId && getAllData();
  }
    , [pageData, props.startDate, props.endDate]);


  const [views, setViews] = useState([]);
  const [likes, setLikes] = useState([]);
  const getViews = async (pageData) => {
    const response = await axios.post(
      `${process.env.REACT_APP_SERVER_URI}/api/toowe/users/${userId}/crud/getAnalytics/views`,
      {
        dimension: "day",
        startDate: new Date(props.startDate.date).toISOString().slice(0, 10),
        endDate: new Date(props.endDate.date).toISOString().slice(0, 10),
        jwToken: localStorage.getItem("token"),
        pageId: pageData?.pageId,
      },
      {
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          token: localStorage.getItem("token"),
        },
      }
    );
    setViews(response.data);
  };

  const getLikes = async (pageData) => {
    const response = await axios.post(
      `${process.env.REACT_APP_SERVER_URI}/api/toowe/users/${userId}/crud/getAnalytics/likes`,
      {
        dimension: "day",
        startDate: new Date(props.startDate.date).toISOString().slice(0, 10),
        endDate: new Date(props.endDate.date).toISOString().slice(0, 10),
        jwToken: localStorage.getItem("token"),
        pageId: pageData?.pageId,
      },
      {
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          token: localStorage.getItem("token"),
        },
      }
    );
    setLikes(response.data);
  };
  const handleAdd = (e) => {
    // check if the value is already in the array
    if (metric.includes(e)) {
      // if it is, remove it
      setMetric(metric.filter((item) => item !== e));
    } else {
      // if it isn't, add it
      setMetric([...metric, e]);
    }
    // setSmallGraphs([...smallGraphs, e])
    console.log(metric);
  };

  useEffect(() => {
    const Networkpages = [];
    props.pagesDetails.pagesDetails?.map((item) => {
      if (item.account_type === "youtube") {
        Networkpages.push(item.name);
      }
    });
    setNetworkPages(Networkpages);
    setSelectedPage(Networkpages[0]);
    console.log("Network----------->", Networkpages);
  }, [props.pagesDetails]);

  useEffect(() => {
  pageData?.pageId &&  getViews(pageData);
  pageData?.pageId &&   getLikes(pageData);
  }, [pageData]);

  return (
    <div className="fbAnalyticBlock">
      <div className="mediaFilterBlock">
        <p>Youtube</p>
        <CustomDropDown
          isActive={IsPageSelectedYoutube}
          setIsActive={setIsPageSelectedYoutube}
          selected={SelectedPage}
          setIsSelected={setSelectedPage}
          list={networkPages}
          type="mediaFilter"
        />
      </div>

      {/*<div style={{ margin: '10px', display: 'flex', flexDirection: 'start' }}>*/}
      {/*    <ButtonDropdown toggle={() => { setGraphdropdownOpen(!GraphdropdownOpen) }}*/}
      {/*        isOpen={GraphdropdownOpen} >*/}
      {/*        <DropdownToggle caret color="transparent" style={{ boxShadow: '0 4px 24px 0 rgb(34 41 47 / 31%)' }}>*/}
      {/*            Add Graph*/}
      {/*        </DropdownToggle>*/}
      {/*        <DropdownMenu>*/}
      {/*            <DropdownItem header>Numeric Characters*/}
      {/*            </DropdownItem>*/}
      {/*            {matrics.map((filter) => {*/}
      {/*                return (*/}
      {/*                    <DropdownItem onClick={() => { handleAdd(filter) }}>{filter}</DropdownItem>*/}
      {/*                )*/}
      {/*            })*/}
      {/*            }*/}

      {/*        </DropdownMenu>*/}
      {/*    </ButtonDropdown>*/}
      {/*</div>*/}

      <div className="mediaKPIBlock">
        {
          // eslint-disable-next-line array-callback-return

          metric.map((element) => {
            return (
              <SmallGraphs
                metric={element}
                filters={matrics}
                startDate={props.startDate.date}
                endDate={props.endDate.date}
                selectedPageID={pageData}
              />
            );
          })
        }
      </div>

      <div className="mediaLineChart">
        <LineChart
          data1={views}
          startDate={props.startDate}
          endDate={props.endDate}
          catagories={views.catagories}
          data2={likes}
          selectedPageID={pageData}
        />
      </div>
    </div>
  );
};

export default YoutubeAnalytics;
