import { Card, CardBody, Modal, Button } from "reactstrap";
import React, { useState, useEffect } from "react";
import {GrFacebook} from 'react-icons/gr'
import "./Ads.css"
import axios from "axios";
import DataTable from 'react-data-table-component';
import { json, Navigate } from "react-router";
import NewCampaignModal from "../../Components/CreateCampaigns/CreateCampaigns";
import CreateFacebookAdsSets from "../../Components/CreateCampaigns/CreateAdsSets";
// import { Toast, ToastContainer } from "react-bootstrap";
import { ToastContainer} from 'react-toastify'
import { Switch } from "@mui/material";
import { useNavigate } from "react-router";

export default function Ads({userInfo}) {

const [connectedAdsAccounts, setConnectedAdsAccounts] = useState([])
const [facebookCampaigns, setFacebookCampaigns] = useState([])
const [selectedCampaign, setSelectedCampaign] = useState(null)
const [selectedAccount, setSelectedAccount] = useState(null)
const [adAccountIds, setAdAccountIds] = useState([])
const [isAdsSetsModalOpen, setIsAdsSetsModalOpen] = useState(false)
const navigate = useNavigate();

const [selectedCampaignObjective, setSelectedCampaignObjective] = useState(null)
let userID = JSON.parse(localStorage.getItem("userProfileInfo"))?._id;
const [userId, setUserId] = useState(userID);

    useEffect(() => {
        window.fbAsyncInit = () => {
          window.FB.init({
            appId: process.env.REACT_APP_FB_APP_ID,
            autoLogAppEvents: true,
            xfbml: true,
            version: "v15.0",
          });
        };
        (function (d, s, id) {
          var js,
            fjs = d.getElementsByTagName(s)[0];
          if (d.getElementById(id)) {
            return;
          }
          js = d.createElement(s);
          js.id = id;
          js.src = "https://connect.facebook.net/en_US/sdk.js";
          fjs.parentNode.insertBefore(js, fjs);
        })(document, "script", "facebook-jssdk");
      }, []);


      const onFacebookAdsLoginClick = () => {
        window.FB.login(
          function (response) {
            console.log("auth", response);
            let token = response.authResponse.accessToken;
            let id = response.authResponse.userID;
            if (response.authResponse) {
              console.log("Welcome!  Fetching your information.... ");
              window.FB.api("/me", function async(response) {
              
                async function saveAccountsVerified(){
                  await axios.get(`https://graph.facebook.com/v21.0/me?fields=adaccounts,name&access_token=${token}`).then((res)=>{
                    console.log(res.data)
                    if(res?.data?.adaccounts?.data){
                      //(res?.data?.adaccounts?.data).forEach((item)=>{
                    const AccountsData = {
                      accountType: "facebook",
                      user: userInfo._id,
                      token,
                      id,
                      accountId: res?.data?.adaccounts?.data,
                      jwToken: localStorage?.getItem("token"),
                    };
                    axios.post(process.env.REACT_APP_SERVER_URI + `/api/toowe/users/${userId}/ads/connect`, AccountsData).then((result)=>{
                      axios.post(process.env.REACT_APP_SERVER_URI + `/api/toowe/users/${userId}/ads/getconnectedadsaccounts`, { userId: userId,jwToken: localStorage?.getItem("token") }).then(res =>{
                        setConnectedAdsAccounts(res.data)
                    })  
                    })
                  }})
                
                }
        
                saveAccountsVerified();
              
                const PagesCredentials = {
                  token,
                  id,
                  user: userInfo._id,
                  jwToken: sessionStorage.getItem("token"),
                };
                console.log(response);
                
                                                                                                  
              });                                                                                               
            } else {
              console.log("User cancelled login or did not fully authorize.");     
            }
          },
          {
            scope:
              "ads_management,ads_read,read_insights",
          }                            
        );
      };

     
      useEffect(() => {
         axios.post(process.env.REACT_APP_SERVER_URI + `/api/toowe/users/${userId}/ads/getconnectedadsaccounts`, { userId: userId,jwToken: localStorage?.getItem("token") }).then(res =>{
              setConnectedAdsAccounts(res.data)
             
          })
        }, [])

        useEffect(() => {
          if (connectedAdsAccounts) {
            const ids = connectedAdsAccounts.map((item) => item.adAccountId);
            setAdAccountIds(ids);
          }
        }, [connectedAdsAccounts])

        useEffect(()=>{
          axios.post(process.env.REACT_APP_SERVER_URI + `/api/toowe/users/${userId}/ads/GetFacebookCampaigns`, { userId: userId, jwToken: localStorage?.getItem("token"), adid:selectedAccount }).then(res =>{
            console.log(res.data)

            setFacebookCampaigns(res.data)
               }
          )
        },[selectedAccount])


const getFacebookCampaingsFunction =()=>{
  axios.post(process.env.REACT_APP_SERVER_URI + `/api/toowe/users/${userId}/ads/GetFacebookCampaignInsights`, { userId: userId,jwToken: localStorage?.getItem("token"), campaignid:"", token:"EAAb9ifo1PGABO2uDZCitCehOkQAGPOzOzGZCkc97XgZC3KMEmJGZBVs5JAqexIrdk2uQVs3AiojcDFiLacRDJwPDp89bZAht2fr3tdaki0rVZBZC56WkZB961yDnXvR3QpawO3akEtoa4nZAMIgPhPfZBjUKwGclQHzB9GCUOUvpjX7Nk1Yb9lDaEp9pZAXvW0ZD" }).then(res =>{
})
    axios.post(process.env.REACT_APP_SERVER_URI + `/api/toowe/users/${userId}/ads/GetFacebookCampaigns`, { adid: selectedAccount, userId: userId, jwToken: localStorage?.getItem("token") }).then(res =>{
    console.log(res.data)



    
    setFacebookCampaigns(res.data)
  })
}

    
useEffect(() => {
  getFacebookCampaingsFunction()

}, [])

const handleChange = (e) =>{
  setSelectedAccount(e.target.value)
}


const [showCreateNewCampaingModal, setShowCreateNewCampaingModal] = useState(false);


const handleClose = () => setShowCreateNewCampaingModal(false);



const handleStatusChange = (status,campaign) => async(event) => {
  console.log(status)
  console.log(event.target.checked)
  let newStatus = event.target.checked ? 'ACTIVE' : 'PAUSED'
  console.log(newStatus)
  await axios.post(process.env.REACT_APP_SERVER_URI + `/api/toowe/users/${userId}/ads/pauseUnPausecampaign`, { adAccountId: selectedAccount, campaignid: campaign, status: newStatus, token:"EAAb9ifo1PGABO2uDZCitCehOkQAGPOzOzGZCkc97XgZC3KMEmJGZBVs5JAqexIrdk2uQVs3AiojcDFiLacRDJwPDp89bZAht2fr3tdaki0rVZBZC56WkZB961yDnXvR3QpawO3akEtoa4nZAMIgPhPfZBjUKwGclQHzB9GCUOUvpjX7Nk1Yb9lDaEp9pZAXvW0ZD", jwToken: localStorage?.getItem("token") }).then(res =>{
    console.log(res.data)
    getFacebookCampaingsFunction()
  })
}

    return (       
        <div>

<ToastContainer  style={{zIndex:"999999"}} />

          {
  isAdsSetsModalOpen && (
    <CreateFacebookAdsSets  campaignObjective={selectedCampaignObjective}  setIsAdsSetsModalOpen={setIsAdsSetsModalOpen}   AdCampaign={ selectedCampaign} adAccountId={selectedAccount}/>
  )
}


                 <Card className="calenderModuleCard">
    



        <div className="AdsBodyContainer">


<div class="calender_top">
  <div class="margin-left"></div>
<div class="calender_info">
  <h5>Campaigns - META</h5>
  <span className="info_lbl__">Account ID: [{selectedAccount}]</span>
    </div>
    <div className="account_select_wrp">
      <select className="account_select" value={selectedAccount} onChange={handleChange}>
        <option value="">Select AD Account id</option>
        {adAccountIds && adAccountIds?.map((account) => (
        <option value={account}>{account}</option>))}
            </select>
    </div>
    </div> 

          {      
            connectedAdsAccounts?.length > 0 ? ( " " ) : ( 
<div className="connect_accounts_wrp">
<h2 className="header_content_hilights">Connect Meta Ad Account</h2>
<button className="AdsConnectButton_" onClick={onFacebookAdsLoginClick}>
  <GrFacebook/> Facebook                            
         
</button> 
  </div>
          )
          }                                       
                                      
      <NewCampaignModal getFacebookCampaingsFunction={getFacebookCampaingsFunction()} adId={selectedAccount} show={showCreateNewCampaingModal} onClose={handleClose} />                      
                                              
                                                                                                                                                                                                                         
    <div className="connect_button_wrp">
      <button className="createNewcamp" onClick={()=>setShowCreateNewCampaingModal(true)}>Create New Campaign</button> 
       <button className="adinsights__wrp" onClick={()=>navigate("/adinsights")}>Ad Insights</button>
      
      </div>                                                                                          
                
<div className="wrp_grid_fb">  
{                            
  facebookCampaigns && (    
<table className="grid__">          
  <thead>
    <tr>
      <th>Campaign Name</th>
      <th>id</th>     
      <th>Objective</th>    
      <th>Status</th> 
    </tr>
  </thead>
  <tbody>

    {
      facebookCampaigns?.data?.map((campaign)=>{
        return(
          <tr>                                                        
            <td style={{cursor:"pointer"}}  onClick={()=>{setSelectedCampaign(campaign?.id);setSelectedCampaignObjective(campaign?.objective); setIsAdsSetsModalOpen(true)}}>{campaign?.name}</td>
            <td   onClick={()=>{setSelectedCampaign(campaign?.id); setIsAdsSetsModalOpen(true)}}  > {campaign?.id}</td>
            <td>{campaign?.objective}</td>
            <td><Switch checked={campaign.status == 'ACTIVE' ? true: false} onChange={handleStatusChange(campaign?.status, campaign?.id)}/>{campaign?.status}</td>
            {/* <td>{campaign?.status}</td> */}
          </tr>
        )
      }
      )
    }
  
      </tbody> 
      
</table> 

  )

}
</div>
  <div>
     </div>
      </div>
           
             
 
         </Card>
        </div>
    );
    }