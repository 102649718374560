import React, { useEffect, useState } from 'react';
import { Modal, Button, Form } from 'react-bootstrap';
import axios from 'axios';
import DatePicker from 'react-datepicker';
import "react-datepicker/dist/react-datepicker.css";
import { format } from 'date-fns';
import { toast } from 'react-toastify';

function NewCampaignModal(props) {
  const [campaignName, setCampaignName] = useState('');
  const [campaignObjective, setCampaignObjective] = useState('OUTCOME_AWARENESS');
  const [campaignBudget, setCampaignBudget] = useState('');
  const [budgetType, setBudgetType] = useState('daily_budget');
  const [startDateTime, setStartDateTime] = useState(null);
  const [endDateTime, setEndDateTime] = useState(null);
  let userID = JSON.parse(localStorage.getItem("userProfileInfo"))?._id;
  const [userId, setUserId] = useState(userID);
  const currentDateTime = new Date();

  const handleSubmit = async(event) => {
    event.preventDefault();
    // Call API or dispatch redux action to create the new campaign
    // Reset form and close modal

await axios.post(process.env.REACT_APP_SERVER_URI + `/api/toowe/users/${userId}/ads/CreateFacebookCampaign`, { adid: props.adId, name: campaignName, objective: campaignObjective, status: 'ACTIVE', jwToken: localStorage?.getItem("token"), token:"EAAb9ifo1PGABO2uDZCitCehOkQAGPOzOzGZCkc97XgZC3KMEmJGZBVs5JAqexIrdk2uQVs3AiojcDFiLacRDJwPDp89bZAht2fr3tdaki0rVZBZC56WkZB961yDnXvR3QpawO3akEtoa4nZAMIgPhPfZBjUKwGclQHzB9GCUOUvpjX7Nk1Yb9lDaEp9pZAXvW0ZD", budget:campaignBudget, type:budgetType }).then(res =>{
    console.log(res.data)

if(res.status === 200){
toast.success('Campaign Created Successfully')
props.getFacebookCampaingsFunction()
}else{
toast.error('Something went wrong')
}

props.onClose()
  }).then(()=>{
    props.getFacebookCampaingsFunction()
  })



    setBudgetType('');
    setCampaignName('');
    setCampaignObjective('');
    setCampaignBudget('');
    props.onClose();
  };

  const handleStartDateChange = (date) => {
    const formattedDate = format(date, "yyyy-MM-dd'T'HH:mm:ssXXX");
    console.log(formattedDate);

    setStartDateTime(date);
  };

  const handleEndDateChange = (date) => {
    const formattedDate = format(date, "yyyy-MM-dd'T'HH:mm:ssXXX");
    console.log(formattedDate);

    setEndDateTime(date);
  };

  useEffect(()=>{
    console.log(startDateTime)
    console.log(new Date())
  },[startDateTime])

  return (
    <Modal show={props.show} onHide={props.onClose}>
      <Modal.Header closeButton>
        <Modal.Title>Create New Campaign</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form onSubmit={handleSubmit}>
          <Form.Group>
            <Form.Label>Campaign Name</Form.Label>
            <Form.Control
              type="text"
              value={campaignName}
              onChange={(event) => setCampaignName(event.target.value)}
            />
          </Form.Group>
          <Form.Group>
            <Form.Label>Campaign Objective</Form.Label>
            <Form.Control
              as="select"
              value={campaignObjective}
              style={{height: '40px'}}
              onChange={(event) => setCampaignObjective(event.target.value)}
            >
              {/* <option value="">--Select--</option> */}
              {/* <option value="LINK_CLICKS">Link Clicks</option> */}
              {/* <option value="REACH">REACH</option> */}
              <option value="OUTCOME_AWARENESS">Brand Awareness</option>
              <option value="OUTCOME_ENGAGEMENT">Post Engagement</option>
              <option value="OUTCOME_APP_PROMOTION">App Promotion</option>
              {/* <option value="PAGE_LIKES">Page Likes</option> */}
              <option value="OUTCOME_TRAFFIC">Traffic</option>
              <option value="OUTCOME_LEADS">Leads</option>
              <option value="OUTCOME_SALES">Sales</option>
              {/* <option value="CONVERSIONS">Conversions</option> */}
            </Form.Control>         
          </Form.Group>
          {/* <Form.Group>
            <Form.Label>Daily Budget</Form.Label>
            <Form.Control
              type="text"
              value={campaignName}
              onChange={(event) => setCampaignName(event.target.value)}
            />
          </Form.Group> */}
                    <Form.Group>
            <Form.Label>Budget Type</Form.Label>
            <Form.Control
              as="select"
              value={budgetType}
              style={{height: '40px'}}
              onChange={(event) => setBudgetType(event.target.value)}>
              <option value="daily_budget">Daily Budget</option> 
               <option value="lifetime_budget">Lifetime Budget</option>
            </Form.Control>         
          </Form.Group>
         {budgetType==='lifetime_budget'? <Form.Group>
            <Form.Label>Start Date</Form.Label>
            <DatePicker
        selected={startDateTime}
        onChange={handleStartDateChange}
        showTimeSelect
        timeIntervals={15}
        dateFormat="Pp" 
        timeCaption="Time"
        placeholderText="Select start date and time"
        className="form-control"
        minDate={currentDateTime}
      />  
      </Form.Group>:null}
      {budgetType==='lifetime_budget'?<Form.Group>
            <Form.Label>End Date</Form.Label>
            <DatePicker
        selected={endDateTime}
        onChange={handleEndDateChange}
        showTimeSelect
        timeIntervals={15}  
        dateFormat="Pp"  
        timeCaption="Time"
        placeholderText="Select end date and time"
        className="form-control"
        minDate={startDateTime}
      />   
          </Form.Group>:null}
          <Form.Group>                                
            <Form.Label>Budget</Form.Label>
            <Form.Control
              type="number"
              value={campaignBudget}
              onChange={(event) => setCampaignBudget(event.target.value)}
            />
          </Form.Group>
        </Form>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="primary" onClick={handleSubmit}>
          Create Campaign
        </Button>
        <Button variant="secondary" onClick={props.onClose}>
          Cancel
        </Button>
      </Modal.Footer>
    </Modal>
  );
}

export default NewCampaignModal;
