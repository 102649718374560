import "./AdsLibrary.css";
import {
  Card,
  CardBody,
  ButtonDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  Button,
} from "reactstrap";
import { useEffect, useState } from "react";
import Select, { StylesConfig } from "react-select";
import { Global } from "@emotion/react";
import { Progress } from "reactstrap";
import millify from "millify";
import { MoonLoader ,BeatLoader} from "react-spinners";
import axios from "axios";
import { useSelector } from "react-redux";
import CreateBoard from "../Dashboard/CreateBoard";
import CreatePin from "../Dashboard/CreatePin";
import CreateNewPost from "../Dashboard/CreateNewPost";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import GaugeChart from "react-gauge-chart";
import { byIso } from "country-code-lookup";
import { json } from "react-router";
import VideoList from "../Dashboard/Analytics/GoogleVideos/VideoList";
import DataTable from "react-data-table-component";
import AsyncSelect from "react-select/async";
import adlib1 from"../../assets/ad_lib1.png";
import adlib2 from"../../assets/ad_lib2.png";
import adlib3 from"../../assets/ad_lib3.png";

export default function AdsLibrary({ pagesDetails }) {
  let userID = JSON.parse(localStorage.getItem("userProfileInfo"))?._id;
  const [userId, setUserId] = useState(userID);
  const [loader, setLoader] = useState(false);
  const [selectedForm, setSelected] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [loading, setLoading] = useState(true);
  const [videos, setVideos] = useState([]);
  const [currentVideoPage, setCurrentVideoPage] = useState(1);
  const [googleAds, setGoogleAds] = useState([]);
  const [itemsPerPage] = useState(10);
  const [isFreeAccount, setIsFreeAccount] = useState(false);
  const [selectedCountry, setSelectedCountry] = useState(null);
  const [adCategory, setAdCategory] = useState("All");
  const [options, setOptions] = useState([
    { value: "ALL", label: "All ads" },
    { value: "EMPLOYMENT_ADS", label: "Employment Ads" },
    { value: "FINANCIAL_PRODUCTS_AND_SERVICES_ADS", label: "Financial Products and Services Ads" },
    { value: "HOUSING_ADS", label: "Housing Ads" },
    { value: "POLITICAL_AND_ISSUE_ADS", label: "Issues, elections or politics" },
]);

  const colourStyles = {
    control: (styles) => ({
      ...styles,
      backgroundColor: "#f9f8f4",
      borderRadius: "0px",
      border: "1px solid #d5d3d3",
      height: "20px",
      textAlign: "left",
    }),
    input: (styles) => ({
      ...styles,
      marginTop: "-8px",
      paddingTop: "3px",
      //   display: "none",
    }),
    placeholder: (styles) => ({ ...styles }),
  };

  useEffect(() => {
    if (localStorage.getItem("userProfileInfo")) {
    
      let acc = JSON.parse(localStorage.getItem("userProfileInfo")).subscription;
      if (acc === "free") {
        setIsFreeAccount(true);
      }


    }
  }, []);



  const [data, setData] = useState(null);



  const getAds = async (hostname) => {
  
       await axios
          .post(`${process.env.REACT_APP_SERVER_URI}/api/toowe/users/${userId}/googleTrends/videos`, {
            q: domain.replace(/(http:\/\/|https:\/\/)/g, ''),
            jwToken: JSON.parse(localStorage.getItem("userProfileInfo")).token,
          })
          .then((response) => {
            setVideos(response.data);
          })
          .catch((error) => {
            console.log("Error fetching video data:", error);
          });
        axios
          .post(
            `${process.env.REACT_APP_SERVER_URI}/api/toowe/users/${userId}/googleTrends/transparency_center`,
            {
              q: domain.replace(/(http:\/\/|https:\/\/)/g, ''),
              jwToken: JSON.parse(localStorage.getItem("userProfileInfo")).token,
            }
          )
          .then((response) => {
            const { ad_creatives } = response.data;

            let onlyTenAds = ad_creatives.slice(0, 10);
            // Convert Unix timestamps to actual dates for each entry
            const convertedData = onlyTenAds.map((ad) => {
              const { first_shown, last_shown, image, target_domain } = ad;
              return {
                image,
                target_domain,
                first_shown: new Date(first_shown * 1000),
                last_shown: new Date(last_shown * 1000),
              };
            });
            if (convertedData.length > 0) {
              console.log(convertedData);
              setGoogleAds(convertedData);
              console.log(
                "Converted Data:%%%%%%%%%%%%%%%%%%%%%%%%%",
                googleAds
              );
            }
          })
          .catch((error) => {
            console.log("Error fetching google_ads_data:", error);
          });
    


  };



  const getData = async () => {
    setData(null)

    if ( isFreeAccount) {
      toast.error("Please upgrade to premium to use this feature");
      return;
    }



 else {
   
      try {
        setLoader(true);
       await getAds();

        const dataFromWeb = await axios
          .post(process.env.REACT_APP_SERVER_URI + `/api/toowe/users/${userId}/ads/adsLibrary`, {
            ad_type: adCategory,
            search_term:domain,
            ad_reached_countries:selectedCountry,
            jwtToken: JSON.parse(localStorage.getItem("userProfileInfo")).token,
          },{
            headers: {
              'token': JSON.parse(localStorage.getItem("userProfileInfo")).token,
              'userID': JSON.parse(localStorage.getItem("userProfileInfo"))._id,
            }})
          .then((res, err) => {
            if (err) {
              console.log(
                err );
              toast.error(err.message);
              setData("errs");
              setLoader(false);
              return;
            } else {
              getAds(domain);
              console.log("all ok");
              setData(res.data);
              setLoader(false);
              toast.success("You're all set to go 🚀");
              setLoading(false);
              //set cookie for 1 day
              // localStorage.setItem({"TimesUsedAnalysis": 1, "expires": new Date(Date.now() + 86400000)})
            }
          });

        if (dataFromWeb) {
          setLoader(false);
          setData(dataFromWeb.data);


          toast.success("You're all set to go 🚀");
          console.log(dataFromWeb);
        }
      } catch (err) {
        console.log(err);
        toast.error(err.response?.data?.message || "something went wrong");
        setLoader(false);
      }

      setLoader(false);


    }
  };

  const loadOptions = async (inputValue) => {
    if (!inputValue) return [];

    try {
      const response = await fetch(
        `https://restcountries.com/v3.1/name/${inputValue}`
      );
      const data = await response.json();

      return data.map((country) => ({
        value: country.cca2,
        label: country.name.common,
      }));
    } catch (error) {
      console.error("Error fetching countries:", error);
      return [];
    }
  };

  const handleChange = (selectedOption) => {
    setSelectedCountry(selectedOption);
    console.log("Selected country:", selectedOption);
  };

  const handleOption = (value) => {
    setAdCategory(value);
  };


  const [domain, setDomain] = useState("https://www.instagram.com/");

  const [NUmData, setNUmData] = useState([]);

  useEffect(() => {
    setNUmData([
      { name: "Global rank", value: millify(data?.Rank?.global_rank) },
      { name: "Country Rank", value: millify(data?.Rank?.country_rank) },
      { name: "Category Rank", value: millify(data?.Rank?.category_rank) },
      { name: "Speed Score", value: data?.pageSpeedScore },
      { name: "performance Score", value: data?.performanceScore },
      {
        name: "first Contentful Paint",
        value: Math.round(data?.firstContentfulPaint),
      },
      {
        name: "first Meaningful Paint",
        value: Math.round(data?.firstMeaningfulPaint),
      },
      { name: "Speed Index", value: Math.round(data?.speedIndex) },
      { name: "Time to Interactive", value: Math.round(data?.interactive) },
      {
        name: "Total Blocking Time",
        value: Math.round(data?.totalBlockingTime),
      },
      { name: "Max Potential FID", value: data?.maxPotentialFID },
      {
        name: "cumulative Layout Shift",
        value: Math.round(data?.cumulativeLayoutShift * 1000) / 1000,
      },
      { name: "Server Response Time", value: data?.serverResponseTime },
      { name: "Total Traffic", value: millify(data?.traffic?.total) },
      {
        name: "Bounce Rate",
        value: Math.round(data?.audience?.bounce_rate * 1000) / 1000 + "%",
      },
      {
        name: "Time on Site",
        value: Math.round(data?.audience?.time_on_site * 1000) / 1000 + "s",
      },
      { name: "Direct Traffic", value: millify(data?.traffic.direct) },
      {
        name: "Organic Traffic",
        value: Math.round(data?.traffic?.Organic * 1000) / 1000 + "%",
      },
      {
        name: "Paid Traffic",
        value: Math.round(data?.traffic?.ads * 1000) / 1000 + "%",
      },
      {
        name: "Referring Traffic",
        value: Math.round(data?.traffic?.referring * 1000) / 1000 + "%",
      },
    ]);
  }, [data]);






  const indexOfLastItem = currentVideoPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentItems = videos?.slice(indexOfFirstItem, indexOfLastItem);

  const paginate = (pageNumber) => setCurrentVideoPage(pageNumber);

  return (
    <>
      <Card className="calenderModuleCard">
        <ToastContainer />

        <CardBody className="calendarBody">
          {loader ? (
            <div className="Email_Loader">
              <MoonLoader color={"#ff9001"} loading={loader} size={150} />
            </div>
          ) : null}

          <div class="messageTopHeadText comp_anal">Meta Ads Library</div>

          <div className="InputContainer clearfix">
            <div className="TextForWebURL">
            <div className="TextForWebURLTitle">
  <div className="main-title">Search Ads</div>
  <div className="subtitle">Set your location and choose an ad category to start your search.</div>
</div>

              <div className="TextForWebURLInput">
                <div className="input-container">
                <AsyncSelect
                cacheOptions
                loadOptions={loadOptions}
                onChange={handleChange}
                className="messageSelect"
                placeholder="Type to search a country..."
                isClearable
              /></div>
                <div className="input-container">
                <Select
              options={options}
              styles={colourStyles}
              defaultValue={options[0]}
              className="messageSelect"
              onChange={(e) => {
                handleOption(e.value);
              }}
            /></div>
                <div className="input-container">
                <input
                  type="text"
                  onChange={(e) => {
                    setDomain(e.target.value);
                  }}
                  placeholder="Enter Web URL"
                /></div>
                <button className="TextForWebURLButton" onClick={getData}>
                  SEARCH
                </button>
              </div>
            </div>
          </div>
<div className="row">
<div className="col-lg-12"><p>8 Results Found</p>
<h6>Launched in January 2025</h6>
</div>
  <div className="col-lg-3">
      <img src={adlib1} alt="Meta Ads" width="100%"/>

  </div>
  <div className="col-lg-3">
      <img src={adlib2} alt="Meta Ads" width="100%"/></div>
  <div className="col-lg-3">
      <img src={adlib3} alt="Meta Ads" width="100%"/></div>
</div>
        </CardBody>
      </Card>
    </>
  );
}
