import axios from "axios";
import facbookLogo from "../../../assets/facebookLogo.png";
import pinterestLogo from "../../../assets/pinterestLogo.png";
import youtubeLogo from "../../../assets/youtubeLogo.png";
import instaLogo from "../../../assets/instaLogo.png";
import linkedinLogo from "../../../assets/linkedinLogo.svg";
import search from "../../../assets/search.png";
import { useEffect, useState } from "react";
import FacebookPosts from "./Post";

import { AiFillDelete } from "react-icons/ai";
import { toast } from "react-toastify";


import {Skeleton} from '@mui/material'
import 'react-loading-skeleton/dist/skeleton.css'




export default function StreamCard(props){

  const {stream, delete: deleteStream} = props;

  console.log("stream",stream);


    const [posts, setPosts] = useState([]);

    const [user, setUser] = useState(null)
    const [loading, setLoading] = useState(false);
    let userID = JSON.parse(localStorage.getItem("userProfileInfo"))?._id;
    const [userId, setUserId] = useState(userID);


    const InfoObject = {
        facebook:{
            name:"facebook",
            icon:facbookLogo,
            api:"/api/crud/facebookStream",
            post:"/api/crud/facebookPost",
            schedule:"/api/crud/facebookSchedule",
            tagged:"/api/crud/facebookTagged",

        },
        instagram:{
            name:"instagram",
            icon:instaLogo,
            api:"/api/crud/instagramStream",
            post:"/api/crud/instagramPost",
            schedule:"/api/crud/instagramSchedule",
            tagged:"/api/crud/instagramTagged",
        },
        twitter:{
            name:"twitter",
            icon:facbookLogo,
            api:"/api/crud/twitterStream",
            post:"/api/crud/twitterPost",
            schedule:"/api/crud/twitterSchedule",
            tagged:"/api/crud/twitterTagged",
        },
        youtube:{
            name:"youtube",
            icon:youtubeLogo,
            api:"/api/crud/youtubeStream",
            post:"/api/crud/youtubePost",
            schedule:"/api/crud/youtubeSchedule",
            tagged:"/api/crud/youtubeTagged",
        },
        linkedin:{
            name:"linkedin",
            icon:linkedinLogo,
            api:"/api/crud/linkedinStream",
            post:"/api/crud jjgsjuyajuigy/linkedinPost",
            schedule:"/api/crud/linkedinSchedule",
            tagged:"/api/crud/linkedinTagged",
        },
        pinterest:{

            name:"pinterest",
            icon:pinterestLogo,
            api:"/api/crud/pinterestStream",
            post:"/api/crud/pinterestPost",
            schedule:"/api/crud/pinterestSchedule",
            tagged:"/api/crud/pinterestTagged",
        },
    }

    const [isError, setIsError] = useState(false);


    useEffect(()=>{
        console.log(stream);

        setLoading(true);

        axios.post(`${process.env.REACT_APP_SERVER_URI}/api/toowe/users/${userId}/` + stream?.NetworkName+'/'+stream?.StreamType,{
          pageId:stream?.pageId,
          name:stream?.AccountName,
        }, {
          headers: {
            token: JSON.parse(localStorage.getItem("userProfileInfo"))?.token,
            userId: JSON.parse(localStorage.getItem("userProfileInfo"))?._id,
          }
        }).then((res)=>{
            
          console.log(res.data);

        setIsError(false);

            setPosts(res.data[0]);
            setUser(res.data[1])
            setLoading(false);
        }).catch((err)=>{
            console.log(err);
            setIsError(true);
            setLoading(false);
        }
        )

    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[stream,stream?.NetworkName,stream?.StreamType])

// const handleDeleteStream = ()=>{
//
//     axios.post(`process.env.REACT_APP_SERVER_URI}/api/toowe/users/${userId}/Streams/deleteStream`,{
//         streamId:stream.stream._id,
//         jwToken:localStorage.getItem("token")
//     }).then((res)=>{
//
//         console.log(res.data);
//         toast.success("Stream Deleted Successfully");
//         window.location.reload();
//     }).catch((err)=>{
//         console.log(err);
//     })
//
// }


return(
    <>
  <div className={"mediaPostSection"} style={{marginInline:'2px'}}>

 
          <div className={"mediaPostSectionGap"}>
        <div className="streamPostSearchArea">
          <div className="postSearh">
            <div className="postSearh_inner_wrp" style={{ overflow: "hidden", maxHeight: "inherit" }}>
              <img className="postSearhMedia"style={{width:'40px',height:'auto'}} src={InfoObject[stream?.NetworkName]?.icon || 'https://upload.wikimedia.org/wikipedia/commons/7/7c/Profile_avatar_placeholder_large.png'} alt="Insta" />
              <div style={{marginTop:'0px'}}>
                {
                  user?.name || stream?.AccountName
                }
              </div>
              {/* {insearch ? (
                <>
                  {" "}
                  <input
                    type="text"
                    width={"50px"}
                    style={{ borderRadius: "0px", fontSize: "13px", border: "1px solid #dfdfdf", backgroundColor: "#fff" }}
                    placeholder={"search in posts"}
                    onInput={searching}
                    value={searchTerm}
                    className="PostSearchAreaInput"
                  />{" "}
                </>
              ) : (
                <>
                  {" "}
                  <span  className= "PostSearchlabel_wrp text-truncate"
                    style={{
                      fontSize:"16px",
                       
                      marginTop: "5px",
                      color: "#3b3b3b",
                    }}
                  >
                    {props.page.name} - {props.sub}{" "}
                  </span>{" "}
                </>
              )} */}
            </div>
            <div  className="icons_timeline"
              style={{ marginTop: "9px", marginLeft: "10px", display: "flex" }}
            >
              {/* <img
                src={search}
                title="Search"
                // onClick={() => setinsearch(insearch ? false : true)}
                style={{ width: "17px", height: "16px", cursor:"pointer" }}
              />
              <img
                src={refreshIcon}
                width={"14px"}
                height={"14px"}
                alt="refresh" 
                title="Refresh"
                onClick={handleRefresh}
                style={{ cursor:"pointer" }}
              /> */}
              <AiFillDelete   onClick={()=>{
                 deleteStream(stream._id);

              }} />
            </div>
          </div>
        </div>
{/*          {*/}
{/*              loading ? (*/}
{/*<Skeleton count={10} />*/}
{/*              ) : null*/}
{/*          }*/}



{
      isError ? (
       <p>
        Can't fetch posts please reconnect <a href="/link-account"> the account { stream?.AccountName }</a> or try again later
       </p>
      ) : (


  loading ? (
    <div className="skeletonPost">
  {
Array.from({ length: 5 }).map((_, index) => (
  <div style={{margin:'5px'}}>
  <Skeleton key={index} variant="rectangular" width={300} height={250} />

  </div>
))
}    </div>
  ) : (
    posts?.length >= 1 ? (
      posts.map((element, i) => {
        return (
          <>
            <FacebookPosts
              // setrefresh={setrefresh}
              // refresh={refresh}
              // id={element?.id}
              stream={stream}
              // searchTerm={searchTerm}
              accountType={stream?.NetworkName}
              user={user}
              post={element}

            />


            {/* <h3>post</h3> */}
          </>
        );
      })
    ) : (
      <div
        style={{
          textAlign: "middle",
          marginTop: "10px",
          width: "100%",
          height: "450px",
          background: "#f3f5f8",
          color: "#c2ccd9",
          paddingTop:"50%",
          fontSize: "28px",
        }}
      >
        <b>No Posts Found</b>
      </div>
    )
  )
  


      )

}
        
      </div>
    


    </div>
 
  
    </>

)

}