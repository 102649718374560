import axios from 'axios'
import React from 'react'
import { useState, useEffect } from 'react'
import './Style.css'
import { Button, Input, Modal, ModalFooter } from 'reactstrap'
import { FormControl, FormGroup, Switch } from '@mui/material'
import {toast} from 'react-toastify'


const CreateNewAd = (props) => {

    const [SelectedPage, setSelectedPage] = useState('')
    const [PagesWithPosts, setPagesWithPosts] = useState([])
    const [AdName, setAdName] = useState('')
    const [SelectedImage, setSelectedImage] = useState('')
    const [DisplayAllPosts, setDisplayAllPosts] = useState(false)
    const [IsPostActive, setIsPostActive] = useState(false)
    const [ImageHash, setImageHash] = useState('')
    const [ImageURL, setImageURL] = useState('')
    const [message, setMessage] = useState('')
    const [websiteUrl, setWebsiteUrl] = useState('')
    const [AllpagesData, setAllpagesData] = useState([])
    let userID = JSON.parse(localStorage.getItem("userProfileInfo"))?._id;
    const [userId, setUserId] = useState(userID);






    const GetAllPages = async() => {
        await axios.post( process.env.REACT_APP_SERVER_URI +  `/api/toowe/users/${userId}/ads/getAllPages`, { AdAccountId: props.adAccountId, jwToken:localStorage?.getItem("token"), token:"EAAb9ifo1PGABO2uDZCitCehOkQAGPOzOzGZCkc97XgZC3KMEmJGZBVs5JAqexIrdk2uQVs3AiojcDFiLacRDJwPDp89bZAht2fr3tdaki0rVZBZC56WkZB961yDnXvR3QpawO3akEtoa4nZAMIgPhPfZBjUKwGclQHzB9GCUOUvpjX7Nk1Yb9lDaEp9pZAXvW0ZD"}).then((res)=>{
    
            console.log(res.data)
            setAllpagesData(res.data)
        })
      }
    
    useEffect(() => {
        GetAllPages()
    }, [])
    





    useEffect(() => {
        async function getPagesWithPosts(){

            await axios.post( process.env.REACT_APP_SERVER_URI +  `/api/toowe/users/${userId}/ads/GetAllImages`, { AdAccountId: props.adAccountId, jwToken:localStorage?.getItem("token"), token:"EAAb9ifo1PGABO2uDZCitCehOkQAGPOzOzGZCkc97XgZC3KMEmJGZBVs5JAqexIrdk2uQVs3AiojcDFiLacRDJwPDp89bZAht2fr3tdaki0rVZBZC56WkZB961yDnXvR3QpawO3akEtoa4nZAMIgPhPfZBjUKwGclQHzB9GCUOUvpjX7Nk1Yb9lDaEp9pZAXvW0ZD"}).then((res)=>{
                console.log(res.data)
                setPagesWithPosts(res.data)
            })
        }

        getPagesWithPosts();
    }, [window.location.href, props.adAccountId])

  const [file, setFile] = useState(null);

const handleCreateNewAdd = async() => {





if(props.campaignObjective === 'OUTCOME_TRAFFIC' ){
    axios.post(process.env.REACT_APP_SERVER_URI +  `/api/toowe/users/${userId}/ads/CreateFacebookCreativeFromImageHash`, { adAccountId: props.adAccountId, AdSetId: props.adSetId  , name: AdName, SelectedImage: SelectedImage, image_hash: ImageHash ,IsPostActive: IsPostActive, page: SelectedPage, message: message, link: websiteUrl, jwToken:localStorage?.getItem("token"), token:"EAAb9ifo1PGABO2uDZCitCehOkQAGPOzOzGZCkc97XgZC3KMEmJGZBVs5JAqexIrdk2uQVs3AiojcDFiLacRDJwPDp89bZAht2fr3tdaki0rVZBZC56WkZB961yDnXvR3QpawO3akEtoa4nZAMIgPhPfZBjUKwGclQHzB9GCUOUvpjX7Nk1Yb9lDaEp9pZAXvW0ZD"}).then((res)=>{
        console.log(res.data)
        // setPagesWithPosts(res.data)

        axios.post( process.env.REACT_APP_SERVER_URI +  `/api/toowe/users/${userId}/ads/CreateFacebookAd`, { adAccountId: props.adAccountId, adset: props.adSetId  , name: AdName, status: IsPostActive, creative: res.data.id, jwToken:localStorage?.getItem("token"), token:"EAAb9ifo1PGABO2uDZCitCehOkQAGPOzOzGZCkc97XgZC3KMEmJGZBVs5JAqexIrdk2uQVs3AiojcDFiLacRDJwPDp89bZAht2fr3tdaki0rVZBZC56WkZB961yDnXvR3QpawO3akEtoa4nZAMIgPhPfZBjUKwGclQHzB9GCUOUvpjX7Nk1Yb9lDaEp9pZAXvW0ZD"}).then((res)=>{
            console.log(res.data)
            // setPagesWithPosts(res.data)



if(res.status == 200){
        toast.success('Ad Created Successfully')
} else{
    toast.error( res.data ||  'Something Went Wrong')
}
        }).catch((err)=>{
            toast.error( err ||  'Something Went Wrong')
        }
        )
    }).then(()=>{
    }
    )

}


    // props.campaignObjective === 'OUTCOME_TRAFFIC' ? (
    // ) : null


    else{
        await  axios.post( process.env.REACT_APP_SERVER_URI +  `/api/toowe/users/${userId}/ads/GetFacebookCreativesFromPostId`, { adAccountId: props.adAccountId, AdSetId: props.adSetId  , name: AdName, postId: SelectedImage.id, jwToken:localStorage?.getItem("token"), token:"EAAb9ifo1PGABO2uDZCitCehOkQAGPOzOzGZCkc97XgZC3KMEmJGZBVs5JAqexIrdk2uQVs3AiojcDFiLacRDJwPDp89bZAht2fr3tdaki0rVZBZC56WkZB961yDnXvR3QpawO3akEtoa4nZAMIgPhPfZBjUKwGclQHzB9GCUOUvpjX7Nk1Yb9lDaEp9pZAXvW0ZD" }).then((res)=>{
            console.log(res.data)
            // setPagesWithPosts(res.data)
             axios.post( process.env.REACT_APP_SERVER_URI +  `/api/toowe/users/${userId}/ads/CreateFacebookAd`,  { adAccountId: props.adAccountId, adset: props.adSetId , name: AdName, status: IsPostActive, creative: res.data.id, jwToken:localStorage?.getItem("token"), token:"EAAb9ifo1PGABO2uDZCitCehOkQAGPOzOzGZCkc97XgZC3KMEmJGZBVs5JAqexIrdk2uQVs3AiojcDFiLacRDJwPDp89bZAht2fr3tdaki0rVZBZC56WkZB961yDnXvR3QpawO3akEtoa4nZAMIgPhPfZBjUKwGclQHzB9GCUOUvpjX7Nk1Yb9lDaEp9pZAXvW0ZD"}).then((res)=>{
                console.log(res.data)
                // setPagesWithPosts(res.data)
    
    if(res.status == 200){
            toast.success('Ad Created Successfully')
    } else{
        toast.error( res.data ||  'Something Went Wrong')
    }
            }).catch((err)=>{
                toast.error( err ||  'Something Went Wrong')
            }
            )

    
        }).then(()=>{
        }
        )
    }






  

}


 const handleFileChange = (event) => {
    setFile(event.target.files[0]);

}; 

  const handleSubmit = (event) => {
    event.preventDefault();
    const formData = new FormData();
    ////formData.append('file', file);            
    //formData.append('adAccountId', props.adAccountId);
    formData.append('file', file); // Append the file
formData.append('adAccountId', props.adAccountId); // Append additional fields
formData.append('jwToken', localStorage?.getItem("token")); 
formData.append('token', "");     

    axios.post(process.env.REACT_APP_SERVER_URI +  `/api/toowe/users/${userId}/ads/ImageToHash`, formData, {

      headers:{
        'Access-Control-Allow-Origin': '*',
        'content-type': 'multipart/form-data',
        'token': localStorage?.getItem("token")
      }
    })
      //.then((response) => response.json())
      .then((data) => {
        console.log(data)
        setImageHash(data.data.images["image.jpg"].hash)
        setImageURL(data.data.images["image.jpg"].url)
    
    
    })
      .catch((error) => console.error(error));       
  };                                                





  




    



    return(
        <div className="BigBox-adsets">

 <div class="withCloseButton"><div class="calender_info withBorderLeft"><h5>Create New Ad</h5> </div><div>
  <button onClick={()=>props.setCreateNewAdOpen(false)}>X</button>  </div></div>

            {/* {props.adAccountId}
            Create New Ad
            {props.adSetId}

            <button onClick={()=>props.setCreateNewAdOpen(false)}>Back</button> */}


           

{/* {JSON.stringify(PagesWithPosts)} */}

<div>

    <Modal isOpen={DisplayAllPosts} >
        <div className='AllPostsContainer'>
            <div className='AllPostsContainerHeader'>
                <h3>Select Post To Promote</h3>
            </div>
            <div className='AllPostsContainerBody'>

                {
                    PagesWithPosts.map((page, index)=>{


                        return(
                            <div key={index}>
                                <div className='AllPostsContainerBodyPageName'>
                                    <h3>{page.pageName}</h3>
                                </div>
                                <div className='AllPostsContainerBodyPosts'>
                                    {
                                        PagesWithPosts.map((post, index)=>{
                                            return(
                                                <div key={index} className='AllPostsContainerBodyPostsPost'>
                                                    <img onClick={()=>{setSelectedImage(post); setDisplayAllPosts(false)}} height={'100px'} src={post.full_picture} alt={post.message}/>
                                                  <div>pageId: {post.page_id}</div>

{/* UNCOMMENT THESE TO SEE THE POST MESSAGE AND CREATED TIME */}

                                                    {/* <div style={{margin:'5px', fontSize:'10px'}}>{post.message}</div> */}
                                                    {/* <div style={{margin:'5px'}}>{post.created_time}</div> */}
                                                   
                                                </div>
                                            )
                                        })
                                    }
                                </div>
                            </div>
                        )
                    }
                    )
                }
            </div>
        </div>
        <ModalFooter>
        
          <Button color="secondary" onClick={()=>{setDisplayAllPosts(false)}}>
            Cancel
          </Button>
        </ModalFooter>



    </Modal>
   
               


{
    // !DisplayAllPosts && 
    <div className='FinalStepAdCreationContainer'>

<div>

{/* <Input type='text'  style={{margin:'20px'}} placeholder='Your Ad Name' onChange={(e)=>{setAdName(e.target.value)}}/> */}



 <Button className='btn btn-success' style={{margin:'20px'}} onClick={()=>{setDisplayAllPosts(true)}}>Select Post To promote</Button>
 </div>

<div>
   
    { 

<div>
    <Input type='text'   placeholder='Your Ad Name' onChange={(e)=>{setAdName(e.target.value)}}/>
     <form onSubmit={handleSubmit}>
      <label>
        <Input type="file" onChange={handleFileChange} />
      </label>
      <button type="submit">Upload</button>
    </form>

{
    ImageURL && <img height={'300px'} src={ImageURL} alt={message}/>
}



{
   props.campaignObjective === 'OUTCOME_TRAFFIC' ? <div>
         <Input type='text'   placeholder='Your Website URL' onChange={(e)=>{setWebsiteUrl(e.target.value)}}/>
<Input type='text'   placeholder='message' onChange={(e)=>{setMessage(e.target.value)}} />
<FormGroup>
    <label for="pages">Select Page</label>
<Input type="select" onChange={(e)=> setSelectedPage(e.target.value)} name="pages" id="pages">
    <option value=''>Select Page</option>
  {
      AllpagesData.map((page, index)=>{
          return(
            <option key={index} value={page.id}>{page.name}</option>
          )
      })
  }
  </Input>
</FormGroup>

    </div> : props.campaignObjective 
}
{
    SelectedImage &&         <img height={'300px'} src={SelectedImage?.full_picture} alt={SelectedImage?.message}/>

}

         {/* <Button className='btn btn-success'onClick={()=>{setDisplayAllPosts(true)}}>Select other post</Button> */}

            <div style={{margin:'5px'}}>{SelectedImage?.message}</div>
            <div style={{margin:'5px'}}>{SelectedImage?.created_time}</div>
            {
                SelectedImage || ImageHash ? <Button className='btn btn-success' onClick={()=> handleCreateNewAdd()} style={{marginTop:'20px'}}>Create Ad</Button>
:<Button className='btn btn-success'onClick={()=>{setDisplayAllPosts(true)}}>Select a post</Button>
            }
        </div>
    }
</div>

    </div>
}




            </div>









</div>
    )
}
export default CreateNewAd