import "./Analysis.css";
import {
  Card,
  CardBody,
  ButtonDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  Button,
} from "reactstrap";
import { useEffect, useState } from "react";
import { Global } from "@emotion/react";
import { Progress } from "reactstrap";
import millify from "millify";
import { MoonLoader ,BeatLoader} from "react-spinners";
import axios from "axios";
import { useSelector } from "react-redux";
import CreateBoard from "../Dashboard/CreateBoard";
import CreatePin from "../Dashboard/CreatePin";
import CreateNewPost from "../Dashboard/CreateNewPost";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import GaugeChart from "react-gauge-chart";
import { byIso } from "country-code-lookup";
import { json } from "react-router";
import VideoList from "../Dashboard/Analytics/GoogleVideos/VideoList";
import BacklinksDataComponent from "../../Components/BacklinksDataComponent";
import DataTable from "react-data-table-component";

export default function Analysis({ pagesDetails }) {
  let userID = JSON.parse(localStorage.getItem("userProfileInfo"))?._id;
  const [userId, setUserId] = useState(userID);
  const [loader, setLoader] = useState(false);
  const [selectedForm, setSelected] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [loading, setLoading] = useState(true);
  const [videos, setVideos] = useState([]);
  const [currentVideoPage, setCurrentVideoPage] = useState(1);
  const [googleAds, setGoogleAds] = useState([]);
  const [itemsPerPage] = useState(10);
  const [isFreeAccount, setIsFreeAccount] = useState(false);

  useEffect(() => {
    if (localStorage.getItem("userProfileInfo")) {
    
      let acc = JSON.parse(localStorage.getItem("userProfileInfo")).subscription;
      if (acc === "free") {
        setIsFreeAccount(true);
      }


    }
  }, []);






  const isCreateNewPostMenu = useSelector(
    (state) => state.createNewPost.showCreatePostMenu
  );

  const isCreateNewPinMenu = useSelector(
    (state) => state.createNewPin.showCreatePinMenu
  );

  const isCreateNewBoardMenu = useSelector(
    (state) => state.createNewBoard.showCreateBoardMenu
  );
  const [data, setData] = useState(null);


  const validateURL = (url) => {
    var pattern = new RegExp(
      "^(https?:\\/\\/)" + // protocol
        "((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|" + // domain name
        "((\\d{1,3}\\.){3}\\d{1,3}))" + // OR ip (v4) address
        "(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*" + // port and path
        "(\\?[;&a-z\\d%_.~+=-]*)?" + // query string
        "(\\#[-a-z\\d_]*)?$",
      "i"
    ); // fragment locator
    return !!pattern.test(url);
  };



  const getAds = async (hostname) => {
  
       await axios
          .post(`${process.env.REACT_APP_SERVER_URI}/api/toowe/users/${userId}/googleTrends/videos`, {
            q: domain.replace(/(http:\/\/|https:\/\/)/g, ''),
            jwToken: JSON.parse(localStorage.getItem("userProfileInfo")).token,
          })
          .then((response) => {
            setVideos(response.data);
          })
          .catch((error) => {
            console.log("Error fetching video data:", error);
          });
        axios
          .post(
            `${process.env.REACT_APP_SERVER_URI}/api/toowe/users/${userId}/googleTrends/transparency_center`,
            {
              q: domain.replace(/(http:\/\/|https:\/\/)/g, ''),
              jwToken: JSON.parse(localStorage.getItem("userProfileInfo")).token,
            }
          )
          .then((response) => {
            const { ad_creatives } = response.data;

            let onlyTenAds = ad_creatives.slice(0, 10);
            // Convert Unix timestamps to actual dates for each entry
            const convertedData = onlyTenAds.map((ad) => {
              const { first_shown, last_shown, image, target_domain } = ad;
              return {
                image,
                target_domain,
                first_shown: new Date(first_shown * 1000),
                last_shown: new Date(last_shown * 1000),
              };
            });
            if (convertedData.length > 0) {
              console.log(convertedData);
              setGoogleAds(convertedData);
              console.log(
                "Converted Data:%%%%%%%%%%%%%%%%%%%%%%%%%",
                googleAds
              );
            }
          })
          .catch((error) => {
            console.log("Error fetching google_ads_data:", error);
          });
    


  };



  const getData = async () => {
    setData(null)

    if ( isFreeAccount) {
      toast.error("Please upgrade to premium to use this feature");
      return;
    }



    if (validateURL(domain) == false) {
      toast.error("Please enter a valid URL");
      setLoader(false);

      return;
    } else {
   
      try {
        setLoader(true);
       await getAds();

        setBackLinksData(null);
        const dataFromWeb = await axios
          .post(process.env.REACT_APP_SERVER_URI + `/api/toowe/users/${userId}/competitorAnalysis/competitorAnalysis`, {
            domain: domain,
            jwtToken: JSON.parse(localStorage.getItem("userProfileInfo")).token,
          },{
            headers: {
              'token': JSON.parse(localStorage.getItem("userProfileInfo")).token,
              'userID': JSON.parse(localStorage.getItem("userProfileInfo"))._id,
            }})
          .then((res, err) => {
            if (err) {
              console.log(
                err );
              toast.error(err.message);
              setData("errs");
              setLoader(false);
              return;
            } else {
              getAds(domain);
              console.log("all ok");
              setData(res.data);
              setLoader(false);
              toast.success("You're all set to go 🚀");
              setLoading(false);
              //set cookie for 1 day
              // localStorage.setItem({"TimesUsedAnalysis": 1, "expires": new Date(Date.now() + 86400000)})
            }
          });

        if (dataFromWeb) {
          setLoader(false);
          setData(dataFromWeb.data);


          toast.success("You're all set to go 🚀");
          console.log(dataFromWeb);
        }
      } catch (err) {
        console.log(err);
        toast.error(err.response?.data?.message || "something went wrong");
        setLoader(false);
      }

      setLoader(false);


    }
  };

  const [BackLinksData, setBackLinksData] = useState(null);
  const [IsBacklinksLoading, setIsBacklinksLoading] = useState(false);


  const getBackLinksData = async () => {
    if (validateURL(domain) == false) {
      toast.error("Please enter a valid URL");
      setLoader(false);
      return;
    } else {

      setIsBacklinksLoading(true);
      axios.post(process.env.REACT_APP_SERVER_URI + `/api/toowe/users/${userId}/competitorAnalysis/BackLinksData`, {
        domain: domain,
        jwToken: JSON.parse(localStorage.getItem("userProfileInfo")).token,
      })
       .then((res, err) => {
        if (err) {
            console.log(
              err );
              setIsBacklinksLoading(false);
            toast.error(err.message);
            setData("errs");
            setLoader(false);
            return;
          } else {
            console.log("all ok");
            setBackLinksData(res.data);
            setIsBacklinksLoading(false);
            setLoader(false);


            if(res.data.backlinks === undefined){
              toast.error("No backlinks found");
            }else{
            toast.success("You're all set to go 🚀");
            }
            setLoading(false);
            //set cookie for 1 day
            // localStorage.setItem({"TimesUsedAnalysis": 1, "expires": new Date(Date.now() + 86400000)})
          }
        })
     


    }

  };


  const [domain, setDomain] = useState("https://www.instagram.com/");

  const [NUmData, setNUmData] = useState([]);

  useEffect(() => {
    setNUmData([
      { name: "Global rank", value: millify(data?.Rank?.global_rank) },
      { name: "Country Rank", value: millify(data?.Rank?.country_rank) },
      { name: "Category Rank", value: millify(data?.Rank?.category_rank) },
      { name: "Speed Score", value: data?.pageSpeedScore },
      { name: "performance Score", value: data?.performanceScore },
      {
        name: "first Contentful Paint",
        value: Math.round(data?.firstContentfulPaint),
      },
      {
        name: "first Meaningful Paint",
        value: Math.round(data?.firstMeaningfulPaint),
      },
      { name: "Speed Index", value: Math.round(data?.speedIndex) },
      { name: "Time to Interactive", value: Math.round(data?.interactive) },
      {
        name: "Total Blocking Time",
        value: Math.round(data?.totalBlockingTime),
      },
      { name: "Max Potential FID", value: data?.maxPotentialFID },
      {
        name: "cumulative Layout Shift",
        value: Math.round(data?.cumulativeLayoutShift * 1000) / 1000,
      },
      { name: "Server Response Time", value: data?.serverResponseTime },
      { name: "Total Traffic", value: millify(data?.traffic?.total) },
      {
        name: "Bounce Rate",
        value: Math.round(data?.audience?.bounce_rate * 1000) / 1000 + "%",
      },
      {
        name: "Time on Site",
        value: Math.round(data?.audience?.time_on_site * 1000) / 1000 + "s",
      },
      { name: "Direct Traffic", value: millify(data?.traffic.direct) },
      {
        name: "Organic Traffic",
        value: Math.round(data?.traffic?.Organic * 1000) / 1000 + "%",
      },
      {
        name: "Paid Traffic",
        value: Math.round(data?.traffic?.ads * 1000) / 1000 + "%",
      },
      {
        name: "Referring Traffic",
        value: Math.round(data?.traffic?.referring * 1000) / 1000 + "%",
      },
    ]);
  }, [data]);

  // useEffect(() => {
  //   if (validateURL(domain) == false) {
  //     return;
  //   } else {
  //     let hostname = new URL(domain).hostname.replace(/^www\./, "");
  //     const parts = hostname.split(".");
  //     if (parts.length > 1) {
  //       parts.pop();
  //       hostname = parts.join(".");
  //     }
  //     if (hostname !== "" && hostname !== "instagram") {
  //       axios
  //         .post(`${process.env.REACT_APP_SERVER_URI}/api/toowe/users/${userId}/googleTrends/videos`, {
  //           q: hostname,
  //         })
  //         .then((response) => {
  //           setVideos(response.data.video_results);
  //         })
  //         .catch((error) => {
  //           console.log("Error fetching video data:", error);
  //         });
  //       axios
  //         .post(
  //           `${process.env.REACT_APP_SERVER_URI}/api/toowe/users/${userId}/googleTrends/transparency_center`,
  //           {
  //             q: hostname,
  //           }
  //         )
  //         .then((response) => {
  //           const { ad_creatives } = response.data;

  //           let onlyTenAds = ad_creatives.slice(0, 10);
  //           // Convert Unix timestamps to actual dates for each entry
  //           const convertedData = onlyTenAds.map((ad) => {
  //             const { first_shown, last_shown, image, target_domain } = ad;
  //             return {
  //               image,
  //               target_domain,
  //               first_shown: new Date(first_shown * 1000),
  //               last_shown: new Date(last_shown * 1000),
  //             };
  //           });
  //           if (convertedData.length > 0) {
  //             console.log(convertedData);
  //             setGoogleAds(convertedData);
  //             console.log(
  //               "Converted Data:%%%%%%%%%%%%%%%%%%%%%%%%%",
  //               googleAds
  //             );
  //           }
  //         })
  //         .catch((error) => {
  //           console.log("Error fetching google_ads_data:", error);
  //         });
  //     }
  //   }
  // }, [domain]);





  const indexOfLastItem = currentVideoPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentItems = videos?.slice(indexOfFirstItem, indexOfLastItem);

  const paginate = (pageNumber) => setCurrentVideoPage(pageNumber);

  return (
    <>
      <Card className="calenderModuleCard">
        <ToastContainer />

        <CardBody className="calendarBody">
          {loader ? (
            <div className="Email_Loader">
              <MoonLoader color={"#ff9001"} loading={loader} size={150} />
            </div>
          ) : null}

          <div class="messageTopHeadText comp_anal">Competitor Analysis</div>

          <div className="InputContainer clearfix">
            <div className="TextForWebURL">
              <div className="TextForWebURLTitle">
                Enter your competitors website{" "}
              </div>
              <div className="TextForWebURLInput">
                <input
                  type="text"
                  onChange={(e) => {
                    setDomain(e.target.value);
                  }}
                  placeholder="Enter Web URL"
                />
                <button className="TextForWebURLButton" onClick={getData}>
                  ANALYSE
                </button>
              </div>
            </div>
          </div>
          {data ? (
            <div className="moduleNameTitlesmall">Result for {domain}</div>
          ) : null}
          {/* <div className="moduleNameTitlesmall">Result for {domain}</div> */}

          {data ? (
            <div>
              <div className="BoxesForDataContainer">
                {NUmData?.map((item) => {
                  if (
                    item.value !== undefined &&
                    item.value !== null &&
                    item.value !== "" &&
                    item.value !== NaN &&
                    item.value !== "undefined%" &&
                    item.value !== "undefined" &&
                    item.value !== "undefineds"
                  ) {
                    return (
                      <div className="Box">
                        <div className="AboutNumberArea">{item?.name} </div>
                        <div className="NumberArea">
                          {item.value == "null" ? "no data" : item?.value}
                        </div>
                      </div>
                    );
                  } else {
                    return null;
                  }
                })}
              </div>
              <div className="TextDataSectionContainer">
                {data?.traffic?.country?.length > 0 ? (
                  <div className="TextDataContainer">
                    <div style={{ display: "flex" }}>
                      {" "}
                      <div className="messageTopHeadText comp_anal mb-3 ml-0">
                        Traffic Distribution
                      </div>
                    </div>
                    <div className="InnerContent">
                      <div>
                        {" "}
                        S[\]
                        {data?.traffic?.country?.map((item) => {
                          return (
                            <div className="dataContainerCountry">
                              <div className="InnerContent">
                                <div className="TextForTExtDataContury">
                                  <b>{byIso(item?.Country)?.country}</b>
                                </div>
                                <div className="TextForTExtDataContury">
                                  {Math.round(item?.Value * 100)}%{" "}
                                </div>
                              </div>
                              <div>
                                {" "}
                                <Progress
                                  color="primary"
                                  value={Math.round(item?.Value * 100)}
                                  style={{
                                    height: "3px",
                                  }}
                                />
                              </div>
                            </div>
                          );
                        })}
                      </div>
                    </div>
                  </div>
                ) : null}

                {data?.keywords ? (
                  <div
                    style={{ padding: "20px" }}
                    className="KeywordsSuperContainer"
                  >
                    <div className="messageTopHeadText comp_anal mb-3 ml-0">Keywords Used </div>
                    <div className="KeywordsContainer">
                      {data?.keywords?.map((item) => {
                        return (
                          <div className="Keywords">
                            <div className="KeywordsText">
                              <div>{item.keyword}</div>
                            </div>
                            <div className="KeywordsFreq">
                              <div>{item.frequency}</div>
                            </div>
                          </div>
                        );
                      })}
                    </div>
                  </div>
                ) : null}
              </div>{" "}
              <div className="GaugeChartContainer">
                <div className="gaugeAnalysisBox">
                  <GaugeChart  
                    id="gauge-chart1"
                    percent={
                      Math.round(data?.audience?.bounce_rate * 1000) / 100000
                    }
                    textColor="#5b5b5b"
                  />
                  <h2>Bounce Rate</h2>
                </div>

                <div className="gaugeAnalysisBox">
                  <GaugeChart
                    id="gauge-chart1"
                    percent={data?.pageSpeedScore}
                    colors={["#EA4228", "#F5CD19", "#5BE12C"]}
                    textColor="#5b5b5b"
                  />
                  <h2>Speed Score</h2>
                </div>

                <div className="gaugeAnalysisBox">
                  <GaugeChart
                    id="gauge-chart1"
                    percent={data?.performanceScore}
                    colors={["#EA4228", "#F5CD19", "#5BE12C"]}
                    textColor="#5b5b5b"
                  />
                  <h2>Performance Score</h2>
                </div>
              </div>
            </div>
          ) : null}

          {data?.Blogs ? (
            <div className="BlogsContainer">
              <div className="messageTopHeadText comp_anal mb-3 ml-0">Top Blogs</div>

              {data?.Blogs?.map((item) => {
                return (
                  <div className="BlogContainer">
                    <div className="BlogIconBox">
                      <img
                        src={item?.about_this_result?.source?.icon}
                        className="BlogIcon"
                      />
                    </div>
                    <div className="BlogstextDataContainer">
                      <div className="BlogsTitle">{item?.title}</div>
                      <div className="BlogsURL">{item?.link}</div>
                      <div className="BlogsSnippet">{item?.snippet}</div>
                    </div>
                  </div>
                );
              })}
            </div>
          ) : null}
          {loading && googleAds.length < 0 ? (
            <></>
          ) : (
            <div className="ad-gallery">
              {
               data && (
              <div className="messageTopHeadText comp_anal mb-3 ml-0">Paid AD's</div>

               ) 
              }
           
           {
            googleAds.length < 1 && data ? (
              <div className="info_ads">
                No paid ads found for this domain.
              </div>
            ): null
           }
         
           
              {googleAds.map((ad, index) => (
                <div key={index} className="ad-item">
                  <img src={ad.image} alt={`Ad ${index + 1}`} />
                  <div className="ad-details">
                    <p>
                      First Shown: {new Date(ad.first_shown).toLocaleString()}
                    </p>
                    <p>
                      Last Shown: {new Date(ad.last_shown).toLocaleString()}
                    </p>
                  </div>
                </div>
              ))}
            </div>
          )}

          <div
            style={{
              display: "flex",
              width: "100%",
              marginTop: "20px",
              marginBottom: "10px",
              flexWrap: "wrap",
            }}
          >


            {
              data && <div className="messageTopHeadText comp_anal mb-3 ml-0">Videos</div> 
            }
            <div className="videos_wrp">
            
            {loading ? (
              <></>
            ) : (

              <div style={{maxWidth:'85vw'}}>

             
                      <DataTable
            columns={[

              {
                name: 'Thumbnail',
                cell: row => <a href={row.link} style={{ display: 'flex', textDecoration: 'none', color: 'inherit', flexDirection:'column' }}>
  <img src={row.thumbnail} alt="thumbnail" style={{  height: '50px', width:'70px', marginRight: '10px', borderRadius:'12px' }} />
  <div style={{ fontSize: '12px', fontWeight: 'bold' }}>{row.title}</div>
</a>,
                sortable: true,
              },
              {
                name: 'Channel',
                cell: row => <a href={row.channel.link} style={{ display: 'flex', alignItems: 'center', textDecoration: 'none', color: 'inherit', flexDirection:'column' }}>
  <img src={row.channel.thumbnail} alt="thumbnail" style={{ width: '50px', height: '50px', marginRight: '10px', borderRadius:'50%' }} />
  <div style={{ fontSize: '12px', fontWeight: 'bold' }}>{row.channel.name}</div>
</a>,
                sortable: true,
              },
            //   {
            //     name: 'Title',
            //     selector: row => <a href={row.link} target="_blank">{row.title}</a>,
            //     sortable: true,
            // },
              {
                name: 'Desc',
                selector: row => row.description,
                sortable: true,
            },
            //   {
            //     name: 'Desc',
            //     selector: row => row.description,
            //     sortable: true,
            // },
            // {
            //     name: 'Duration',
            //     selector: row =>  row.duration,
            //     sortable: true,
            // },
            // {
            //    name: 'PublishedAt',
            //    selector: row => row.publishedAt,
            //    sortable: true,
            // },
            // {
            //   name: 'Likes',
            //   selector: row => row.statistics.likeCount,
            //   sortable: true,
            // },{
            //   name: 'Comments',
            //   selector: row => row.statistics.commentCount,
            //   sortable: true,
            // },
            {
              name: 'Views',
              selector: row => row.views,
              sortable: true,
            }
            ]}
            data={videos}
            direction="auto"
            fixedHeaderScrollHeight="300px"
            pagination
            responsive
            subHeaderAlign="right"
            subHeaderWrap
          
        />
               </div>
            
            )}
</div>
          </div>

          {data?.News ? (
            <div className="BlogsContainer">
              <div className="messageTopHeadText comp_anal mb-3 ml-0">Top News</div>

              { data?.News  && data?.News?.map((item) => {
                return (
                  <div className="BlogContainer">
                    <div className="BlogIconBox">
                      <img src={item?.image} className="NewsIcon" />
                    </div>
                    <div className="BlogstextDataContainer">
                      <div className="BlogsTitle">{item?.title}</div>
                      <div className="BlogsURL">{item?.url}</div>
                      <div className="BlogsSnippet">{item?.description}</div>
                    </div>
                  </div>
                );
              })}
            </div>
          ) : null}

          <div style={{display:'block', width:'100%', marginTop:'30px'}}>

{
   data &&    <div className="messageTopHeadText comp_anal mb-3 ml-0">Backlinks Info</div> 
}
          
             
             {

              data && !BackLinksData ? (
                <div className="info_bklinks">
                Stay Ahead of the Game! <br/>Click below button for an in-depth analysis of your competitor's website backlinks and other vital SEO information. Discover strategies to boost your website's ranking and outperform the competition!
                </div>
          
              ): null

             }
           
              <BeatLoader loading={IsBacklinksLoading} size={20} />
          

{
  BackLinksData || IsBacklinksLoading || !data ? null : <Button className="get_bklinks" onClick={()=>{getBackLinksData()}}>
    Get Backlinks
    </Button>

}



{
  BackLinksData?.backlinks?.length > 1 ?  <BacklinksDataComponent backlinksData={BackLinksData} /> : null

}

          </div>
      



        </CardBody>
      </Card>
      {isCreateNewPostMenu && (
        <CreateNewPost
          pagesDetails={pagesDetails}
          eventData={selectedForm}
          isLoading={isLoading}
          setIsLoading={setIsLoading}
        />
      )}
      {isCreateNewPinMenu && (
        <CreatePin
          pagesDetails={pagesDetails}
          eventData={selectedForm}
          isLoading={isLoading}
          setIsLoading={setIsLoading}
        />
      )}
      {isCreateNewBoardMenu && (
        <CreateBoard
          pagesDetails={pagesDetails}
          eventData={selectedForm}
          isLoading={isLoading}
          setIsLoading={setIsLoading}
        />
      )}
    </>
  );
}
