
import './Style.css'
import { useState, useEffect } from 'react'
import axios from 'axios'
    import React from 'react';
// import Button from 'reactstrap/Button';
// import Form from 'reactstrap/Form';
// import Input from 'reactstrap/Input';
// import Textarea from 'reactstrap/Textarea';
import { Form,InputGroup } from 'react-bootstrap';
import { Input } from 'reactstrap';
import CreateAdsetForm from './CreateAdsetForm';
import AllAds from './AllAds';
import { Switch } from '@mui/material';


export default function CreateFacebookAdsSets(props){

    let userID = JSON.parse(localStorage.getItem("userProfileInfo"))?._id;
    const [userId, setUserId] = useState(userID);
    const [adsets, setAdsets] = useState([])
    const [adsetsName, setAdsetsName] = useState('')

    const [IsCreateAdsetForm, setIsCreateAdsetForm] = useState(false)
    async function getAdsets(){
        await axios.post( process.env.REACT_APP_SERVER_URI +  `/api/toowe/users/${userId}/ads/GetFacebookAdsets`, { jwToken: localStorage?.getItem("token"), token:"EAAb9ifo1PGABO2uDZCitCehOkQAGPOzOzGZCkc97XgZC3KMEmJGZBVs5JAqexIrdk2uQVs3AiojcDFiLacRDJwPDp89bZAht2fr3tdaki0rVZBZC56WkZB961yDnXvR3QpawO3akEtoa4nZAMIgPhPfZBjUKwGclQHzB9GCUOUvpjX7Nk1Yb9lDaEp9pZAXvW0ZD",campaignid: props.AdCampaign , AdAccountId: props.adAccountId}).then((res)=>{
            console.log(res.data)
            setAdsets(res.data.data)
        })
    }

    useEffect(() => {

       

        getAdsets();
    }, [window.location.href, props.AdCampaign, props.adAccountId])






    const handleFormOpen = () => {
      setIsCreateAdsetForm(true)
        
        console.log(IsCreateAdsetForm)
    }
    
const [AllAdsOpen, setAllAdsOpen] = useState(false)
const [selectedAdset, setSelectedAdset] = useState({})



const habdleClickAllAdsOpen = (id) => {
     setSelectedAdset(id)
    setAllAdsOpen(true)
   
}


const handleStatusChange = (status,campaign) => async(event) => {
    console.log(status)
    console.log(event.target.checked)
    let newStatus = event.target.checked ? 'ACTIVE' : 'PAUSED'
    console.log(newStatus)
    await axios.post(process.env.REACT_APP_SERVER_URI + `/api/toowe/users/${userId}/ads/pauseUnPauseAdset`, {jwToken: localStorage?.getItem("token"), token:"EAAb9ifo1PGABO2uDZCitCehOkQAGPOzOzGZCkc97XgZC3KMEmJGZBVs5JAqexIrdk2uQVs3AiojcDFiLacRDJwPDp89bZAht2fr3tdaki0rVZBZC56WkZB961yDnXvR3QpawO3akEtoa4nZAMIgPhPfZBjUKwGclQHzB9GCUOUvpjX7Nk1Yb9lDaEp9pZAXvW0ZD", adAccountId: props.adAccountId, adsetid: campaign, status: newStatus }).then(res =>{
      console.log(res.data)
      getAdsets()
    })
  }



    return(
        <div className="BigBox-adsets">




{
    IsCreateAdsetForm ?        <CreateAdsetForm AdCampaign={props.AdCampaign} getAdsets={getAdsets()} campaignObjective={props.campaignObjective} adAccountId={props.adAccountId} IsCreateAdsetForm={IsCreateAdsetForm} setShowCreateAdsetForm={setIsCreateAdsetForm} setAdsets={setAdsets} adsets={adsets} /> : AllAdsOpen ? <AllAds AdCampaign={props?.AdCampaign} campaignObjective={props.campaignObjective} adSetId={selectedAdset} setAllAdsOpen={setAllAdsOpen} adAccountId={props?.adAccountId} /> : null

}
            {/* <h1>Create Facebook Ads Sets on {props.AdCampaign} --- {props.AdAccountId}</h1> */}
            <div>
                
            </div>
            <div class="withCloseButton">
                <div class="calender_info withBorderLeft">
                <h5>Ad sets</h5> </div>
                <div>
  <button onClick={()=>props.setIsAdsSetsModalOpen(false)}>X</button>  </div></div>
<div className='connect_button_wrp'>

<button onClick={handleFormOpen}  type="button" class="createNewcamp" data-toggle="modal" data-target="#exampleModal">Create new Adset</button>
</div>
<div className='wrp_grid_fb'>
<table className="grid__">
    <thead>
        <tr>
            <th>Adset Name</th>
            <th>Adset ID</th>
            <th>Adset Status</th>
            {/* <th>Adset Objective</th> */}
            <th>Adset Bid Amount</th>
            {/* <th>Adset Bid Strategy</th> */}
            <th>Adset Daily Budget</th>
            <th>Adset Lifetime Budget</th>
            <th>Adset Start Time</th>
            <th>Adset End Time</th>
            {/* <th>Adset Optimization Goal</th> */}
          </tr>
    </thead>
    <tbody>
{/* {selectedAdset} */}

        {
            adsets.map((adset, index)=>{

                return(
                    <tr key={index}>
                        <td onClick={()=>{setSelectedAdset(adset.id); setAllAdsOpen(true)}} >{adset.name}</td>
                        <td>{adset.id}</td>
                        {/* <td>{adset.status}</td> */}
            <td><Switch checked={adset.status == 'ACTIVE' ? true: false} onChange={handleStatusChange(adset?.status, adset?.id)}/>{adset?.status}</td>

                        <td>{adset.bid_amount}</td>
                                        <td>{adset.daily_budget}</td>

                        <td>{adset.lifetime_budget}</td>
                        <td>{adset.start_time}</td>
                        <td>{adset.end_time}</td>
                        {/* <td>{adset.optimization_goal}</td> */}
          
                    </tr>
                )
            }
            )
        }
    </tbody>
</table>

</div>


        </div>
    )
}

