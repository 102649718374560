import React, { useState, useEffect, useRef } from "react";
import "./analytics.css";
import axios from "axios";
import ApexAreaChartsFacebookPage from "./Charts/ApexAreaChartsFacebookPage";
import ApexDonutChart from "./Charts/ApexDonutChart";
import ApexLineChart from "./Charts/ApexLineChart";
import Posts from "./Charts/posts/Posts";
import "flatpickr/dist/themes/light.css";
import PinintrestAnalytics from "./PinintrestAnalytics"
import { DropdownToggle, DropdownMenu, DropdownItem, ButtonDropdown } from 'reactstrap'
import Flatpickr from 'react-flatpickr';
import ApexAreaChartsFacebookPost from "./Charts/ApexAreaChartsFacebookPost";
import FaceBookAnalytics from "./FaceBookAnalytics";
import InstagramAnalytics from "./Instagram/InstagramAnalytics";
import YoutubeAnalytics from "./Youtube/YoutubeAnalytics";
const Analytics = (pagesDetails) => {
  const [chartOptionPinterest, setChartOptionPinterest] = useState(null);
  const [chartOptionpTwitterUser, setChartOptionTwitterUser] = useState(null);
  const [chartOptionpTwitterTweet, setChartOptionTwitterTweet] = useState(null);
  const [chartOptionMetapage, setChartOptionMetapage] = useState(null);
  const [chartOptionMetaPagePostInsight, setChartOptionMetaPagePostInsight] =
    useState(null);
  const [chartOptionMetaPostInsight, setChartOptionMetaPostInsight] = useState(null);
  const [chartOptionMetaPageImpression, setChartOptionMetaPageImpression] = useState(null);
  const [facebookposts, setFacebookposts] = useState(null);
  console.log(chartOptionPinterest)
  const [SelectedPostAnalytics, setSelectedPostAnalytics] = useState("103191482473211_122362340556125");
  const [analyticsStartDate, setAnalyticsStartDate] = useState({date: new Date(new Date()-  7 * 24 * 60 * 60 * 1000).toISOString(), pindate:new Date(new Date()-  7 * 24 * 60 * 60 * 1000).toISOString().split("T")[0]});
  const [analyticsEndDate, setAnalyticsEndDate] = useState({date: new Date().toISOString(), pindate:new Date().toISOString().split("T")[0]});
  // const [allPages, setAllPages] = useState(pagesDetails);
  let userID = JSON.parse(localStorage.getItem("userProfileInfo"))?._id;
  const [userId, setUserId] = useState(userID);
  const socials = {
    facebook: {
      name: "Facebook",
      icon: `
       M20 10.0001C19.9997 8.08916 19.4518 6.21827 18.4213 4.60898C17.3908 2.99968 15.9207 1.71936 14.1851 0.919594C12.4495 0.119828 10.5211 -0.165895 8.62821 0.0962525C6.73529 0.3584 4.95712 1.15744 3.50421 2.39877C2.0513 3.64011 0.984494 5.27176 0.430087 7.10055C-0.124321 8.92935 -0.143113 10.8787 0.375935 12.7178C0.894983 14.557 1.93014 16.2089 3.35884 17.478C4.78755 18.7471 6.54999 19.5803 8.4375 19.8789V12.8908H5.89875V10.0001H8.4375V7.79702C8.4375 5.29077 9.93062 3.90639 12.2144 3.90639C12.9644 3.91679 13.7126 3.98197 14.4531 4.10139V6.56264H13.1919C12.9769 6.5341 12.7584 6.55428 12.5523 6.6217C12.3462 6.68912 12.158 6.80205 12.0015 6.95211C11.845 7.10218 11.7243 7.28553 11.6483 7.48859C11.5723 7.69165 11.543 7.90921 11.5625 8.12514V10.0001H14.3362L13.8925 12.8908H11.5625V19.8789C13.9149 19.5068 16.0572 18.3073 17.604 16.4963C19.1507 14.6853 20.0004 12.3818 20 10.0001V10.0001Z
     `,

    },
    twitter: {
      name: "Twitter",
      icon: `
       M16.134 6.188C15.806 6.699 15.404 7.133 14.934 7.492C14.943 7.566 14.943 7.676 14.943 7.82C14.9401 9.22326 14.5464 10.598 13.806 11.79C13.4322 12.4001 12.9733 12.9537 12.443 13.434C11.8821 13.9286 11.2346 14.3152 10.533 14.574C9.76895 14.8618 8.95839 15.0062 8.14197 15C6.83072 15.008 5.54876 14.6124 4.46997 13.867C4.64197 13.887 4.83297 13.898 5.04397 13.898C6.13107 13.9074 7.18515 13.5247 8.01297 12.82C7.50642 12.8116 7.01719 12.6342 6.62297 12.316C6.21843 11.999 5.9233 11.5633 5.77897 11.07C5.94297 11.098 6.09097 11.11 6.23197 11.11C6.44197 11.11 6.65397 11.082 6.86097 11.023C6.31332 10.9068 5.82547 10.5981 5.48597 10.153C5.12478 9.69652 4.9329 9.129 4.94297 8.547V8.516C5.27096 8.71203 5.64312 8.8221 6.02497 8.836C5.69559 8.60494 5.42835 8.29614 5.24697 7.937C5.05272 7.56604 4.95343 7.15272 4.95797 6.734C4.95797 6.277 5.06797 5.852 5.28197 5.461C5.8689 6.229 6.61188 6.86394 7.46197 7.324C8.30816 7.78759 9.24833 8.05358 10.212 8.102C10.174 7.91133 10.1545 7.71742 10.154 7.523C10.154 6.824 10.388 6.23 10.853 5.738C11.322 5.246 11.884 5 12.544 5C13.236 5 13.818 5.266 14.29 5.797C14.8295 5.68765 15.3445 5.48094 15.81 5.187C15.6435 5.76458 15.2691 6.26008 14.759 6.578C15.216 6.528 15.677 6.395 16.134 6.188Z"
         `
    },
    linkedin: {
      name: "Linkedin",
      icon: `  
      M6.97347 5.39759C6.97878 5.79554 6.84294 6.1277 6.56703 6.39512C6.29112 6.66254 5.92819 6.79519 5.47825 6.79519H5.46233C5.02299 6.79519 4.66961 6.66254 4.40113 6.39512C4.13371 6.1277 4 5.79554 4 5.39759C4 4.99434 4.13796 4.66112 4.41387 4.39689C4.68978 4.13159 5.05058 4 5.49416 4C5.93987 4 6.29537 4.13265 6.56279 4.39689C6.83127 4.66219 6.96604 4.99434 6.97241 5.39759H6.97347ZM6.80368 7.89883V16H4.15387V7.89883H6.80368ZM10.9126 9.04676C11.0138 8.88166 11.1171 8.7416 11.2236 8.61514C11.336 8.48461 11.4889 8.34241 11.6778 8.19066C11.8688 8.03785 12.1022 7.919 12.3781 7.83516C12.654 7.75027 12.9597 7.70782 13.2971 7.70782C14.2129 7.70782 14.9494 8.01769 15.5065 8.6353C16.0637 9.25398 16.3428 10.1602 16.3428 11.3541V15.9968H13.6983V11.664C13.6983 11.0909 13.59 10.6452 13.3735 10.3194C13.156 9.99576 12.8175 9.83339 12.3569 9.83339C12.0195 9.83339 11.7372 9.92572 11.508 10.1146C11.2809 10.3024 11.1111 10.5348 10.9986 10.8129C10.9402 10.9763 10.9105 11.197 10.9105 11.4751V15.9958H8.26813C8.27874 13.8203 8.28299 12.0587 8.28299 10.7068C8.28299 9.35585 8.28299 8.54935 8.27768 8.28723L8.27025 7.89459H10.9126V9.04676Z"
      
    `,

    },
    medium: {
      name: "Medium",
      icon: `M2.846 6.887c.03-.295-.083-.586-.303-.784l-2.24-2.7v-.403h6.958l5.378 11.795 4.728-11.795h6.633v.403l-1.916 1.837c-.165.126-.247.333-.213.538v13.498c-.034.204.048.411.213.537l1.871 1.837v.403h-9.412v-.403l1.939-1.882c.19-.19.19-.246.19-.537v-10.91l-5.389 13.688h-.728l-6.275-13.688v9.174c-.052.385.076.774.347 1.052l2.521 3.058v.404h-7.148v-.404l2.521-3.058c.27-.279.39-.67.325-1.052v-10.608z`,
      buttonBottom: {
        like: `https://i.hootsuite.com/assets/plancreate/fb-like.svg`,
        share: `https://i.hootsuite.com/assets/plancreate/fb-share.svg`,
        comment: `https://i.hootsuite.com/assets/plancreate/fb-comment.svg`
      }
    },
    pinterest: {
      name: "Pinterest",
      icon: `M2.846 6.887c.03-.295-.083-.586-.303-.784l-2.24-2.7v-.403h6.958l5.378 11.795 4.728-11.795h6.633v.403l-1.916 1.837c-.165.126-.247.333-.213.538v13.498c-.034.204.048.411.213.537l1.871 1.837v.403h-9.412v-.403l1.939-1.882c.19-.19.19-.246.19-.537v-10.91l-5.389 13.688h-.728l-6.275-13.688v9.174c-.052.385.076.774.347 1.052l2.521 3.058v.404h-7.148v-.404l2.521-3.058c.27-.279.39-.67.325-1.052v-10.608z`,


    },
    BUSINESS: {
      name: "Business",
      icon: `M2.846 6.887c.03-.295-.083-.586-.303-.784l-2.24-2.7v-.403h6.958l5.378 11.795 4.728-11.795h6.633v.403l-1.916 1.837c-.165.126-.247.333-.213.538v13.498c-.034.204.048.411.213.537l1.871 1.837v.403h-9.412v-.403l1.939-1.882c.19-.19.19-.246.19-.537v-10.91l-5.389 13.688h-.728l-6.275-13.688v9.174c-.052.385.076.774.347 1.052l2.521 3.058v.404h-7.148v-.404l2.521-3.058c.27-.279.39-.67.325-1.052v-10.608z`,

    },
    instagram: {
      name: "Business",
      icon: `M2.846 6.887c.03-.295-.083-.586-.303-.784l-2.24-2.7v-.403h6.958l5.378 11.795 4.728-11.795h6.633v.403l-1.916 1.837c-.165.126-.247.333-.213.538v13.498c-.034.204.048.411.213.537l1.871 1.837v.403h-9.412v-.403l1.939-1.882c.19-.19.19-.246.19-.537v-10.91l-5.389 13.688h-.728l-6.275-13.688v9.174c-.052.385.076.774.347 1.052l2.521 3.058v.404h-7.148v-.404l2.521-3.058c.27-.279.39-.67.325-1.052v-10.608z`,

    },
    youtube: {
      name: "Business",
      icon: `M2.846 6.887c.03-.295-.083-.586-.303-.784l-2.24-2.7v-.403h6.958l5.378 11.795 4.728-11.795h6.633v.403l-1.916 1.837c-.165.126-.247.333-.213.538v13.498c-.034.204.048.411.213.537l1.871 1.837v.403h-9.412v-.403l1.939-1.882c.19-.19.19-.246.19-.537v-10.91l-5.389 13.688h-.728l-6.275-13.688v9.174c-.052.385.076.774.347 1.052l2.521 3.058v.404h-7.148v-.404l2.521-3.058c.27-.279.39-.67.325-1.052v-10.608z`,

    }
  }



  const getMetaPageData = async () => {
    const response = await axios.post(
      `${process.env.REACT_APP_SERVER_URI}/api/toowe/users/${userId}/crud/getPageInsights/week`,
      {
        token:
          "EAAKReHrIdCMBABscXebyWhoQgv7GQKgPRmkh8c1sJWq2hE9Uh6eIhNomqnZBIxQNZCu5RQ2BdI0WZBTNU6ymyE3vZAxfiLXj6fTFe3GRpZAiniYbRl7iWcM01Bi9BFXvze2XR1xfY67nWbMZCAtj470hKXL9Uhf16lZATInIeV9q9EoFmbzx23r",
        id: "103191482473211",
      }
    );
    if (response) setChartOptionMetapage(response.data);
  };

  const getMetaPageImpressionData = async () => {
    const response = await axios.post(
      `${process.env.REACT_APP_SERVER_URI}/api/toowe/users/${userId}/crud/getPageImpressions`,
      {
        token:
          "EAAKReHrIdCMBABscXebyWhoQgv7GQKgPRmkh8c1sJWq2hE9Uh6eIhNomqnZBIxQNZCu5RQ2BdI0WZBTNU6ymyE3vZAxfiLXj6fTFe3GRpZAiniYbRl7iWcM01Bi9BFXvze2XR1xfY67nWbMZCAtj470hKXL9Uhf16lZATInIeV9q9EoFmbzx23r",
        id: "103191482473211",
      }
    );
    if (response) setChartOptionMetaPageImpression(response.data);
  };

  const getMetaPagePostInsight = async () => {
    const response = await axios.post(
      `${process.env.REACT_APP_SERVER_URI}/api/toowe/users/${userId}/crud/getPagePostInsights`,
      {
        token:
          "EAAKReHrIdCMBABscXebyWhoQgv7GQKgPRmkh8c1sJWq2hE9Uh6eIhNomqnZBIxQNZCu5RQ2BdI0WZBTNU6ymyE3vZAxfiLXj6fTFe3GRpZAiniYbRl7iWcM01Bi9BFXvze2XR1xfY67nWbMZCAtj470hKXL9Uhf16lZATInIeV9q9EoFmbzx23r",
        id: SelectedPostAnalytics || "103191482473211_114316978027328",
      }
    );
    if (response) setChartOptionMetaPagePostInsight(response.data);
  };

  // const getMetaPostInsight = async () => {
  //   const response = await axios.post(
  //     "${process.env.REACT_APP_SERVER_URI}/api/toowe/users/${userId}/crud/getPostInsights",
  //     {
  //       token:
  //       "EAAKReHrIdCMBABscXebyWhoQgv7GQKgPRmkh8c1sJWq2hE9Uh6eIhNomqnZBIxQNZCu5RQ2BdI0WZBTNU6ymyE3vZAxfiLXj6fTFe3GRpZAiniYbRl7iWcM01Bi9BFXvze2XR1xfY67nWbMZCAtj470hKXL9Uhf16lZATInIeV9q9EoFmbzx23r",
  //       id: SelectedPostAnalytics,
  //     }
  //   );
  //   if (response) setChartOptionMetaPostInsight(response.data);
  // };

  const getPinterestData = async () => {
    const response = await axios.post(`${process.env.REACT_APP_SERVER_URI}/api/toowe/users/${userId}/crud/analytics/impression`, {
      token: "pina_AEAVNEYWABTQMBAAGBABICSGKQA5VAQBACGSOXTGKPR7FGAATMBFNEUPN5CP4KS3MVY2EIPESE7OPTJ6USGR6BJGQC3OW2AA"
    })
    console.log(response)
    if (response) setChartOptionPinterest(response.data);
    else console.log("Error");
  };

  const getPostData = async () => {
    const postIds = await axios.get('https://graph.facebook.com/v21.0/103191482473211/posts?pretty=0&limit=100&access_token=EAAKReHrIdCMBABscXebyWhoQgv7GQKgPRmkh8c1sJWq2hE9Uh6eIhNomqnZBIxQNZCu5RQ2BdI0WZBTNU6ymyE3vZAxfiLXj6fTFe3GRpZAiniYbRl7iWcM01Bi9BFXvze2XR1xfY67nWbMZCAtj470hKXL9Uhf16lZATInIeV9q9EoFmbzx23r&fields=id&limit=100')
    setFacebookposts(postIds.data.data)
    console.log(postIds.data.data)

  }






  //   if (response) setChartOptionTwitterTweet(await response.json());
  //   else console.log("Error");
  // };

  useEffect(() => {
    getPostData();
    getMetaPageData();
    getMetaPageImpressionData()
  }, [analyticsStartDate, analyticsEndDate, SelectedPostAnalytics]);

  const handleSelect = (key) => {
    setSelectedPostAnalytics(key)
  }
  useEffect(() => {
    console.log(analyticsStartDate, analyticsEndDate)
    console.log(SelectedPostAnalytics)
  }, [SelectedPostAnalytics, analyticsStartDate, analyticsEndDate])

  const facebookChartsData = ["page_impressions", "page_engaged_users", "page_total_actions", "page_consumptions",
    "page_consumptions_unique", "page_impressions_unique", "page_tab_views_logout_top", "page_impressions_paid*"
  ]

  const facebookPostFilters = ["post_impressions*", "post_impressions_unique*", "post_impressions_organic*", "post_impressions_paid*", "post_impressions_viral*", "post_impressions_fan*",]
  const fp = useRef(null);
  const [dropdownOpen, setOpen] = useState(false);
  const [dropdownOpenPage, setOpenPage] = useState(false);
  const [dropdownOpenBoard, setOpenBoard] = useState(false);
  const [board, setBoard] = useState("Select Board");
  const [GraphdropdownOpen, setGraphdropdownOpen] = useState(false);
  const [smallGraphs, setSmallGraphs] = useState([]);
  const [selectedAccount, setSelectedAccount] = useState("facebook");
  const [selectedPage, setSelectedPage] = useState(pagesDetails.pagesDetails.filter(page => page.account_type === selectedAccount)[0]);

  const handleAdd = (e) => {
    // check if the value is already in the array
    if (smallGraphs.includes(e)) {
      // if it is, remove it
      setSmallGraphs(smallGraphs.filter((item) => item !== e));
    } else {
      // if it isn't, add it
      setSmallGraphs([...smallGraphs, e]);
    }
    // setSmallGraphs([...smallGraphs, e])
    console.log(smallGraphs)
  }
  const handleSelectPage = (e) => {
    handleSetBoard(null)
    setSelectedPage(e)
    console.log(e)
  }
  const handleSetBoard = (e) => {
    setBoard(e)
    console.log(e)
  }
  const handleSelectAccount = (e) => {
    setSelectedAccount(e)
  setSelectedPage(pagesDetails.pagesDetails.filter(page => page.account_type === e)[0])
    console.log(e)
  }
  useEffect(() => {
    console.log(smallGraphs)
  }, [smallGraphs])
  let distinctPages = [];
  // pagesDetails.forEach(element => {
  //   if (!distinctPages.includes(element.pageName)) {
  //     distinctPages.push(element.account_type)
  //   }

  // });

  // useEffect(() => {
  // //   (pagesDetails != null && undefined &&     pagesDetails.length
  // //     > 0  ? 
  // //  ( 
  //   pagesDetails.forEach((page) => {
  //    if (distinctPages.includes(page.account_type)){

  //    } else{
  //  distinctPages.push(page.account_type)
  //  console.log(distinctPages)
  //    }})
  // //  })) : null)
  // }, [pagesDetails])
  for (let i = 0; i < pagesDetails.pagesDetails.length; i++) {
    if (distinctPages.includes(pagesDetails.pagesDetails[i].account_type)) {
      console.log("already exists")
    } else {
      distinctPages.push(pagesDetails.pagesDetails[i].account_type)
      console.log(distinctPages)
    }
  }
  const [boards, setBoards] = useState({
    items: [
      {
        name: "Board 1",
        id: "1"
      },
      {
        name: "Board 2",
        id: "2"
      },
      {
        name: "Board 3",
        id: "3"
      }
    ]
  });
  async function getboards() {
    const data = await axios.post(`${process.env.REACT_APP_SERVER_URI}/api/toowe/users/${userId}/crud/boards`, {
      token: selectedPage.token,
      refresh_token: selectedPage.refresh_token,
      jwToken: sessionStorage.getItem('token')
    })
    selectedPage.account_type === "BUSINESS" ? (setBoards(data.data)) : setBoards(null)
    console.log(boards)
  }


  useEffect(() => {
    getboards()
  }, [selectedPage])
  return (
    <>

      <div style={{ margin: "auto" }}>
        {/* {JSON.stringify(pagesDetails)} */}
        {/* {/ <div className="date-picker"> /} */}
        <div className="Analytics-top" style={{ marginTop: '0px', width: '93vw', background: "#7367f0", padding: "10px" }}>
          {/* {/ <input type="text"  / > /} */}
          {/* {JSON.stringify(boards)} */}
          <div>
            <ButtonDropdown toggle={() => { setOpen(!dropdownOpen) }}
              isOpen={dropdownOpen} >
              <DropdownToggle caret color="light" style={{ boxShadow: '0 4px 24px 0 rgb(34 41 47 / 31%)' }}>
                {selectedAccount ? (JSON.stringify(selectedAccount)) : ('Select social Page ')}
              </DropdownToggle>
              <DropdownMenu style={{ height: "auto", overflowY: 'scroll' }}>
                <DropdownItem header>Select Account
                </DropdownItem>
                {

                  console.log(pagesDetails)
                }
                {distinctPages.map((page) => {
                  return (
                    <DropdownItem onClick={() => handleSelectAccount(page)}><svg
                      width="30"
                      height="30"
                      viewBox="0 0 20 20"
                      fill="#143059"
                      className="sc-jMGUhp hVdFLT"
                    >

                      <g>
                        <path
                          fill="#1877F2"
                          d={page
                            ? socials[page].icon : null}
                        ></path>
                      </g>
                    </svg>{page}</DropdownItem>
                  )
                })}
              </DropdownMenu>
            </ButtonDropdown>



            <ButtonDropdown style={{ margin: '10px' }} toggle={() => { setOpenPage(!dropdownOpenPage) }}
              isOpen={dropdownOpenPage} >
              <DropdownToggle caret color="light" style={{ boxShadow: '0 4px 24px 0 rgb(34 41 47 / 31%)' }}>
                {selectedPage?.name ? (selectedPage?.name) : ('Select social Page ')}
              </DropdownToggle>
              <DropdownMenu style={{ height: "auto", overflowY: 'scroll' }}>
                <DropdownItem header>Select page
                </DropdownItem>
                {

                  console.log(pagesDetails)
                }
                {pagesDetails?.pagesDetails?.map((page) => {
                  return (
                    <>
                      {selectedAccount == page?.account_type ? (<DropdownItem onClick={() => handleSelectPage(page)}>
                        {/* <svg
                  width="30"
                  height="30"
                  viewBox="0 0 20 20"
                  fill="#143059"
                  className="sc-jMGUhp hVdFLT"
                >
           
                  <g>
                    <path
                      fill="#1877F2"
                      d={page
                        ? socials[page].icon : null}
                    ></path>
                  </g>
                </svg> */}
                        {page.name}</DropdownItem>) : null}



                    </>
                  )
                })}
              </DropdownMenu>
            </ButtonDropdown>

            {
              // if selected page is BUISSNESS then show the dropdown with the pages
              selectedAccount == "BUSINESS" ? (

                <ButtonDropdown style={{ margin: '10px' }} toggle={() => { setOpenBoard(!dropdownOpenBoard) }}
                  isOpen={dropdownOpenBoard} >
                  <DropdownToggle caret color="light" style={{ boxShadow: '0 4px 24px 0 rgb(34 41 47 / 31%)' }}>
                    {board ? (board.name ? (board.name) : ('Select Board ')) : null}
                  </DropdownToggle>
                  <DropdownMenu style={{ height: "auto", maxHeight: "200px", overflowY: 'scroll' }}>
                    <DropdownItem header>Select page
                    </DropdownItem>
                    {
                      boards.items.map((page) => {
                        return (
                          <DropdownItem onClick={() => { handleSetBoard(page) }}>
                            {page.name}
                          </DropdownItem>)
                      })
                    }

                  </DropdownMenu>
                </ButtonDropdown>
              ) : null



            }


          </div>
          <h3 style={{ color: 'white', margin: '10px' }}>{selectedPage.name}</h3>
          <Flatpickr style={{ margin: '10px' }} ref={fp} options={{
            mode: "range",
            dateFormat: "Y-m-d",
            defaultDate: [ new Date(new Date()-  7 * 24 * 60 * 60 * 1000).toISOString(),new Date()],

            onChange: function (selectedDates, dateStr, instance) {

              if (selectedDates.length === 2) {
                console.log(selectedDates)
                setAnalyticsStartDate({
                  pindate: selectedDates[0].toISOString().split('T')[0],
                  date: selectedDates[0],
                  epoch: Math.floor(Date.parse(selectedDates[0]) / 1000),
                })
                setAnalyticsEndDate({
                  pindate: selectedDates[1].toISOString().split('T')[0],
                  date: selectedDates[1],
                  epoch: Math.floor(Date.parse(selectedDates[1]) / 1000),
                })
                console.log(analyticsStartDate, analyticsEndDate)
                // selectedDates.forEach(dat => { let epoch = Date.parse(dat); console.log(epoch) })
              }
              // console.log(dateStr)
              // console.log(instance)
            }
          }} />
        </div>

       
        {
          selectedPage.account_type === 'facebook' ? (
            <FaceBookAnalytics PageData={selectedPage} pagesDetails={pagesDetails} startDate={analyticsStartDate} endDate={analyticsEndDate} />) : selectedPage.account_type === "BUSINESS" ? (<PinintrestAnalytics PageData={selectedPage} startDate={analyticsStartDate.pindate} endDate={analyticsEndDate.pindate} board={board} />)
            // : selectedPage.account_type === "TWITTER" ? (<TwitterAnalytics PageData= {selectedPage} />) 
            : selectedPage.account_type === "instagram" ? (<InstagramAnalytics PageData={selectedPage} startDate={analyticsStartDate} endDate={analyticsEndDate} />)
              : selectedPage.account_type === "youtube" ? (<YoutubeAnalytics PageData={selectedPage} startDate={analyticsStartDate} endDate={analyticsEndDate} />) : null
        }
      </div>




      {/* <ButtonDropdown toggle={() => { setGraphdropdownOpen(!GraphdropdownOpen) }}
                isOpen={GraphdropdownOpen} >
              <DropdownToggle caret color="transparent" style={{boxShadow:'0 4px 24px 0 rgb(34 41 47 / 31%)'}}>
         Add Graph
        </DropdownToggle>
                <DropdownMenu>
                    <DropdownItem header>Numeric Characters
                    </DropdownItem>
                    {facebookChartsData.map((filter) => {
                        return (
                            <DropdownItem onClick={()=>{handleAdd(filter)}}>{filter}</DropdownItem>
                        )})
                    }
                
                </DropdownMenu>
            </ButtonDropdown> */}

      {/* <div className="post-insights" style={{ width: "85vw", margin: "25px",boxShadow: '0 4px 24px 0 rgb(34 41 47 / 31%)' }}>
        {chartOptionMetaPageImpression !== null ? (<ApexLineChart categories={chartOptionMetaPageImpression[0].xaxis} data={chartOptionMetaPageImpression} />
        ) : null}
      </div> */}

    </>
  );
};

export default Analytics;