import "./Seo.css";
import { Card, CardBody } from "reactstrap";
import { useEffect, useState } from "react";
import Select, { StylesConfig } from "react-select";
import { MoonLoader, BeatLoader } from "react-spinners";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import CreatableSelect from "react-select/creatable";
import AsyncSelect from "react-select/async";
import "flatpickr/dist/themes/light.css";
import Flatpickr from "react-flatpickr";
import calendarIcon from "../../assets/calendarIcon.png";

export default function Seo({ pagesDetails }) {
  let userID = JSON.parse(localStorage.getItem("userProfileInfo"))?._id;
  const [userId, setUserId] = useState(userID);
  const [loader, setLoader] = useState(false);
  const [loading, setLoading] = useState(true);
  const [seoData, setSeoData] = useState([]);
  const [isFreeAccount, setIsFreeAccount] = useState(false);
  const [selectedVendorsKeyword, setSelectedVendorsKeyword] = useState([]);
  const [metaKeywords, setMetaKeywords] = useState(null);
  const [addSeo, setAddSeo] = useState(false);
  const [options, setOptions] = useState(false);
  const [domain, setDomain] = useState("");
  const [userUrl, setUserUrl] = useState("");
  const [data, setData] = useState(null);
  const [selectedCountry, setSelectedCountry] = useState(null);
  const [urls, setUrls] = useState([]);
  const [startDate, setStartDate] = useState({
    date: new Date(new Date() - 7 * 24 * 60 * 60 * 1000).toISOString(),
    pindate: new Date(new Date() - 7 * 24 * 60 * 60 * 1000)
      .toISOString()
      .split("T")[0],
  });
  const [endDate, setEndDate] = useState({
    date: new Date().toISOString(),
    pindate: new Date().toISOString().split("T")[0],
  });

  const colourStyles = {
    control: (styles) => ({
      ...styles,
      backgroundColor: "#f9f8f4",
      borderRadius: "0px",
      border: "1px solid #d5d3d3",
      height: "20px",
      textAlign: "left",
    }),
    input: (styles) => ({
      ...styles,
      marginTop: "-8px",
      paddingTop: "3px",
      //   display: "none",
    }),
    placeholder: (styles) => ({ ...styles }),
  };

  // Function to load options dynamically
  const loadOptions = async (inputValue) => {
    if (!inputValue) return [];

    try {
      const response = await fetch(
        `https://restcountries.com/v3.1/name/${inputValue}`
      );
      const data = await response.json();

      return data.map((country) => ({
        value: country.cca2,
        label: country.name.common,
      }));
    } catch (error) {
      console.error("Error fetching countries:", error);
      return [];
    }
  };

  const handleChange = (selectedOption) => {
    setSelectedCountry(selectedOption);
    console.log("Selected country:", selectedOption);
  };

  useEffect(() => {
    if (localStorage.getItem("userProfileInfo")) {
      let acc = JSON.parse(
        localStorage.getItem("userProfileInfo")
      ).subscription;
      if (acc === "free") {
        setIsFreeAccount(true);
      }
    }

    getUrl();
  }, []);

  const getUrl = async () => {
    const dataFromWeb = await axios.get(
      process.env.REACT_APP_SERVER_URI +
        `/api/toowe/users/${userId}/seoKeywords`,
      {
        headers: {
          jwt: JSON.parse(localStorage.getItem("userProfileInfo")).token,
          userID: JSON.parse(localStorage.getItem("userProfileInfo"))._id,
        },
      }
    );

    setSeoData(dataFromWeb?.data?.data);
  };

  useEffect(() => {
    setOptions(
      seoData?.map((seo) => ({
        value: seo,
        label: seo.Website_link,
      }))
    );
  }, [seoData]);

  const handleOption = (value) => {
    setUserUrl(value.Website_link);
    setUrls(value.competitor_link);
    setSelectedVendorsKeyword(value.keywords);
  };

  const getData = async () => {
    setData(null);

    if (isFreeAccount) {
      toast.error("Please upgrade to premium to use this feature");
      return;
    } else {
      try {
        setLoader(true);

        const dataFromWeb = await axios
          .post(
            process.env.REACT_APP_SERVER_URI +
              `/api/toowe/users/${userId}/seoKeywords`,
            {
              Website_link: userUrl,
              competitor_link: urls,
              keywords: selectedVendorsKeyword.map((item) => {
                return item;
              }),
              location: selectedCountry,
              StartDate: startDate,
              EndDate: endDate,
              jwtToken: JSON.parse(localStorage.getItem("userProfileInfo"))
                .token,
            },
            {
              headers: {
                jwt: JSON.parse(localStorage.getItem("userProfileInfo")).token,
                userID: JSON.parse(localStorage.getItem("userProfileInfo"))._id,
              },
            }
          )
          .then((res, err) => {
            if (err) {
              console.log(err);
              toast.error(err.message);
              setData("errs");
              setLoader(false);
              return;
            } else {
              console.log("all ok");
              setData(res.data);
              setLoader(false);
              toast.success("Data saved successfully");
              setLoading(false);
            }
          });
      } catch (err) {
        console.log(err);
        toast.error(err.response?.data?.message || "something went wrong");
        setLoader(false);
      }

      setLoader(false);
    }
  };

  const updateKeywords = async () => {
    if (isFreeAccount) {
      toast.error("Please upgrade to premium to use this feature");
      return;
    } else {
      try {
        setLoader(true);

        const dataFromWeb = await axios
          .put(
            process.env.REACT_APP_SERVER_URI +
              `/api/toowe/users/${userId}/seoKeywords/keywordsPositions`,
            {
              Website_link: userUrl,
              competitor_link: urls,
              keywords: selectedVendorsKeyword.map((item) => {
                return item;
              }),
              location: selectedCountry,
              StartDate: startDate,
              EndDate: endDate,
              jwtToken: JSON.parse(localStorage.getItem("userProfileInfo"))
                .token,
            },
            {
              headers: {
                jwt: JSON.parse(localStorage.getItem("userProfileInfo")).token,
                userID: JSON.parse(localStorage.getItem("userProfileInfo"))._id,
              },
            }
          )
          .then((res, err) => {
            if (err) {
              console.log(err);
              toast.error(err.message);
              setData("errs");
              setLoader(false);
              return;
            } else {
              console.log("all ok");
              setData(res.data);
              setLoader(false);
              toast.success("You're all set to go 🚀");
              setLoading(false);
            }
          });
      } catch (err) {
        console.log(err);
        toast.error(err.response?.data?.message || "something went wrong");
        setLoader(false);
      }

      setLoader(false);
    }
  };

  const addURL = () => {
    if (domain.trim() !== "" && !urls.includes(domain)) {
      setUrls((prevUrls) => [...prevUrls, domain]);
      setDomain("");
    }
  };

  const removeURL = (indexToRemove) => {
    setUrls((prevUrls) =>
      prevUrls.filter((_, index) => index !== indexToRemove)
    );
  };

  const handleSelectedVendorsKeywords = (e) => {
    setSelectedVendorsKeyword(e);
  };

  return (
    <>
      <Card className="calenderModuleCard">
        <ToastContainer />

        <CardBody className="calendarBody">
          {loader ? (
            <div className="Email_Loader">
              <MoonLoader color={"#ff9001"} loading={loader} size={150} />
            </div>
          ) : null}

          <div class="messageTopHeadText comp_anal">SEO Data</div>
          <div>
            <Select
              options={options}
              styles={colourStyles}
              defaultValue={options[0]}
              className="messageSelect"
              onChange={(e) => {
                handleOption(e.value);
              }}
            />
            <div className="TextForWebURL">
              Region
              <AsyncSelect
                cacheOptions
                loadOptions={loadOptions}
                onChange={handleChange}
                className="messageSelect"
                placeholder="Type to search a country..."
                isClearable
              />
              <div className="dateFilterBlock">
                <div className="fromFilter">
                  <Flatpickr
                    options={{
                      theme: "light",
                      dateFormat: "Y-m-d",
                      defaultDate: new Date(
                        new Date() - 7 * 24 * 60 * 60 * 1000
                      ).toISOString(),
                      minDate: new Date(
                        new Date() - 30 * 24 * 60 * 60 * 1000
                      ).toISOString(),

                      onChange: function (selectedDates, dateStr, instance) {
                        console.log(dateStr);
                        setStartDate({
                          pindate: dateStr.split("T")[0],
                          date: dateStr,
                          epoch: Math.floor(Date.parse(dateStr) / 1000),
                        });

                        console.log(startDate, endDate);
                      },
                    }}
                  />
                  <img src={calendarIcon} alt="calendar icon" />
                </div>
                <div className="toFilter">
                  <p>TO</p>
                  <div className="fromFilter">
                    <Flatpickr
                      options={{
                        dateFormat: "Y-m-d",
                        defaultDate: new Date().toISOString(),

                        onChange: function (selectedDate, dateStr, instance) {
                          if (new Date(dateStr) < new Date(startDate.date)) {
                            alert(
                              "Please select a date greater than start date"
                            );
                          } else {
                            console.log(dateStr);
                            setEndDate({
                              pindate: dateStr.split("T")[0],
                              date: dateStr,
                              epoch: Math.floor(Date.parse(dateStr) / 1000),
                            });
                          }
                          console.log(startDate, endDate);
                        },
                      }}
                    />{" "}
                    <img src={calendarIcon} alt="calendar icon" />
                  </div>
                </div>
              </div>
              <button className="TextForWebURLButton" onClick={updateKeywords}>
                ANALYSE
              </button>
              {/* </div> */}
            </div>
            <button onClick={() => setAddSeo(!addSeo)}>Add New Link</button>
          </div>
          {addSeo ? (
            <div
              style={{
                position: "fixed",
                top: 0,
                left: 0,
                width: "100%",
                height: "100%",
                backgroundColor: "rgba(0, 0, 0, 0.5)",
                zIndex: 1000,
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <div
                style={{
                  backgroundColor: "white",
                  borderRadius: "8px",
                  padding: "20px",
                  width: "60%",
                  maxWidth: "700px",
                  boxShadow: "0 4px 6px rgba(0, 0, 0, 0.1)",
                  position: "relative",
                }}
              >
                <button
                  onClick={() => setAddSeo(false)}
                  style={{
                    position: "absolute",
                    top: "10px",
                    right: "10px",
                    backgroundColor: "red",
                    color: "white",
                    border: "none",
                    borderRadius: "4px",
                    padding: "5px 10px",
                    cursor: "pointer",
                  }}
                >
                  X
                </button>
                <div className="InputContainer clearfix">
                  <div className="TextForWebURL">
                    <div className="TextForWebURLTitle">
                      Enter your website{" "}
                    </div>
                    <div className="TextForWebURLInput">
                      <input
                        type="text"
                        value={userUrl}
                        onChange={(e) => {
                          setUserUrl(e.target.value);
                        }}
                        placeholder="Enter Web URL"
                      />
                      {/* <button className="TextForWebURLButton" onClick={getData}>
                  ANALYSE
                </button> */}
                    </div>
                  </div>
                  <div className="TextForWebURL">
                    <div className="TextForWebURLTitle">Keywords for Rank </div>
                    <div style={{ marginTop: "25px", width: "50%" }}>
                      <CreatableSelect
                        placeholder="Select or Enter Keywords"
                        options={(() => {
                          const allKeywords = [];

                          if (
                            metaKeywords &&
                            metaKeywords.keywords.length > 0
                          ) {
                            allKeywords.push(...metaKeywords.keywords);
                          }
                          if (
                            metaKeywords &&
                            metaKeywords.description.length > 0
                          ) {
                            allKeywords.push(...metaKeywords.description);
                          }
                          if (metaKeywords && metaKeywords.title.length > 0) {
                            allKeywords.push(...metaKeywords.title);
                          }

                          return allKeywords.map((item, i) => ({
                            value: item,
                            label: item,
                          }));
                        })()}
                        onChange={handleSelectedVendorsKeywords}
                        isMulti={true}
                        styles={{ width: "95%", marginTop: "25px" }}
                      />
                    </div>
                    Region
              <AsyncSelect
                cacheOptions
                loadOptions={loadOptions}
                onChange={handleChange}
                className="messageSelect"
                placeholder="Type to search a country..."
                isClearable
              />
                  </div>
                  <div className="TextForWebURL">
                    <div className="TextForWebURLTitle">
                      Enter your competitors website{" "}
                    </div>
                    <div className="TextForWebURLInput">
                      <input
                        type="text"
                        value={domain}
                        onChange={(e) => {
                          setDomain(e.target.value);
                        }}
                        placeholder="Enter Web URL"
                      />
                      <button onClick={addURL} style={{ marginLeft: "10px" }}>
                        +
                      </button>
                    </div>
                    <div className="URLList">
                      {urls.map((url, index) => (
                        <div
                          key={index}
                          style={{
                            display: "flex",
                            alignItems: "center",
                            marginTop: "10px",
                          }}
                        >
                          <span style={{ marginRight: "10px" }}>{url}</span>
                          <button
                            onClick={() => removeURL(index)}
                            style={{
                              cursor: "pointer",
                              color: "red",
                              border: "none",
                              backgroundColor: "white",
                            }}
                          >
                            X
                          </button>
                        </div>
                      ))}
                    </div>
                  </div>
                  <button className="TextForWebURLButton" onClick={getData}>
                    SAVE
                  </button>
                </div>
              </div>
            </div>
          ) : null}
          {data ? (
            <div className="moduleNameTitlesmall">Result for {userUrl}</div>
          ) : null}

          {data ? (
            <div style={{ padding: "20px" }} className="KeywordsSuperContainer">
              <div className="messageTopHeadText comp_anal mb-3 ml-0">
                Keywords{" "}
              </div>
              <div className="KeywordsContainer">
                {data?.data?.map((item) =>
                  item.keywords.map((value) => (
                    <div className="Keywords">
                      <div className="KeywordsText">
                        <div>{value.value}</div>
                      </div>
                      <div className="KeywordsFreq">
                        <div>{value.currentPosition}</div>
                      </div>
                    </div>
                  ))
                )}
              </div>
            </div>
          ) : null}
        </CardBody>
      </Card>
    </>
  );
}
